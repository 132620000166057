import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from "@angular/router";
import {ConstantsService} from "./constants.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class LogEventRouteGuardService implements CanActivate {

  constructor(private constantsService: ConstantsService,
              private httpClient: HttpClient) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    //console.log("ROUTELOG: " + route.url);
    const url = `${this.constantsService.constantsData.apiUrl}/users/0/logEvent`;
    const logEvent  = route.data.logEvent;

    if (logEvent && logEvent != '') {
      //console.log("LOGGING: " + logEvent);
      const data = JSON.parse(logEvent);
      const response = await this.httpClient.post(url, data).toPromise() as any;
    }

    return true;
  }

}
