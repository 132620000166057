import { Component, OnInit, Input } from '@angular/core';
import { SMCDataList } from 'src/app/model/SMCDataList';

/**
 * Shared component for pages with non-table labeled values to display.
 * Values can be strings or lists of strings.
 */
@Component({
  selector: 'smc-data-list',
  templateUrl: './smc-data-list.component.html',
  styleUrls: ['./smc-data-list.component.scss'],
})
export class SMCDataListComponent  {

  @Input() set data(value: SMCDataList) {
    if (value && value.options && value.options.hideEmptyFields) {
      this.processedData = { items: value.items.filter(item => {
        // show items if their value is an array with at least one item or a non-empty string
        if (Array.isArray(item)) {
          if (item.value.length > 1) return true;
        }
        else if (item.value) return true;
        return false;
      })};
    }
    else {
      this.processedData = value;
    }
  };

  processedData: SMCDataList;

  constructor() { }

  toArray(value: string | string[]): string[] {
    if (Array.isArray(value)) return value;
    else return [value];
  }

}
