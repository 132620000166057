import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { SmcTableData } from "src/app/model/SmcTableData";
import { TestResultsService } from "src/app/service/test-results.service";
import { Router } from "@angular/router";
import { StateService } from "src/app/service/state.service";
import { BhanalyticsService } from "../../../service/bhanalytics.service";
import { HealthRecordService } from "../../../service/health-record.service";
import { DateHandlerService } from "src/app/service/date-handler.service";
import { PrintService } from "src/app/service/print.service";
import { AwsTranslateService } from "../../../service/aws-translate.service";
import { table } from "console";
import { environment } from "src/environments/environment";
import { BhDataGridColumnDefinition } from "src/app/model/bh-grid-column-definition";
import { TestResult } from "src/app/model/TestResult";
import {
  BhTableData,
  TestResultTableFormat,
} from "src/app/model/bh-table-data";
import { ModalController } from "@ionic/angular";
import { TestResultDetailsModalComponent } from "../test-result-details-modal/test-result-details-modal.component";
import { ModalService } from "src/app/service/modal.service";

@Component({
  selector: "app-test-results",
  templateUrl: "./test-results.component.html",
  styleUrls: ["./test-results.component.scss"],
})
export class TestResultsComponent implements OnInit {
  env = environment;
  category: any;
  testResultsData: TestResult[] = [];
  data: SmcTableData;
  //data: BhTableData;
  public facilitiesList: any[];

  columnDefinitions: BhDataGridColumnDefinition[] = [
    {
      fieldName: "image",
      columnLabel: "",
      showColumn: true,
      ionIcon: "",
      ionIconColor: "warning",
      showIconArg: 1,
      useIconArg: true,
      showIconOnly: true,
      alwaysShowIcon: true,
    },
    {
      fieldName: "OrderedItemDisplay",
      columnLabel: "Test",
      ionIcon: "",
      ionIconColor: "primary",
      showIconArg: "",
      showIconOnly: false,
      alwaysShowIcon: true,
      showColumn: true,
    },
    {
      fieldName: "ResultStatus",
      columnLabel: "Result",
      ionIcon: "",
      ionIconColor: null,
      showIconArg: "",
      showIconOnly: false,
      alwaysShowIcon: false,
      showColumn: true,
    },
    {
      fieldName: "OrderedBy",
      columnLabel: "Ordered By",
      ionIcon: "",
      ionIconColor: null,
      showIconArg: "",
      showIconOnly: false,
      alwaysShowIcon: false,
      showColumn: false,
    },
    {
      fieldName: "Date",
      columnLabel: "Date",
      ionIcon: "",
      ionIconColor: null,
      showIconArg: "",
      showIconOnly: false,
      alwaysShowIcon: false,
      showColumn: true,
    },
    {
      fieldName: "image",
      columnLabel: "",
      showColumn: true,
      ionIcon: "",
      ionIconColor: "warning",
      showIconArg: 1,
      useIconArg: true,
      showIconOnly: true,
      alwaysShowIcon: true,
    },
  ];

  @ViewChild("iconColumnTemplate") iconColumnTemplate: ElementRef;
  @ViewChild("dateColumnTemplate") dateColumnTemplate: ElementRef;
  @ViewChild("iconSelectableTemplate") iconSelectableTemplate: ElementRef;
  @ViewChild("printSection") printSection: ElementRef;

  constructor(
    private _healthRecordService: HealthRecordService,
    private _testResultsService: TestResultsService,
    private _router: Router,
    private _bhAnalyticsService: BhanalyticsService,
    private _dateHandler: DateHandlerService,
    private _printService: PrintService,
    private _changeDetectorRef: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private modalService: ModalService,
    public AWSTranslate: AwsTranslateService,
  ) {}

  async ngOnInit() {
    const category = this.env.banner_seq.test_result;
    console.log("cat", category);
    this.category = category;
    this.loadData();
    const testRadResults = this._testResultsService.getTestResults();
    //console.log('test Rad Results', testRadResults);
  }

  async loadData(filter?: Date | null) {
    const [rows, lastLoginDate, facilitiesList] = await Promise.all([
      this._testResultsService.getTestResults(),
      this._testResultsService.getLastLoginDate(),
      this._healthRecordService.getFacilitiesPhoneNumber(),
    ]);
    this.facilitiesList = facilitiesList.providers;
    const tableData: SmcTableData = {
      columns: [
        {
          name: "",
          prop: "OrderCategory",
          cellTemplate: this.iconColumnTemplate,
          headerClass: "header-icon",
          cellClass: "cell-icon",
          collapseCol: 0,
        },
        {
          name: "Test",
          prop: "OrderedItemDisplay",
          maxWidth: 2000,
          cellClass: "card-bold",
          collapseCol: 1,
          collapseMinWidth: 200,
        },
        {
          name: "Result status",
          prop: "ResultStatus",
          maxWidth: 2000,
          cellClass: "card-detail",
          collapseCol: 1,
          collapseMinWidth: 200,
        },
        {
          name: "Ordered by",
          prop: "OrderedBy",
          maxWidth: 2000,
          cellClass: "card-detail",
          collapseCol: 1,
          collapseMinWidth: 200,
        },
        {
          name: "Date",
          prop: "Date",
          cellTemplate: this.dateColumnTemplate,
          maxWidth: 120,
          cellClass: "card-detail",
          collapseCol: 1,
          collapseMinWidth: 200,
        },
        {
          name: "Location",
          prop: "LocationFromMultiple",
          maxWidth: 2000,
          cellClass: "card-detail",
          collapseCol: 1,
          collapseMinWidth: 200,
        },
        // { name: 'Provider Number', prop: 'ProviderPhone', cellClass: 'card-detail' },
        {
          name: "",
          cellTemplate: this.iconSelectableTemplate,
          maxWidth: 60,
          cellClass: "select-icon",
          collapseCol: 2,
        },
      ],
      rows: rows.sort((a, b) => (b.Date > a.Date ? 1 : -1)),
    };

    // mark test results as unread if they are dated later than the last login date,
    // or if the user has never logged in before mark all as unread
    tableData.rows = tableData.rows.map((row) => {
      const testResultDate = new Date(row.Date);
      const location =
        row.EnteredAt ||
        row.EnteredBy ||
        row.EnteringOrganization ||
        row.PerformedAt;
      return Object.assign(row, {
        unread:
          lastLoginDate == null ||
          testResultDate.getTime() > lastLoginDate.getTime(),
        LocationFromMultiple: location,
        ProviderPhone: this.getFilteredFacilityNumber(location.toLowerCase()),
      });
    });
    if (filter && filter != null) {
      tableData.rows = tableData.rows.filter((a: any) =>
        this._dateHandler.parseDateString(a.Date).isSameDay(filter)
      );
    }

    tableData.rows = tableData.rows.sort((a,b) => {
      if (a.Date === b.Date) {
          if (a.OrderedItemDisplay === b.OrderedItemDisplay) {
              return (a.EnteringOrganization > b.EnteringOrganization) ? -1 : (a.EnteringOrganization > b.EnteringOrganization) ? 1 : 0;
          } else {
              return (a.OrderedItemDisplay > b.OrderedItemDisplay) ? -1 : 1;
          }
      } else {
          return (a.Date > b.Date) ? -1 : 1;
      }
    });

    tableData.rows = tableData.rows.filter(
      (result, index) =>
        index ===
        tableData.rows.findIndex(
          (otherResult) =>
            result.OrderedItemDisplay === otherResult.OrderedItemDisplay &&
            result.Date === otherResult.Date
        )
    );

    this.data = tableData;
	this._changeDetectorRef.detectChanges();
   	 tableData.rows.filter( (a: any) => {
   	 })

    console.log("table data", this.data);

    this._changeDetectorRef.detectChanges();

    // this.data.columns.forEach( (col: any) => {
    //   console.log('col', col.prop);
    //   if(col.prop !== undefined) {
    //     this.data.testResult.forEach(tr => {
    //       tr.orderedItemDisplay = col.prop
    //     });
    //     const testData: TestResultTableFormat = {
    //       orderedItemDisplay: col.prop
    //     }
    //   }
    // });
    //console.log('columns =>', this.data.columns);

    // this.data.rows.forEach( (row: any) => {
    //     const testData: TestResultTableFormat = {
    //       resultStatus: row.ResultStatus,
    //       orderedBy: row.OrderedBy,
    //       date: row.Date
    //     }
    //     this.data.testResult.push(testData);
    // });

    //console.log('rows =>', this.data.rows);

    //console.log('test results table data =>', this.data.testResult);
  }

  // setTestResults(rows, columns) {
  //     data.columns.forEach(d => {
  //         d.
  //     });
  // }

  async openDetails(data) {
    this.setParamData(data.selected[0], "testResult");
    const modal = await this.modalCtrl.create({
      component: TestResultDetailsModalComponent,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        data: data.selected[0],
        tableData: this.data,
      },
    });
    modal.onDidDismiss().then(async (data) => {});
    await modal.present();

    if (data.selected[0].OrderCategory != "RAD") {
      const testResultHistory = [].concat.apply([], this.data.rows.filter(r => r['Code'] == data.selected[0].Code)
      .map(x => x.ResultItems && x.ResultItems.length > 0 ? x.ResultItems : '')).map(({Test,ObservationTime,ObservationTimeDisplay,ResultValue,LocationFromMultiple}) =>
      ({Test,ObservationTime,ObservationTimeDisplay,ResultValue,LocationFromMultiple})
      );

      this.setParamData(testResultHistory, 'testResultHistory');

      // this._router.navigate(['authenticated', 'health-record', 'test-results', 'test-result-details', encodeURIComponent(data.selected[0].OrderItem.toLowerCase().split(' ').join('-'))]);
    } else {
      // this._router.navigate(['authenticated', 'health-record', 'test-results', 'x-ray-results', encodeURIComponent(data.selected[0].OrderItem.toLowerCase().split(' ').join('-'))]);
    }
  }

  setParamData(data, storeParamName) {
    localStorage.setItem(storeParamName, JSON.stringify(data));
  }

  print() {
    // const innerHTMLContent = this.printSection.nativeElement.innerHTML;
    // this._printService.printHtmlString(innerHTMLContent);
    this._bhAnalyticsService.clickEvent("Print Test Results", "");
    window.print();
  }

  // Return facilities phone number if locations match.
  getFilteredFacilityNumber(locationStr: string) {
    const locationName = locationStr
      .toLowerCase()
      .replace(/[^a-zA-Z0-9] | \s+ /g, "");
    const filteredFacility = this.facilitiesList.filter((facilityNumber) => {
      const facilityName = facilityNumber.name
        .toLowerCase()
        .replace(/[^a-zA-Z0-9] | \s+ /g, "");
      const facilityCode = facilityNumber.code
        .toLowerCase()
        .replace(/[^a-zA-Z0-9] | \s+ /g, "");
      const namesMatch =
        locationName.match(facilityName) ||
        locationName
          .split(" ")
          .join("")
          .indexOf(facilityName.split(" ").join("")) > -1;
      const codeMatchesName = facilityCode.match(locationName);
      return codeMatchesName || namesMatch;
    });
    return filteredFacility[0] ? filteredFacility[0].phone : "";
  }
}


