import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidateNewAccountRequest } from "../../../model/ValidateNewAccountRequest";
import { ActivateAccountService } from 'src/app/service/activate-account.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import { TermsOfUse } from 'src/app/model/TermsOfUse';
import { TermsOfUseService } from 'src/app/service/terms-of-use.service';
import {IonButton, IonCheckbox, IonContent} from '@ionic/angular';
import { PasswordRequirements } from 'src/app/model/PasswordRequirements';
import { ActivateAccountRequest } from 'src/app/model/ActivateAccountRequest';
import { formatDate } from '@angular/common';
import { ModalService } from 'src/app/service/modal.service';
import { ModalType } from 'src/app/model/ModalTypeEnum';
import { BhanalyticsService } from "../../../service/bhanalytics.service";
import { MatDatepicker } from '@angular/material/datepicker';
import { PasswordChangeRequest, PasswordChangeFormComponent } from '../../shared/password-change-form/password-change-form.component';
import { AwsTranslateService } from '../../../service/aws-translate.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss'],
})
export class ActivateAccountComponent {

  @ViewChild('iReadIt') private iReadItComponent: IonCheckbox;
  @ViewChild('iAgree') private iAgreeComponent: IonButton;
  @ViewChild('password') password;
  @ViewChild('newPassword') newPassword;
  @ViewChild('newPasswordConfirm') newPasswordConfirm;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('passwordChangeForm') private set passwordChangeFormSetter(component: PasswordChangeFormComponent) {
    this.passwordChangeFormComponent = component;
  }

  currentStep: 0 | 1 | 2 | 3;
  stepTitles = [
    'activate',
    'review-privacy-and-terms-of-use',
    'choose-credentials'
  ];
  accessTokenForm: FormGroup;
  createUsernameAndPasswordForm: FormGroup;
  validateAccountRequest: ValidateNewAccountRequest;
  termsOfUse: TermsOfUse;
  acceptButtonEnabled: boolean;
  activateAccountRequest: ActivateAccountRequest = {};

  private passwordFormData: PasswordChangeRequest;
  private passwordChangeFormComponent: PasswordChangeFormComponent;

  constructor(private activateAccountService: ActivateAccountService,
              private router: Router,
              private termsService: TermsOfUseService,
              private modalService: ModalService,
              private route: ActivatedRoute,
              private _bhAnalyticsService: BhanalyticsService,
    private _activatedRoute: ActivatedRoute,
    private AWSTranslate: AwsTranslateService
  ) {
    this.accessTokenForm = new FormGroup({
      Token: new FormControl('', Validators.required),
      DateOfBirth: new FormControl('', Validators.required),
      Passphrase: new FormControl('', Validators.required),
    });

    this.createUsernameAndPasswordForm = new FormGroup({
      Username: new FormControl('', Validators.required)
    });
  }

  async ionViewWillEnter() {
    let token = this.route.snapshot.queryParamMap.get("TOKEN");
    if (token) {
      this.accessTokenForm.controls['Token'].setValue(token);
    }
    this.validateAccountRequest = {};
    this.acceptButtonEnabled = false;
    this.onAccessTokenFormChange();
    this.onCreateUsernameAndPasswordFormChange();
    this.currentStep = 0;
    this.termsOfUse = await (this.termsService.getTermsOfUse());
    this.iAgreeComponent.disabled = true;
    this.iReadItComponent.checked = false;
  }

  ionViewWillLeave() {
    // clear fields
    if (this.passwordChangeFormComponent) this.passwordChangeFormComponent.clear();
    this.accessTokenForm.reset();
    this.createUsernameAndPasswordForm.reset();
  }

  cancel(): void {

    this.router.navigate(["/login"]);
  }

  async submitAccessToken(): Promise<void> {
    const passPhrase = this.validateAccountRequest['Passphrase'];
    this.validateAccountRequest['Passphrase'] = '';
    this.validateAccountRequest['RegistrationField1'] = '';
    this.validateAccountRequest['RegistrationField2'] = '';
    const formattedDate = formatDate(this.validateAccountRequest.DateOfBirth, 'yyyy-MM-dd', 'en-US');
    this.validateAccountRequest.DateOfBirth = formattedDate;
    const response = await (this.activateAccountService.submitAccessToken(this.validateAccountRequest))
    if (response) {
      this.validateAccountRequest['Passphrase'] = passPhrase;
      const passphraseValidationSuccessful = await this.validatePassphrase();
      this._bhAnalyticsService.clickEvent('Submit Access Token', '');
      if (passphraseValidationSuccessful) this.nextStep();
    }
  }

  onAccessTokenFormChange(): void {
    this.accessTokenForm.valueChanges.subscribe(val => {
      Object.keys(val).forEach(key => {
        this.validateAccountRequest[key] = val[key];
      })
    })
  }


  onCreateUsernameAndPasswordFormChange(): void {
    this.createUsernameAndPasswordForm.valueChanges.subscribe(val => {
      this.activateAccountRequest.Username = val.Username;
    })
  }

  accessTokenFormComplete(): boolean {
    return !!(
      this.validateAccountRequest.DateOfBirth &&
      this.validateAccountRequest.Token && this.validateAccountRequest.Passphrase
    )
  }

  /** Returns a boolean indicating whether the passphrase was successfully validated */
  async validatePassphrase(): Promise<boolean> {
    this.validateAccountRequest.Passphrase = this.accessTokenForm.value.Passphrase;
    const response = await (this.activateAccountService.validateStagedUser(this.validateAccountRequest));
    return response != undefined;
  }

  async createUsernameAndPassword() {
    this.activateAccountRequest.TermsAccepted = this.termsOfUse;
    const response = await (this.activateAccountService.activateAccount(this.activateAccountRequest));
    if (response) {
      this.modalService.open("Account Activated", "Sign in with your username and password to access your health information and helpful resources.", ModalType.BASIC);
      this.router.navigate(["/login"]);
    }
    this._bhAnalyticsService.clickEvent('Create Username/Password', this.activateAccountRequest.Username);
  }

  async checkScrollHeight(event) {
    let el = await (this.content.getScrollElement())
    if ((el.scrollTop + el.offsetHeight) >= el.scrollHeight) {
      this.acceptButtonEnabled = true;
    }
  }

  createUsernameAndPasswordFormComplete(): boolean {
    return this.createUsernameAndPasswordForm.valid && 
           this.passwordFormData && this.passwordFormData.isValid;
  }

  nextStep(): void {
    this.currentStep++;
    this.router.navigate(['..', this.stepTitles[this.currentStep]], {relativeTo: this._activatedRoute});
  }

  checkPasswordFormStatus(event: PasswordChangeRequest) {
    this.passwordFormData = event;
    this.activateAccountRequest.Password = event.newPassword;
    this.activateAccountRequest.passwordConfirm = event.newPassword; // confirmation field has already been validated at this point
  }

  toggleIReadIt() {
    if (this.iReadItComponent.checked) {
      this.iAgreeComponent.disabled = false;
    } else {
      this.iAgreeComponent.disabled = true;
    }
  }
}
