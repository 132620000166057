import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConstantsService } from 'src/app/service/constants.service';
import { AwsTranslateService } from 'src/app/service/aws-translate.service';

@Component({
  selector: 'app-find-provider-modal',
  templateUrl: './find-provider-modal.component.html',
  styleUrls: ['./find-provider-modal.component.scss'],
})
export class FindProviderModalComponent implements OnInit {

  constructor(
    public modalController: ModalController,
    public constantsService: ConstantsService,
    public AWSTranslate: AwsTranslateService
  ) { }

  ngOnInit() {}

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
