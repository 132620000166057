import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-pci-lab-info',
  templateUrl: './pci-lab-info.page.html',
  styleUrls: ['./pci-lab-info.page.scss'],
})
export class PciLabInfoPage implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
  ) { }

  ngOnInit() {
  }

  back() {
    this.navCtrl.navigateRoot('/authenticated/resources');
    this.modalCtrl.dismiss();
  }
  dismiss() {
    this.navCtrl.navigateRoot('/authenticated/resources');
    this.modalCtrl.dismiss();
  }


  navigateToLabCorp() {
    this.navCtrl.navigateRoot('/authenticated/resources');
    this.modalCtrl.dismiss();
    const url = 'https://www.Labcorp.com/labs-and-appointments';
    window.open(url, '_blank');
  }
}
