import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  NgZone,
  ApplicationRef,
  ChangeDetectorRef,
  Input,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { StateService } from "src/app/service/state.service";
import { TestResult } from "src/app/model/TestResult";
import { HealthRecordService } from "../../../service/health-record.service";
import { TestResultsService } from "src/app/service/test-results.service";
import { DatePipe } from "@angular/common";
import { SmcTableData } from "src/app/model/SmcTableData";
import { SMCDataList } from "src/app/model/SMCDataList";
import { Options } from "ng5-slider";
import { ModalController } from "@ionic/angular";
// import { PastResultsModalComponent } from './past-results-modal/past-results-modal.component';
import { BhanalyticsService } from "../../../service/bhanalytics.service";
import { ModalService } from "src/app/service/modal.service";
import { PrintService } from "src/app/service/print.service";
import { AwsTranslateService } from "src/app/service/aws-translate.service";
import { MessagesService } from "src/app/service/messages.service";
import { ComponentOfTestResult } from "src/app/model/ComponentOfTestResult";
import { ThrowStmt } from "@angular/compiler";
import { LoginTermsAndConditionsModalComponent } from "../login-terms-and-conditions-modal/login-terms-and-conditions-modal.component";
import { ResultHistoryComponent } from "../../shared/result-history/result-history.component";
import { RadImageInstructionalVideoModalComponent } from "../image-test-result/rad-image-instructional-video-modal/rad-image-instructional-video-modal.component";
import { ToastService } from "src/app/service/toast.service";
import { log } from "console";
import { NewMessagePage } from "../messages/new-message/new-message.page";

interface PrintableTableData {
  columns: string[];
  rows: Array<string[]>;
}

@Component({
  selector: "app-test-result-details-modal",
  templateUrl: "./test-result-details-modal.component.html",
  styleUrls: ["./test-result-details-modal.component.scss"],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestResultDetailsModalComponent implements OnInit, OnDestroy {
  @Input() data;
  @Input() tableData: SmcTableData;
  @Input() source: 'default' | 'new-message' = 'default';
  @ViewChild("componentSection") componentSection: ElementRef;
  public TestResult: TestResult;
  //public data: SmcTableData;
  lastCode: string;
  public historyHasStrings: boolean;
  public currentLocation: string;
  showTestResults: boolean = false;
  facilitiesList: any[];
  testResultData: SMCDataList = null;
  printableTableData: PrintableTableData;
  resultComponent: ComponentOfTestResult;
  orderedBy: string;
  mainTest: string;
  referenceRangeMin: number;
  referenceRangeMax: number;
  rangeOptions: Options;
  details: SMCDataList;
  subTests: any[] = [];
  colorInRange: string = "#72B342";
  colorNotInRange: string = "#c85e5e";
  viewImageLink;
  imagePin;
  isMobile = false;

  private resultHistoryElement: ResultHistoryComponent;

  @ViewChild("rowDetailTemplate") rowDetailTemplate: ElementRef;
  @ViewChild("resultValueTemplate") resultValueTemplate: ElementRef;
  @ViewChild("iconSelectableTemplate") iconSelectableTemplate: ElementRef;
  @ViewChild("printSection") printSection: ElementRef;
  @ViewChild("labPrintSection") labPrintSection: ElementRef;
  @ViewChild("labComponentPrintSection") labComponentPrintSection: ElementRef;
  @ViewChild('resultHistory') private set resultHistorySetter(ref) {
    this.resultHistoryElement = ref;
  }
  // options for the chart
  view: any[] = [1200, 200];

  colorScheme = {
    domain: ["#55b5e6"],
  };

  // line, area
  autoScale = true;
  currentView:
    | "loading"
    | "lab-test-result"
    | "lab-component-result"
    | "imaging-result"
    | "view-image" = "loading";

  currentForm: 'list' | 'grid' = 'list';

  constructor(
    private modalCtrl: ModalController,
    private bhAnalyticsService: BhanalyticsService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _testResultsService: TestResultsService,
    private _healthRecordService: HealthRecordService,
    private _bhAnalyticsService: BhanalyticsService,
    private zone: NgZone,
    private applicationRef: ApplicationRef,
    private _datePipe: DatePipe,
    private modalService: ModalService,
    private _printService: PrintService,
    private changeDetectorRef: ChangeDetectorRef,
    public AWSTranslate: AwsTranslateService,
    private messageService: MessagesService,
    private _el: ElementRef,
    private modalController: ModalController,
    private _toastService: ToastService,
  ) { }



  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = event.target.innerWidth;
    this.checkViewportSize(width);
  }


  ngOnInit() {
    this.filterTableData();
    this.toggleMedChatFab(true);
    const innerWidth = window.innerWidth;
    this.checkViewportSize(innerWidth);
    console.log('table data', this.tableData);

    if (this.data) {
      console.log('data', this.data);
      this.getLabResults();
      this.getComponentData();
      this.orderedBy = this.data.OrderedBy;
      this.mainTest = this.resultComponent.Test;

      if (this.data.CustomPairs !== undefined) {
        this.viewImageLink = this.getCustomPairValue("eHGTURL", this.data.CustomPairs);
        this.imagePin = this.getCustomPairValue("eHGTPIN", this.data.CustomPairs);

        console.log('view Image link', this.viewImageLink);
        console.log('Image pin', this.imagePin);

      }
    }
  }

  ngOnDestroy() {
    this.toggleMedChatFab(false);
  }

  toggleMedChatFab(hide: boolean) {
    const medChatiFrame = document.getElementById('medchat-launcher-frame') as HTMLElement;
    const medChatBubbleiFrame = document.getElementById('medchat-chat-frame') as HTMLElement;
    console.log('toggleMedChatFab', medChatiFrame, medChatBubbleiFrame);
    medChatiFrame.style.display = hide ? 'none' : 'block';
    medChatBubbleiFrame.style.display = hide ? 'none' : 'block';
  }

  checkViewportSize(width) {
    if (width > 1024) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    console.log('width', width, 'isMobile', this.isMobile);
  }

  getCustomPairValue(name, customPairs) {
    let pair = customPairs.find(pair => pair.Name == name);
    if (pair) {
      return pair.Value;
    }
    return '';
  }


  dismiss() {
    this.modalCtrl.dismiss();
  }

  viewImages() {
    this.currentView = 'imaging-result';
  }

  changeSegment(val) {
    console.log('changeSegment: ', val);
    this.currentForm = val.detail.value;
    this.bhAnalyticsService.clickEvent('test-result-modal: switch-view', this.currentForm);
  }

  filterTableData() {
    //
    this.tableData = new SmcTableData();
    this.tableData.columns = [
      { name: 'Component Test', prop: 'Test', cellClass: 'card-bold', collapseCol: 0, collapseMinWidth: 200 },
      { name: 'Result', prop: 'ResultValue', cellClass: this._testResultsService.getResultClass, cellTemplate: this.resultValueTemplate, collapseCol: 0, collapseMinWidth: 200 },
      { name: 'Interpretation', prop: 'ResultInterpretation', cellClass: this._testResultsService.getResultClass, collapseCol: 0, collapseMinWidth: 200 },
      { name: 'Normal Range', prop: 'ReferenceRange', cellClass: 'card-detail', collapseCol: 0, collapseMinWidth: 200 },
      { name: "", cellTemplate: this.iconSelectableTemplate, maxWidth: 3000, cellClass: 'select-icon', collapseCol: 1 }
    ];

    this.tableData.rows = this.data.ResultItems ? this.data.ResultItems : this.data;

    //this.tableData.rows = this.tableData.rows.filter(r => r['Code'] == this.data.Code);

    // if (this.TestResult.ResultItems === undefined) {
    //   this.TestResult.ResultItems = null;
    // }

    // this.tableData.rows = this.tableData[0].ResultItems;
    // if (this.tableData.rows) {
    //   // construct data fir print-friendly table
    //   this.printableTableData = {
    //     columns: this.tableData.columns.map(column => column.name),
    //     rows: (() => {
    //       const rows = [];
    //       this.tableData.rows.forEach((row, index) => {
    //         const arrayRow = [];
    //         this.tableData.columns.forEach(column => {
    //           arrayRow.push(row[column.prop]);
    //         });
    //         rows.push(arrayRow);
    //       });
    //       return rows;
    //     })()
    //   };
    // }
  }

  async getLabResults() {
    this.currentView = "lab-test-result";
    await this.getSubTests();
  }

  getResultDetails() {
    this.currentView = "loading";
  }

  getSubTests() {
    // this.subTest = this.data.ResultItems ? [].concat.apply(
    //   [],
    //   this.data.ResultItems.map(
    //     ({
    //       Test,
    //       ObservationTime,
    //       ObservationTimeDisplay,
    //       ResultValue,
    //       LocationFromMultiple,
    //       ReferenceRange,
    //       ResultValueUnits,
    //       Comments,
    //       ObservationValueType,
    //     }) => ({
    //       Test,
    //       ObservationTime,
    //       ObservationTimeDisplay,
    //       ResultValue,
    //       LocationFromMultiple,
    //       ReferenceRange,
    //       ResultValueUnits,
    //       Comments,
    //       ObservationValueType,
    //     })
    //   )
    // ) : [];
    this.subTests = this.data.ResultItems;
    console.log("sub test =>", this.subTests);
  }

  async loadProviderData() {
    const facilitiesList =
      await this._healthRecordService.getFacilitiesPhoneNumber();
    this.facilitiesList = facilitiesList.providers;

    this.currentLocation =
      this.TestResult.EnteredAt ||
      this.TestResult.EnteredBy ||
      this.TestResult.EnteringOrganization ||
      this.TestResult.PerformedAt;

    this.testResultData = {
      options: {
        hideEmptyFields: false,
      },
      items: [
        { label: "Ordered by", value: [this.TestResult.OrderedBy] },
        {
          label: "Test date",
          value: [
            this._datePipe.transform(this.TestResult.Date, "MMM dd, yyyy"),
          ],
        },
        { label: "Test location", value: [this.currentLocation] },
        //  { label: 'Have Questions?', value: [this.getFilteredFacilityNumber(this.currentLocation.toLowerCase())] }
      ],
    };
    if (
      this.TestResult.ResultItems === undefined ||
      this.TestResult.ResultItems == null
    ) {
      this.changeDetectorRef.detectChanges();
    }
  }

  loadData() {
    this.data = new SmcTableData();
    this.data.columns = [
      {
        name: "Component Test",
        prop: "Test",
        cellClass: "card-bold",
        collapseCol: 0,
        collapseMinWidth: 200,
      },
      {
        name: "Result",
        prop: "ResultValue",
        cellClass: this._testResultsService.getResultClass,
        cellTemplate: this.resultValueTemplate,
        collapseCol: 0,
        collapseMinWidth: 200,
      },
      {
        name: "Interpretation",
        prop: "ResultInterpretation",
        cellClass: this._testResultsService.getResultClass,
        collapseCol: 0,
        collapseMinWidth: 200,
      },
      {
        name: "Normal Range",
        prop: "ReferenceRange",
        cellClass: "card-detail",
        collapseCol: 0,
        collapseMinWidth: 200,
      },
      {
        name: "",
        cellTemplate: this.iconSelectableTemplate,
        maxWidth: 3000,
        cellClass: "select-icon",
        collapseCol: 1,
      },
    ];

    if (this.TestResult.ResultItems === undefined) {
      this.TestResult.ResultItems = null;
    }

    this.data.rows = this.TestResult.ResultItems;
    if (this.data.rows) {
      // construct data fir print-friendly table
      this.printableTableData = {
        columns: this.data.columns.map((column) => column.name),
        rows: (() => {
          const rows = [];
          this.data.rows.forEach((row, index) => {
            const arrayRow = [];
            this.data.columns.forEach((column) => {
              arrayRow.push(row[column.prop]);
            });
            rows.push(arrayRow);
          });
          return rows;
        })(),
      };
    }
  }

  getParamData(data, storeParamName) {
    let value;
    if (data) {
      value = data;

      localStorage.setItem(storeParamName, JSON.stringify(value));
    } else {
      value = JSON.parse(localStorage.getItem(storeParamName));
    }
    return value;
  }

  ionViewWillLeave() {
    this.TestResult = null;
    this.data.rows = [];
  }

  async askProvider(data) {
    if (this.source === 'new-message') {
      this.modalCtrl.dismiss({ isSelected: true });
    } else {
      const modal = await this.modalCtrl.create({
        component: NewMessagePage,
        cssClass: "wide-modal",
        backdropDismiss: false,
        componentProps: {
          mode: 'test-result',
          testResult: data
        },
      });
      modal.onDidDismiss().then(async (data) => {

      });
      await modal.present();

    }
  }

  // getMinValue(referenceRange) {
  //   let minRange = 0;
  //   if (referenceRange.indexOf("-") > -1) {
  //     minRange = referenceRange.split("-")[0];
  //   } else {
  //     if (referenceRange.indexOf(">") > -1) {
  //       minRange = referenceRange.split(">")[1];
  //     }
  //   }
  //   return Number(minRange);
  // }

  // getMaxValue(referenceRange) {
  //   let maxRange = 1000;
  //   if (referenceRange.indexOf("-") > -1) {
  //     maxRange = referenceRange.split("-")[1];
  //   } else {
  //     if (referenceRange.indexOf("<") > -1) {
  //       return referenceRange.split("<")[1];
  //     }
  //   }
  //   return Number(maxRange);
  // }

  // Return facilities phone number if locations match.
  getFilteredFacilityNumber(locationStr: string) {
    const locationName = locationStr
      .toLowerCase()
      .replace(/[^a-zA-Z0-9] | \s+ /g, "");
    const filteredFacility = this.facilitiesList.filter((facilityNumber) => {
      const facilityName = facilityNumber.name
        .toLowerCase()
        .replace(/[^a-zA-Z0-9] | \s+ /g, "");
      const facilityCode = facilityNumber.code
        .toLowerCase()
        .replace(/[^a-zA-Z0-9] | \s+ /g, "");
      const namesMatch =
        locationName.match(facilityName) ||
        locationName
          .split(" ")
          .join("")
          .indexOf(facilityName.split(" ").join("")) > -1;
      const codeMatchesName = facilityCode.match(locationName);
      return codeMatchesName || namesMatch;
    });
    return filteredFacility[0] ? filteredFacility[0].phone : "";
  }
  async print() {
    const printContent = document.getElementById("lab-print-section").innerHTML;
    this._printService.printHtmlString(printContent, this.isMobile);
    this._bhAnalyticsService.clickEvent("Print Test Result Detail", "");
    // window.print();
  }

  // printSingleLab() {
  //   const printContent = document.getElementById("lab-print-section").innerHTML;
  //   //console.log('print content', printContent);

  //   this._printService.printHtmlString(printContent);
  //   // window.print();
  // }
  printLabComponent() {
   const printContent = document.getElementById("component-print-section").innerHTML;
   console.log('print Content', printContent);

    this._printService.printHtmlString(printContent, this.isMobile);
  }

  yFormat = (e) => {
    return e;
  };

  selectComponent(component) {
    console.log('chosen component', component);

    const selected = component["selected"][0];
    let selectedTest = selected.Test;

    if (typeof selected.Test !== "string") {
      selectedTest = selected.Test.toString();
    }

    selected.Date = this.TestResult.Date;

    selectedTest = encodeURIComponent(selectedTest);
    // this._router.navigate([selectedTest], {
    //   relativeTo: this._route,
    //   state: {
    //     data: selected,
    //     orderedBy: this.TestResult.OrderedBy,
    //     mainTest: this.TestResult.OrderedItemDisplay,
    //   },
    // });
  }

  //
  //component-details-functions
  //

  getComponentData() {
    this.resultComponent = this.data.ResultItems ? this.data.ResultItems[0] : this.data;
    console.log("result component", this.resultComponent);
    //
  }

  viewComponentDetails(compTest) {
    if ('selected' in compTest && Array.isArray(compTest.selected)) {
      compTest = compTest.selected[0];
    }
    this.resultComponent = compTest;
    // this.resultComponent.Date = this.TestResult.Date;
    console.log('viewComponentDetails', compTest, this.TestResult);
    if (this.resultComponent.ReferenceRange !== undefined) {
      this.referenceRangeMin = this.getMinValue(
        this.resultComponent.ReferenceRange
      );
      this.referenceRangeMax = this.getMaxValue(
        this.resultComponent.ReferenceRange
      );
      this.rangeOptions = this.getOptions(this.resultComponent);
      this.details = this.getDetailsData(this.resultComponent);

      if (
        this.resultComponent.ResultInterpretation.indexOf("High") > -1 ||
        this.resultComponent.ResultInterpretation.indexOf("Low") > -1
      ) {
        this._el.nativeElement.style.setProperty(
          "--test-result-component-color",
          this.colorNotInRange
        );
      } else {
        this._el.nativeElement.style.setProperty(
          "--test-result-component-color",
          this.colorInRange
        );
      }
    }
    this.currentView = "lab-component-result";
    setTimeout(() => {
      document.getElementById('component-print-section').scrollIntoView({ behavior: 'smooth' });
    }, 250);
  }

  private getMinValue(referenceRange: string) {
    let minRange = 0;
    if (referenceRange.indexOf("-") > -1) {
      minRange = parseFloat(referenceRange.split("-")[0].replace(/[(]/g, ""));
    } else {
      if (referenceRange.indexOf(">") > -1) {
        minRange = parseFloat(referenceRange.split(">")[1].replace(/[(]/g, ""));
      }
    }
    return minRange;
  }

  private showSlider() {
    console.log('NEW: resultComponent', this.resultComponent);
    if (this.resultComponent.ResultValue != null) {
      let resultString: String = this.resultComponent.ResultValue.toString();
      try {
        if (resultString.match(/\d+/g) == null) {
          return false;
        }
      } catch (e) { }
    }
    return true;
  }

  private getMaxValue(referenceRange: string) {
    let maxRange = 1000;
    if (referenceRange.indexOf("-") > -1) {
      maxRange = parseFloat(referenceRange.split("-")[1].replace(/[)]/g, ""));
    } else {
      if (referenceRange.indexOf("<") > -1) {
        return parseFloat(referenceRange.split("<")[1].replace(/[)]/g, ""));
      }
    }
    return maxRange;
  }

  private getOptions(resultComponent) {
    let value = Number(resultComponent.ResultValue);
    if (resultComponent.ResultValueUnits == "%") {
      return {
        floor: 0,
        ceil: 100,
        enforceRange: false,
        enforceStep: false,
        showTicks: false,
        showTicksValues: false,
        readOnly: true,
        ticksArray: [value],
        getLegend: (value: number): string => {
          return (
            "<b>" + value + " " + resultComponent.ResultValueUnits + "</b>"
          );
        },
      };
    }
    let minRange = this.getMinValue(resultComponent.ReferenceRange);
    minRange = minRange - minRange * 0.25;
    if (minRange < 0) {
      minRange = 0;
    }

    let maxRange = this.getMaxValue(resultComponent.ReferenceRange);
    maxRange = maxRange * 1.25;

    let options: Options = {
      floor: value < minRange ? value * 0.9 : minRange,
      ceil: maxRange < value ? value * 1.02 : maxRange,
      enforceRange: false,
      enforceStep: false,
      showTicks: false,
      showTicksValues: false,
      readOnly: true,
      ticksArray: [value],
      getLegend: (value: number): string => {
        return "<b>" + value + " " + resultComponent.ResultValueUnits + "</b>";
      },
    };

    return options;
  }

  private getDetailsData(resultComponent: ComponentOfTestResult): SMCDataList {
    //let cssClass = resultComponent.ResultInterpretation == 'High' || resultComponent.ResultInterpretation == 'Low' ? 'test-result-not-in-range' : 'test-result-in-range';
    let cssClass = this._testResultsService.getResultClass({
      column: { prop: "ResultValue" },
      row: resultComponent,
    });
    let data = {
      options: {
        hideEmptyFields: false,
      },
      items: [
        { label: "Ordered by", value: this.orderedBy },
        { label: "Main Test", value: this.mainTest },
        {
          label: "Test date",
          value: [
            this._datePipe.transform(resultComponent.ObservationTime, "MMM dd, yyyy"),
          ],
        },
        {
          label: "Test location",
          value: [
            resultComponent.EnteredAt ||
            resultComponent.EnteredBy ||
            resultComponent.PerformedAt,
          ],
        },
        { label: "Component Test", value: [resultComponent.Test] },
        {
          label: "Result",
          value: [
            resultComponent.ResultValue +
            " " +
            resultComponent.ResultValueUnits,
          ],
          cellTemplate: this.resultValueTemplate,
          cellClass: this._testResultsService.getResultClass,
          cssClass: cssClass,
        },
        { label: "Normal range", value: resultComponent.ReferenceRange },
      ],
    };
    return data;
  }

  back() {
    this.currentView = 'lab-test-result';
  }


  //Images view

  async openVideo() {
    this._bhAnalyticsService.clickEvent('Open Rad Image Video', '');
    const modal = await this.modalController.create({
      component: RadImageInstructionalVideoModalComponent,
      cssClass: "custom-rad-modal"
    });
    this.modalService.register(modal);
    return await modal.present();
  }

  openHelpPage() {
    this._router.navigate(["help"]);
    this.dismiss();
  }

  async copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    //    const toastController = this.injector.get(ToastController);
    //   let toast =await toastController.create({message:'PIN copied to the clipboard',duration:3000});
    this._toastService.openToast({
      message: 'PIN copied to the clipboard',
      position: 'top',
      header: "",
      buttons: null,
      cssClass: 'success-toast',
      color: 'tertiary',
      duration: 3000
    });

    //toast.present();
  }

}
