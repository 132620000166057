import { Component, OnInit } from '@angular/core';
import {Workflow} from "../../../model/Workflow";

@Component({
  selector: 'app-refill-prescription',
  templateUrl: './refill-prescription.component.html',
  styleUrls: ['./refill-prescription.component.scss'],
})
export class RefillPrescriptionComponent  {

  public workflows: Workflow[] = [
    { Title: "Select medicine", Content: "Dr. Jones", State: "past" },
    { Title: "Select provider", Content: "", State: "current" },
    { Title: "Select pharmacy", Content: "", State: "future" },
    { Title: "Your contact info", Content: "", State: "future" },
    { Title: "Review & submit", Content: "", State: "future" }
  ];

  ionViewWillEnter() {

  }

}
