import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ModalService } from './modal.service';
import { ModalType } from '../model/ModalTypeEnum';
import { ToastController } from '@ionic/angular';
import { UserFriendlyError } from '../model/UserFriendlyError';
import { ConstantsService } from './constants.service';

/**
 * Service that notifies users with a toast dialog when javascript errors occur.
 * If the error is a UserFriendlyError it will display the provided description,
 * otherwise it hide the error details behind a "Technical details" button.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  private currentErrorToast: HTMLIonToastElement;
  private currentlyConstructingToast = false;

  constructor(
    private injector: Injector,
    private modalService: ModalService,
    private constantsService: ConstantsService
  ) {}

  handleError = async (error: Error) => {
    // abort creating toast for normal errors if displayToastForEveryErrorEvent is false
    if (this.constantsService.constantsData.displayToastForEveryErrorEvent == false &&
        !(error instanceof UserFriendlyError)) {
      throw error;
    }

    const showDetails = error instanceof UserFriendlyError;
    const toastController = this.injector.get(ToastController);

    // only spawn a new error toast if one is not already present
    if (!this.currentErrorToast && !this.currentlyConstructingToast) {
      this.currentlyConstructingToast = true;

      const toast = await toastController.create({
        message: `An error occurred${showDetails ? ':\n' + error.message : ''}`,
        cssClass: 'error-toast',
        position: 'top',
        buttons: showDetails ? [
          {
            icon: 'close',
            handler: () => this.currentErrorToast = null
          }
        ] : [
            {
              text: 'Technical details',
              handler: () => { this.showErrorMessage(error) }
            },
            {
              icon: 'close',
              handler: () => this.currentErrorToast = null
            }
          ],
      });
      this.currentErrorToast = toast;
      toast.present();
      this.currentlyConstructingToast = false;
    }
    throw error;
  }

  private showErrorMessage(error: Error) {
    this.modalService.open('Technical error details', error.message, ModalType.BASIC)
  }

}
