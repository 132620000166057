import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})
export class GoalsService {

  constructor(private _httpBaseService: HttpBaseService
    ) { }

  async getGoals(user?: UserIdentifier) {
    const response = await this._httpBaseService.getList('/ehr/category/goals', true, user);
    return response['Goals'];
  }
}
