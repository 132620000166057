import { Injectable } from '@angular/core';
import { DocumentTypeList, DownloadableFileReference, TransmitDocumentRequest } from '../model/ShareMyRecords';
import { ConstantsService } from './constants.service';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ShareHealthRecordService {

  constructor(
    private constantsService: ConstantsService,
    private httpClient: HttpClient
  ) { }

  documentTypeList(): Promise<DocumentTypeList> {
    const url = this.constantsService.constantsData.apiUrl + '/ehr/document/types';
    return this.httpClient.get<DocumentTypeList>(url).toPromise();
  }

  prepareDownload(startDate: Date, endDate: Date, type: string): Promise<DownloadableFileReference> {
    return new Promise(async (resolve, reject) => {

      try {
        const formattedStartDate = formatDate(startDate, 'yyyy-MM-dd', 'en-US');
        const formattedEndDate = formatDate(endDate, 'yyyy-MM-dd', 'en-US');
        const startDownloadUrl = `${this.constantsService.constantsData.apiUrl}/ehr/document/startDownload?startDate=${formattedStartDate}&endDate=${formattedEndDate}&type=${type}`;
        const checkStatusUrl = `${this.constantsService.constantsData.apiUrl}/ehr/document/ready?startDate=${formattedStartDate}&endDate=${formattedEndDate}&type=${type}`;
        await this.httpClient.get(startDownloadUrl).toPromise();
        const maxTries = 10;
        for (let i = 0; i < maxTries; i++) {
          const status = await this.httpClient.get<DownloadableFileReference>(checkStatusUrl).toPromise();
          if (typeof status.DocumentID == 'number') {
            resolve(status);
            return;
          }
          else await new Promise(resolve => setTimeout(resolve, 1000));
        }
        throw new Error('Exceeded maximum status checks while preparing file download');
      }
      catch (error) {
        reject(error);
      }

    });
  }

  getDownloadUrl(id: number) {
    return `${ this.constantsService.constantsData.apiUrl }/ehr/document/download/${id}`;
  }

  sendToProvider(transmitRequest: TransmitDocumentRequest): Promise<Object> {
    const url = `${this.constantsService.constantsData.apiUrl}/ehr/document/transmit`;
    return this.httpClient.post(url, transmitRequest).toPromise();
  }

}
