import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidationErrors,
  ValidatorFn,
  AbstractControlOptions
} from "@angular/forms";
import { PersonalInformationService } from '../../../../service/personal-information.service';
import { ActivatedRoute, Router } from "@angular/router";
import { FormsService } from "../../../../service/forms.service";
import { StateService } from "../../../../service/state.service";
import { ExpressionParserService } from "../../../../service/expression-parser.service";
import { ToastController } from '@ionic/angular';
import { ToastService } from 'src/app/service/toast.service';
import { FormsValidationService } from "../../../../service/forms-validation.service";
import { validate } from "codelyzer/walkerFactory/walkerFn";
import { FormHooks } from "@angular/forms/src/model";
import { BhanalyticsService } from "../../../../service/bhanalytics.service";
import {FormsExportService} from "../../../../service/forms-export.service";
import { AuthenticateService } from 'src/app/service/authenticate.service';


@Component({
  selector: 'app-form-create',
  templateUrl: './forms-submission-create-tab.component.html',
  styleUrls: ['./forms-submission-create-tab.component.scss'],
})
export class FormsSubmissionCreateTabComponent implements OnInit {

  public sampleForm;
  public sampleForm1 = require('./sample-form.json');
  public sampleForm2 = require('./sample-form2.json');
  public sampleForm3 = require('./sample-form3.json');
  public sampleForm4 = require('./sample-form4.json');

  public patientDemographics: any;

  public patientForm: FormGroup;

  public hideEvents: { fieldid: string, expression: string, divid: string }[];

  public formKey: string;

  showTestForms: boolean = true;

  constructor(private expressionParserService: ExpressionParserService,
    private _stateService: StateService,
    private formService: FormsService,
    private formBuilder: FormBuilder,
    private location: Location,
    private _Activatedroute: ActivatedRoute,
    public _router: Router,
    private toastController: ToastController,
    private _toastService: ToastService,
    private formValidationService: FormsValidationService,
    private formsExportService: FormsExportService,
    private _bhAnalyticsService: BhanalyticsService,
    private _personalInformationService: PersonalInformationService,
    private _auth: AuthenticateService) {
    this.hideEvents = [];
  }



  async ionViewDidEnter() {
    for (var hideEvent of this.hideEvents) {
      let hideState = this.expressionParserService.evalExpression(hideEvent.expression, this.patientForm);
      if (hideState == true) {
        document.getElementById(hideEvent.divid).style.display = 'none';
      } else {
        document.getElementById(hideEvent.divid).style.display = 'block';
      }
    }
  }

  async ionViewDidLoad() {
  }

  async ngOnInit() {
    await this.loadPatientDemographics();

    this._Activatedroute.paramMap.subscribe(params => {
      this.formKey = params.get('id');
    });

    if (this.showTestForms && this.formKey.startsWith("test-form-")) {
      if (this.formKey == "test-form-sample-form.json") {
        this.sampleForm = this.sampleForm1;
      } else if (this.formKey == "test-form-sample-form2.json") {
        this.sampleForm = this.sampleForm2;
      } else if (this.formKey == "test-form-sample-form3.json") {
        this.sampleForm = this.sampleForm3;
      } else if (this.formKey == "test-form-sample-form4.json") {
        this.sampleForm = this.sampleForm4;
      }
    } else {
      this.sampleForm = await this.formService.getElectronicForm(this.formKey);
    }
    this.patientForm = this.formBuilder.group({});
    this.buildFormControls();
  }

  goBack(): void {
    this.location.back();
  }

  async submitForm() {
    //let formData = this.generateFormData();
    let formData = this.formsExportService.generateFormData(this.sampleForm, this.patientForm);
    // console.log(formData.fullHtml)

    if (this.patientForm.valid) {
      this._bhAnalyticsService.clickEvent('submit form', this.formKey);

      this._auth.loadFreshAuthStatus().subscribe(async authStatus => {
        const userId = authStatus.EffectiveUserID;
        if (userId != null) {
        const result = await this.formService.submitForm(userId.toString(), JSON.stringify(formData))
        //console.log("DATA: " + JSON.stringify(formData));

        if (result === null) {
          this._router.navigate(['authenticated', 'resources', 'forms-list', 'forms-submission-complete']);
        }

      } else {
        //TODO: fix user response
        console.log("USER ID IS NULL");
      }
      });

    } else {
      let focusDone = false;
      Object.keys(this.patientForm.controls).forEach(field => {
        const control: any = this.patientForm.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
        control.updateValueAndValidity();
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement != undefined && typeof control.nativeElement.setFocus === "function") {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }
  }

  async loadPatientDemographics() {
    this.patientDemographics = await this._personalInformationService.demographics().then(demographics => {
      return demographics.Patient;
    });
    this.patientDemographics.Addresses = this.patientDemographics.Addresses[0];
  }

  private buildFormControls() {
    let i = 0;
    for (let section of this.sampleForm._Body.FormDefinition.sections) {
      section.divId = (i++);
      if (section.hideExpression != null) {
        this.hideEvents.push({ fieldid: '', expression: section.hideExpression, divid: section.divId });
      }

      // Move template layout data to field obj. (Sections w/o a fieldGroup).
      if (section.data) {
        for (const field of section.fields) {
          field['layout'] = section.data.layout;
        }
      }
      this.buildFormFieldControls(section.fields, section.divId);
    }
  }

  private populatePatientDemoInputs(identifier: string, keyId: string): void {
    let identifierValue = this.patientDemographics[identifier];
    if (!identifierValue) {
      identifierValue = this.patientDemographics.Addresses[identifier];
    }
    this.patientForm.controls[keyId].setValue(identifierValue);
  }

  private buildFormFieldControls(fields: any, divId: string) {
    let i = 0;

    for (let field of fields) {
      field.divId = divId + "-" + (i++);

      if (field.hideExpression != null) {
        this.hideEvents.push({ fieldid: field.key, expression: field.hideExpression, divid: field.divId });
      }

      if (field.key != null &&
        field.type != 'instructions') { // bug fix, one of the forms had a dup key assigned to instructions
        let validators: ValidatorFn[] = [];

        if (field.templateOptions != null && field.templateOptions.required != null && field.templateOptions.required == 1) {
          validators.push(Validators.required);
        }

        if (field.type && field.type == 'input') {
          if (field.templateOptions != null && field.templateOptions.type && field.templateOptions.type === 'email') {
            validators.push(this.formValidationService.getEmailValidator());
          } else if (field.templateOptions != null && field.templateOptions.type && field.templateOptions.type === 'number') {
            validators.push(this.formValidationService.getNumberValidator());
          } else if (field.templateOptions != null && field.templateOptions.type && field.templateOptions.type === 'date') {
            validators.push(this.formValidationService.getDateValidator());
          } else if (field.templateOptions != null && field.templateOptions.label != null) {
            let validator = this.formValidationService.getValidatorByLabel(field.templateOptions.label);
            if (validator != null) {
              validators.push(validator);
            }
          }
        }

        this.patientForm.addControl(field.key, new FormControl('', { validators: validators, updateOn: this.formValidationService.getValidationUpdateOn() }));

        // set the default value on the check box. didn't seem to work using [checked] = false
        if (field.type && field.type == 'checkbox') {
          this.patientForm.get(field.key).setValue(false);
        }
        // Insert patient demographics where className matches the patient identifier.
        if (field.className) {
          this.populatePatientDemoInputs(field.className, field.key);
        }

      }
      if (field.fieldGroup != null) {
        this.buildFormFieldControls(field.fieldGroup, field.divId);
      }
    }
  }

}
