import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-symptom-checker',
  templateUrl: './public-symptom-checker.component.html',
  styleUrls: ['./public-symptom-checker.component.scss'],
})
export class PublicSymptomCheckerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
