import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { MessageModalComponent } from '../message-modal/message-modal.component';
import { Router } from '@angular/router';
import { ModalController, AlertController } from '@ionic/angular';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { MessagesService } from 'src/app/service/messages.service';
import { ModalService } from 'src/app/service/modal.service';
import { PreviousRouteService } from 'src/app/service/previous-route.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Message } from 'src/app/model/Messages';
import moment from 'moment';
import { AwsTranslateService } from 'src/app/service/aws-translate.service';

@Component({
  selector: 'bh-message-table',
  templateUrl: './bh-message-table.component.html',
  styleUrls: ['./bh-message-table.component.scss'],
})
export class BhMessageTableComponent implements OnChanges, AfterViewInit {
  @Input() messages = [];
  @Input() view: 'inbox' | 'sent' | 'archived';
  @Output() reloadEvent = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;
  initMessages: Message[] = [];
  dataSource
  selection
  pageSize = 25;
  page = 1;
  isLoaded = false;
  hideShowMore = false;
  criteria = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [];
  @ViewChild('iconSelectableTemplate') iconSelectableTemplate: ElementRef;

  constructor(
    private messagesService: MessagesService,
    private modalCtrl: ModalController,
    private _bhAnalyticsService: BhanalyticsService,
    private alertCtrl: AlertController,
    public AWSTranslate: AwsTranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    console.log('bh-message-table: ngOnChanges', changes);
    if ('messages' in changes) {
      this.isLoaded = false;
      this.initMessages = Object.assign([], this.messages);
      this.hideShowMore = this.initMessages.length < this.pageSize;
      this.messages = this.initMessages.slice(0, this.pageSize - 1);
      this.page = 1;
      console.log('messages', this.messages);
      this.setColumns();

      this.dataSource = new MatTableDataSource<Message>(this.messages);
      this.selection = new SelectionModel<Message>(true, []);
      setTimeout(() => { this.dataSource.sort = this.sort; });
      this.isLoaded = true;
    }
  }

  ngAfterViewInit() {

  }

  showMore(step = 1) {
    if (this.messages.length !== this.initMessages.length) {
      this.page += step;
      this.messages = this.initMessages.slice(0, this.pageSize * this.page - 1);
      this.dataSource = new MatTableDataSource<Message>(this.messages);
      this.hideShowMore = this.initMessages.length < this.pageSize || this.messages.length === this.initMessages.length;
      setTimeout(() => { this.dataSource.sort = this.sort; });
    } else {
      this.hideShowMore = true;
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    // Perform actions based on viewport size change
    console.log('Viewport size changed', window.innerHeight, window.innerWidth);
    // this.pageSize = window.innerWidth < 800 ? 999 : 50;
    // this.paginator.disabled = window.innerWidth < 800;
  }

  setColumns() {
    switch (this.view) {
      case 'inbox':
        this.displayedColumns = ['select', 'from', 'subject', 'date'];
        break;

      case 'sent':
        this.displayedColumns = ['select', 'to', 'subject', 'date'];
        break;

      case 'archived':
        this.displayedColumns = ['select', 'from', 'subject', 'date'];
        break;

    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row =>
        this.selection.select(row)
      );
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Message): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }


  archiveMessages() {
    if (this.selection.selected.length > 0) {
      let total = 0;
      this.selection.selected.forEach(s => {
        this._bhAnalyticsService.clickEvent('Archive Message', s.id);
        this.messagesService.archiveMessages([s.id]);
        const index = this.dataSource.data.indexOf(s);
        this.dataSource.data.splice(index, 1);
        this.dataSource._updateChangeSubscription();
        total += 1;
        // setTimeout(() => this.dataSource.paginator = this.paginator);
      });
      this.presentAlert(total + ' message(s) archived.');
    } else {
      this.presentAlert('No messages selected.');
    }
  }

  unarchiveMessages() {
    if (this.selection.selected.length > 0) {
      let total = 0;
      this.selection.selected.forEach(s => {
        this._bhAnalyticsService.clickEvent('Archive Message', s.id);
        this.messagesService.unarchiveMessages([s.id]);
        const index = this.dataSource.data.indexOf(s);
        this.dataSource.data.splice(index, 1);
        this.dataSource._updateChangeSubscription();
        total += 1;
        // setTimeout(() => this.dataSource.paginator = this.paginator);
      });
      this.presentAlert(total + ' message(s) unarchived.');
    } else {
      this.presentAlert('No messages selected.');
    }
  }

  filterMessages(ev) {
    console.log('Searching', ev.target.value.toLowerCase());
    const criteria = ev.target.value.toLowerCase().trim();
    this.criteria = criteria;
    if (criteria) {
      // console.log('initMessages', this.initMessages);
      this.messages = this.initMessages.filter(im =>
        (im.to && im.to.toLowerCase().indexOf(criteria) > -1) ||
        (im.from && im.from.toLowerCase().indexOf(criteria) > -1) ||
        im.subject.toLowerCase().indexOf(criteria) > -1 ||
        im.id.toString().indexOf(criteria) > -1 ||
        im.date.indexOf(criteria) > -1 ||
        moment(im.date, 'YYYY-MM-DD HH:mm:ss.ms').format('MMMM D, YYYY').indexOf(criteria) > -1
      );
      this.dataSource.data = this.messages;
      this.hideShowMore = true;
    } else {
      this.showMore(0);
    }
  }

  async openMessageModal(row) {
    console.log('event message', row);
    const modal = await this.modalCtrl.create({
      component: MessageModalComponent,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        message: row
      },
    });
    modal.onDidDismiss().then(async (data) => {
      console.log('onDidDismiss > reload: ', data);
      if (data && data.data && data.data.reload) {
        this.reloadEvent.emit();
      }
    });
    await modal.present();
  }


  filter(event) {

  }

  async presentAlert(msg) {
    const alert = await this.alertCtrl.create({
      message: msg,
      buttons: [
        'Ok'
      ]
    });
    alert.present();
  }

  // Synchronize header/footer paginators
  syncTopPaginator(event: PageEvent) {
    console.log('syncing paginator');
    document.querySelector('#message-wrapper').scrollTo(0, 0);
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.paginator.page.emit(event);
  }


}
