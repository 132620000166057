import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Tile } from 'src/app/model/Tile';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AuthenticateService } from 'src/app/service/authenticate.service';
import { MenuController, ModalController } from '@ionic/angular';
import { StateService } from 'src/app/service/state.service';
import { PersonalCommunityUser } from 'src/app/model/PersonalCommunityUser';
import { MatSelectChange } from '@angular/material';
import { UserFriendlyError } from 'src/app/model/UserFriendlyError';
import { LoginTermsAndConditionsModalComponent } from '../login-terms-and-conditions-modal/login-terms-and-conditions-modal.component';
import { TermsOfUseService } from 'src/app/service/terms-of-use.service';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { ModalService } from 'src/app/service/modal.service';
import { Subscription } from 'rxjs';
import { ScrollingUtilitiesService } from 'src/app/service/scrolling-utilities.service';


@Component({
  selector: 'app-authenticated',
  templateUrl: './authenticated.component.html',
  styleUrls: ['./authenticated.component.scss'],
})
export class AuthenticatedComponent  {

  @ViewChild('content') private contentElement: ElementRef<HTMLDivElement>;
  @ViewChild('pageContent') private pageContentElement: ElementRef<HTMLDivElement>;

  public mobile:boolean;
  private routerSubscription: Subscription;
  private scrollToTopSubscription: Subscription;
  navItems: Tile[] = [
    {image: 'home', text: 'Home', link: "/authenticated/home"},
    {image: 'document', text: 'Health Record', link: "/authenticated/health-record"},
    {image: 'chatbubbles', text: 'Messages', link: "/authenticated/messages"},
    {image: 'book', text: 'Resources', link: "/authenticated/resources"},
    {image: 'person', text: 'Account', link: "/authenticated/account"},
  ];

  currentRoute: String;

  currentlyViewedPatientName: string;

  constructor(private menu: MenuController,
              private router: Router,
              private scrollingUtilities: ScrollingUtilitiesService,
              private authService: AuthenticateService,
              private stateService: StateService,
              private modalController: ModalController,
              private termsOfUseService:TermsOfUseService,
              private modalService: ModalService,
              private _bhAnalyticsService: BhanalyticsService) {
  }

  private _scrollToTop() {
    this.contentElement.nativeElement.scrollTop = 0;
    this.contentElement.nativeElement.scrollLeft = 0;
    this.pageContentElement.nativeElement.scrollTop = 0;
    this.pageContentElement.nativeElement.scrollLeft = 0;
  }

  async ngOnInit() {
    this.routerSubscription = this.router.events.subscribe(event => {
      // Check if user is viewing forms, if so, remove max-width css prop. (Check when navigating).
      this._checkFormsList();
      // Scroll authenticated pages to top left when they are loaded.
      if (event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/authenticated/')) {
        this._scrollToTop();
      }
    });
    this.scrollToTopSubscription = this.scrollingUtilities.scrollToTopEvents.subscribe(() => {
      this._scrollToTop();
    });

    this.authService.getCachedAuthStatus().subscribe(async authStatus => {
      if (authStatus != null) {
        this.currentlyViewedPatientName = authStatus.PatientName;
        if(authStatus.TermsAccepted==0){
          this.router.navigate(['login-terms-and-conditions'])
          /*
          let terms =await this.termsOfUseService.getTermsOfUse();

          const modal = await this.modalController.create({
            component: LoginTermsAndConditionsModalComponent,
            componentProps: { terms: terms },
            cssClass:"modal-fullscreen"
          });
          this.modalService.register(modal);


          await modal.present();
          */
        }

        if (authStatus.ChangePassword == 1) {
          this.router.navigate(['change-password']);
        }

      }
    });
  }

  // Remove page-contents max-width on patient forms.
  _checkFormsList() {
    const currentUrl = this.router.url;
    const pageMargin = (document.getElementsByClassName('page-margin') as HTMLCollectionOf<HTMLElement>);
    // Only remove max-width if user is viewing a form.
    const formViewOnly = (!currentUrl.includes('forms-submission-tab') && !currentUrl.includes('forms-submission-history-tab'));
    if (currentUrl.startsWith('/authenticated/resources/forms-list/') && formViewOnly) {
      pageMargin[0].style.padding = '45px 55px 45px 55px';
      pageMargin[0].style.maxWidth = 'none';
     } else {
      pageMargin[0].style.maxWidth = '1200px';
     }
  }

  onActivate(event) {
    const pageContent = document.querySelector('.page-content');
    if (pageContent) {
      pageContent.scrollTo(0, 0);
    }
  }

  toPossessive(name: string) {
    if (!name) return '';
    const endsWithS = name.toLowerCase()[name.length - 1] == 's';
    return name + (endsWithS ? "'" : "'s");
  }

  ionViewWillEnter() {}
  menuClick(){
    if(window.innerWidth<=500){
      this.menu.toggle('first');
    }
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
    this.scrollToTopSubscription.unsubscribe();
  }

}
