import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/service/constants.service';

@Component({
  selector: 'app-apt-complete',
  templateUrl: './apt-complete.component.html',
  styleUrls: ['./apt-complete.component.scss'],
})
export class AptCompleteComponent implements OnInit {

  constructor(
    public constantsService: ConstantsService
  ) {}

  ngOnInit() {

  }

}
