import { Injectable } from '@angular/core';
import { TermsOfUse } from '../model/TermsOfUse';
import {HttpBaseService} from "./http-base.service";

@Injectable({
  providedIn: 'root'
})
export class TermsOfUseService {

  termsOfUse: TermsOfUse = null;

  constructor(private _httpBaseService: HttpBaseService) { }

  async getTermsOfUse(){
    if (this.termsOfUse === null){
      const response = this._httpBaseService.getList("/users/0/termsOfUse", true);
      this.termsOfUse = response as any;
      return response;
    } else {
      return this.termsOfUse;
    }
  }

  async updateTermsOfUse(term:any){
      const response = this._httpBaseService.post(term,"/users/0/termsOfUse", true);
  }
}
