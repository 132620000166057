import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { DatePipe } from '@angular/common';
import { SmcTableData } from '../model/SmcTableData';
import { ShareTestResult } from '../model/ShareTestResult';
import { ConstantsService } from './constants.service';
import { DateHandlerService } from './date-handler.service';

@Injectable({
  providedIn: 'root'
})
export class TestResultsService {

  constructor(
    private _httpBaseService: HttpBaseService,
    private _datePipe: DatePipe,
    private constantsService: ConstantsService,
    private _dateHandler: DateHandlerService
  ) { }

  async getTestResults(user?: UserIdentifier){
    const response = await this._httpBaseService.getList('/ehr/category/labOrders', true, user);
    const responseRad = await this._httpBaseService.getList('/ehr/category/radOrders', true, user);
    let labOrders = response['LabOrders'] as any[];
    let radOrders = responseRad['RadOrders'] as any[];
    //console.log('lab orders', labOrders);
    //console.log('rad orders', radOrders);
    if (labOrders && labOrders.length > 0 || radOrders && radOrders.length > 0) {
      labOrders.map(orders => {
        if (orders && orders.ResultItems && orders.ResultItems.length > 0) {
          orders.ResultItems.forEach(itm => {
            // Handle missing Observation time values; populate with Entered Time
            if (!itm.ObservationTime) {
              itm.ObservationTime = orders.EnteredTime;
            }
          });
        }
        orders.labOrders = true;
      });
      if (radOrders && radOrders.length > 0) {
        radOrders.map(orders => orders.labOrders = false);
        labOrders = labOrders && Array.isArray(labOrders) ? labOrders.concat(radOrders) : radOrders;
        //console.log('concatted orders', labOrders);
      }
      return labOrders;
    } else {
      return [];
    }
  }
  
  lastCode: string;
  public TestResultHistory: any[];
  chartDataCache: any[];
  lastQuantity:number;


  getchartData(code,quantity){
    if(quantity==0){
      return null;
    }
    if(quantity!=this.lastQuantity){
      return this.getChartDataInternal(code);
    }else{
      return this.chartDataCache;
    }
  }

  getChartData(code) {
    //only generate data if code changed
    if (this.lastCode != code) {
      return this.getChartDataInternal(code);
    } else {
      return this.chartDataCache;
    }
  }

  private getChartDataInternal(code){
    let chartdata = [];
    let seriesChart = [];
    let historyData = this.TestResultHistory.filter(resultItem => resultItem.Code == code).sort((a, b) => b.ObservationTime < a.ObservationTime ? 1 : -1);
    this.lastQuantity = this.TestResultHistory.length;
    for (let index = 0; index < historyData.length; index++) {
      const element = historyData[index];
      seriesChart.push({
        "name": this._dateHandler.parseDateString(element.ObservationTime),
        "value": element.ResultValue
      });
    }
    if (historyData.length > 0) {
      chartdata.push({
        "name": historyData[0].Test,
        "series": seriesChart
      });
    }

    this.chartDataCache = chartdata;
    this.lastCode = code;
    return chartdata;
  }


  ChekcIfHasString(code) {
    let historyData = this.TestResultHistory.filter(resultItem => resultItem.Code == code);
    let result = historyData.some(function (element) {
      var number = Number(element.ResultValue);

      return isNaN(number);
    });
    return result;
  }

  getTableData(code,columns=null) {

    let history = new SmcTableData();
    if(columns){
      history.columns = columns;
    }else{
      history.columns = [{ name: 'Date', prop: 'ObservationTime' }, { name: 'Result', prop: 'ResultValue' }];
    }


    history.rows = this.TestResultHistory.filter(resultItem => resultItem.Code == code);
    return history;

  }

  getResultClass(cellData) {

    if (cellData.column.prop == "ResultValue") {
      if ( cellData.row.ResultValue != null) {
        try {
          if (cellData.row.ResultValue.match(/\d+/g) == null ) {
            return " hide-arrow";
          }
        } catch (e) {}
      }
      if (cellData.row.ResultInterpretation.indexOf("High") >-1)  {
        return " test-result-not-in-range arrow-up";
      } else if (cellData.row.ResultInterpretation.indexOf("Low")>-1) {
        return " test-result-not-in-range arrow-down";
      } else {
        return " test-result-in-range hide-arrow";
      }
    } else {
      if (cellData.column.prop == "ResultInterpretation") {
        if (cellData.row.ResultInterpretation.indexOf("High")>-1 || cellData.row.ResultInterpretation.indexOf("Low")>-1) {
          return " test-result-not-in-range ";
        }
      }
    }

    return "";
  }

  async shareTestResultsWithProvider(testResultData:ShareTestResult){
    return this._httpBaseService.post(testResultData,'/customapi/emailToProvider');
  }

  /** Returns the last login date or null */
  async getLastLoginDate(): Promise<Date> {
    try {
      const categoryCounts = await this._httpBaseService.getList('/ehr/category/counts', true);
      if (!categoryCounts.LastLogin) throw new Error('server did not return a last login date');
      const isoDateString = (categoryCounts.LastLogin as string)
        .replace(' ', 'T')
        .substring(0, categoryCounts.LastLogin.indexOf('.'))
        .concat('Z'); // this date is returned in GMT
      const lastLoginDate = new Date(isoDateString);
      return lastLoginDate;
    }
    catch (error) {
      return null; // this data is not as critical as the test results themselves so resolve the promise even if fetching it fails
    }
  }

  /** Returns the number of new test results since last login */
  async getNewTestResultsCount(): Promise<number> {
    try {
      const [testResults, lastLoginDate] = await Promise.all([this.getTestResults(), this.getLastLoginDate()]);
      // if this is the first time the user logged in consider all test results new
      if (!lastLoginDate) return testResults.length;
      const newTestResults = testResults.filter(testResult => {
        const testResultDate = new Date(testResult.Date);
        return testResultDate.getTime() > lastLoginDate.getTime()
      });
      return newTestResults.length;
    }
    catch (error) {
      return 0; // if fetching count fails do not show any new count indicator
    }
  }

}
