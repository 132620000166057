import { Injectable } from '@angular/core';
import { CreateAnAccountRequest } from '../model/CreateAnAccountRequest';
import {HttpBaseService} from "./http-base.service";

@Injectable({
  providedIn: 'root'
})
export class CreateAccountService {

  constructor(private _httpBaseService: HttpBaseService) { }

  async createAccount(request: CreateAnAccountRequest){
    const response = this._httpBaseService.post(request, "/users/enroll", true);
    return response;
  }

}
