import { PciLocationGroup } from './../../../../model/PatientCheckinData';
import { environment } from 'src/environments/environment';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { Subscription, interval } from 'rxjs';
import { PatientCheckinService } from './../../../../service/patient-checkin.service';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsValidationService } from 'src/app/service/forms-validation.service';
import { PatientCheckinData, PciLocation, PciQueueArgs, PciService } from 'src/app/model/PatientCheckinData';
import { first } from 'rxjs/operators';
import { Workflow } from 'src/app/model/Workflow';
import { AwsTranslateService } from '../../../../service/aws-translate.service';

@Component({
  selector: 'bh-pci-service-questions',
  templateUrl: './pci-service-questions.component.html',
  styleUrls: ['./pci-service-questions.component.scss'],
})
export class PciServiceQuestionsComponent implements OnChanges {
  @Input() checkinData: PatientCheckinData;
  @Output() next: EventEmitter<PatientCheckinData> = new EventEmitter();
  @Output() back: EventEmitter<PatientCheckinData> = new EventEmitter();
  env = environment;
  queueSub: Subscription = null;
  totalPatients: number = null;
  patientLabel = 'People';
  services: PciService[] = [];
  isNextDisabled = false;
  showNextSteps = false;
  noCheckinText: string = null;
  groups: PciLocationGroup[] = [];
  workflows: Workflow[] = [
    { Title: 'Service', Content: '', State: 'past' },
    { Title: 'Reason for visit', Content: '', State: 'current' },
    { Title: 'Appointment', Content: '', State: 'future' },
    { Title: 'Location', Content: '', State: 'future' },
    { Title: 'Patient Info', Content: '', State: 'future' }
  ];

  form1: FormGroup = new FormGroup(
    {
      serviceType: new FormControl('', [Validators.required]),
    }
  );
  constructor(
    public router: Router,
    private formValidationService: FormsValidationService,
    private pciService: PatientCheckinService,
    private analytics: BhanalyticsService,
    public AWSTranslate: AwsTranslateService
  ) {
  }

  /**
   * On load, update visit type selection from global variable
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.checkinData) {
      this.services = this.checkinData.group.services;
      // console.log('Services', this.services);
      this.form1.controls.serviceType.setValue(this.checkinData.service);
      this.monitorQueue();
    }
  }

  /**
   * On selection of visit type, update check-in data
   */
  formStateChange() {
    const service: PciService = this.form1.controls.serviceType.value;
    this.checkinData.service = service;
    this.checkinData.ls_seq = service.ls_seq;
    this.checkinData.serviceText = service.serviceName;
    if (service.noCheckinTextMybaystate) {
      // console.log('case 1: no-check-in text found: block user and show no-check-in text', service);
      this.isNextDisabled = true;
      this.checkinData.prompt_appointment = false;
      this.showNextSteps = true;
      // console.log(service.noCheckinTextMybaystate);
    } else if (!service.apptRequired) {
      // console.log('case 2: No appt required and there is no appt text: skip appointment prompt', service);
      this.isNextDisabled = false;
      this.checkinData.prompt_appointment = false;
      this.showNextSteps = false;
    } else if (service.apptRequired) {
      // console.log('case 3: Appt Required: Continue to appointment prompt', service);
      this.isNextDisabled = false;
      this.checkinData.prompt_appointment = true;
      this.showNextSteps = false;
    } else {
      // console.log('Other case', service);
    }
  }

  /**
   * Check patient's place in line every minute
   */
  monitorQueue() {
    if (!this.queueSub) {
      this.getQueue();
      const timer = interval(60000);
      this.queueSub = timer.subscribe(() => {
        this.getQueue();
      });
    }
  }

  /**
   * Get number of patients in queue
   */
  getQueue() {
    const body: PciQueueArgs = {
      dli_seq: this.checkinData.dli_seq,
    };
    this.pciService.getQueueCount(body).pipe(first()).subscribe((val) => {
      this.totalPatients = this.pciService.extractNumber(val.inqueue);
      this.patientLabel = (this.totalPatients === 1) ? 'Person' : 'People';
    });
  }

  /**
   * Return to previous page
   */
  previousPage() {
    this.analytics.clickEvent('PCI-ServiceQuestions: PreviousButton', '');
    this.back.emit(this.checkinData);
  }

  /**
   * Check form validity, continue to next page
   */
  nextPage() {
    if (this.form1.valid) {
      this.analytics.clickEvent('PCI-ServiceQuestions: NextButton', 'Form valid');
      this.next.emit(this.checkinData);
    } else {
      this.analytics.clickEvent('PCI-ServiceQuestions: NextButton', 'Form invalid');
      let focusDone = false;
      Object.keys(this.form1.controls).forEach(field => {
        const control: any = this.form1.get(field);
        control.markAsTouched({ onlySelf: true });
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement !== undefined && typeof control.nativeElement.setFocus === 'function') {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }
  }

  /**
   * Unsubscribe from timer
   */
  ngOnDestroy() {
    if (this.queueSub) {
      this.queueSub.unsubscribe();
      this.queueSub = null;
    }
  }

}
