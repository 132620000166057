import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AwsTranslateService } from '../../../service/aws-translate.service';
// import Exceptions from 'src/assets/translations/exceptions.json';

interface Link {
  label: string;
  url: string;
  isExternal: boolean;
}

/** Overflow menu storing links for on mobile */
@Component({
  selector: 'app-resources-dropdown',
  templateUrl: './resources-dropdown.component.html',
  styleUrls: ['./resources-dropdown.component.scss'],
})
export class ResourcesDropdownComponent implements OnInit {
  environment = environment;
  links: Link[] = [
    { label: 'Find a provider', url: 'https://providers.baystatehealth.org/', isExternal: true },
    { label: 'Explore resources', url: '/resources', isExternal: false },
    { label: 'Pay a bill', url: this.environment.PAY_URL, isExternal: true },
    { label: 'About us', url: '/about-us', isExternal: false },
    { label: 'Contact us', url: '/contact-us', isExternal: false },
    { label: 'Privacy & Terms of Use', url: '/privacy-and-terms-of-use', isExternal: false },
  ]

  // langId = localStorage.getItem("BSPP2.0LangOpt") || "en";
  // translationOverride = Exceptions.resources;

  constructor(
    private router: Router,
    private popoverController: PopoverController,
    private AWSTranslate: AwsTranslateService
  ) { }

  ngOnInit() { }

  linkSelected(link: Link) {
    if (link.isExternal) {
      // window.open(link.url, '_blank');
      window.location.href = link.url;
    } else {
      this.router.navigate([link.url]);
      this.popoverController.dismiss();
    }
  }

}
