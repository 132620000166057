import { Pipe, PipeTransform } from '@angular/core';

/** Wrapper for angulars date pipe. Needed because Safari cannot natively parse the date format coming from the personal community back end. */
@Pipe({
  name: 'diagnosisFilter'
})
export class DiagnosisFilter implements PipeTransform {

  constructor() {}

  transform(list: any, filterType: string, expandList: boolean) {
    //  Filter diagnosis list based on filter passed.
    if (list) {
      switch (filterType) {
        case 'show-all':
          // Only show top ten.
          const showAllList = (expandList ? list : list.slice(0, 10));
          list = showAllList;
          break;
        case 'common':
          const commonList = list.filter(diagnosis => diagnosis.common_diagnoses === 'true');
          list = commonList;
          break;
        case 'red-flag':
          const flagList = list.filter(diagnosis => diagnosis.red_flag === 'true')
          list = flagList;
          break;
      }
    }
    return list;
  }
}