import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { formatDate } from '@angular/common';
import {FormsValidationService} from "../../../service/forms-validation.service";
import {RequestAppleHealthService} from "../../../service/request-applehealth.service";
import {ModalType} from "../../../model/ModalTypeEnum";
import {ToastService} from "../../../service/toast.service";
import {ModalService} from "../../../service/modal.service";
import { ToastOptions } from '@ionic/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-apple-health-setup',
  templateUrl: './apple-health-setup.component.html',
  styleUrls: ['./apple-health-setup.component.scss'],
})
export class AppleHealthSetupComponent implements OnInit {

  public steps = [
    { image: 'AppleHealth1.png', text: 'Open the Apple Health app on your Apple iPhone or iPad.', htmlNote: `<strong>Having trouble finding it?</strong><div>Swipe from the left side of your screen to the right, and try using your iPhone's search feature.</div>`},
    { image: 'AppleHealth2.png', text: 'Tap the blue user profile icon in the upper right corner.'},
    { image: 'AppleHealth3.png', text: 'Tap on Health Records.'},
    { image: 'AppleHealth4.png', text: 'Depending if you previously added another health record source, tap on “Get Started” or “Add account”.'},
    { image: 'AppleHealth5.png', text: 'Enter “Baystate Health” into the search bar and tap on the Baystate Health option.'},
    { image: 'AppleHealth6.png', text: 'Tap on the blue “Connect to Account” link.' },
    { image: 'AppleHealth7.png', text: 'Enter your Baystate Health username and password.' },
    { image: 'AppleHealth8.png', text: 'Scroll down the following page and tap the blue “Allow Access” button.' },
    { image: 'AppleHealth9.png', text: 'Baystate Health is now linked to your Apple Health. Tap the blue “Done” button.' },
    { image: 'AppleHealth10.png', text: 'You can find your Baystate Health data in the “Browse” tab under “Health Records”.' },
  ];

  enrollmentForm: FormGroup;
  selectedCard: string = null;
  statusAllDone = false;

  constructor(private formValidationService: FormsValidationService,
              private location: Location,
              private requestAppleHealthService: RequestAppleHealthService,
              private toastService: ToastService,
              private modalService: ModalService,
              private _router: Router) {
    this.enrollmentForm = new FormGroup({
      FirstName: new FormControl('', Validators.required),
      LastName: new FormControl('', Validators.required),
      DateOfBirth: new FormControl('', Validators.required),
      PhoneNumber: new FormControl('', [Validators.required,this.formValidationService.getPhoneNumberValidator()]),
      EmailAddress: new FormControl('', [Validators.required,this.formValidationService.getEmailValidator()]),
      AppToConnect: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    this.selectedCard = null;
    //this.selectedCard = 'apple-connect-help';
  }

  selectCard(selectedCard: string) : void {
    this.selectedCard = this.selectedCard != selectedCard ? selectedCard : null;
  }

  submitEnrollment() {
    //const successToastOptions: ToastOptions = { message: "Message successfully sent.", position: "top", header: "", color: 'tertiary', cssClass: 'success-toast' };
    const successToastOptions: ToastOptions = {
      message: 'Message successfully sent.',
      position: 'top',
      header:"",
      buttons:null,
      cssClass: 'success-toast',
      color: 'tertiary',
      duration: 3000
    };

    Object.keys(this.enrollmentForm.controls).forEach(field => {
      const control: any = this.enrollmentForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    if (this.enrollmentForm.valid) {
      let data =
      {
        data: {
          firstName: this.enrollmentForm.get('FirstName').value,
            lastName: this.enrollmentForm.get('LastName').value,
            dob: formatDate(this.enrollmentForm.get('DateOfBirth').value, 'yyyy-MM-dd', 'en-US'),
            phone: this.enrollmentForm.get('PhoneNumber').value,
            email: this.enrollmentForm.get('EmailAddress').value,
            appNames: this.enrollmentForm.get('AppToConnect').value
        }
      };

      this.requestAppleHealthService.requestConnection(data)
          .then(() => {
            this.statusAllDone = true;
          })
          .catch(problem => this.modalService.open(
            'Problem sending request',
            problem,
            ModalType.BASIC
          ));

    } else {
      let focusDone = false;
      Object.keys(this.enrollmentForm.controls).forEach(field => {
        const control: any = this.enrollmentForm.get(field);
        control.markAsTouched({ onlySelf: true });
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement != undefined && typeof control.nativeElement.setFocus === "function") {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }

  }

  back() {
    this.location.back();
  }

  continueFromAllDone() {
    this.statusAllDone = false;
    this._router.navigate(['/authenticated/resources']);
  }

}
