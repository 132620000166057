import { test } from '@angular-devkit/core/src/virtual-fs/host';
import { Provider } from './../../../model/Provider';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { VisitsService } from 'src/app/service/visits.service';
import { Encounter } from 'src/app/model/Encounter';
import { VisitsResponse } from 'src/app/model/VisitsResponse';
import { Appointment } from 'src/app/model/Appointment';
import { AppointmentsService } from 'src/app/service/appointments.service';
import { Insurance } from 'src/app/model/Insurance';
import { ModalService } from 'src/app/service/modal.service';
import { ModalType } from 'src/app/model/ModalTypeEnum';
import { SmcTableData } from 'src/app/model/SmcTableData';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { HealthRecordService } from '../../../service/health-record.service';
import { FindProviderModalComponent } from './find-provider-modal/find-provider-modal.component';
import { BhanalyticsService } from "../../../service/bhanalytics.service";
import { DateHandlerService } from 'src/app/service/date-handler.service';
import { ConsoleDataService } from 'src/app/service/console-data.service';
import { ConsoleContent } from 'src/app/model/ConsoleContent';
import { environment } from 'src/environments/environment';
import { NewMessagePage } from '../messages/new-message/new-message.page';


@Component({
  selector: 'app-my-visits',
  templateUrl: './my-visits.component.html',
  styleUrls: ['./my-visits.component.scss'],
})
export class MyVisitsComponent implements OnInit {

  public upcomingVisits: SmcTableData;
  public pastVisits: SmcTableData;

  facilitiesList: any[];
  visits: Encounter[];
  appointments: Appointment[];
  bannerContent: ConsoleContent;
  env = environment;
  category: any;
  @ViewChild('dateColumnTemplate') dateColumnTemplate: ElementRef;
  @ViewChild('iconSelectableTemplate') iconSelectableTemplate: ElementRef;

  constructor(private visitsService: VisitsService,
    private appointmentsService: AppointmentsService,
    private _router: Router,
    private location: Location,
    public modalController: ModalController,
    private _bhAnalyticsService: BhanalyticsService,
    private _healthRecordService: HealthRecordService,
    private dateHandler: DateHandlerService,
    private consoleDataService: ConsoleDataService,
    private modalService: ModalService) { }

  async ngOnInit() {
    const category = this.env.banner_seq.visit;
    console.log('cat', category);
    this.category = category;
    //this.getMessageInformation();
    const facilityList = await this._healthRecordService.getFacilitiesPhoneNumber();
    this.facilitiesList = facilityList.providers;
    await this.getVisits();
    await this.getAppointments();
    this.getData();

    // Check for appointment request
    const isReqAppointment = this.checkIfStringInRoutePath('request-appointment');
    if (isReqAppointment) {
      this.newMessage('appt-request');
    }
  }

  async newMessage(mode) {
    const modal = await this.modalController.create({
      component: NewMessagePage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        mode
      },
    });
    modal.onDidDismiss().then(async (data) => {

    });
    await modal.present();
  }

  checkIfStringInRoutePath(desiredString: string): boolean {
    const currentUrl = this._router.url.toLocaleLowerCase();
    return currentUrl.includes(desiredString.toLowerCase());
  }

  getData() {
    this.pastVisits = new SmcTableData();
    this.pastVisits.Title = 'Previous visits';
    console.log('past visits data', this.pastVisits);
    //x => x.ResultItems && x.ResultItems.length > 0 ? x.ResultItems : ''
    //const filterthis = this.pastVisits.rows.filter(pv => pv.EncounterType === 'E');
    //console.log('filtering =>', filterthis);

    this.pastVisits.columns = [
      { name: 'Visit Date', prop: 'FromTime', cellClass: 'card-bold', cellTemplate: this.dateColumnTemplate, collapseCol: 0, collapseMinWidth: 200  },
      { name: 'Provider', prop: 'Provider', cellClass: 'card-detail', collapseCol: 0, collapseMinWidth: 200  },
      { name: 'Location', prop: 'Location', cellClass: 'card-detail', collapseCol: 0, collapseMinWidth: 200  },
      // { name: 'Phone Number', prop: 'PhoneNumber', cellClass: 'card-detail', collapseCol: 0, collapseMinWidth: 200},
      { name: '', cellTemplate: this.iconSelectableTemplate, maxWidth: 3000, cellClass: 'select-icon', collapseCol: 1  }
    ];

    this.pastVisits.rows = this.visits.sort((a, b) => b.FromTime > a.FromTime ? 1 : -1);

    this.upcomingVisits = new SmcTableData();
    this.upcomingVisits.Title = 'Upcoming appointments';
    this.upcomingVisits.columns = [
      { name: 'Appointment', prop: 'FromTime', cellClass: 'card-bold', cellTemplate: this.dateColumnTemplate },
      { name: 'Provider', prop: 'Provider', cellClass: 'card-detail' },
      { name: 'Location', prop: 'Location', cellClass: 'card-detail' },
      // { name: 'Phone Number', prop: 'PhoneNumber', cellClass: 'card-detail'},
      { name: '', cellTemplate: this.iconSelectableTemplate, maxWidth: 3000, cellClass: 'select-icon', collapseCol: 1  }
    ];


    this.upcomingVisits.rows = this.appointments
      .sort((a, b) => b.FromTime > a.FromTime ? 1 : -1)
      .filter((app) => app.FromTime > new Date());
  }

  async getVisits() {
    const response = await (this.visitsService.getVisists()) as VisitsResponse;
    if (response) {
      console.log('get encounters', response);
      const encounters = response.Encounters;
      encounters.forEach((enc) => {
        // enc.PhoneNumber = this.getFilteredFacilityNumber(enc.EnteredAt,  enc.Location);
        enc.FromTime = this.dateHandler.parseDateString(enc.FromTime);
      });
      this.visits = encounters;
    }
  }


  selectVisit(visit: Encounter) {
    this._router.navigate(['authenticated/health-record/my-visits/previous-visit'], { state: { data: visit['selected'][0] } });
  }

  async selectAppointment(appointment: Appointment) {
    this._router.navigate(['authenticated/health-record/my-visits/upcoming-visit'], { state: { data: appointment['selected'][0] } });
  }



  async getAppointments() {
    const response = await (this.appointmentsService.getAppointments());
    const appointments = response.Appointments;
    appointments.forEach((app) => {
      // app.PhoneNumber = this.getFilteredFacilityNumber(app.EnteredAt, app.Location);
      app.FromTime = this.dateHandler.parseDateString(app.FromTime);
    });
    this.appointments = appointments;
  }

  async openFindAProvider() {
    this._bhAnalyticsService.clickEvent('open find provider', '');
    // const modal = await this.modalController.create({
    //   component: FindProviderModalComponent,
    //   cssClass: 'custom-modal-find-css'
    // });
    // this.modalService.register(modal);

    // return await modal.present();
    this._router.navigate(['/authenticated/health-record/visits/request-appointment/apt-select-provider']);
  }

  // Return facilities phone number if locations match.
  getFilteredFacilityNumber(locationStr: string, locationArr: string) {
    const locationName = locationStr.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
    const locationsArr = locationArr.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
    const filteredFacility = this.facilitiesList.filter(facilityNumber => {
      const facilityName = facilityNumber.name.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
      const facilityCode =  facilityNumber.code.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
      const namesMatch = (locationName.match(facilityName) || locationName.indexOf(facilityName.split(' ').join('')) > -1);
      const codeMatchName = (locationName.match(facilityCode) || locationsArr.match(facilityCode) || locationName.indexOf(facilityCode.split(' ').join('')) > -1);
      const nameMatchesLoc = (locationsArr.match(facilityName) || locationsArr.indexOf(facilityName.split(' ').join('')) > -1);
      return (namesMatch || nameMatchesLoc || codeMatchName);
    });
    return (filteredFacility[0] ? filteredFacility[0].phone :  '');
  }

  print() {
    this._bhAnalyticsService.clickEvent('Print Visits', '');
    window.print();
  }

  // async getMessageInformation() {
  //   try {
  //     const res = await this.consoleDataService.getSlides(1).toPromise();
  //     const bannerData = res.x_result.filter(row => row.ps_seq === 152 && row.active === 1);
  //     this.bannerContent = bannerData[0];
  //     console.log('banner Content', this.bannerContent);
  //     //this.consoleContents = bannerDat

  //   } catch (err) {
  //     console.error("Get banner information: ", err);
  //   }
  // }

}
