import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import moment from 'moment';
import { AwsTranslateService } from 'src/app/service/aws-translate.service';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { DateHandlerService } from 'src/app/service/date-handler.service';

@Component({
  selector: 'bh-task',
  templateUrl: './bh-task.component.html',
  styleUrls: ['./bh-task.component.scss'],
})
export class BhTaskComponent implements OnChanges {
  @Input() unreadMessagesNumber: number;
  @Input() taskMessageDate: string;
  @Input() unreadResultsNumber: number;
  @Input() eduDocumentsNumber: number;
  @Input() taskResultDate: string;
  @Input() taskEvents: any[];
  @Input() taskEducation: any[];
  @Input() taskNotes: any[];
  @Input() lastCheckedTime: string;

  initCheckedTime: String;
  taskDetail: string;
  eventDate: string;
  timeElapsed = false;
  timer = null;
  timer2 = null;
  dateCollection = [];
  mostRecentNotesTime: string = '';

  constructor(
    private navCtrl: NavController,
    private _bhAnalyticsService: BhanalyticsService,
    public AWSTranslate: AwsTranslateService
  ) {
    this.setSkeletonTimeOut();
    this.setEntry();
  }

  ngOnChanges() {
    console.log('task notes', this.taskNotes);
    this.mostRecentNoteCreated();
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
    this.timer = null;
  }

  setEntry() {
    setTimeout(() => {
      this.timer2 = this.lastCheckedTime;
      console.log('task timer', this.timer2);
    }, 5000);
  }

  setSkeletonTimeOut() {
    this.timer = setTimeout(() => {
      this.timeElapsed = true;
    }, 2000);
  }

  goToMessages() {
    this._bhAnalyticsService.clickEvent('task-list', 'messages');
    this.navCtrl.navigateRoot('/authenticated/messages/inbox');
  }

  goToResults() {
    this._bhAnalyticsService.clickEvent('task-list', 'test-results');
    this.navCtrl.navigateRoot('/authenticated/health-record/test-results');
  }

  goToReports() {
    this._bhAnalyticsService.clickEvent('task-list', 'reports');
    this.navCtrl.navigateRoot('/authenticated/health-record/reports');
  }

  goToEvents() {
    this._bhAnalyticsService.clickEvent('task-list', 'appointments');
    this.navCtrl.navigateRoot('authenticated/health-record/my-visits');
  }

  mostRecentNoteCreated() {
    const now = moment();
    const dateObjects = [];
    let closestDifference = Infinity;
    for (const tn of this.taskNotes) {
      if(tn.EnteredTime !== undefined) {
        dateObjects.push(tn.EnteredTime);
      }
    }
    console.log("data Objects", dateObjects);
    for (const data of dateObjects) {
      const dataDateTime = moment(data);
      console.log('data Refresh', dataDateTime);

      const difference = Math.abs(now.diff(dataDateTime));
      //console.log('diff', difference);

      if (difference < closestDifference) {
        closestDifference = difference;
        this.mostRecentNotesTime =
          moment(dataDateTime).toString();
      }
    }
    console.log('most recent time', this.mostRecentNotesTime);

  }

}
