import { style } from '@angular/animations';
import { NativeElementInjectorDirective } from './../../../../directive/native-element-injector.directive';
import { element } from 'protractor';
import { Address } from './../../../../model/Address';
import { Component, Input, OnInit, ViewChild, AfterViewInit, DoCheck, ElementRef } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { ExpressionParserService } from "../../../../service/expression-parser.service";
import { FormsValidationService } from "../../../../service/forms-validation.service";
import { PersonalInformationService } from '../../../../service/personal-information.service';


@Component({
  selector: 'form-fields-template',
  templateUrl: './form-fields-template.component.html',
  styleUrls: ['./form-fields-template.component.scss'],
})
export class FormFieldsTemplateComponent implements OnInit, AfterViewInit {

  public myJSON: any = JSON;

  public patientDemographics: any;

  @Input()
  public templateLayout: any;

  @Input()
  public formFields: any[];

  @Input()
  public patientForm: FormGroup;

  @Input()
  public divId: string;

  @Input()
  public hideEvents: { fieldid: string, expression: string, divid: string }[];

  constructor(
    private expressionParserService: ExpressionParserService,
    private formValidationService: FormsValidationService,
    private _personalInformationService: PersonalInformationService
    ) {
  }

  async ngOnInit() { }

  ngAfterViewInit() {
    setTimeout(() => {
      for (let index = 0; index < this.formFields.length; index++) {
        const element = this.formFields[index];
        if (element.fieldGroup) {
          if (element.key) {
            this.formStateChange(element.key, null);
          } else {
            for (let i = 0; i < element.fieldGroup.length; i++) {
              const groupItem = element.fieldGroup[i];
              if (groupItem.key) {
                this.formStateChange(groupItem.key, null, true);
              }
            }
          }
        }
      }
    }, 200);
  }

  async ionViewWillEnter() { }

  getValidationError(label: string) {
    return this.formValidationService.getErrorMessageByLabel(label);
  }

  formStateChange(fieldkey, event, invertLogic = false) {
    for (const hideEvent of this.hideEvents) {
      if (hideEvent.expression.indexOf(fieldkey) !== -1) {
        const hideState = this.expressionParserService.evalExpression(hideEvent.expression, this.patientForm);
        if (!hideState) {
            if (document.getElementById(hideEvent.divid) != null) {
              if (invertLogic) {
                document.getElementById(hideEvent.divid).style.display = 'block';
              } else {
                document.getElementById(hideEvent.divid).style.display = 'none';
              }
            }
          } else {
            if (document.getElementById(hideEvent.divid) != null) {
              if (invertLogic) {
                document.getElementById(hideEvent.divid).style.display = 'none';
              } else {
                document.getElementById(hideEvent.divid).style.display = 'block';
              }
            }
          }
      }
    }
  }

  isEvenColumns(val: any): boolean {
    return (typeof val === 'number');
  }
}
