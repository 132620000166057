import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ForgotCredentialsService } from 'src/app/service/forgot-credentials.service';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import {FormsValidationService} from "../../../service/forms-validation.service";
import {BhanalyticsService} from "../../../service/bhanalytics.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AwsTranslateService } from '../../../service/aws-translate.service';

@Component({
  selector: 'smc-forgot-credentials',
  templateUrl: './forgot-credentials.component.html',
  styleUrls: ['./forgot-credentials.component.scss'],
})
export class ForgotCredentialsComponent  {

  forgottenItem = 'password';
  formGroup: FormGroup;


  @ViewChild('usernameInput') usernameInput: ElementRef;
  @ViewChild('emailInput') emailInput: ElementRef;

  constructor(
    private forgotService: ForgotCredentialsService,
    private alertController: AlertController,
    private _bhAnalyticsService: BhanalyticsService,
    private formValidationService: FormsValidationService,
    private router: Router,
    private AWSTranslate: AwsTranslateService
  ) {

    this.formGroup = new FormGroup({
      usernameInput: new FormControl('', Validators.required),
      emailInput: new FormControl('',[Validators.required,this.formValidationService.getEmailValidator()])
    });

   }

  ionViewWillEnter() {}

  submit() {
    if (this.forgottenItem == 'password' && this.formGroup.controls.usernameInput.valid) {
      this._bhAnalyticsService.clickEvent('Submit Forgot Username', this.formGroup.controls.usernameInput.value);
      this.forgotService.forgotPassword(this.formGroup.controls.usernameInput.value)
        .then(this.forgotPasswordSuccess)
        .catch(this.callFailed);
    } else if (this.forgottenItem == 'username' && this.formGroup.controls.emailInput.valid) {
      this._bhAnalyticsService.clickEvent('Submit Forgot Password', this.formGroup.controls.emailInput.value);
      this.forgotService.forgotUsername(this.formGroup.controls.emailInput.value)
        .then(this.forgotUsernameSuccess)
        .catch(this.callFailed);
    } else {
      let focusDone = false;
      Object.keys(this.formGroup.controls).forEach(field => {
        const control: any = this.formGroup.get(field);
        control.markAsTouched({ onlySelf: true });
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement != undefined && typeof control.nativeElement.setFocus === "function") {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }

    // this.formGroup.controls.usernameInput.setValue('');
    // this.formGroup.controls.emailInput.setValue('');
  }



  forgotPasswordSuccess = async (response) => {
    let alert;
    if (response.error) alert = await this.alertController.create({
      header: 'Error',
      message: response.error,
      buttons: [{
        text: 'OK',
      }]
    });
    else alert = await this.alertController.create({
      header: this.AWSTranslate.read('Success', this.AWSTranslate.langId),
      message: this.AWSTranslate.read('Your request to reset your password is being processed. Please check your email for a message with a link to enter your new password.', this.AWSTranslate.langId),
      buttons: [{
        text: 'OK',
        handler: () => this.router.navigateByUrl('/login')
      }]
    });
    await alert.present();
  }

  forgotUsernameSuccess = async (response) => {
    let alert;
    if (response.error) alert = await this.alertController.create({
      header: 'Error',
      message: response.error,
      buttons: [{
        text: 'OK',
      }]
    });
    else alert = await this.alertController.create({
      header: this.AWSTranslate.read('Success', this.AWSTranslate.langId),
      message: this.AWSTranslate.read('Please check your email for a list of usernames registered with the email you provided.', this.AWSTranslate.langId),
      buttons: [{
        text: 'OK',
        handler: () => this.router.navigateByUrl('/login')
      }]
    });
    await alert.present();
  }

  callFailed = async (response) => {
    const alert = await this.alertController.create({
      header: 'Error',
      message: 'Please check your network connection and try again.',
      buttons: ['OK']
    });
    await alert.present();
  }

}
