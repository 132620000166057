import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { FormGroup } from "@angular/forms";
import { group } from "@angular/animations";

@Component({
  selector: 'smc-form-text-input',
  templateUrl: './smc-form-text-input.component.html',
  styleUrls: ['./smc-form-text-input.component.scss'],
})
export class SmcFormTextInputComponent implements OnInit {

  @Input() public formControlNameAtr: string;
  @Input() public placeholderText: string;
  @Input() public extraClass: string;
  @Input() public inputType: string;
  @Input() public sizeCategory: 'small' | 'normal' | 'large' = 'normal';
  @Input() public group: FormGroup;
  @Input() public iconName: string;
  @Input() public iconPosition: string;
  @Input() public label: string;
  @Input() public labelColumnWidth: string;
  @Input() public inputColumnWidth: string;
  @Input() public inputWidth: string;
  @Input() public alignInputRight: boolean;//for item with label only
  @Input() public required: string; // display a red asterisk with label only
  @Input() public displayErrors: string;
  @Input() public requiredError: string;
  @Input() public patternError: string;
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  inputAlignRight: string;
  /** Whether a password should be displayed as text or dots */
  showPassword = false;
  /** Whether to display the show/hide toggle */
  showPasswordControls: boolean;

  constructor() { }

  ngOnInit() {
    if (!this.inputType) {
      this.inputType = "text";
    }

    if (!this.extraClass) {
      this.extraClass = "";
    }


    if (this.iconName) {
      this.extraClass = this.extraClass + " smc-input-with-icon";
    }

    if (this.alignInputRight) {
      this.inputAlignRight = "input-align-right";
    }

//    if (!this.labelColumnWidth) {
      this.labelColumnWidth = "200px";
 //   }
  //  if (!this.inputColumnWidth) {
      this.inputColumnWidth = "70%";
   // }

    if(!this.inputWidth){
      this.inputWidth = "100%";
    }

    // If input type is initially password, always display the hide/show toggle even if the type changes to text later
    this.showPasswordControls = this.inputType == 'password';
  }

  dataChanged(event): void {
    this.onChange.emit(event);
  }

  grabFocus() {
    if (this.group) {
      const control: any = this.group.get(this.formControlNameAtr);
      if (control.nativeElement != undefined) {
        control.nativeElement.setFocus();
      }
    }
  }

  /** Toggle revealing the password */
  hideOrShowPassword() {
    this.showPassword = !this.showPassword;
    this.inputType = this.showPassword ? 'text' : 'password';
  }

}
