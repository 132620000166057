import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ConsoleContent } from 'src/app/model/ConsoleContent';

@Component({
  selector: 'bh-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnChanges {

  @Input() banner: ConsoleContent;
  isExpanded = false;
  title: string = ''
  characterCount = 285;
  constructor() { }

  ngOnChanges() {
    if(this.banner && this.banner.body.length < this.characterCount) {
      this.expand();
    }
  }
    
  expand() {
      this.isExpanded = true;
  }

}
