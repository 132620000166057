import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { LoadingServiceService } from './../../../../service/loading-service.service';
import { PatientCheckinService } from './../../../../service/patient-checkin.service';
import { Demographics } from 'src/app/model/PersonalInformation';
import { ModalController, AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter, forwardRef, ViewChild, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInputEvent, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { DateHandlerService } from 'src/app/service/date-handler.service';
import { PatientCheckinData } from 'src/app/model/PatientCheckinData';
import { FormsValidationService } from 'src/app/service/forms-validation.service';
import { LoginFormModalComponent } from 'src/app/component/shared/login-form-modal/login-form-modal.component';
import { first } from 'rxjs/operators';
import { AuthenticateService } from 'src/app/service/authenticate.service';
import { PersonalInformationService } from 'src/app/service/personal-information.service';
import { Workflow } from 'src/app/model/Workflow';
const moment = require('moment');


@Component({
  selector: 'bh-pci-patient-info',
  templateUrl: './pci-patient-info.component.html',
  styleUrls: ['./pci-patient-info.component.scss'],
})
export class PciPatientInfoComponent implements OnChanges {
  @Input() checkinData: PatientCheckinData;
  @Output() next: EventEmitter<PatientCheckinData> = new EventEmitter();
  @Output() back: EventEmitter<PatientCheckinData> = new EventEmitter();

  formSub: Subscription;
  waitingArea = '';
  showWaitingAreaMessage = false;
  minDate: Date;
  maxDate: Date;
  promptedForSignIn = false;
  isLoggedIn = false;
  mobilePhoneRequired = 'true';
  workflows: Workflow[] = [
    { Title: 'Service', Content: '', State: 'past' },
    { Title: 'Reason for visit', Content: '', State: 'past' },
    { Title: 'Appointment', Content: '', State: 'past' },
    { Title: 'Location', Content: '', State: 'past' },
    { Title: 'Patient Info', Content: '', State: 'current' }
  ];
  nextButtonLabel = 'Get in line';

  form1: FormGroup = new FormGroup(
    {
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      birthdate: new FormControl('', [Validators.required]),
      mobilePhone: new FormControl('', [Validators.required, Validators.pattern(/^[2-9]\d{2}-\d{3}-\d{4}$/)]),
      email: new FormControl('', [Validators.required, this.formValidationService.getEmailValidator()]),
    }
  );
  constructor(
    public router: Router,
    private formValidationService: FormsValidationService,
    private modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public pciService: PatientCheckinService,
    private authenticateService: AuthenticateService,
    private personalInformationService: PersonalInformationService,
    private loadingService: LoadingServiceService,
    private analytics: BhanalyticsService
  ) {
  }

  /**
   * Listen for changes, update inputs
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.checkinData) {
      // Timeout is a workaround for timing issue with ngChanges check
      setTimeout(() => {
        this.onFormChanges();
        this.isLoggedIn = this.authenticateService.isLoggedIn().getValue();
        this.checkinData.is_logged_in = this.isLoggedIn;
        this.nextButtonLabel = this.checkinData.service.apptRequired ? 'Check in' : 'Get in line';
        // Pause for a brief period, prompt guest to login
        if (!this.isLoggedIn) {
          const timeout = setTimeout(() => {
            this.promptForSignIn();
            clearTimeout(timeout);
          }, 500);
        } else {
          this.loadDemographics();
        }

        if (this.checkinData.is_complete) {
          console.log('Completed registration detected');
        }
        this.form1.controls.firstName.setValue(this.checkinData.firstname);
        this.form1.controls.lastName.setValue(this.checkinData.lastname);
        this.form1.controls.birthdate.setValue(this.checkinData.dob);
        this.form1.controls.mobilePhone.setValue(this.checkinData.phone);
        this.form1.controls.email.setValue(this.checkinData.email);
        this.waitingArea = this.checkinData.waiting_area;
      });
    }
  }

  /**
   * Update checkinData object as fields are completed
   */
  onFormChanges() {
    if (!this.formSub) {
      this.formSub = this.form1.valueChanges.subscribe(val => {
        this.checkinData.firstname = val.firstName;
        this.checkinData.lastname = val.lastName;
        this.checkinData.dob = val.birthdate ? moment.parseZone(val.birthdate).format('MM/DD/YYYY') : '';
        this.checkinData.phone = val.mobilePhone;
        this.checkinData.email = val.email;
      });
    }
  }

  /**
   * Prompt guest user to sign in
   */
  async promptForSignIn() {
    const alert = await this.alertCtrl.create({
      cssClass: 'bh-alert',
      backdropDismiss: false,
      header: 'Sign in to save time?',
      message: 'Sign in to your MyBaystate account to auto-fill your personal information.',
      buttons: [
        {
          text: 'Sign in',
          handler: () => {
            // console.log('Confirm Okay');
            this.presentSignInModal();
          }
        },
        {
          text: 'Continue as Guest',
          role: 'cancel',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }
      ]
    });

    await alert.present();
  }

  /**
   * Present sign-in modal for auto-fill of demographics information
   */
  async presentSignInModal() {
    const modal = await this.modalCtrl.create({
      cssClass: 'bh-signin-modal',
      component: LoginFormModalComponent,
      backdropDismiss: true
    });
    modal.onDidDismiss()
      .then((data) => {
        if (data.data && data.data.isLoggedIn === true) {
          this.analytics.clickEvent('PCI-PatientInfo: Signed in', '');
          this.isLoggedIn = true;
          this.checkinData.is_logged_in = true;
          this.loadDemographics();
        }
      });
    await modal.present();
  }

  /***
   * Load demographics data into form fields
   */
  loadDemographics() {
    // Show loading spinner
    this.loadingService.setLoading(true);
    // Populate fields with demographics info
    const interval = setInterval(() => {
      // Get personal information
      this.personalInformationService.demographics().then(demographics => {
        // console.log(demographics);
        if (demographics.Patient) {
          this.analytics.clickEvent('PCI-PatientInfo: Loaded demographics', '');
          const dob = moment(demographics.Patient.DateOfBirth, 'YYYY-MM-DD');
          this.form1.controls.firstName.setValue(demographics.Patient.FirstName);
          this.form1.controls.lastName.setValue(demographics.Patient.LastName);
          this.form1.controls.birthdate.setValue(dob.toDate());
          // this.form1.controls.mobilePhone.setValue(demographics.Patient.MobilePhone);
          this.form1.controls.email.setValue(demographics.Patient.Email);
          // Dismiss modal
          clearInterval(interval);
          this.loadingService.setLoading(false);
        }
      },
        (err) => {
          this.loadingService.setLoading(false);
        });
    }, 1000);
  }

  /**
   * Toggle Waiting Area; workaround for segment buttons that were not displaying correctly
   * @param location waiting_area code (WR = waiting room, OTHER = outside)
   */
  toggleWaitingArea(location) {
    this.showWaitingAreaMessage = false;
    this.checkinData.waiting_area = location;
    this.waitingArea = location;
    this.analytics.clickEvent('PCI-PatientInfo: Chose WaitingArea', location);

    // Turn-off mobile phone requirement for Waiting Room
    if (location === 'WR') {
      this.form1.get('mobilePhone').clearValidators();
      this.form1.get('mobilePhone')
        .setValidators([Validators.compose([Validators.pattern(/^[2-9]\d{2}-\d{3}-\d{4}$/)])]);
      this.form1.get('mobilePhone').updateValueAndValidity();
      this.mobilePhoneRequired = 'false';
    } else {
      this.form1.get('mobilePhone').clearValidators();
      this.form1.get('mobilePhone')
        .setValidators([Validators.compose([Validators.required, Validators.pattern(/^[2-9]\d{2}-\d{3}-\d{4}$/)])]);
      this.form1.get('mobilePhone').updateValueAndValidity();
      this.mobilePhoneRequired = 'true';
    }
  }

  /**
   * Scrolls to toggle buttons for failed validation focus
   */
  scrollToToggleButtons() {
    const el = document.getElementById('toggleButtons');
    el.scrollIntoView({ behavior: 'smooth' });
  }

  /**
   * Return to previous page
   */
  previousPage() {
    this.analytics.clickEvent('PCI-PatientInfo: PreviousButton', '');
    this.back.emit(this.checkinData);
  }

  /**
   * Check form validity, register patient, continue to Confirmation page
   */
  nextPage() {
    if (this.form1.valid && typeof this.waitingArea !== 'undefined' && this.waitingArea !== '') {
      this.analytics.clickEvent('PCI-PatientInfo: NextButton',
        this.checkinData.group.group_display_name + '-' + this.checkinData.group.group_comment);
      // console.log('Registering patient', this.checkinData);
      this.pciService.registerPatient(this.checkinData).pipe(first()).subscribe(
        (val) => {
          this.checkinData.pc_seq = val.pc_seq;
          this.checkinData.is_complete = true;
          this.next.emit(this.checkinData);
        },
        (err) => {
          console.log('registerPatient: Error occurred: ', err);
        });
    } else {
      this.analytics.clickEvent('PCI-PatientInfo: NextButton', 'Form invalid');
      let focusDone = false;
      Object.keys(this.form1.controls).forEach(field => {
        const control: any = this.form1.get(field);
        control.markAsTouched({ onlySelf: true });
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement !== undefined && typeof control.nativeElement.setFocus === 'function') {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
      // Check for empty waiting area
      if (typeof this.waitingArea === 'undefined' || this.waitingArea === '') {
        this.showWaitingAreaMessage = true;
        // Scroll only if it's the last invalid item
        if (this.form1.valid) {
          this.scrollToToggleButtons();
        }
      } else {
        this.showWaitingAreaMessage = false;
      }
    }
  }

  // Unsubscribe from form changes
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy() {
    if (this.formSub) {
      this.formSub.unsubscribe();
      this.formSub = null;
    }
  }

}
