import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import { BhInputType } from 'src/app/model/input-type';
import { SelectOption } from 'src/app/model/select-option';

export const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'M/D/YYYY',
  },
  display: {
    dateInput: 'M/D/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-bh-input',
  templateUrl: './bh-input.component.html',
  styleUrls: ['./bh-input.component.scss'],
})
export class BhInputComponent implements OnInit, OnChanges{
  @Input() formGroup: FormGroup;
  @Input() type: BhInputType;
  @Input() label = '';
  @Input() value = null;
  @Input() placeholder = '';
  @Input() formControlName = '';
  @Input() lines;
  @Input() selectOptions: any[] = [];
  @Input() selectLabelProperty = 'label';
  @Input() selectValueProperty = 'value';
  @Input() includeDefaultBlankValue = false;
  @Input() readonly = false;
  @Input() viewOnly = false;
  @Input() viewOnlyValue = '';
  @Input() pattern;
  @Input() maxlength;
  @Input() index = -1;
  @Input() showSeparatorLine = false;
  @Input() indentLeft = false;
  @Input() ionIcon = null;
  @Input() ionIconColor = 'primary';
  @Input() ionIconSource;
  @Input() min;
  @Input() max;
  @Input() maxDate;
  @Input() minWidth;
  @Input() step;
  @Input() required;
  @Input() interface = 'popover';
  @Input() validationMessages: any;
  @Input() submitAttempted = false;
  @Input() helperText = null;
  @Input() useTextEditor;
  @Input() mask: string = null;
  @Input() textTransform = null;
  @Input() marginBottom = '16px';
  @Input() translation: boolean = false;
  @Output() valueChangeEvent = new EventEmitter();
  @Output() blurEvent = new EventEmitter();
  @Output() selectDxEvent = new EventEmitter();
  @Output() selectTagEvent = new EventEmitter();
  @Output() addTagEvent = new EventEmitter();
  onChange;
  onTouched;
  showPlaceholder = true;
  lookupTimer = null;
  lockedTerm = '';
  viewOnlySelectLabel = '';
  isMobile = false;
  showingTextEditor = false;
  parsedSelectOptions: SelectOption[] = [];

  constructor(
    public controlContainer: ControlContainer,
    private modalCtrl: ModalController,
  ) {
    if (this.type === 'date' && this.value) {
      const parsedDate = moment(this.value, 'M/D/YYYY');
    }
    console.log('translate', this.translation);

  }
  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = event.target.innerWidth;
    this.checkViewportSize(width);
  }

  checkViewportSize(width) {
    if (width > 767) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const width = window.innerWidth;

    this.checkViewportSize(width);
    if ('selectOptions' in changes) {
      this.parseSelectOptions(this.viewOnlyValue);
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  parseSelectOptions(value) {
    if (this.selectOptions && this.selectOptions.length > 0) {
      this.parsedSelectOptions = [];
      for (const s of this.selectOptions) {
        const selOption: SelectOption = { label: s[this.selectLabelProperty], value: s[this.selectValueProperty], active: 1 };
        this.parsedSelectOptions.push(selOption);
      };
      const selectedItem: SelectOption = this.parsedSelectOptions.find(a => a.value && a.value.toString() === value.toString());
      this.viewOnlySelectLabel = selectedItem !== undefined ? selectedItem.label : '';
      // console.log('bh-input: parseSelectOptions: ', this.selectOptions, this.parsedSelectOptions);
    }
  }

  valueChanged(index, label, ev) {
    const value = ev.detail.value;
    const input = { index, label, value };
    this.valueChangeEvent.emit(input);
    this.showPlaceholder = (value && value !== '');
  }

  handleKeyDown(ev, inputIdPrefix) {
    const input = { index: this.index, label: this.label, value: ev, mask: this.mask };
    // Check mask
    if (this.mask) {
      const formControl = this.formGroup.controls[this.formControlName];
      const value = formControl.value as string;
      const lastCharPos = (value && value.length > 0) ? (value.length - 1) : 0;
      const lastChar = (value && value.length > 0) ? value.charAt(lastCharPos) : '';
      const maskChar = this.mask.charAt(lastCharPos + 1);
      const isIgnoredKey = this.isIgnoredKey(ev);
      const inputElement: HTMLInputElement = document.querySelector('#' + inputIdPrefix + this.formControlName + ' input');
      const selectionStart = inputElement.selectionStart;
      const selectionEnd = inputElement.selectionEnd;
      const endOfInput = (value && value.length > 0) ? value.length === selectionStart : true;
      const hasSelectedRange = selectionStart !== selectionEnd;
      // console.log(`handleKeyDown => holding metaKey: ${ev.metaKey}`);
      // console.log(`handleKeyDown => holding ctrlKey: ${ev.ctrlKey}`);
      // console.log(`handleKeyDown => pressing V key: ${ev.keyCode === 86}`);
      // console.log(`handleKeyDown => new key entry: ${ev.keyCode}`);
      // console.log(`handleKeyDown => is ignored key: ${isIgnoredKey}`);
      // console.log(`handleKeyDown => last char detected: ${lastChar}`);
      // console.log(`handleKeyDown => comparing against mask: ${maskChar}`);
      // console.log(`handleKeyDown => control element:`, inputElement);
      // console.log(`handleKeyDown => value length:`, value.length);
      // console.log(`handleKeyDown => selection: ${selectionStart} through ${selectionEnd} `);
      // console.log(`handleKeyDown => select has range: ${hasSelectedRange} `);
      // console.log(`handleKeyDown => selection end of input: ${endOfInput} `);

      if (!isIgnoredKey) {
        // Check for end of input
        // console.log('Non-backspace detected: ' + ev.keyCode);
        const isBackspacing = ev.keyCode === 8;
        const isDeleting = ev.keyCode === 46;

        // Entering new characters
        if (!isBackspacing && !isDeleting) {
          if (endOfInput) {
            // Refactor
            this.refactorEntry(value, 0, null);

            // Check input character
            const currentPosValid = this.checkMaskCharacter(maskChar, ev.key);
            if (!currentPosValid) {
              return false;
            }
          } else {
            // Key entries occur in middle of input
            // console.log('Inserting characters', value);
            const refactorValue = value.slice(0, selectionStart) + ev.key + value.slice(selectionStart);
            this.refactorEntry(refactorValue, 1, inputElement);
            ev.preventDefault();
          }
        } else {
          // Backspace key detected, deleted masked non-alpha-numeric char
          const prevChar = this.mask.charAt(value.length - 2);
          // console.log('Backspacing --- checking previous character: ' + prevChar);
          if (prevChar !== undefined && !this.isAlphabetic(prevChar) && !this.isNumeric(prevChar)) {
            formControl.setValue(value.substring(0, value.length - 2));
            return false;
          }
        }
      }
    }
  }

  handleKeyUp(ev, inputIdPrefix) {
    if (this.mask) {
      const formControl = this.formGroup.controls[this.formControlName];
      const value = formControl.value as string;
      const lastCharPos = (value.length - 1);
      const lastChar = value.charAt(lastCharPos);
      const maskChar = this.mask.charAt(lastCharPos);
      const inputElement: HTMLInputElement = document.querySelector('#' + inputIdPrefix + this.formControlName + ' input');
      const selectionStart = inputElement.selectionStart;
      const selectionEnd = inputElement.selectionEnd;
      const endOfInput = value.length === selectionStart;

      // Detect backspace -- key == 8 || key == 46
      if (ev.keyCode !== 8 && ev.keyCode !== 46 && endOfInput) {
        // Check next mask character
        const nextChar = this.mask.charAt(value.length);
        // console.log('handleKeyUp: keyCode: ' + ev.keyCode);
        // console.log(`handleKeyUp => checking: ${value} || ${maskChar} || ${nextChar}`);
        if (nextChar !== undefined && !this.isAlphabetic(nextChar) && !this.isNumeric(nextChar) && !this.isWildCard(nextChar)) {
          formControl.setValue(value + nextChar);
        }
      }
    }
  }

  isIgnoredKey(ev) {
    const ignoredKeyCodes = [
      9,    // Tab
      13,   // Enter
      16,   // Shift
      17,   // Ctrl
      18,   // Alt
      20,   // Caps Lock
      27,   // Escape
      33,   // Page Up
      34,   // Page Down
      35,   // End
      36,   // Home
      37,   // Left Arrow
      38,   // Up Arrow
      39,   // Right Arrow
      40,   // Down Arrow
      44,   // Print Screen
      45,   // Insert
      46,   // Delete
      144,  // Num lock
      145,  // Scroll lock
    ];
    return (
      // Check for CTRL/Cmd + V
      ((ev.ctrlKey || ev.metaKey) && ev.keyCode === 86) ||
      // Check for CTRL/Cmd + C
      ((ev.ctrlKey || ev.metaKey) && ev.keyCode === 67) ||
      // Check for CTRL/Cmd + Z
      ((ev.ctrlKey || ev.metaKey) && ev.keyCode === 90) ||
      (ev.ctrlKey) ||
      (ev.metaKey) ||
      // Check for ignored key codes
      ignoredKeyCodes.find(k => ev.keyCode === k) !== undefined
    );
  }

  handlePaste(ev: ClipboardEvent, inputIdPrefix) {
    console.log('handlePaste => Pasting value', ev, ev.clipboardData.getData);
    if (this.mask) {
      const formControl = this.formGroup.controls[this.formControlName];
      const value = formControl.value as string;
      const inputElement: HTMLInputElement = document.querySelector('#' + inputIdPrefix + this.formControlName + ' input');
      const selectionStart = inputElement.selectionStart;
      const selectionEnd = inputElement.selectionEnd;
      const endOfInput = value.length === selectionStart;
      const clipboardText = ev.clipboardData.getData('text');
      const refactorValue =
        // Check for end of input
        (endOfInput) ?
          // Handle end of value paste
          (value + clipboardText)
          :
          // Handle paste insertion
          (value.slice(0, selectionStart) + clipboardText + value.slice(selectionStart));
      this.refactorEntry(refactorValue, clipboardText.length, inputElement);
      ev.preventDefault();
    }
  }

  refactorEntry(value, cursorOffset, inputElement) {
    let newValue = '';
    // Define cursor position
    const cursorPos = (inputElement) ? inputElement.selectionEnd + cursorOffset : 0;
    if (this.mask && value) {
      let cleanedValue = '';
      // Strip any special characters
      for (const char of value) {
        cleanedValue += (this.isAlphabetic(char) || this.isNumeric(char)) ? char : '';
      }
      let valCharPos = 0;
      let lookAhead = true;
      for (const maskChar of this.mask) {
        const valChar = cleanedValue.charAt(valCharPos);
        // console.log(`checking valChar: ${valChar} of ${cleanedValue}`);
        if (valChar) {
          if (this.isNumeric(maskChar) || this.isAlphabetic(maskChar) || this.isWildCard(maskChar)) {
            // Check for character
            const isValidChar = this.checkMaskCharacter(maskChar, valChar);
            // console.log(`refactorEntry: validMaskedChar: ${char} === ${valChar} (${valCharPos}) || ${isValidChar}`);
            newValue += (isValidChar) ? valChar : '';
            valCharPos += 1;
          } else {
            // Enter non-alpha-numeric
            newValue += maskChar;
          }
        } else {
          // Look ahead to next character and populate special character in mask
          if (lookAhead) {
            lookAhead = false;
            if (!this.isNumeric(maskChar) && !this.isAlphabetic(maskChar) && !this.isWildCard(maskChar)) {
              newValue += maskChar;
            }
          }
        }
      }
    } else {
      newValue = value;
    }

    // Set control value
    const formControl = this.formGroup.controls[this.formControlName];
    formControl.setValue(newValue ? newValue.substring(0, this.mask.length) : '');

    // Set cursor position
    if (inputElement) {
      inputElement.setSelectionRange(cursorPos, cursorPos);
    }
  }

  checkMaskCharacter(maskChar, valChar): boolean {
    let validChar = true;
    if (maskChar !== undefined && maskChar) {
      if (this.isNumeric(maskChar)) {
        if (!this.isNumeric(valChar)) {
          console.log('Numeric mask violated by: ' + valChar);
          validChar = false;
        }
      } else if (this.isAlphabetic(maskChar)) {
        if (!this.isAlphabetic(valChar) && maskChar !== valChar) {
          console.log('Alpha mask violated by: ' + valChar);
          validChar = false;
        }
        // } else if (
        //   (this.isNumeric(maskChar) || this.isAlphabetic(maskChar)) &&
        //   !this.isNumeric(valChar) &&
        //   !this.isAlphabetic(valChar) &&
        //   maskChar !== valChar
        // ) {
        //   console.log('Non-alpha-numeric mask violated but ')
        //   validChar = false;
      } else if (this.isWildCard(maskChar) && !this.isAlphabetic(valChar) && !this.isNumeric(valChar)) {
        validChar = false;
      } else {
        if ((this.isNumeric(maskChar) || this.isAlphabetic(maskChar)) && maskChar !== valChar) {
          console.log('Non-alpha-numeric mask violated by: ' + valChar);
          validChar = false;
        }
      }
    } else {
      validChar = false;
    }
    return validChar;
  }

  isNumeric(k) {
    // return !isNaN(k);
    return new RegExp('^[0-9]*$').test(k);
  }

  isAlphabetic(k) {
    return new RegExp('^[a-zA-Z]*$').test(k);
  }

  isWildCard(k) {
    return k === '*';
  }

  blurInput(ev) {
    const value = this.formGroup.controls[this.formControlName].value;
    // console.log('Blur value', value);
    this.blurEvent.emit(value);
  }

  compareValues(a, b) {
    // console.log('comparing a|b', a, b, a === b);
    return a === b;
  }

  showHelperTip() {
    console.log('Showing helper tip');
  }


}
