import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/service/modal.service';


@Component({
  selector: 'app-ask-a-librarian',
  templateUrl: './ask-a-librarian.component.html',
  styleUrls: ['./ask-a-librarian.component.scss'],
})
export class AskALibrarianComponent  {

 // emailIsChecked: boolean = true;

  constructor() { }

}
