import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})
export class SocialHabitsService {

  constructor(private _httpBaseService: HttpBaseService
    ) { }

    async getSocialHabits(user?: UserIdentifier) {
      const response = await this._httpBaseService.getList('/ehr/category/socialHx', true, user);
      // Sort SocialHx by latest date.
      response.SocialHistories = response.SocialHistories.sort((a, b) => {
        let aDateToSort;
        let bDateToSort;

        if (a.UpdatedTime !== '') {
          aDateToSort = a.UpdatedTime;
        } else if (a.EnteredTime !== '') {
          aDateToSort = a.EnteredTime;
        }

        if (b.UpdatedTime !== '') {
          bDateToSort = b.UpdatedTime;
        } else if (b.EnteredTime !== '') {
          bDateToSort = b.EnteredTime;
        }

        return (Date.parse(bDateToSort) - Date.parse(aDateToSort));
      });
      return response.SocialHistories;
    }
}
