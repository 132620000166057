import { Injectable } from '@angular/core';
import {ExpressionParserService} from "./expression-parser.service";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class FormsExportService {

  constructor(private expressionParserService: ExpressionParserService) { }

  public generateFormData(sampleForm: any, patientForm: FormGroup) {
    let dataFields: { [key: string]: any } = {};
    let htmlOutput = new String("<html> <body>\n" +  this.HTML_STYLE +  "\n<div style='padding: 20px;'> <center> <h1>" + sampleForm._Body.FormDefinition.name + "</h1> </center>\n");
    for (let section of sampleForm._Body.FormDefinition.sections) {
      if (section.hideExpression == null || (section.hideExpression != null && !this.expressionParserService.evalExpression(section.hideExpression, patientForm))) {
        if (section.name != null) {
          htmlOutput = htmlOutput.concat(
            "<div style='border: 1px solid lightgrey; padding: 20px;'> <h2>" +
            section.name +
            "</h2> <hr>\n");

          let columns = null;
          if (section.data != null && section.data.layout != null && section.data.layout.columns != null) {
            columns = section.data.layout.columns
          }
          htmlOutput = htmlOutput.concat(this.generateFormFieldData(section.fields, dataFields, patientForm, columns).toString());
          htmlOutput = htmlOutput.concat("</div>\n");
        }
      }
    }
    htmlOutput = htmlOutput.concat("</div> </body> </html>\n");

    let formData = {
      "formData": {
        "formKey": sampleForm._Header._ConsumerID,
        "formVersion": sampleForm._Header._RevisionNumber,
        "data": dataFields,
      },
      "fullHtml": htmlOutput.toString()
    };
    return formData;
  }

  private generateFormFieldData(fields: any, dataFields: any, patientForm: FormGroup, columns: any): String {
    var htmlOutput = "";

    if (fields != null && fields.length > 0) {
      if (columns!= null) {
        if (Number.isInteger(columns)) {
          htmlOutput = htmlOutput.concat("<table style='width: 100%;table-layout: fixed;'>\n");
        } else {
          htmlOutput = htmlOutput.concat("<table style='width: 100%;'>\n");
        }
        for (var prop in columns) {
          htmlOutput = htmlOutput.concat("<col style='width:" + columns[prop]+ "'>\n")
        }
        htmlOutput = htmlOutput.concat("<tbody>\n")
        htmlOutput = htmlOutput.concat("<tr>\n")
      } else {
        htmlOutput = htmlOutput.concat("<table style='width: 100%;table-layout: fixed;'>\n");
      }
      for (let field of fields) {
        if (field.hideExpression == null || (field.hideExpression != null && !this.expressionParserService.evalExpression(field.hideExpression, patientForm))) {
          if (columns == null) {
            htmlOutput = htmlOutput.concat("<tr>\n")
          }
          htmlOutput = htmlOutput.concat("<td>\n")
          if (field.key != null && field.type == 'checkbox' && patientForm.get(field.key) != null) {
            dataFields[field.key] = patientForm.get(field.key).value;
            if (patientForm.get(field.key).value == true) {
              //htmlOutput = htmlOutput.concat("<input type='checkbox' checked>&nbsp;&nbsp;\n")
              htmlOutput = htmlOutput.concat("<label class=\"container\">\n")
              if (field.templateOptions != null && field.templateOptions.label != null) {
                htmlOutput = htmlOutput.concat(field.templateOptions.label);
              }
              htmlOutput = htmlOutput.concat("  <input type=\"checkbox\" checked=\"checked\">\n")
              htmlOutput = htmlOutput.concat("  <span class=\"checkmark\"></span>\n")
              htmlOutput = htmlOutput.concat("</label>\n")
            } else {
              //htmlOutput = htmlOutput.concat("<input type='checkbox'>&nbsp;&nbsp;\n")
              htmlOutput = htmlOutput.concat("<label class=\"container\">\n")
              if (field.templateOptions != null && field.templateOptions.label != null) {
                htmlOutput = htmlOutput.concat(field.templateOptions.label);
              }
              htmlOutput = htmlOutput.concat("  <input type=\"checkbox\">\n")
              htmlOutput = htmlOutput.concat("  <span class=\"checkmark\"></span>\n")
              htmlOutput = htmlOutput.concat("</label>\n")
            }
          } else if (field.key != null && field.type == 'datePicker' && patientForm.get(field.key) != null) {
//            "data":{"content":"Date of Birth:"}},{"type":"datePicker","key":"dataField2","data":{"date":{"format":"MM-DD-YYYY"}}}
//            var dateFormat = field.data.date.format;
//            if (dateFormat == null) {
//              dateFormat = "MM-DD-YYYY";
//            }

            dataFields[field.key] = patientForm.get(field.key).value;
            if (field.templateOptions != null && field.templateOptions.label != null) {
              htmlOutput = htmlOutput.concat(field.templateOptions.label);
            }
            htmlOutput = htmlOutput.concat(
              "<div style='border: 1px solid lightgrey; padding: 10px;'>" +
              this.dateToYMD(patientForm.get(field.key).value) +
              "</div>\n");
          } else if (field.type != null && field.type == 'instructions' && field.templateOptions != null && field.templateOptions != null && field.data.content != null) {
            htmlOutput = htmlOutput.concat(
              "<p>" +
              field.templateOptions.label +
              "</p>\n" +
              "<p>" +
              field.data.content +
              "</p>\n");
          } else if (field.type != null && field.type == 'instructions' && field.templateOptions == null && field.data.content != null) {
            htmlOutput = htmlOutput.concat(field.data.content);
          } else if (field.templateOptions != null && field.templateOptions.label != null && field.type == null) {
            htmlOutput = htmlOutput.concat(field.templateOptions.label);
          } else if (field.key != null && patientForm.get(field.key) != null) {
            dataFields[field.key] = patientForm.get(field.key).value;
            if (field.templateOptions != null && field.templateOptions.label != null) {
              htmlOutput = htmlOutput.concat(field.templateOptions.label);
              htmlOutput = htmlOutput.concat("<br/><br/>");
            }
            htmlOutput = htmlOutput.concat(
              "<div style='border: 1px solid lightgrey; padding: 10px;'>" +
              patientForm.get(field.key).value +
              "</div>\n");
          }
          if (field.fieldGroup != null) {
            let fieldColumns = null;
            if (field.data != null && field.data.layout != null && field.data.layout.columns != null) {
              fieldColumns = field.data.layout.columns
            }
            htmlOutput = htmlOutput.concat((this.generateFormFieldData(field.fieldGroup, dataFields, patientForm, fieldColumns).toString()));
          }
          htmlOutput = htmlOutput.concat("</td>\n")
          if (columns == null) {
            htmlOutput = htmlOutput.concat("</tr>\n")
          }
        }
      }
      if (columns!= null) {
        htmlOutput = htmlOutput.concat("</tr>\n")
        htmlOutput = htmlOutput.concat("</tbody>\n")
      }
      htmlOutput = htmlOutput.concat("</table>\n")
    }

    return htmlOutput;
  }

  private dateToYMD(date) {
    if (date != null && typeof date.getMonth === 'function') {
      var d = date.getDate();
      var m = date.getMonth() + 1; //Month from 0 to 11
      var y = date.getFullYear();
      return '' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d) + '-' + y;
    } else {
      return ''
    }
  }

  private HTML_STYLE = `
  <style>

  html {
    font-family: Helvetica;
    zoom: 0.60;
  }

  body {
    font-family: Helvetica;
    display: block;
    margin: 8px;
  }

  div {
    font-family: Helvetica;
    display: block;
  }

  b {
    font-family: Helvetica;
    font-weight: bold;
  }

  h1 {
    display: block;
    font-family: Helvetica;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-family: Helvetica;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  hr {
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: inset;
    border-width: 1px;
  }

  table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    white-space: normal;
    line-height: normal;
    font-family: Helvetica;
    font-weight: normal;
    font-size: medium;
    font-style: normal;
    text-align: start;
    border-spacing: 2px;
    border-color: grey;
    font-variant: normal;
  }

  td {
    line-height: 120%;
    padding: 5px;
  }

  input {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: textfield;
    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: 0em;
    font: 400 13.3333px Helvetica;
    padding: 1px 2px;
    border-width: 2px;
    border-style: inset;
    border-image: initial;
  }

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-top: 0px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: inline-block;
}

.container .checkmark:after {
  left: 6px;
  top: 0px;
  width: 3px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

</style>`;


}
