import { PatientCheckinService } from 'src/app/service/patient-checkin.service';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { PatientCheckinData, PciLocation } from './../../../../model/PatientCheckinData';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs/operators';

@Component({
  selector: 'bh-pci-information',
  templateUrl: './pci-information.component.html',
  styleUrls: ['./pci-information.component.scss'],
})
export class PciInformationComponent implements OnChanges {
  @Input() checkinData: PatientCheckinData;
  @Output() next: EventEmitter<PatientCheckinData> = new EventEmitter();
  env = environment;

  constructor(
    public router: Router,
    public analytics: BhanalyticsService,
    private pciService: PatientCheckinService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
  }

  /**
   * Continue to next page
   */
  nextPage() {
    this.checkinData.checkin_method = 'MyBaystate';
    // Temp variable for initial PCI launch
    // this.checkinData.dli_seq = this.env.PCI_LOC_LOOKUP_ID;
    this.pciService.getLocationInfo(this.env.PCI_LOC_LOOKUP_ID).pipe(first()).subscribe((val: PciLocation) => {
      console.log('Location info', val);
      this.checkinData.location = val;
      this.analytics.clickEvent('PCI-Instructions: NextButton', 'Form valid');
      this.next.emit(this.checkinData);
    },
      (err) => {
        console.log(err);
      });
  }

}
