import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/service/constants.service';
import { AwsTranslateService } from '../../../../service/aws-translate.service';

@Component({
  selector: 'app-call-us-popover',
  templateUrl: './call-us-popover.component.html',
  styleUrls: ['./call-us-popover.component.scss'],
})
export class CallUsPopoverComponent implements OnInit {

  constructor(public constantsService: ConstantsService, public AWSTranslate: AwsTranslateService) { }

  ngOnInit() {}

}
