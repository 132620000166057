import { Injectable } from '@angular/core';
import {ConstantsService} from "./constants.service";
import {HttpClient} from "@angular/common/http";
import {HttpBaseService} from "./http-base.service";
import { PharmacySearchQuery } from '../model/Pharmacy';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {

  constructor(private _httpBaseService: HttpBaseService, private constantsService: ConstantsService,
              private httpClient: HttpClient) { }

  async getProviders(searchKey: string) {

    const url = `${this.constantsService.constantsData.apiUrl}/messages/search/recipients`;

    const response = await this.httpClient.post(url,
      "{ SearchKey: \"" + searchKey + "\", MessageType: \"refill\"}",
    { headers: { 'SMC-Ignore-500-Error': 'true' } }).toPromise() as any;
    if (response == undefined) {
      return [];
    }

    return response['children'];
  }

  async getAllPharmacies() {
    const url = `${this.constantsService.constantsData.apiUrl}/messages/search/pharmacies`;

    const response = await this.httpClient.post(url, {"MessageType":"refill"}).toPromise() as any;

    return response['children'];
  }

  async getFavoritePharmacies() {
    let allPharmacies = await this.getAllPharmacies();
    let favorites = [];

    for (let i in allPharmacies){
      if (allPharmacies[i]['Preferred'] == 1) {
         favorites.push(allPharmacies[i]);
      }
    }
    return favorites;
  }

  // async searchPharmaciesList(query: PharmacySearchQuery): Promise<any> {
  //   const url =
  //     this.constantsService.constantsData.apiUrl +
  //     "/messages/search/pharmacies";
  //   const response = await this.httpClient.post(url, {"MessageType":"refill"}).toPromise() as any;
  //   if (response === undefined) {
  //     const newResponse = [] as unknown;
  //     return newResponse as Promise<any>;
  //   }
  //   return response as Promise<any>;
  // }

  async addFavoritePharmacy(pharmacyid: string) {
    let url ="/users/0/preferredPharmacies/" + pharmacyid;
    await this._httpBaseService.post({},url);
  }

  async deleteFavoritePharmacy(pharmacyid: string) {
    let url ="/users/0/preferredPharmacies/" + pharmacyid;
    await this._httpBaseService.delete(url);
  }

  async getPharmacies(searchKey: string) {

    const url = `${this.constantsService.constantsData.apiUrl}/messages/search/pharmacies`;

    const response = await this.httpClient.post(url, "{SearchKey: \"" + searchKey + "\"}").toPromise() as any;

    return response['children'];
  }

  async refillRequest(data: string) {

    //console.log("refill request data: " + data);
    const url = `${this.constantsService.constantsData.apiUrl}/messages/send/refillRequest`;
    /*
    {
       "Subject": "string",
       "Text": "string",
       "To": [ "string" ],
       "Pharmacy": "string",
       "PharmacyText": "string",
       "ContactType": "string",
       "ContactInfo": "string",
       "ContactInstructions": "string",
       "MedicationText": "string",
       "Medications": [
         {
           "FillerID": "string",
           "PlacerID": "string",
           "PrescriptionNumber": "string",
           "Code": "string",
           "CodeSystem": "string",
           "MedicationTranslated": "string",
           "DoseQuantity": "string",
           "DoseUnits": "string",
           "Frequency": "string",
           "Route": "string",
           "Provider": "string"
         } ]
     }
     */

    const response = await this.httpClient.post(url, data).toPromise() as any;

    return response['children'];
  }
}
