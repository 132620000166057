import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-share-health-record',
  templateUrl: './share-health-record.component.html',
  styleUrls: ['./share-health-record.component.scss'],
})
export class ShareHealthRecordComponent implements OnInit {
  env = environment;
  constructor(
  ) { 
  }

  ngOnInit() {
  }


  onActivate(event) {
    const pageContent = document.querySelector('.page-content');
    if (pageContent) {
      pageContent.scrollTo(0, 0);
    }
  }

}
