import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/service/account.service';
import { AccountSummaryService } from 'src/app/service/account-summary.service';
import { StateService } from 'src/app/service/state.service';
import { ModalController, PopoverController } from '@ionic/angular';
import { ChangePasswordModalComponent } from '../../change-password-modal/change-password-modal.component';
import { ChangeEmailModalComponent } from '../../change-email-modal/change-email-modal.component';
import { SMCDataList } from 'src/app/model/SMCDataList';
import { PersonalInformationService } from 'src/app/service/personal-information.service';
import { PersonalCommunityUser } from 'src/app/model/PersonalCommunityUser';
import { AuthenticateService } from 'src/app/service/authenticate.service';
import { BhanalyticsService } from "../../../../service/bhanalytics.service";
import { ModalService } from 'src/app/service/modal.service';
import { ConstantsService } from 'src/app/service/constants.service';
import { LanguagesDropdownComponent } from 'src/app/component/shared/languages-dropdown/languages-dropdown.component';
import { AwsTranslateService } from '../../../../service/aws-translate.service';


@Component({
  selector: 'app-account-preferences',
  templateUrl: './account-preferences.component.html',
  styleUrls: ['./account-preferences.component.scss'],
})
export class AccountPreferencesComponent {

  public accountSummary: AccountSummary;
  public Name: string;
  public proxies: PersonalCommunityUser[];
  public proxiesFor: PersonalCommunityUser[];
  public showHistory: boolean = false;
  public proxiesHistory: PersonalCommunityUser[] = [];
  public currentlyViewingLoggedInUser: boolean;



  contactInformation: SMCDataList;
  enteredAt: string;
  aws = this.constantsService.constantsData["awsConfig"];

  constructor(
    private _stateService: StateService,
    private _accountSummaryService: AccountSummaryService,
    private _accountService: AccountService,
    public modalController: ModalController,
    private personalInformationService: PersonalInformationService,
    private _authenticateService: AuthenticateService,
    private _bhAnalyticsService: BhanalyticsService,
    private _modalService: ModalService,
    private analytics: BhanalyticsService,
    private constantsService: ConstantsService,
    private popoverController: PopoverController,
    public AWSTranslate: AwsTranslateService
  ) { }


  async ngOnInit() {
    // set boolean indicating whether the logged in user is viewing themselves or someone they have proxy access to
    this._authenticateService.getCachedAuthStatus().subscribe(
      authStatus => this.currentlyViewingLoggedInUser = (authStatus.EffectiveUserID == authStatus.LoggedInUserID)
    );

    const summary = await this._accountSummaryService.getSummary();
    this.accountSummary = summary;
    this._stateService.getPatientName().subscribe(val => this.Name = val);
    const proxyData = await this._accountService.GetProxyData();
    if (proxyData) {
      if (proxyData["Proxies"]) {
        this.proxies = proxyData["Proxies"].filter(proxy => proxy.DateCreated != null && proxy.Expired == 0);
        this.proxiesHistory = proxyData["Proxies"].filter(proxy => proxy.DateCreated != null && proxy.Expired == 1);
      }
      if (proxyData["ProxyFor"]) {
        this.proxiesFor = proxyData["ProxyFor"].filter(proxy => proxy.DateCreated != null && proxy.Expired == 0);
        let proxiesForHistory = proxyData["ProxyFor"].filter(proxy => proxy.DateCreated != null && proxy.Expired == 1);
        for (let index = 0; index < proxiesForHistory.length; index++) {
          const element = proxiesForHistory[index];
          this.proxiesHistory.push(element);
        }
      }
    }
    // let langId = localStorage.getItem("BSPP2.0LangOpt") || "en";
    // document.getElementById("sp_langId").innerText = this.translationOverride[this.languageName[langId]][langId]
    document.getElementById("sp_langId").innerText = this.aws.languages[this.AWSTranslate.langId];;
  }

  public async openChangePasswordModal() {
    const modal = await this.modalController.create({
      component: ChangePasswordModalComponent,
      id: "ChangePasswordModal"
    });
    this._modalService.register(modal);
    this._bhAnalyticsService.clickEvent('Open Change Password Modal', '');
    return await modal.present();
  }

  public async openChangeEmailModal() {
    const modal = await this.modalController.create({
      component: ChangeEmailModalComponent,
      componentProps: { email: this.accountSummary.Email },
      id: "ChangeEmailModal"
    });
    this._modalService.register(modal);

    modal.onDidDismiss().then((data) => {
      if (data && data.data) {
        this.accountSummary.Email = data.data.email;
      }
    });
    this._bhAnalyticsService.clickEvent('Open Change Email Modal', '');
    return await modal.present();
  }

  formatAddressTimeframe = (addressObject: { AddressFromTime: string, AddressToTime: string }) =>
    `(Address Start Date: ${addressObject.AddressFromTime || 'not specified'}, Address End Date: ${addressObject.AddressToTime || 'not specified'})`;

  async selectProxy(userId: string, stagedUserID: string, displayName: string) {
    this.analytics.switchUser('preferences', userId);
    await this._authenticateService.SwitchUser({ UserID: userId, StagedUserID: stagedUserID });
    this._stateService.setPatientName(displayName);
    location.reload();
    this._bhAnalyticsService.clickEvent('Select Proxy', userId);
  }

  async LoadProxyHistory() {
    this.showHistory = true;
    this._bhAnalyticsService.clickEvent('Load Proxy History', '');
  }

  toPossessive(name: string) {
    if (!name) return '';
    const endsWithS = name.toLowerCase()[name.length - 1] == 's';
    return name + (endsWithS ? "'" : "'s");
  }

  /* for lanugage dropdown */

  public async openLanguagesDropdown(event: MouseEvent) {
    this.analytics.clickEventPromise('my-account: change-language', '');
    const popover = await this.popoverController.create({
      component: LanguagesDropdownComponent,
      event: event,
      translucent: true
    });
    await popover.present();
  }
}
