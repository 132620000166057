import { BhUrgentDisclaimerComponent } from './component/shared/bh-urgent-disclaimer/bh-urgent-disclaimer.component';
import { PublicSymptomCheckerComponent } from './component/page/public-symptom-checker/public-symptom-checker.component';
import { BhCovidInfoComponent } from './component/shared/bh-covid-info/bh-covid-info.component';
import { BhInfoCardComponent } from './component/shared/info-card/info-card.component';
import { BhPageHeadingComponent } from './component/shared/page-heading/page-heading.component';
import { PciAppointmentPromptComponent } from './component/page/patient-checkin/pci-appointment-prompt/pci-appointment-prompt.component';
import { PciServiceLinesComponent } from './component/page/patient-checkin/pci-service-lines/pci-service-lines.component';
import { PciMessageBannerComponent } from './component/page/patient-checkin/pci-message-banner/pci-message-banner.component';
import { PublicPciConfirmationComponent } from './component/page/patient-checkin/public-pci-confirmation/public-pci-confirmation.component';
import { PublicPciCheckinNowComponent } from './component/page/patient-checkin/public-pci-checkin-now/public-pci-checkin-now.component';
import { LoginFormComponent } from './component/shared/login-form/login-form.component';
import { PciCheckinNowComponent } from './component/page/patient-checkin/pci-checkin-now/pci-checkin-now.component';
import { BhDockedButtonsComponent } from './component/shared/bh-docked-buttons/bh-docked-buttons.component';
import { PciServiceQuestionsComponent } from './component/page/patient-checkin/pci-service-questions/pci-service-questions.component';
import { PciInformationComponent } from './component/page/patient-checkin/pci-information/pci-information.component';
import { Device } from '@ionic-native/device/ngx';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, ErrorHandler } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatToolbarModule, MatButtonModule, MatCardModule, MatInputModule, MatFormFieldModule, MatDividerModule, MatGridListModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatTabsModule, MatAutocompleteModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule } from "@angular/material";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from "@ionic/storage";
import { HttpModule } from '@angular/http';
import { Printer } from '@ionic-native/printer/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicModule } from '@ionic/angular';
import { LoginPageComponent } from './component/page/login-page/login-page.component';
import { LandingPageComponent } from './component/page/landing-page/landing-page.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AccountComponent } from './component/page/account/account.component';
import { HealthRecordComponent } from "./component/page/health-record/health-record.component";
import { HealthStatsComponent } from './component/page/health-stats/health-stats.component';
import { MainNavigationComponent } from './component/page/main-navigation/main-navigation.component';
import { MessagesComponent } from './component/page/messages/messages.component';
import { ResourcesComponent } from './component/page/resources/resources.component';
import { RequestAppointmentComponent } from './component/page/request-appointment/request-appointment.component';
import { RefillPrescriptionComponent } from './component/page/request-prescription/refill-prescription.component';
import { BreadcrumbComponent } from './component/shared/breadcrumb/breadcrumb.component';
import { MedicinesComponent } from './component/page/medicines/medicines.component';

import { SMCNavigationGridComponent } from "./component/shared/smc-navigation-grid/smc-navigation-grid.component";
import { HttpInterceptorService } from './service/http-interceptor.service';
import { LoginRouteGuardService } from './service/login-route-guard.service';
import { AboutUsComponent } from './component/page/about-us/about-us.component';
import { ContactUsComponent } from './component/page/contact-us/contact-us.component';
import { PrivacyComponent } from './component/page/privacy/privacy.component';
import { EnrollmentTermsOfUseComponent } from "./component/page/enroll/terms-of-use/terms-of-use.component";
import { CreateAnAccountComponent } from './component/page/enroll/create-an-account/create-an-account.component';
import { EnrollmentRouteGuardService } from './service/enrollment-route-guard.service';
import { ModalComponent } from './component/shared/modal/modal.component';
import { HelpComponent } from './component/page/help/help.component';
import { ForgotCredentialsComponent } from './component/page/forgot-credentials/forgot-credentials.component';
import { ResetPasswordComponent} from "./component/page/reset-password/reset-password.component";
import { SymptomCheckerComponent } from "./component/page/symptom-checker/symptom-checker.component";
import { ScBasicInfoComponent } from "./component/page/symptom-checker/sc-basic-info/sc-basic-info.component";
import { ScCausesComponent } from "./component/page/symptom-checker/sc-causes/sc-causes.component";
import { ScFindcareComponent } from "./component/page/symptom-checker/sc-findcare/sc-findcare.component";
import { ActivateAccountComponent } from './component/page/activate-account/activate-account.component';
import { FormsListComponent } from "./component/page/patient-forms/forms-list/forms-list.component";
import { AskALibrarianComponent } from "./component/page/ask-a-librarian/ask-a-librarian.component";
import { AuthenticatedComponent } from './component/page/authenticated/authenticated.component';
import { AllergiesComponent } from './component/page/allergies/allergies.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxChartsModule, TooltipModule } from '@swimlane/ngx-charts';
import { VaccinationsComponent } from './component/page/vaccinations/vaccinations.component';
import {SmcTableModule} from "./component/shared/smc-table/smc-table.component.module";
import { InboxComponent } from './component/page/messages/inbox/inbox.component';
import { ConcernsComponent } from './component/page/concerns/concerns.component';
import { HealthConditionsComponent } from './component/page/health-conditions/health-conditions.component';
import { MyVisitsComponent } from './component/page/my-visits/my-visits.component';
import { HealthStatDetailComponent } from './component/page/health-stat-detail/health-stat-detail.component';
import { SentMessagesComponent } from './component/page/messages/sent-messages/sent-messages.component';
import { ArchivedMessagesComponent } from './component/page/messages/archived-messages/archived-messages.component';
import { TestResultsComponent } from './component/page/test-results/test-results.component';
import { AppointmentDetailsComponent } from './component/page/appointment-details/appointment-details.component';
import { TestResultDetailsComponent } from './component/page/test-result-details/test-result-details.component';
import { Ng5SliderModule } from 'ng5-slider';
import { PersonalInformationComponent } from './component/page/personal-information/personal-information.component';
import { SMCDataListComponent } from './component/shared/smc-data-list/smc-data-list.component';
import { SMCWorkflowComponent} from "./component/shared/smc-workflow/smc-workflow.component";
import {RxSelectMedicineComponent} from "./component/page/request-prescription/rx-select-medicine/rx-select-medicine.component";
import {RxSelectProviderComponent} from "./component/page/request-prescription/rx-select-provider/rx-select-provider.component";
import {RxSelectPharmacyComponent} from "./component/page/request-prescription/rx-select-pharmacy/rx-select-pharmacy.component";
import {RxContactInfoComponent} from "./component/page/request-prescription/rx-contact-info/rx-contact-info.component";
import {RxReviewComponent} from "./component/page/request-prescription/rx-review/rx-review.component";
import {RxCompleteComponent} from "./component/page/request-prescription/rx-complete/rx-complete.component";
import { DatePipe, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AccountPreferencesComponent } from './component/page/account/account-preferences/account-preferences.component';
import { AccountHistoryComponent } from './component/page/account/account-history/account-history.component';
import { ChangePasswordModalComponent } from './component/page/change-password-modal/change-password-modal.component';
import { ChangeEmailModalComponent } from './component/page/change-email-modal/change-email-modal.component';
import { ShareHealthRecordComponent } from './component/page/share-health-record/share-health-record.component';
import { ShareHealthRecordOptionsComponent } from './component/page/share-health-record/share-health-record-options/share-health-record-options.component';
import { ShareHealthRecordWithProviderComponent } from './component/page/share-health-record/share-health-record-with-provider/share-health-record-with-provider.component';
import { ShareHealthRecordWithProviderDoneComponent } from './component/page/share-health-record/share-health-record-with-provider-done/share-health-record-with-provider-done.component';
import { ImageTestResultComponent } from './component/page/image-test-result/image-test-result.component';
import { ViewRadImageModalComponent } from './component/page/image-test-result/view-rad-image-modal/view-rad-image-modal.component';
import { RadImageInstructionalVideoModalComponent } from './component/page/image-test-result/rad-image-instructional-video-modal/rad-image-instructional-video-modal.component';
import { ShareWithExternalProviderModalComponent } from './component/page/image-test-result/share-with-external-provider-modal/share-with-external-provider-modal.component';
import { ReportsComponent } from './component/page/reports/reports.component';
import { ErrorHandlerService } from './service/error-handler.service';
import { UpcomingVisitComponent } from './component/page/my-visits/upcoming-visit/upcoming-visit.component';
import { PastVisitComponent } from './component/page/my-visits/past-visit/past-visit.component';
import { SmcInboxTableComponent } from './component/shared/smc-inbox-table/smc-inbox-table.component';
import {AptSelectProviderComponent} from "./component/page/request-appointment/apt-select-provider/apt-select-provider.component";
import {AptScheduleComponent} from "./component/page/request-appointment/apt-schedule/apt-schedule.component";
import {AptContactInfoComponent} from "./component/page/request-appointment/apt-contact-info/apt-contact-info.component";
import {AptReasonComponent} from "./component/page/request-appointment/apt-reason/apt-reason.component";
import {AptReviewComponent} from "./component/page/request-appointment/apt-review/apt-review.component";
import {AptCompleteComponent} from "./component/page/request-appointment/apt-complete/apt-complete.component";
import { AppleHealthSetupComponent } from './component/page/apple-health-setup/apple-health-setup.component';
import { HealthDictionaryComponent } from './component/page/health-dictionary/health-dictionary.component';
import { GoalsComponent } from './component/page/goals/goals.component';
import { AccountMenuComponent } from './component/shared/account-menu/account-menu.component';
import {FormsSubmissionCreateTabComponent} from "./component/page/patient-forms/forms-submission-create/forms-submission-create-tab.component";
import {NgxPrintModule} from 'ngx-print';
import {FormFieldsTemplateComponent} from "./component/page/patient-forms/form-fields-template/form-fields-template.component";
import {FormsSubmissionCompleteComponent} from "./component/page/patient-forms/forms-submission-complete/forms-submission-complete.component";
import { ReportDetailComponent } from './component/page/reports/report-detail/report-detail.component';
import { SmcTextInputComponent } from './component/shared/smc-text-input/smc-text-input.component';
import { SmcFormTextInputComponent } from './component/shared/smc-form-text-input/smc-form-text-input.component';
import { SmallScreenOnlyComponent } from './component/shared/small-screen-only/small-screen-only.component';
import { LargeScreenOnlyComponent } from './component/shared/large-screen-only/large-screen-only.component';
import { RequestAppointmentChangeModalComponent } from './component/page/my-visits/upcoming-visit/request-appointment-change-modal/request-appointment-change-modal.component';
import { FindProviderModalComponent } from './component/page/my-visits/find-provider-modal/find-provider-modal.component';
import { UploadPhotosModalComponent } from './component/page/messages/upload-photos-modal/upload-photos-modal.component';
import { TabsComponent } from './component/shared/tabs/tabs.component';
import { SmcRoleHideComponent } from './component/shared/smc-role-hide/smc-role-hide.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { PastResultsModalComponent } from './component/page/test-result-details/past-results-modal/past-results-modal.component';
import './utils/ListHelpers';
import './utils/DateHelpers';
import {NativeElementInjectorDirective} from "./directive/native-element-injector.directive";
import { BackPanePageComponent } from './component/shared/back-pane-page/back-pane-page.component';
import { SelectUserListComponent } from './component/shared/select-user-list/select-user-list.component';
import { AppFooterComponent } from './component/shared/app-footer/app-footer.component';
import {SmcFormFieldComponent} from "./component/shared/smc-form-field/smc-form-field.component";
import { LoginTermsAndConditionsModalComponent } from './component/page/login-terms-and-conditions-modal/login-terms-and-conditions-modal.component';
import { SmcDatePipe } from './pipe/smc-date.pipe';
import { DiagnosisFilter } from './pipe/diagnosisPipe';
import { ResourcesDropdownComponent } from './component/shared/resources-dropdown/resources-dropdown.component';
import { CallUsPopoverComponent } from './component/page/help/call-us-popover/call-us-popover.component';
import { PublicResourcesComponent } from './component/page/public-resources/public-resources.component';
import { LockedTileModalComponent } from './component/shared/smc-navigation-grid/locked-tile-modal/locked-tile-modal.component';
import { PublicHealthDictionaryComponent } from './component/page/public-health-dictionary/public-health-dictionary.component';
import { EnrollSuccessComponent } from './component/page/enroll/enroll-success/enroll-success.component';
import { PasswordResetSuccessComponent } from './component/page/reset-password/password-reset-success/password-reset-success.component';
import { RequestAChangeModalComponent } from './component/page/account/request-achange-modal/request-achange-modal.component';
import { ChangePasswordComponent } from './component/page/change-password/change-password.component';
import { TestResultComponentDetailsComponent } from './component/page/test-result-component-details/test-result-component-details.component';
import { ResultHistoryComponent } from './component/shared/result-history/result-history.component';
import { PasswordChangeFormComponent } from './component/shared/password-change-form/password-change-form.component';
import { ChangePasswordDoneComponent } from './component/page/change-password-done/change-password-done.component';
import { PublicAppleHealthSetupComponent } from './component/page/public-apple-health-setup/public-apple-health-setup.component';
import { AllDonePageComponent } from './component/shared/all-done-page/all-done-page.component';
import { ShareHealthRecordDownloadSuccessComponent } from './component/page/share-health-record/share-health-record-download-success/share-health-record-download-success.component';
import { PciPatientInfoComponent } from './component/page/patient-checkin/pci-patient-info/pci-patient-info.component';
import { PciConfirmationComponent } from './component/page/patient-checkin/pci-confirmation/pci-confirmation.component';
import { LoginFormModalComponent } from './component/shared/login-form-modal/login-form-modal.component';
import {NgxMaskIonicModule} from 'ngx-mask-ionic';
import { PciLocationComponent } from './component/page/patient-checkin/pci-location/pci-location.component';
import { LanguagesDropdownComponent } from './component/shared/languages-dropdown/languages-dropdown.component';
import { BannerComponent} from './component/shared/banner/banner.component';
import { FeedbackPageComponent } from './component/shared/feedback/feedback.component';
import { BhTaskComponent } from './component/shared/bh-task/bh-task.component';
import { SymptomCheckerPageComponent } from './component/page/symptom-checker-page/symptom-checker-page.component';
import { BhWhatsNewArticleComponent } from './component/shared/bh-whats-new-article/bh-whats-new-article.component';
import { TestResultDetailsModalComponent } from './component/page/test-result-details-modal/test-result-details-modal.component';
import { BhSpinnerComponent } from './component/shared/bh-spinner/bh-spinner.component';
import { BhContentSearchComponent } from './component/shared/bh-content-search/bh-content-search.component';
import { MessageModalComponent } from './component/page/messages/message-modal/message-modal.component';
import { SentMessagesModalComponent } from './component/page/sent-messages-modal/sent-messages-modal.component';
import { NewMessagePage } from './component/page/messages/new-message/new-message.page';
import { BhDataGridComponent } from './component/shared/bh-data-grid/bh-data-grid.component';
import { FormatDatePipe } from './pipe/format-date.pipe';
import { MedicalUrgentModalPage } from './component/page/messages/new-message/medical-urgent-modal/medical-urgent-modal.page';
import { BhStarRatingComponent } from './component/shared/bh-star-rating/bh-star-rating.component';
import { BhInputComponent } from './component/shared/bh-input/bh-input.component';
import { PhoneMaskDirective } from './directive/phone-directive';
import { WhatsNewPage } from './component/page/whats-new/whats-new.page';
import { BhMessageTableComponent } from './component/page/messages/bh-message-table/bh-message-table.component';
import { BhMsgFlowCollapsedStepComponent } from './component/page/messages/new-message/bh-msg-flow-collapsed-step/bh-msg-flow-collapsed-step.component';
import { BhMsgFlowExpandedStepComponent } from './component/page/messages/new-message/bh-msg-flow-expanded-step/bh-msg-flow-expanded-step.component';
import { DeleteRequestPagePage } from './component/page/delete-request-page/delete-request-page.page';
import { PciLabInfoPage } from './component/page/patient-checkin/pci-lab-info/pci-lab-info.page';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
//import { Printer } from '@awesome-cordova-plugins/printer/ngx';
@NgModule({
  declarations: [
    AppComponent,
    NativeElementInjectorDirective,
    LoginPageComponent,
    LandingPageComponent,
    AccountComponent,
    HealthRecordComponent,
    SMCNavigationGridComponent,
    SMCDataListComponent,
    ShareHealthRecordComponent,
    ShareHealthRecordOptionsComponent,
    ShareHealthRecordWithProviderComponent,
    ShareHealthRecordWithProviderDoneComponent,
    ShareHealthRecordDownloadSuccessComponent,
    HealthRecordComponent,
    HealthStatsComponent,
    MainNavigationComponent,
    MessagesComponent,
    SmcInboxTableComponent,
    ResourcesComponent,
    SymptomCheckerPageComponent,
    SymptomCheckerComponent,
    ScBasicInfoComponent,
    ScCausesComponent,
    ScFindcareComponent,
    AskALibrarianComponent,
    RequestAppointmentComponent,
    RefillPrescriptionComponent,
    MedicinesComponent,
    BreadcrumbComponent,
    AboutUsComponent,
    AppointmentDetailsComponent,
    ContactUsComponent,
    PrivacyComponent,
    EnrollmentTermsOfUseComponent,
    CreateAnAccountComponent,
    HelpComponent,
    ForgotCredentialsComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    ChangePasswordDoneComponent,
    ActivateAccountComponent,
    ModalComponent,
    FormsListComponent,
    AuthenticatedComponent,
    AllergiesComponent,
    VaccinationsComponent,
    WhatsNewPage,
    InboxComponent,
    SentMessagesComponent,
    ArchivedMessagesComponent,
    ConcernsComponent,
    HealthConditionsComponent,
    MyVisitsComponent,
    HealthStatDetailComponent,
    TestResultsComponent,
    TestResultDetailsComponent,
    TestResultComponentDetailsComponent,
    PersonalInformationComponent,
    SMCWorkflowComponent,
    RxSelectMedicineComponent,
    RxSelectProviderComponent,
    RxSelectPharmacyComponent,
    RxContactInfoComponent,
    RxReviewComponent,
    RxCompleteComponent,
    AccountPreferencesComponent,
    AccountHistoryComponent,
    ChangePasswordModalComponent,
    ChangeEmailModalComponent,
    ImageTestResultComponent,
    ViewRadImageModalComponent,
    RadImageInstructionalVideoModalComponent,
    ShareWithExternalProviderModalComponent,
    ReportsComponent,
    UpcomingVisitComponent,
    PastVisitComponent,
    AptSelectProviderComponent,
    AptReasonComponent,
    AptScheduleComponent,
    AptContactInfoComponent,
    AptReviewComponent,
    AptCompleteComponent,
    AppleHealthSetupComponent,
    HealthDictionaryComponent,
    GoalsComponent,
    AccountMenuComponent,
    FormsSubmissionCreateTabComponent,
    FormFieldsTemplateComponent,
    FormsSubmissionCompleteComponent,
    ReportDetailComponent,
    SmcTextInputComponent,
    SmcFormTextInputComponent,
    SmcFormFieldComponent,
    SmallScreenOnlyComponent,
    LargeScreenOnlyComponent,
    BackPanePageComponent,
    RequestAppointmentChangeModalComponent,
    FindProviderModalComponent,
    UploadPhotosModalComponent,
    TabsComponent,
    SmcRoleHideComponent,
    PastResultsModalComponent,
    ResultHistoryComponent,
    SelectUserListComponent,
    AppFooterComponent,
    LoginTermsAndConditionsModalComponent,
    SmcDatePipe,
    DiagnosisFilter,
    ResourcesDropdownComponent,
    CallUsPopoverComponent,
    PublicResourcesComponent,
    PublicAppleHealthSetupComponent,
    LockedTileModalComponent,
    PublicHealthDictionaryComponent,
    EnrollSuccessComponent,
    PasswordResetSuccessComponent,
    RequestAChangeModalComponent,
    TestResultDetailsModalComponent,
    MessageModalComponent,
    SentMessagesModalComponent,
    PasswordChangeFormComponent,
    AllDonePageComponent,
    LoginFormComponent,
    LoginFormModalComponent,
    PciCheckinNowComponent,
    PciMessageBannerComponent,
    PublicPciCheckinNowComponent,
    PciInformationComponent,
    PciServiceQuestionsComponent,
    PciServiceLinesComponent,
    PciPatientInfoComponent,
    PciConfirmationComponent,
    PciAppointmentPromptComponent,
    PciLocationComponent,
    PciLabInfoPage,
    BhPageHeadingComponent,
    PublicPciConfirmationComponent,
    PublicSymptomCheckerComponent,
    BhDockedButtonsComponent,
    LanguagesDropdownComponent,
    BannerComponent,
    FeedbackPageComponent,
    BhTaskComponent,
    BhInfoCardComponent,
    BhCovidInfoComponent,
    BhWhatsNewArticleComponent,
    BhUrgentDisclaimerComponent,
    BhSpinnerComponent,
    BhContentSearchComponent,
    NewMessagePage,
    BhMsgFlowCollapsedStepComponent,
    BhMsgFlowExpandedStepComponent,
    BhDataGridComponent,
    FormatDatePipe,
    MedicalUrgentModalPage,
    BhMessageTableComponent,
    MedicalUrgentModalPage,
    BhStarRatingComponent,
    BhInputComponent,
    PhoneMaskDirective,
    DeleteRequestPagePage
  ],
  imports: [
    IonicModule.forRoot({
      // always use material design components, even on iOS
      mode: 'md'
    }),
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatSortModule,
    MatToolbarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatDividerModule,
    MatGridListModule,
    MatSelectModule,
    MatPaginatorModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxDatatableModule,
    SmcTableModule,
    NgxChartsModule,
    Ng5SliderModule,
    NgxPrintModule,
    MatNativeDateModule,
    MatTabsModule,
    NgxMaterialTimepickerModule,
    MatAutocompleteModule,
    IonicStorageModule.forRoot(),
    HttpModule,
    TooltipModule,
    NgxMaskIonicModule.forRoot(),
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    Printer,
    LoginRouteGuardService,
    EnrollmentRouteGuardService,
    DatePipe,
    FormatDatePipe,
    SmcDatePipe,
    Device,
    InAppBrowser
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  entryComponents: [
    ModalComponent,
    ChangePasswordModalComponent,
    ChangeEmailModalComponent,
    ViewRadImageModalComponent,
    RadImageInstructionalVideoModalComponent,
    ShareWithExternalProviderModalComponent,
    AccountMenuComponent,
    SelectUserListComponent,
    RequestAppointmentChangeModalComponent,
    FindProviderModalComponent,
    UploadPhotosModalComponent,
    PastResultsModalComponent,
    LoginTermsAndConditionsModalComponent,
    ResourcesDropdownComponent,
    CallUsPopoverComponent,
    LockedTileModalComponent,
    RequestAChangeModalComponent,
    TestResultDetailsModalComponent,
    MessageModalComponent,
    SentMessagesModalComponent,
    LoginFormModalComponent,
    LanguagesDropdownComponent,
    BannerComponent,
    FeedbackPageComponent,
    BhTaskComponent,
    BhInfoCardComponent,
    BhSpinnerComponent,
    BhContentSearchComponent,
    NewMessagePage,
    MedicalUrgentModalPage,
    MedicalUrgentModalPage,
    BhStarRatingComponent,
    BhInputComponent,
    DeleteRequestPagePage,
    PciLabInfoPage
  ]
})
export class AppModule { }

