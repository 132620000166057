import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ReplaySubject, Observable } from 'rxjs';
import { PasswordRequirements, PasswordRequirement } from 'src/app/model/PasswordRequirements';
import { ActivateAccountService } from 'src/app/service/activate-account.service';
import { first } from 'rxjs/operators';
import { AwsTranslateService } from '../../../service/aws-translate.service';

export interface PasswordChangeRequest {
  isValid: boolean;
  newPassword: string;
  currentPassword?: string;
}

interface MappedPasswordRequirementDescription {
  valueFromServer: {
    Description: string;
    Regex: string;
  }
  mappedDescription: string;
}

interface PasswordRequirementWithStatus {
  description: string;
  regex: RegExp;
  status: boolean;
}

/** Shared component for password resets with live validation updates UI and more user friendly mapped requirement descriptions */
@Component({
  selector: 'smc-password-change-form',
  templateUrl: './password-change-form.component.html',
  styleUrls: ['./password-change-form.component.scss'],
})
export class PasswordChangeFormComponent implements OnInit {

  @Input() requireCurrentPassword = false;
  @Output() private formChange = new EventEmitter<PasswordChangeRequest>();

  showRequirementsChecks = false;
  enablePasswordsNotMatchingError = false;
  passwordRequirements: PasswordRequirementWithStatus[];
  complexityRequirementsMet = false;
  newPasswordsMatch = false;
  formGroup = new FormGroup({
    currentPassword: new FormControl('', Validators.required),
    newPassword: new FormControl('', Validators.required),
    newPasswordConfirm: new FormControl('', Validators.required)
  });

  clear() {
    this.formGroup.reset();
  }

  /** 
   * Map for known password requirements with more user friendly descriptions. If a requirement 
   * doesn't have a exact match in the map it will fall back to the server-supplied description. 
   * This feature was a direct baystate request. 
   */
  private readonly mappedPasswordRequirementDescriptions: MappedPasswordRequirementDescription[] = [
    {
      valueFromServer: {
        Description: "You must enter at least 6 characters",
        Regex: "^(.){6,}$"
      },
      mappedDescription: "At least 6 characters in length"
    },
    {
      valueFromServer: {
        Description: "You must enter at least one numeric character",
        Regex: "^(?:\\D*\\d\\D*){1,}$"
      },
      mappedDescription: "Numbers (0-9)"
    },
    {
      valueFromServer: {
        Description: "You must enter at least one lower case character",
        Regex: "^(?:.*[a-z].*){1,}$"
      },
      mappedDescription: "Lower case letters (a-z)"
    },
    {
      valueFromServer: {
        Description: "Your must enter at least one UPPER CASE character",
        Regex: "^(?:.*[A-Z].*){1,}$"
      },
      mappedDescription: "Upper case letters (A-Z)"
    },
  ];

  constructor(
    private _activateAccountService: ActivateAccountService,
    public AWSTranslate: AwsTranslateService
  ) { }

  async ngOnInit() {
    const passwordRequirements = await this._activateAccountService.getPasswordRequirements();
    const mappedPasswordRequirements: PasswordRequirementWithStatus[] = passwordRequirements.Requirements.map(requirement => {
      const mapValue = this.mappedPasswordRequirementDescriptions.find(value => 
        value.valueFromServer.Description == requirement.Description && 
        value.valueFromServer.Regex == requirement.Regex
      );
      return {
        status: false,
        regex: new RegExp(requirement.Regex),
        description: mapValue ? mapValue.mappedDescription : requirement.Description
      };
    });

    this.passwordRequirements = mappedPasswordRequirements;
  }

  currentPasswordChange() {
    this.updateFormStatus();
  }

  newPasswordChange() {
    // show requirements dropdown now that the new password field has received input
    this.showRequirementsChecks = true;
    this.updateFormStatus();
  }

  newPasswordConfirmChange() {
    // enable matching passwords message now that the confirmation field has received input
    this.enablePasswordsNotMatchingError = true;
    this.updateFormStatus();
  }

  /** Handles input, updating requirement statuses */
  private updateFormStatus() {
    const passwordString = this.formGroup.get('newPassword').value;
    const passwordConfirmString = this.formGroup.get('newPasswordConfirm').value;
    
    for (const requirement of this.passwordRequirements) {
      requirement.status = requirement.regex.test(passwordString);
    }
    this.complexityRequirementsMet = this.passwordRequirements.every(requirement => requirement.status);

    this.newPasswordsMatch = passwordString == passwordConfirmString;

    this.formChange.emit(this.getValues());
  }

  /** Gets the current field values and whether all requirements are met */
  getValues(): PasswordChangeRequest {
    const currentPassword = this.formGroup.get('currentPassword').value;
    const newPassword = this.formGroup.get('newPassword').value;
    const newPasswordConfirm = this.formGroup.get('newPasswordConfirm').value;
    const noEmptyFields = this.requireCurrentPassword ?
      !!(currentPassword && newPassword && newPasswordConfirm) :
      !!(newPassword && newPasswordConfirm);
    return {
      isValid: this.complexityRequirementsMet && this.newPasswordsMatch && noEmptyFields,
      newPassword: this.formGroup.get('newPassword').value,
      currentPassword: this.formGroup.get('currentPassword').value
    };
  }

}
