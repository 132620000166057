import { PublicPciConfirmationComponent } from './component/page/patient-checkin/public-pci-confirmation/public-pci-confirmation.component';
import { PciCheckinNowComponent } from './component/page/patient-checkin/pci-checkin-now/pci-checkin-now.component';
import { PciConfirmationComponent } from './component/page/patient-checkin/pci-confirmation/pci-confirmation.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './component/page/login-page/login-page.component';
import { LandingPageComponent } from './component/page/landing-page/landing-page.component';
import { AccountComponent } from './component/page/account/account.component';
import { HealthRecordComponent } from "./component/page/health-record/health-record.component";
import { HealthStatsComponent } from './component/page/health-stats/health-stats.component';
import { MessagesComponent } from './component/page/messages/messages.component';
import { ResourcesComponent } from './component/page/resources/resources.component';
import { SymptomCheckerComponent } from "./component/page/symptom-checker/symptom-checker.component";
import { ScBasicInfoComponent } from "./component/page/symptom-checker/sc-basic-info/sc-basic-info.component";
import { ScCausesComponent } from "./component/page/symptom-checker/sc-causes/sc-causes.component";
import { ScFindcareComponent } from "./component/page/symptom-checker/sc-findcare/sc-findcare.component";
import { RequestAppointmentComponent } from './component/page/request-appointment/request-appointment.component';
import { RefillPrescriptionComponent } from './component/page/request-prescription/refill-prescription.component';
import { MedicinesComponent } from './component/page/medicines/medicines.component';
import { LoginRouteGuardService } from './service/login-route-guard.service';
//import { FaqComponent } from './component/page/faq/faq.component';
import { ContactUsComponent } from './component/page/contact-us/contact-us.component';
import { PrivacyComponent } from './component/page/privacy/privacy.component';
import { AboutUsComponent } from './component/page/about-us/about-us.component';
import { EnrollmentTermsOfUseComponent } from "./component/page/enroll/terms-of-use/terms-of-use.component";
import { CreateAnAccountComponent } from './component/page/enroll/create-an-account/create-an-account.component';
import { EnrollmentRouteGuardService } from './service/enrollment-route-guard.service';
import { HelpComponent } from './component/page/help/help.component';
import { ForgotCredentialsComponent } from './component/page/forgot-credentials/forgot-credentials.component';
import { ActivateAccountComponent } from './component/page/activate-account/activate-account.component';
import { FormsListComponent } from "./component/page/patient-forms/forms-list/forms-list.component";
import { AskALibrarianComponent } from './component/page/ask-a-librarian/ask-a-librarian.component';
import { AuthenticatedComponent } from './component/page/authenticated/authenticated.component';
import { AllergiesComponent } from './component/page/allergies/allergies.component';
import { VaccinationsComponent } from './component/page/vaccinations/vaccinations.component';
import { InboxComponent } from './component/page/messages/inbox/inbox.component';
import { ConcernsComponent } from './component/page/concerns/concerns.component';
import { HealthConditionsComponent } from './component/page/health-conditions/health-conditions.component';
import { MyVisitsComponent } from './component/page/my-visits/my-visits.component';
import { HealthStatDetailComponent } from './component/page/health-stat-detail/health-stat-detail.component';
import { SentMessagesComponent } from './component/page/messages/sent-messages/sent-messages.component';
import { ArchivedMessagesComponent } from './component/page/messages/archived-messages/archived-messages.component';
import { TestResultsService } from './service/test-results.service';
import { TestResultsComponent } from './component/page/test-results/test-results.component';
import { TestResultDetailsComponent } from './component/page/test-result-details/test-result-details.component';

import { PersonalInformationComponent } from './component/page/personal-information/personal-information.component';
import { RxSelectMedicineComponent } from "./component/page/request-prescription/rx-select-medicine/rx-select-medicine.component";
import { RxSelectPharmacyComponent } from "./component/page/request-prescription/rx-select-pharmacy/rx-select-pharmacy.component";
import { RxSelectProviderComponent } from "./component/page/request-prescription/rx-select-provider/rx-select-provider.component";
import { RxContactInfoComponent } from "./component/page/request-prescription/rx-contact-info/rx-contact-info.component";
import { RxReviewComponent } from "./component/page/request-prescription/rx-review/rx-review.component";
import { RxCompleteComponent } from "./component/page/request-prescription/rx-complete/rx-complete.component";
import { AccountPreferencesComponent } from './component/page/account/account-preferences/account-preferences.component';
import { AccountHistoryComponent } from './component/page/account/account-history/account-history.component';
import { ShareHealthRecordComponent } from './component/page/share-health-record/share-health-record.component';
import { ShareHealthRecordOptionsComponent } from './component/page/share-health-record/share-health-record-options/share-health-record-options.component';
import { ShareHealthRecordWithProviderComponent } from './component/page/share-health-record/share-health-record-with-provider/share-health-record-with-provider.component';
import { ImageTestResultComponent } from './component/page/image-test-result/image-test-result.component';
import { ReportsComponent } from './component/page/reports/reports.component';
import { UpcomingVisitComponent } from './component/page/my-visits/upcoming-visit/upcoming-visit.component';
import { PastVisitComponent } from './component/page/my-visits/past-visit/past-visit.component';
import { AppleHealthSetupComponent } from './component/page/apple-health-setup/apple-health-setup.component';
import { AptSelectProviderComponent } from "./component/page/request-appointment/apt-select-provider/apt-select-provider.component";
import { AptReasonComponent } from "./component/page/request-appointment/apt-reason/apt-reason.component";
import { AptScheduleComponent } from "./component/page/request-appointment/apt-schedule/apt-schedule.component";
import { AptContactInfoComponent } from "./component/page/request-appointment/apt-contact-info/apt-contact-info.component";
import { AptReviewComponent } from "./component/page/request-appointment/apt-review/apt-review.component";
import { AptCompleteComponent } from "./component/page/request-appointment/apt-complete/apt-complete.component";
import { HealthDictionaryComponent } from './component/page/health-dictionary/health-dictionary.component';
import { GoalsComponent } from './component/page/goals/goals.component';
import { FormsSubmissionCreateTabComponent } from "./component/page/patient-forms/forms-submission-create/forms-submission-create-tab.component";
import { FormsSubmissionCompleteComponent } from "./component/page/patient-forms/forms-submission-complete/forms-submission-complete.component";
import { ReportDetailComponent } from './component/page/reports/report-detail/report-detail.component';
import { LogEventRouteGuardService } from "./service/log-event-route-guard.service";
import { ShareHealthRecordWithProviderDoneComponent } from "./component/page/share-health-record/share-health-record-with-provider-done/share-health-record-with-provider-done.component";
import { BhanalyticsRouteGuardService } from "./service/bhanalytics-route-guard.service";
import { PublicResourcesComponent } from './component/page/public-resources/public-resources.component';
import { PublicHealthDictionaryComponent } from './component/page/public-health-dictionary/public-health-dictionary.component';
import { EnrollSuccessComponent } from './component/page/enroll/enroll-success/enroll-success.component';
import { PasswordResetSuccessComponent } from "./component/page/reset-password/password-reset-success/password-reset-success.component";
import { ResetPasswordComponent } from "./component/page/reset-password/reset-password.component";
import { ChangePasswordComponent } from './component/page/change-password/change-password.component';
import { ResetPasswordGuard } from './service/reset-password.guard';
import { TestResultComponentDetailsComponent } from './component/page/test-result-component-details/test-result-component-details.component';
import { LoginTermsAndConditionsModalComponent } from "./component/page/login-terms-and-conditions-modal/login-terms-and-conditions-modal.component";
import { ChangePasswordDoneComponent } from './component/page/change-password-done/change-password-done.component';
import { PublicAppleHealthSetupComponent } from './component/page/public-apple-health-setup/public-apple-health-setup.component';
import { ShareHealthRecordDownloadSuccessComponent } from './component/page/share-health-record/share-health-record-download-success/share-health-record-download-success.component';
import { PublicPciCheckinNowComponent } from './component/page/patient-checkin/public-pci-checkin-now/public-pci-checkin-now.component';
import { SymptomCheckerPageComponent } from './component/page/symptom-checker-page/symptom-checker-page.component';
import { PublicSymptomCheckerComponent } from './component/page/public-symptom-checker/public-symptom-checker.component';
import { WhatsNewPage } from './component/page/whats-new/whats-new.page';
import { DeleteRequestPagePage } from './component/page/delete-request-page/delete-request-page.page';


export const routes: Routes = [
  // {
  //   path: 'pay-bill',
  //   redirectTo: 'https://baystate.onlinepaymentcenter.net/Account/Search/'
  // },
  {
    path: 'authenticated',
    component: AuthenticatedComponent,
    canActivate: [ResetPasswordGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: LandingPageComponent,
        data: { title: 'Home Page', logEvent: '{"Action":"PageView","Page":"Home","Application":"public","Section":"Home"}' },
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService]
      },
      {
        path: 'whats-new',
        component: WhatsNewPage,
        data: { title: 'Whats New Page', logEvent: '{"Action":"PageView","Page":"Whats New","Application":"public","Section":"Whats New"}' },
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService]
      },
      {
        path: 'account',
        component: AccountComponent,
        canActivate: [LoginRouteGuardService, BhanalyticsRouteGuardService],
        children: [
          {
            path: 'preferences',
            component: AccountPreferencesComponent,
            canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
            data: { logEvent: '{"Action":"PageView","Page":"MyAccount","Application":"public","Section":"Summary"}' },
          },
          {
            path: 'history',
            component: AccountHistoryComponent,
            canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
            data: { logEvent: '{"Action":"PageView","Page":"MyAccount","Application":"public","Section":"History"}' },
          },
          {
            path: '',
            redirectTo: 'preferences',
            pathMatch: 'prefix',
          }
        ]
      },
      { path: 'account/request-delete',
      component: DeleteRequestPagePage,
      canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
      data: { logEvent: '{"Action":"PageView","Page":"MyAccount","Application":"public","Section":"RequestDelete"}' },
      },
      {
        path: 'patient-checkin/check-in-now',
        component: PciCheckinNowComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"PatientCheckIn","Application":"public","Section":"Patient-Checkin-Now","IsAPI":"0"}' },
      },
      {
        path: 'patient-checkin/confirmation',
        component: PciConfirmationComponent,
        canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"Patient-CheckIn-Confirmation"}' },
      },
      {
        path: 'health-record',
        component: HealthRecordComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"Wellness","IsAPI":"0"}' },
      },
      {
        path: 'health-record/health-stats',
        component: HealthStatsComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"Observations","IsAPI":"0"}' },
      },
      {
        path: 'health-record/health-stats/health-stat-detail',
        component: HealthStatDetailComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"Observations.Observation","IsAPI":"0"}' },
      },
      {
        path: 'resources/apple-health-setup',
        component: AppleHealthSetupComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"ConnectAppsToHealthRecord"}' },
      },
      {
        path: 'messages',
        component: MessagesComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        children: [
          {
            path: 'inbox',
            component: InboxComponent,
            canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
            data: { logEvent: '{"Action":"PageView","Page":"Messages","Application":"public","Section":"Inbox"}' },
          },
          {
            path: 'sent',
            component: SentMessagesComponent,
            canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
            data: { logEvent: '{"Action":"PageView","Page":"Messages","Application":"public","Section":"Outbox"}' },
          },
          {
            path: 'archived',
            component: ArchivedMessagesComponent,
            canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
            data: { logEvent: '{"Action":"PageView","Page":"Messages","Application":"public","Section":"ArchivedInbox"}' },
          },
          {
            path: 'message',
            redirectTo: 'inbox',
            pathMatch: 'prefix',
          },
          {
            path: 'archived-message',
            redirectTo: 'inbox',
            pathMatch: 'prefix',
          },
          {
            path: '',
            redirectTo: 'inbox',
            pathMatch: 'prefix'
          }
        ]
      },
      {
        path: 'resources',
        component: ResourcesComponent,
        canActivate: [LoginRouteGuardService, BhanalyticsRouteGuardService],
        // no events to log here.
      },
      {
        path: 'resources/symptom-checker',
        component: SymptomCheckerPageComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"SymChecker","IsAPI":"0"}' },
      },
      {
        path: 'resources/symptom-checker-old',
        component: SymptomCheckerComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"SymChecker","IsAPI":"0"}' },
      },
      {
        path: 'resources/symptom-checker-old/sc-basic-info/:formState',
        component: ScBasicInfoComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"SymChecker","IsAPI":"0"}' },
      },
      {
        path: 'resources/symptom-checker-old/sc-causes/:formState',
        component: ScCausesComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"SymChecker","IsAPI":"0"}' },
      },
      {
        path: 'resources/symptom-checker-old/sc-findcare/:formState',
        component: ScFindcareComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"SymChecker","IsAPI":"0"}' },
      },
      {
        path: 'resources/ask-a-librarian',
        component: AskALibrarianComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Library","Application":"public","Section":"AskLibrarian"}' },
      },
      {
        path: 'resources/health-dictionary',
        component: HealthDictionaryComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Library","Application":"public","Section":"HealthDictionary"}' },

      },
      {
        path: 'resources/forms-list',
        component: FormsListComponent,
        children: [
          {
            path: 'forms-submission-tab',
            loadChildren: './component/page/patient-forms/forms-submission/forms-submission-tab.module#FormsSubmissionTabModule',
            canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
            data: { logEvent: '{"Action":"PageView","Page":"FormsTab","Application":"public","Section":"FormsTab"}' },
          },
          {
            path: 'forms-submission-history-tab',
            loadChildren: './component/page/patient-forms/forms-submission-history/forms-submission-history-tab.module#FormsSubmissionHistoryTabModule',
            canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
            data: { logEvent: '{"Action":"PageView","Page":"FormsTab","Application":"public","Section":"Tab2"}' },
          },
          {
            path: '',
            redirectTo: 'forms-submission-tab',
            pathMatch: 'prefix'
          }
        ],
        canActivate: [LoginRouteGuardService, BhanalyticsRouteGuardService],
      },
      {
        path: 'resources/forms-list/forms-submission-complete',
        component: FormsSubmissionCompleteComponent,
        canActivate: [LoginRouteGuardService, BhanalyticsRouteGuardService],
        // no events to log here.
      },
      {
        path: 'resources/forms-list/:id',
        component: FormsSubmissionCreateTabComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Form","Application":"public","Section":"Form"}' },
      },
      {
        path: 'health-record/medicines',
        component: MedicinesComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"Medications","IsAPI":"0"}' },
      },
      //      {
      //        path: 'home/request-appointment',
      //        component: RequestAppointmentComponent,
      //        canActivate: [LoginRouteGuardService,LogEventRouteGuardService,BhanalyticsRouteGuardService],
      //        data: { logEvent: '' },
      //      },
      {
        path: 'health-record/allergies',
        component: AllergiesComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"Allergies","IsAPI":"0"}' },
      },
      {
        path: 'health-record/vaccinations',
        component: VaccinationsComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"Immunizations","IsAPI":"0"}' },
      },
      {
        path: 'health-record/concerns',
        component: ConcernsComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"HealthConcerns","IsAPI":"0"}' },
      },
      {
        path: 'health-record/goals',
        component: GoalsComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"Goals","IsAPI":"0"}' },
      },
      {
        path: 'health-record/health-conditions',
        component: HealthConditionsComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"Problems","IsAPI":"0"}' },
      },
      {
        path: 'health-record/my-visits',
        component: MyVisitsComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"Appointments","IsAPI":"0"}' },
      },
      {
        path: 'health-record/my-visits/upcoming-visit',
        component: UpcomingVisitComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"Appointments.Details","IsAPI":"0"}' },
      },
      {
        path: 'health-record/my-visits/previous-visit',
        component: PastVisitComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"Encounters.Details","IsAPI":"0"}' },
      },
      {
        path: 'health-record/test-results',
        component: TestResultsComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"LabOrders","IsAPI":"0"}' },
      },
      //      {
      //        path: 'health-record/personal-information',
      //        component: PersonalInformationComponent
      //      },
      {
        path: 'health-record/test-results/test-result-details/:blurb',
        component: TestResultDetailsComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"LabOrders.Details","IsAPI":"0"}' },
      },
      {
        path: 'health-record/test-results/test-result-details/:blurb/:blurb',
        component: TestResultComponentDetailsComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"LabOrders.Details.Component","IsAPI":"0"}' },
      },
      {
        path: 'health-record/test-results/x-ray-results/:blurb',
        component: ImageTestResultComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"RadOrders.Details","IsAPI":"0"}' },
      },
      {
        path: 'health-record/visits/request-appointment',
        // component: AptSelectProviderComponent,
        component: MyVisitsComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Messages","Application":"public","Section":"RequestAppointment"}' },
      },
      {
        path: 'health-record/visits/request-appointment/apt-select-provider',
        // component: AptSelectProviderComponent,
        component: MyVisitsComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Messages","Application":"public","Section":"RequestAppointment"}' },
      },
      {
        path: 'health-record/visits/request-appointment/apt-reason',
        component: AptReasonComponent,
        canActivate: [LoginRouteGuardService, BhanalyticsRouteGuardService],
        // no events to log here.
      },
      {
        path: 'health-record/visits/request-appointment/apt-schedule',
        component: AptScheduleComponent,
        canActivate: [LoginRouteGuardService, BhanalyticsRouteGuardService],
        // no events to log here.
      },
      {
        path: 'health-record/visits/request-appointment/apt-contact-info',
        component: AptContactInfoComponent,
        canActivate: [LoginRouteGuardService, BhanalyticsRouteGuardService],
        // no events to log here.
      },
      {
        path: 'health-record/visits/request-appointment/apt-review',
        component: AptReviewComponent,
        canActivate: [LoginRouteGuardService, BhanalyticsRouteGuardService],
        // no events to log here.
      },
      {
        path: 'health-record/visits/request-appointment/apt-complete',
        component: AptCompleteComponent,
        canActivate: [LoginRouteGuardService, BhanalyticsRouteGuardService],
        // no events to log here.
      },
      {
        path: 'health-record/medicines/request-prescription',
        component: RxSelectMedicineComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Messages","Application":"public","Section":"RefillPrescription"}' },
      },
      {
        path: 'health-record/medicines/request-prescription/rx-select-medicine',
        component: RxSelectMedicineComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Messages","Application":"public","Section":"RefillPrescription"}' },
      },
      {
        path: 'health-record/medicines/request-prescription/rx-select-medicine/:key',
        component: RxSelectMedicineComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '' },
      },
      {
        path: 'health-record/medicines/request-prescription/rx-select-provider',
        component: RxSelectProviderComponent,
        canActivate: [LoginRouteGuardService, BhanalyticsRouteGuardService],
        // no events to log here.
      },
      {
        path: 'health-record/medicines/request-prescription/rx-select-pharmacy',
        component: RxSelectPharmacyComponent,
        canActivate: [LoginRouteGuardService, BhanalyticsRouteGuardService],
        // no events to log here.
      },
      {
        path: 'health-record/medicines/request-prescription/rx-contact-info',
        component: RxContactInfoComponent,
        canActivate: [LoginRouteGuardService, BhanalyticsRouteGuardService],
        // no events to log here.
      },
      {
        path: 'health-record/medicines/request-prescription/rx-review',
        component: RxReviewComponent,
        canActivate: [LoginRouteGuardService, BhanalyticsRouteGuardService],
        // no events to log here.
      },
      {
        path: 'health-record/medicines/request-prescription/rx-complete',
        component: RxCompleteComponent,
        canActivate: [LoginRouteGuardService, BhanalyticsRouteGuardService],
        // no events to log here.
      },
      {
        path: 'health-record/share',
        component: ShareHealthRecordComponent,
        canActivate: [LoginRouteGuardService, BhanalyticsRouteGuardService],
        children: [
          {
            path: 'options',
            component: ShareHealthRecordOptionsComponent,
            canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
            data: { logEvent: '{"Action":"PageView","Page":"ShareRecords","Application":"public","Section":"ShareRecords"}' },
          },
          {
            path: 'share-with-provider/:documentFormat/:startDate/:endDate',
            component: ShareHealthRecordWithProviderComponent,
            canActivate: [BhanalyticsRouteGuardService],
            // no events to log here.
          },
          {
            path: 'download-done',
            component: ShareHealthRecordDownloadSuccessComponent,
            canActivate: [BhanalyticsRouteGuardService],
            // no events to log here.
          },
          {
            path: 'share-with-provider-done',
            component: ShareHealthRecordWithProviderDoneComponent,
            canActivate: [BhanalyticsRouteGuardService],
            // no events to log here.
          },
          {
            path: '**',
            redirectTo: 'options',
            pathMatch: 'prefix'
          }
        ]
      },
      {
        path: 'health-record/reports',
        component: ReportsComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"Documents","IsAPI":"0"}' },
      },
      {
        path: 'health-record/reports/report-detail',
        component: ReportDetailComponent,
        canActivate: [LoginRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
        data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"Documents.Details","IsAPI":"0"}' },
      }

    ]
  },
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { title: 'Login Page', logEvent: '{"Action":"PageView","Page":"Home","Application":"public","Section":"Home"}' },
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"AboutUs"}' },
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"ContactUs"}' },
  },
  {
    path: 'privacy-and-terms-of-use/:id',
    component: PrivacyComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"PrivacyStatement"}' },
  },
  {
    path: 'privacy-and-terms-of-use',
    component: PrivacyComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"PrivacyStatement"}' },
  },
  {
    path: 'apple-health-setup',
    component: PublicAppleHealthSetupComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"ConnectAppsToHealthRecord"}' },
  },

  {
    path: 'enroll/1',
    component: EnrollmentTermsOfUseComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"Terms"}' },
  },
  {
    path: 'enroll/2',
    component: CreateAnAccountComponent,
    canActivate: [EnrollmentRouteGuardService, LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"Enroll"}' },
  },
  {
    path: 'enroll-success',
    component: EnrollSuccessComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"Enroll-Success"}' },

  },
  {
    path: 'password-reset-success',
    component: PasswordResetSuccessComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"Password-Reset-Success"}' },

  },
  {
    path: 'activate-account/:pageName',
    component: ActivateAccountComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Activate","Application":"public","Section":"ActivateAccount"}' },
  },
  {
    path: 'activate-account',
    redirectTo: 'activate-account/activate',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login/:targetResource',
    component: LoginPageComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { title: 'Login Page', logEvent: '{"Action":"PageView","Page":"Home","Application":"public","Section":"Home"}' },
  },
  /*
  {
    path: 'faq',
    component: FaqComponent,
    canActivate: [LogEventRouteGuardService,BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"Faq"}' },
  },
  */
  { // replaces the FAQ page
    path: 'help',
    component: HelpComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"Faq"}' },
  },
  {
    path: 'forgot-credentials',
    component: ForgotCredentialsComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"Forgot"}' },
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"ResetPassword"}' }
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"ChangePassword"}' }
  },
  {
    path: 'change-password-done',
    component: ChangePasswordDoneComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"Password-Reset-Success"}' }
  },
  {
    path: 'resources',
    component: PublicResourcesComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"Resources"}' },

  },
  {
    path: 'symptom-checker',
    component: PublicSymptomCheckerComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Wellness","Application":"public","Section":"SymChecker","IsAPI":"0"}' },
  },
  {
    path: 'health-dictionary',
    component: PublicHealthDictionaryComponent,
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"HealthDictionary"}' },
  },
  {
    path: 'login-terms-and-conditions',
    component: LoginTermsAndConditionsModalComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"LoginTerms"}' },
  },
  // Patient Check-in screens
  {
    path: 'patient-checkin/check-in-now',
    component: PublicPciCheckinNowComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"Public-Patient-CheckIn-Now"}' },
  },
  {
    path: 'patient-checkin/confirmation',
    component: PublicPciConfirmationComponent,
    canActivate: [LogEventRouteGuardService, BhanalyticsRouteGuardService],
    data: { logEvent: '{"Action":"PageView","Page":"Info","Application":"public","Section":"Public-Patient-CheckIn-Confirmation"}' },
  },
  {
    path: '**',
    redirectTo: '/login',
    pathMatch: 'full'
  },


  // { path: 'whats-new', loadChildren: './component/page/whats-new/whats-new.module#WhatsNewPageModule' },
  // { path: 'new-message', loadChildren: './component/page/messages/new-message/new-message.module#NewMessagePageModule' },
  // { path: 'medical-urgent-modal', loadChildren: './page/messages/new-message/medical-urgent-modal/medical-urgent-modal.module#MedicalUrgentModalPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
