import {Component, OnInit, AfterViewInit, Input, HostBinding, HostListener, ElementRef, Renderer} from '@angular/core';
import {Workflow} from '../../../model/Workflow';
import { AwsTranslateService } from '../../../service/aws-translate.service';

@Component({
  selector: 'smc-workflow',
  templateUrl: './smc-workflow.component.html',
  styleUrls: ['./smc-workflow.component.scss']
})
export class SMCWorkflowComponent  implements OnInit {

  @Input() workflows: Workflow[];
  @Input() forceMobileLayout = false;
  isMobileLayout = false;

  constructor(private AWSTranslate: AwsTranslateService) { }

  async ngOnInit() {
    const width = window.innerWidth;
    this.isMobileLayout = (innerWidth < 801);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // this.resetShowSummary(event.target.innerWidth);
    this.isMobileLayout = (event.target.innerWidth < 801);
  }

  private doContent(s) {
    let returnText;
    let block = s.split(":");
    returnText = block[1] ? this.AWSTranslate.read(block[0], this.AWSTranslate.langId) + "<br>" + block[1] : this.AWSTranslate.read(s, this.AWSTranslate.langId);
    return returnText;
  }
}
