import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})
export class ConcernsService {

  constructor(private _httpBaseService: HttpBaseService
    ) { }

  async getConcerns(user?: UserIdentifier) {
    const response = await this._httpBaseService.getList('/ehr/category/healthConcerns',true,user);
    return response['HealthConcerns'];
  }
}
