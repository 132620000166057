import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Workflow } from '../model/Workflow';
import { questionTextMap, mapAnswerToQuestionText } from 'src/app/component/page/symptom-checker/sc-utils/questionTextMapMethods';

type FormState = 'send-symptoms' | 'find-care';

@Injectable({
  providedIn: 'root'
})

export class SymptomCheckerStateService {

  medChatBtn: HTMLElement;
  medChatRoom: HTMLElement;
  formState: FormState;
  diagnosesList: any[];
  dropdownList: any;
  questionAnswerMap = new Map<number, any>();
  state: {
    age: { value: string, text: string},
    gender: { value: string, text: string},
    region: { value: string, text: string},
    pregnancy: { value: string, text: string},
    q1: { value: string, text: string},
    q2: { value: string, text: string},
    q3: { value: string, text: string},
    q4: { value: string, text: string},
    q5: { value: string, text: string},
    q6: { value: string, text: string},
    q7: { value: string, text: string},
    symptoms: { value: string[] , text: string}
  };

  constructor() {
    this.clearState();
  }

  mapAnswerToQuestion(savedAnswers: Map<number, any>) {
    this.questionAnswerMap = savedAnswers;
  }

  setCurrentSectionFormData(symptomCheckerForm: FormGroup) {
    for (const formControlName in symptomCheckerForm.controls) {
      if (formControlName) {
        this.state[formControlName].value = symptomCheckerForm.get(formControlName).value;
      }
    }
  }

  getCurrentSectionFormData(symptomCheckerForm: FormGroup) {
    for (const formControlName in symptomCheckerForm.controls) {
      if (formControlName) {
        symptomCheckerForm.get(formControlName).setValue(this.state[formControlName].value);
      }
    }
  }

  storeDropDownlist(list: any) {
    this.dropdownList = list;
  }

  getDropdownList() {
    return this.dropdownList;
  }

  getSymptoms() {
    return this.state.symptoms.value;
  }

  setFormState(formState: FormState) {
    this.formState = formState;
  }

  setDiagnosesList(diagnosesList: any[]) {
    this.diagnosesList = diagnosesList;
  }

  getDiagnosesList(): any [] {
    return this.diagnosesList;
  }

  getFormState(): FormState {
    return this.formState;
  }

  getCurrentState() {
    return this.state;
  }

  getMappedAnswers() {
    return this.questionAnswerMap;
  }

  storeMedChatElements(chatBtn: HTMLElement, chatRoom: HTMLElement) {
    this.medChatBtn = chatBtn;
    this.medChatRoom = chatRoom;

    // Hide widget.
    chatRoom.style.display = 'none';
    chatBtn.style.display = 'none';
  }

  getMedChatElements() {
    return {
      medChatBtn: this.medChatBtn,
      medChatRoom: this.medChatRoom
    };
  }

  updateWorkflowContents(workflow: Workflow[]) {
    workflow[0].Content = this.getSymptomsSummary();
    workflow[1].Content = this.getCausesSummary();
  }

  getSymptomsSummary(): string {
    let basicInfo = '';
    if (this.state.age) {
      basicInfo = `
        Age Group: ${this.state.age.text} <br/>
        Region: ${this.state.region.text} <br/>
      `;
    }
    return basicInfo;
  }

  // MedChat COVID-19 Topics.
  appendMedChatScript(): void {
    const medChatScript = document.createElement('script');
    medChatScript.id = 'medchat-chatbot';
    medChatScript.type = 'text/javascript';
    medChatScript.async = true;
    medChatScript.src = 'https://medchatapp.com/widget/widget.js?api-key=duAMBktVzkOG1r3-7MoQmA';
    document.querySelector('#medchat-scripts').appendChild(medChatScript);
  }

  removeMedChatScript(): void {
    const medChatScript = document.getElementById('medchat-chatbot');
    const medChatView = document.getElementById('chat-box1');
    medChatScript.remove();
    medChatView.remove();
  }

  getCausesSummary() : string {
    let causesInfo = ``;
    if (this.questionAnswerMap.size > 0) {
      const questionTextArr = mapAnswerToQuestionText(this.getMappedAnswers());
      causesInfo =
        `1.How quickly did your symptoms develop? <br />
        ${questionTextArr[0]} ...`;
    }
    return causesInfo;
  }

  clearState() {
    this.questionAnswerMap = new Map<number, any>();
    this.state = {
      age: { value: '', text : ''},
      gender: { value: '', text : ''},
      region: { value: '', text : ''},
      pregnancy: { value: '', text : ''},
      q1: { value: '', text : ''},
      q2: { value: '', text : ''},
      q3: { value: '', text : ''},
      q4: { value: '', text : ''},
      q5: { value: '', text : ''},
      q6: { value: '', text : ''},
      q7: { value: '', text : ''},
      symptoms: { value: [] , text : ''}
    };
  }
}
