interface QuestionText {
    question: string;
    options: Array<{
      text: string;
      code: string;
    }>;
  }

function questionTextMap() {
   return new Map<number, QuestionText>([
    [1, {
      question: 'How quickly did your symptoms develop?',
      options: [
        {
          text: 'Over Minutes / Hours',
          code: '1'
        },
        {
          text: 'Over Days',
          code: '2'
        },
        {
          text: 'Over Weeks',
          code: '3'
        },
        {
          text: 'Over Months',
          code: '4'
        }
      ]
    }],
    [2, {
      question: 'How long have you had your symptoms?',
      options: [
        {
          text: '0 - 6 Days',
          code: '1'
        },
        {
          text: 'Weeks',
          code: '2'
        },
        {
          text: 'Months',
          code: '3'
        }
      ]
    }],
    [3, {
      question: 'How have your symptoms changed over the last few hours/days?',
      options: [
        {
          text: 'Better',
          code: '1'
        },
        {
          text: 'Same',
          code: '2'
        },
        {
          text: 'Worse',
          code: '3'
        }
      ]
    }],
    [4, {
      question: 'How much pain or discomfort are you in?',
      options: [
        {
          text: 'No Pain',
          code: '1'
        },
        {
          text: 'Mild Pain',
          code: '2'
        },
        {
          text: 'Severe Pain',
          code: '3'
        },
        {
          text: 'Worst Possible Pain',
          code: '4'
        }
      ]
    }],
    [5, {
      question: 'How are your symptoms affecting your daily activities?',
      options: [
        {
          text: 'No effect',
          code: '1'
        },
        {
          text: 'Struggling to carry out usual activities',
          code: '2'
        },
        {
          text: 'Unable to carry out usual activities',
          code: '3'
        }
      ]
    }],
    [6, {
      question: 'Do you feel better after taking medication for your symptoms?',
      options: [
        {
          text: 'Not taking any',
          code: '1'
        },
        {
          text: 'Yes',
          code: '2'
        },
        {
          text: 'No',
          code: '3'
        }
      ]
    }],
    [7, {
      question: 'Do you have any other serious, long term conditions such as diabetes, cancer, heart condition, etc.?',
      options: [
        {
          text: 'Yes',
          code: '2'
        },
        {
          text: 'No',
          code: '1'
        }
      ]
    }]
  ]);
}

function mapAnswerToQuestionText(savedQuestions: Map<number, string>) {
  const getQuestionResponse = (n: number) => questionTextMap().get(n).options.find(option => option.code == savedQuestions.get(n)).text;
  return [
    getQuestionResponse(1),
    getQuestionResponse(2),
    getQuestionResponse(3),
    getQuestionResponse(4),
    getQuestionResponse(5),
    getQuestionResponse(6)

  ];
}

export  { questionTextMap, mapAnswerToQuestionText };
