<!-- Main Menu -->
<ion-menu
  menuId="main-menu"
  contentId="root-content"
  side="start"
  *ngIf="langReady"
>
  <ion-content>
    <ion-list>

      <ion-list-header mode="ios" class="margin-top-24 margin-bottom-16">
        <a href="javascript:void(0)" (click)="openRoute('/tabs/messages')">Messages</a>
      </ion-list-header>
      <ion-item button (click)="openFeature(f)" *ngFor="let f of messageFeatures">
        <img [src]="'/assets/baystate-icons/' + f.icon" slot="start" class="thumbnail" />
        <ion-label>{{ f.name }}</ion-label>
      </ion-item>

      <ion-list-header mode="ios" class="margin-top-24 margin-bottom-16">
        <a href="javascript:void(0)" (click)="openRoute('/tabs/health-record')">Health Record</a>
      </ion-list-header>
      <ion-item button (click)="openFeature(f)" *ngFor="let f of healthRecordFeatures">
        <img [src]="'/assets/baystate-icons/' + f.icon" slot="start" class="thumbnail" />
        <ion-label>{{ f.name }}</ion-label>
      </ion-item>

      <ion-list-header mode="ios" class="margin-top-24 margin-bottom-16">
        <a href="javascript:void(0)" (click)="openRoute('/tabs/resources')">Resources</a>
      </ion-list-header>
      <ion-item button (click)="openFeature(f)" *ngFor="let f of resourceFeatures">
        <img [src]="'/assets/baystate-icons/' + f.icon" slot="start" class="thumbnail" />
        <ion-label>{{ f.name }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</ion-menu>

<!-- User Menu -->
<ion-menu
  menuId="user-menu"
  contentId="root-content"
  side="end"
  *ngIf="langReady"
>
  <ion-header>
    <div class="user-menu-header">
      <bh-user-icon
        [userFullName]="authUser?.fullName"
        [userId]="authUser?.userId"
      ></bh-user-icon>
      <div class="user-name" *ngIf="authUser?.userId">
        <strong>{{ authUser?.fullName }}</strong>
        <span class="user-role">{{
          authUser?.role | formatRole | translate
        }}</span>
      </div>
      <div class="sign-in" *ngIf="!authUser?.userId">
        <ion-button
          fill="outline"
          size="small"
          expand="block"
          (click)="signIn()"
          bhAnalyticsClick
          eventName="user-menu: sign-in"
          >{{ "Sign in" | translate }}</ion-button
        >
      </div>
      <div class="close-button">
        <ion-button fill="clear" (click)="closeMenu()">
          <ion-icon name="close-outline" slot="icon-only"></ion-icon>
        </ion-button>
      </div>
    </div>
  </ion-header>
  <ion-content>
    <ion-list>
      <!-- Account -->
      <ng-container *ngIf="authUser?.userId">
        <div class="padding-bottom-24"></div>
        <ion-item-divider>{{ "Account" | translate }}</ion-item-divider>
        <ion-item
          button
          (click)="openMyAccount()"
          bhAnalyticsClick
          eventName="user-menu: my-account"
        >
          <ion-icon
            name="person-circle-outline"
            slot="start"
            aria-hidden="true"
          ></ion-icon>
          <ion-label>{{ "My account" | translate }}</ion-label>
        </ion-item>
        <ion-item
          button
          (click)="signOut()"
          bhAnalyticsClick
          eventName="user-menu: sign-out"
        >
          <ion-icon name="log-out-outline" slot="start" aria-hidden="true">
          </ion-icon>
          <ion-label>{{ "Sign out" | translate }}</ion-label>
        </ion-item>
      </ng-container>

      <!-- Preferences -->
      <ng-container
        *ngIf="env.enableMultiLanguageSupport || env.theme === 'user'"
      >
        <div class="padding-bottom-24"></div>
        <ion-item-divider>{{ "Preferences" | translate }}</ion-item-divider>
        <ion-item
          button
          (click)="setLanguage()"
          bhAnalyticsClick
          eventName="user-menu: set-language"
          *ngIf="env.enableMultiLanguageSupport"
        >
          <ion-icon
            name="globe-outline"
            slot="start"
            aria-hidden="true"
          ></ion-icon>
          <ion-label>{{ "Language" | translate }}</ion-label>
          <ion-label slot="end"
            ><strong>{{
              preferredLanguage?.name | translate
            }}</strong></ion-label
          >
        </ion-item>
        <ion-item
          button
          bhAnalyticsClick
          eventName="user-menu: dark-mode"
          [detail]="false"
          *ngIf="env.theme === 'user'"
        >
          <ion-icon
            name="bulb-outline"
            slot="start"
            aria-hidden="true"
          ></ion-icon>
          <ion-label>{{ "Dark mode" | translate }}</ion-label>
          <ion-toggle
            class="user-menu-toggle"
            slot="end"
            [(ngModel)]="paletteToggle"
            (ionChange)="toggleChange($event)"
          ></ion-toggle>
        </ion-item>
      </ng-container>

      <!-- Other Topics -->
      <div class="padding-bottom-24"></div>
      <ion-item-divider>{{ "Other topics" | translate }}</ion-item-divider>
      <ion-item
        button
        (click)="openFeedback()"
        bhAnalyticsClick
        eventName="user-menu: feedback"
      >
        <ion-icon
          name="volume-medium-outline"
          slot="start"
          aria-hidden="true"
        ></ion-icon>
        <ion-label>{{ "Give feedback" | translate }}</ion-label>
      </ion-item>
      <ion-item
        button
        (click)="openShareThisApp()"
        bhAnalyticsClick
        eventName="user-menu: share-app"
      >
        <ion-icon
          name="share-outline"
          slot="start"
          aria-hidden="true"
        ></ion-icon>
        <ion-label>{{ "Share this app" | translate }}</ion-label>
      </ion-item>
      <ion-item
        button
        (click)="openHelp()"
        bhAnalyticsClick
        eventName="user-menu: get-help"
      >
        <ion-icon
          name="help-circle-outline"
          slot="start"
          aria-hidden="true"
        ></ion-icon>
        <ion-label>{{ "Get help" | translate }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</ion-menu>

<ion-app *ngIf="langReady">
  <ion-content id="root-content"></ion-content>
  <ion-router-outlet></ion-router-outlet>
</ion-app>
<div class="loader-backdrop" [ngClass]="{ show: isLoading }">
  <div class="spinner-wrapper">
    <div class="spinner-background">
      <ion-spinner class="loading-modal-spinner"></ion-spinner>
      <div class="loading-message" *ngIf="loadingMessage">
        {{ loadingMessage | translate }}
      </div>
    </div>
  </div>
</div>
