import { Injectable, OnInit } from '@angular/core';
import { RoleType } from "../model/RoleType";
import { Type } from '@angular/compiler';
import { ActivatedRouteSnapshot } from '@angular/router';
import { HealthRecordComponent } from '../component/page/health-record/health-record.component';
import { MessagesComponent } from '../component/page/messages/messages.component';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { AuthenticateService } from './authenticate.service';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  public isProxyOnlyUser: Observable<boolean>;

  readonly routeGuardMap: Map<string,RoleType[]> = new Map([
    ["health-record", new Array(RoleType.LITEUSER)],
  ])

  readonly allowedRoutes: string[] = [
      'vaccinations',
      'test-results',
      'request-appointment'
  ];

  constructor(private http : HttpClient,
              private constantsService : ConstantsService,
              private _authenticateService: AuthenticateService) {

    this.isProxyOnlyUser = new Observable(subscriber => {
      this._authenticateService.getCachedAuthStatus().subscribe(authStatus => {
        if (!authStatus || !authStatus.LoggedIn) return;
        const isProxyOnly: boolean = (
          authStatus.EffectiveUserID == authStatus.LoggedInUserID &&
          authStatus.UserData.ProxyOnly == 1
        );
        subscriber.next(isProxyOnly);
      });
    });

  }

  async getRoles() : Promise<RoleType[]>{
    return await ((await this.http.get(`${this.constantsService.constantsData.apiUrl}/users/getUserRoles`).toPromise() as RolesResponse)).Roles || []
  }

  public getBlockedRolesByComponent(urlParts: string[]): RoleType[] {
    let blockedRoles = [];
    if (!urlParts.some(r => this.allowedRoutes.includes(r))) {
      urlParts.forEach(url => {
        blockedRoles = blockedRoles.concat(this.routeGuardMap.get(url));
      });
    }
    return Array.from(new Set(blockedRoles)) || [];
  }
}

interface RolesResponse {
  Roles: Array<RoleType>
}
