import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { DateHandlerService } from '../service/date-handler.service';

/** Wrapper for angulars date pipe. Needed because Safari cannot natively parse the date format coming from the personal community back end. */
@Pipe({
  name: 'smcDate'
})
export class SmcDatePipe implements PipeTransform {

  constructor(private dateHandler: DateHandlerService) {}

  transform(date: any, formatString: string): string {
    if (!date) return '';
    if (date instanceof Date) {
      if (isNaN(date.getTime())) return 'Invalid date';
      return formatDate(date, formatString, 'en-us');
    }
    if (typeof date == 'string') {
      try {
        const parsedDate = this.dateHandler.parseDateString(date);
        return formatDate(parsedDate, formatString, 'en-us');
      }
      catch (error) {
        return 'Invalid date';
      }
    }
    throw new Error('unexpected value passed to SmcDatePipe');
  }

}
