import { PersonalInformationService } from './../../../service/personal-information.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bh-page-heading',
  templateUrl: './page-heading.component.html',
  styleUrls: ['./page-heading.component.scss'],
})
export class BhPageHeadingComponent implements OnInit {
  @Input() pageTitle;
  @Input() showTitle = true;
  name = '';
  dob = '';
  age = '';
  gender = '';
  demoInterval = null;

  constructor(
    private personalInformation: PersonalInformationService
  ) { }

  async ngOnInit() {
    let pi = this.personalInformation.demoSubject.getValue();

    if (pi && pi.Patient) {
      this.populatePatient(pi);
    } else {
      this.loadDemographicsData();
    }
  }

  async loadDemographicsData(): Promise<any> {
    const storedPi = this.personalInformation.demoSubject.getValue();
    let pi = {};
    if (!storedPi || !storedPi.Patient) {
      pi = await this.personalInformation.demographics();
      this.personalInformation.demoSubject.next(pi);
      this.populatePatient(pi);
    }
    console.log('pi => ', pi);
    return Promise.resolve(pi)
  }

  populatePatient(pi) {
    this.name = pi.Patient.Name;
    this.dob = pi.Patient.DateOfBirthDisplay;
    this.age = pi.Patient.PatientAge;
    this.gender = pi.Patient.Gender;
  }
}
