import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent  {

  constructor(private location: Location) { }

  ionViewWillEnter() {}

  goBack() : void {
    this.location.back();
  }

}
