import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ShareHealthRecordService } from 'src/app/service/share-health-record.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormsValidationService } from 'src/app/service/forms-validation.service';
import { BhanalyticsService } from "../../../../service/bhanalytics.service";
import { AuthenticateService } from 'src/app/service/authenticate.service';
import { DatePipe } from '@angular/common';
import { DateHandlerService } from 'src/app/service/date-handler.service';

@Component({
  selector: 'app-share-health-record-with-provider',
  templateUrl: './share-health-record-with-provider.component.html',
  styleUrls: ['./share-health-record-with-provider.component.scss'],
  providers: [DatePipe]
})
export class ShareHealthRecordWithProviderComponent implements OnInit {
  private documentFormat: string;
  private startDate: string;
  private endDate: string;
  shareForm: FormGroup;
  userName:string="My";
  patientName:string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private shareHealthRecordService: ShareHealthRecordService,
    private formValidationService: FormsValidationService,
    private _bhAnalyticsService: BhanalyticsService,
    private authService: AuthenticateService,
    private _datePipe: DatePipe,
    private _dateHandler: DateHandlerService
  ) {

    this.shareForm = new FormGroup({
      recipient: new FormControl('', [Validators.required,this.formValidationService.getEmailValidator()]),
      message: new FormControl('')
    });

  }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.documentFormat = params.get('documentFormat');
      this.startDate = params.get('startDate');
      this.endDate = params.get('endDate');
    });

    this.authService.getCachedAuthStatus().subscribe(authStatus => {
      if (authStatus != null && authStatus.PatientName) {
        if(authStatus.PatientName.indexOf(" ")>-1){
          this.patientName = authStatus.PatientName.split(" ")[0];
          this.userName = this.toPossessive(this.patientName);
        }else{
          this.patientName = authStatus.PatientName;
          this.userName = this.toPossessive(this.patientName);
        }

      }

    });
  }

  cancel() {
    this.router.navigate(
      ['../../..'],
      { relativeTo: this.route }
    );
  }

  toPossessive(name: string) {
    if (!name) return '';
    const endsWithS = name.toLowerCase()[name.length - 1] == 's';
    return name + (endsWithS ? "'" : "'s");
  }

  async transmit() {
    Object.keys(this.shareForm.controls).forEach(field => {
      const control: any = this.shareForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if(this.shareForm.valid) {
      this._bhAnalyticsService.clickEvent('transmit health record', this.shareForm.controls.recipient.value);
      let subject = "Health records for " + this.patientName;
      if (this.documentFormat.indexOf(":") > -1) {
        let types = this.documentFormat.split(":");

        await this.shareHealthRecordService.sendToProvider({
          DocumentType: types[0],
          EndDate: this.endDate,
          Message: this.shareForm.controls.message.value,
          SendTo: this.shareForm.controls.recipient.value,
          StartDate: this.startDate,
          Subject: subject
        });

        await this.shareHealthRecordService.sendToProvider({
          DocumentType: types[1],
          EndDate: this.endDate,
          Message: this.shareForm.controls.message.value,
          SendTo: this.shareForm.controls.recipient.value,
          StartDate: this.startDate,
          Subject: subject
        });

      } else {
        await this.shareHealthRecordService.sendToProvider({
          DocumentType: this.documentFormat,
          EndDate: this.endDate,
          Message: this.shareForm.controls.message.value,
          SendTo: this.shareForm.controls.recipient.value,
          StartDate: this.startDate,
          Subject: subject
        });
      }


      this.router.navigateByUrl('/authenticated/health-record/share/share-with-provider-done');
    } else {
      let focusDone = false;
      Object.keys(this.shareForm.controls).forEach(field => {
        const control: any = this.shareForm.get(field);
        control.markAsTouched({onlySelf: true});
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement != undefined && typeof control.nativeElement.setFocus === "function") {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }
  }

  getFormat(){

    let format:string = '';

    if(this.documentFormat.indexOf("HTML")>-1){
      format = "HTML file for viewing";
    }

    if(this.documentFormat.indexOf("CCD")>-1){
      if(format){
        format = format + ", "
      }
      format =  format + "CCD file for the provider office's use";
    }


    return format;
  }

  getPeriod(){

    return this._datePipe.transform(this._dateHandler.parseDateString(this.startDate), 'MM/dd/yyyy') + " - " + this._datePipe.transform(this._dateHandler.parseDateString(this.endDate), 'MM/dd/yyyy');
  }

}
