import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  HttpHandler,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { AuthenticateService } from "./authenticate.service";
import { ModalService } from "./modal.service";
import { ModalType } from "../model/ModalTypeEnum";
import { HTTPStatusService } from "./http-status.service";
import { async } from "@angular/core/testing";
import { ignore } from "selenium-webdriver/testing";
import { ConstantsService } from "./constants.service";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  openedModals: Promise<HTMLIonModalElement>[] = [];

  constructor(
    private authService: AuthenticateService,
    private modalService: ModalService,
    private httpStatusService: HTTPStatusService,
    private _constantsService: ConstantsService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Since submitting form data requires explicitly NOT setting a Content-Type header,
    // the below fake header can be set as an escape hatch to avoid setting any Content-Type at all.
    const contentTypeDesired =
      req.headers.get("SMC-Dont-Set-Content-Type") != "true";
    req.headers.delete("SMC-Dont-Set-Content-Type"); // remove the fake header

    const excludeCredentials =
      req.headers.get("SMC-Exclude-Credentials") == "true";

    // for provider searches, we ocassionally have search strings that generate a 500 error.
    const ignore500Error = req.headers.get("SMC-Ignore-500-Error") == "true";
    req.headers.delete("SMC-Ignore-500-Error"); // remove fake header

    const ignoreAllErrorsForRequest =
      req.headers.get("SMC-Ignore-All-Errors-For-Request") == "true";
    req.headers.delete("SMC-Ignore-All-Errors-For-Request"); // remove fake header

    // for a bad un/pw in the login modal, we don't want to navigate from a hybrid guest/authenticated page
    // include this header to continue all login failure actions except redirection to login page
    const stayOnPageWithError =
      req.headers.get("SMC-Stay-On-Page-With-Error") == "true";
    //console.log("interceptor", stayOnPageWithError, req.headers);

    req.headers.delete("SMC-Stay-On-Page-With-Error"); // remove fake header

    const isabelKey = req.headers.get("Key") == "authorization";
    const isabelValue =
      req.headers.get("Value") == "CnR0t5V4Cfa7i1zsJpH3rtO5n6476c8i";

    let authReq = null;

    if (isabelKey && isabelValue) {
       authReq = req.clone({
        withCredentials: !excludeCredentials,
        headers: contentTypeDesired
          ? new HttpHeaders({
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "authorization": "CnR0t5V4Cfa7i1zsJpH3rtO5n6476c8i",
              "SMC-Exclude-Credentials": "true",
              "SMC-Stay-On-Page-With-Error": "true",
            })
          : new HttpHeaders({
              "Access-Control-Allow-Origin": "*",
            }),
      });
    } else {
       authReq = req.clone({
        withCredentials: !excludeCredentials,
        headers: contentTypeDesired
          ? new HttpHeaders({
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            })
          : new HttpHeaders({
              "Access-Control-Allow-Origin": "*",
            }),
      });
    }

    // const authReq = req.clone({
    //   withCredentials: !excludeCredentials,
    //   headers: contentTypeDesired
    //     ? new HttpHeaders({
    //         "Content-Type": "application/json",
    //         "Access-Control-Allow-Origin": "*",
    //       })
    //     : new HttpHeaders({
    //         "Access-Control-Allow-Origin": "*",
    //       }),
    // });

    return next.handle(authReq).pipe(
      tap(() => {
        // fake header to disable loading indicator for this request
        if (req.headers.get("SMC-Dont-Show-Loader") == "true") {
          req.headers.delete("SMC-Dont-Show-Loader");
          return;
        }
        this.httpStatusService.setHttpStatus(true);
      }),
      catchError((e: HttpErrorResponse) => {
        if (ignoreAllErrorsForRequest) {
          console.log("Ignoring errors");
          return [];
        }
        if (e.status == 401) {
          this.authService.logout(stayOnPageWithError);
          if (e.url.indexOf("login") > -1) {
            throw e;
          }
        } else if (e.status == 500 && ignore500Error) {
          return [];
        } else if (e.error["error"]) {
          this.openModal(
            "An error has ocurred",
            e.error["error"],
            ModalType.BASIC
          );
        } else if (e.status == 404) {
          this.openModal(
            "An error has ocurred",
            "Could not load resource",
            ModalType.BASIC
          );
        } else if (e.status == 503) {
          this.openModal(
            "An error has ocurred",
            "Service temporarily unavailable, please try again",
            ModalType.BASIC
          );
        } else if (e.status == 0 && !navigator.onLine) {
          this.openModal(
            "No Network",
            "No network detected, please check your network and try again.",
            ModalType.BASIC
          );
        } else if (e.status == 0) {
          // microsoft edge returns status zero for the log in call, so do not show error in this case
          if (
            e.url &&
            !e.url.startsWith(
              this._constantsService.constantsData.apiUrl + "/auth/login"
            )
          )
            this.openModal(
              "Network Error",
              "Could not connect to the server. Please try again in a few minutes.",
              ModalType.BASIC
            );
        } else {
          this.openModal("An error has ocurred", e.message, ModalType.BASIC);
        }

        return [];
      }),
      finalize(() => {
        this.httpStatusService.setHttpStatus(false);
      })
    );
  }

  async openModal(title: string, body: string, type: ModalType) {
    // only open a new network error modal if there are none currently open
    if (this.openedModals.length == 0) {
      const newModal = this.modalService.open(title, body, type);
      this.openedModals.push(newModal);
      // remove modals from list when they close
      (await newModal).addEventListener("ionModalDidDismiss", () => {
        const indexToRemove = this.openedModals.indexOf(newModal);
        this.openedModals.splice(indexToRemove, 1);
      });
    }
  }
}
