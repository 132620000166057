import {AfterViewInit, Component, ElementRef, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { MessagesService } from 'src/app/service/messages.service';
import { SmcTableData } from 'src/app/model/SmcTableData';
import { Router } from '@angular/router';
import {PreviousRouteService} from '../../../../service/previous-route.service';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import { AlertController, ModalController } from '@ionic/angular';
import { MessageModalComponent } from '../message-modal/message-modal.component';
import { ModalService } from 'src/app/service/modal.service';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { MatPaginator } from '@angular/material';
import { Message } from 'src/app/model/Messages';

// date: "2022-11-11 16:31:54.101180"
// from: "Clinician-Initiated Communications"
// hasAttachments: 0
// id: 7551
// subject: "Pediatric ED visits are on the RISE"
// unread: false

const ELEMENT_DATA: Message[] = [
  {date: '2022-11-11 16:31:54.101180', from: 'Clinician-Initiated Communications', hasAttachments: 0, id: 7551, subject: 'Pediatric ED visits are on the RISE', unread: false }
  // {from: 'Baystate Pathology', subject: 'Your visit summary', date: 'Dec 12'},
];


@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
})
export class InboxComponent implements OnInit {

  inboxTableData: SmcTableData;
  messages: Message[] = [];

  constructor(
    private messagesService: MessagesService,
  ) { }

  async ngOnInit () {
    await this.getMessages();
  }

  async getMessages() {
    console.log('inbox: getMessages()')
    this.messages = [];
    try {
        const res = (await this.messagesService.inbox()).children.map(item => ({
          date: item.Time,
          from: item.From,
          hasAttachments: item.HasAttachments,
          id: item.id,
          subject: item.Subject,
          unread: !item.IsRead
        })).sort((a, b) => b.date > a.date ? 1 : -1);
        if(Array.isArray(res) && res.length > 0) {
          this.messages = Object.values(res);
        }
    } catch (error) {
      throw error;
    }
  }

}
