import { catchError, first, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { ConsoleContent } from '../model/ConsoleContent';
import { ConsoleSiteInfo } from '../model/ConsoleSiteInfo';
import { ResponseConsoleCollection } from '../model/ConsoleCollection';
import { PieGridComponent } from '@swimlane/ngx-charts';


@Injectable({
  providedIn: 'root'
})
export class ConsoleDataService {
  slidesSubject: BehaviorSubject<ConsoleContent[]> =  new BehaviorSubject([]);
  siteInfo: ConsoleSiteInfo = {};
  env = environment;

  constructor(private http: HttpClient) {
  }

  getSlides(active: number) {
    const headers = this.getRequestHeaders();
    return this.http.get(`${this.env.CONSOLE_URL}/public/slides?active_only=${active}`, { withCredentials: false, headers }) as Observable<ResponseConsoleCollection>;
  }

  getSiteInfo() {
    const headers = this.getRequestHeaders();
    return this.http.get(`${this.env.CONSOLE_URL}/public/info`, { withCredentials: false, headers }) as Observable<ConsoleSiteInfo>;
  }

  getSlidesSubject(): ConsoleContent[] {
    return this.slidesSubject.getValue();
  }

  setSlidesSubject(slides) {
    this.slidesSubject.next(slides);
  }

  async getSpecificBanner(slideSeq: number, active = 1) {
    try {
      const headers = this.getRequestHeaders();
      const res = this.http.get(`${this.env.CONSOLE_URL}/public/slides?active_only=${active}`, { withCredentials: false, headers }) as Observable<ResponseConsoleCollection>;
      console.log('res', res);
      
    } catch (err) {
      console.error("Get banner information: ", err);
    }
  }

  /**
   * Get MyBaystate Console Data API Request headers
   */
   getRequestHeaders() {
    const headers = {
      'Content-Type': 'application/json',
      'SMC-Exclude-Credentials': 'true',
      'SMC-Dont-Set-Content-Type': 'false'
    };
    return headers;
  }
}
