import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-pci-confirmation',
  templateUrl: './public-pci-confirmation.component.html',
  styleUrls: ['./public-pci-confirmation.component.scss'],
})
export class PublicPciConfirmationComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
