import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Encounter } from 'src/app/model/Encounter';
import { Insurance } from 'src/app/model/Insurance';
import { Location } from '@angular/common';
import { SmcTableData } from 'src/app/model/SmcTableData';
import { DocumentsService } from 'src/app/service/documents.service';
import { Router } from '@angular/router';
import { Document } from "../../../../model/Document";
import { TestResultsService } from 'src/app/service/test-results.service';
import { PrintService } from 'src/app/service/print.service';
import { TestResult } from 'src/app/model/TestResult';
import { Vitals } from 'src/app/model/Vitals';
import { Observation } from 'src/app/model/Observation';
import { HealthStatsService } from 'src/app/service/health-stats.service';
import {BhanalyticsService} from "../../../../service/bhanalytics.service";
import { DateHandlerService } from 'src/app/service/date-handler.service';
@Component({
  selector: 'app-past-visit',
  templateUrl: './past-visit.component.html',
  styleUrls: [
    './past-visit.component.scss',
    '../../../shared/smc-data-list/lightweight-list-style.scss'
  ],
})

export class PastVisitComponent implements OnInit {
  visitDetails: Encounter = null;
  insuranceDetails: Insurance = null;
  reports: Document[];
  testResults: TestResult[];
  vitals: Vitals;
  selectedObservation: Observation = null;
  public reportsData: SmcTableData;
  public testData: SmcTableData;
  @ViewChild('printSection') printSection: ElementRef;
  @ViewChild('dateColumnTemplate') dateColumnTemplate: ElementRef;
  @ViewChild('iconSelectableTemplate') iconSelectableTemplate: ElementRef;
  @ViewChild('iconColumnTemplate') iconColumnTemplate: ElementRef;

  constructor(private _location: Location,
              private _documentsService: DocumentsService,
              private _router: Router,
              private _testResultsService: TestResultsService,
              private _healthStatsService: HealthStatsService,
              private _bhAnalyticsService: BhanalyticsService,
              private _dateHandler: DateHandlerService,
              private _printService: PrintService
              ) { }

  ngOnInit() {
    if(history.state.data){
      this.visitDetails = history.state.data;
      this.loadReportsData();
      this.loadTestData();
      this.loadStatsData();
    }else{
      this._location.back();
    }
  }

  selectInsurance(insurance: Insurance): void {
    this.insuranceDetails = this.insuranceDetails == insurance ? null : insurance;
  }

  async loadReportsData(){
    const response = await (this._documentsService.getAppointments());

    this.reportsData = new SmcTableData();
    this.reportsData.Title = "Reports";
    this.reportsData.columns = [
      { name:'Name', prop: 'DocumentName', cellClass: 'card-bold' },
      { name:'Date ',prop:'EnteredTime', cellClass: 'card-detail', cellTemplate:this.dateColumnTemplate },
      { name:'Type', prop:'DocumentType', cellClass: 'card-detail' },
      {name:"",cellTemplate:this.iconSelectableTemplate,maxWidth:3000,cellClass:'select-icon'}
    ];
    this.reports = (response && response.Documents) ? response.Documents.filter((doc) =>
      doc.EnteredTime.isSameDay(this.visitDetails.FromTime)
    ) : [];
    this.reportsData.rows = this.reports;
  }

  selectDocument(document : any | null){
    this._router.navigate(['authenticated/health-record/reports/report-detail'],{state: {data: document['selected'][0]}});
  }

  async loadTestData(){
    var rows = await this._testResultsService.getTestResults() as TestResult[];
    this.testResults = rows
      .sort((a, b) => b.Date > a.Date ? 1 : -1)
      .filter((a: TestResult) => (this._dateHandler.parseDateString(a.FromTime)).isSameDay(this.visitDetails.FromTime))
    this.testData = new SmcTableData();
    this.testData.Title = "Test results";
    this.testData.columns = [
      {name:'',prop:'ResultType', cellTemplate: this.iconColumnTemplate,headerClass: 'header-icon',cellClass: 'cell-icon'},
      { name: 'Test', prop: 'OrderedItemDisplay' ,maxWidth:2000},
      { name: 'Result status', prop: 'ResultStatus',maxWidth:2000 },
      { name: 'Ordered by', prop: 'OrderedBy',maxWidth:2000 },
      { name: 'Date', prop: 'Date', cellTemplate: this.dateColumnTemplate ,maxWidth:2000},
      {name:"",cellTemplate:this.iconSelectableTemplate,maxWidth:3000,cellClass:'select-icon'}
    ];
    this.testData.rows = this.testResults;
  }

  async loadStatsData() {
    const vitals = await (this._healthStatsService.getVitals());
    // Only set vitals if Observation arr is greater that 0.
    if (vitals.Observations.length > 0) {
      // chart call contains historical results (unfortunately also less data than normal vitals call)
      const vitalsChart = await this._healthStatsService.getChart(vitals.Observations.map(i => i.Observation));
      const associatedVitals = [];
      for (const category of vitalsChart.children) {
        for (const entry of category.Series) {
          const entryTime = this._dateHandler.parseDateString(entry.Time);
          if (entryTime && entryTime.isSameDay(this.visitDetails.FromTime)) {
            associatedVitals.push({
              Observation: category.DisplaySummary,
              ObservationValue: entry.Value,
              ObservationUnits: entry.Unit,
              Timestamp: this._dateHandler.parseDateString(entry.Time),
              EnteredAt: null
            });
          }
        }
      }
      this.vitals = {
        HistoryEnd: null,
        HistoryStart: null,
        Observations: associatedVitals,
        ProxyOnly: null
      };
    }
  }

  selectObservation(observation: Observation) : void {
    this.selectedObservation = this.selectedObservation != observation ? observation : null;
  }

  async getChart(observation: Observation){
    const chart = await (this._healthStatsService.getChart([observation].map((ob)=> ob.Observation)))
    this._router.navigate(['/authenticated/health-record/health-stats/health-stat-detail'],{state: {data: chart}});
  }

  openDetails(data) {
    this.setParamData(data.selected[0], 'testResult');
    const blurb = data.selected[0].OrderItem.toLowerCase().split(' ').join('-').split('/').join('-');
    if(data.selected[0].ResultType!="Image"){

      const testResultHistory = [].concat.apply([], this.testData.rows.filter(r => r['Code'] == data.selected[0].Code)
      .map(x => x.ResultItems)).map(({Test,ObservationTime,ObservationTimeDisplay,ResultValue,LocationFromMultiple}) => ({Test,ObservationTime,ObservationTimeDisplay,ResultValue,LocationFromMultiple})
      );
      this.setParamData(testResultHistory, 'testResultHistory');
      this._router.navigate(['authenticated','health-record','test-results','test-result-details', blurb]);
    }else{
      this._router.navigate(['authenticated','health-record' ,'test-results','x-ray-results', blurb]);
    }
  }

  setParamData(data, storeParamName) {
    localStorage.setItem(storeParamName, JSON.stringify(data));
  }

  async print() {
    this._bhAnalyticsService.clickEvent('Print Past Visit', '');
    // const pastVisit = this.printSection.nativeElement.innerHTML;
    // this._printService.printHtmlString(pastVisit);
    window.print();
  }
}
