import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { SmcTableData } from "src/app/model/SmcTableData";
import { MessagesService } from "src/app/service/messages.service";
import { Router } from "@angular/router";
import { PreviousRouteService } from "src/app/service/previous-route.service";
import { AlertController, ModalController } from "@ionic/angular";
import { ModalService } from "src/app/service/modal.service";
import { BhanalyticsService } from "src/app/service/bhanalytics.service";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { SentMessagesModalComponent } from "../../sent-messages-modal/sent-messages-modal.component";
import { Message } from "src/app/model/Messages";

// export interface SentMessages {
//   to: string;
//   from: string;
//   subject: string;
//   date: string;
//   id: number;
//   hasAttachments: number;
//   isSent: boolean;
// }

@Component({
  selector: "app-sent-messages",
  templateUrl: "./sent-messages.component.html",
  styleUrls: ["./sent-messages.component.scss"],
})
export class SentMessagesComponent {
  sentTableData: SmcTableData;
  messages: Message[] = [];

  constructor(
    private messagesService: MessagesService,
    private router: Router,
  ) { }

  viewMessage(event): void {
    this.router.navigate([
      "authenticated",
      "messages",
      "sent",
      "view-message",
      event.selected[0].id,
    ]);
  }

  async ngOnInit() {
    await this.getSentMessages();
  }

  async getSentMessages() {
    //const sentMessages = await this.messagesService.sentMessages();
    this.messages = [];
    try {
      const res = (await this.messagesService.sentMessages()).children
        .map((item) => ({
          to: item.To,
          from: '',
          subject: item.Subject,
          date: item.Time,
          id: item.id,
          hasAttachments: item.HasAttachments,
          isSent: true,
        }))
        .sort((a, b) => (b.date > a.date ? 1 : -1));
      if (Array.isArray(res) && res.length > 0) {
        this.messages = Object.values(res);
        console.log("sent Messages", this.messages);
      }
    } catch (error) {
      throw error;
    }
  }
}
