
import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { BhDataGridColumnDefinition } from 'src/app/model/bh-grid-column-definition';

@Component({
  selector: 'app-bh-data-grid',
  templateUrl: './bh-data-grid.component.html',
  styleUrls: ['./bh-data-grid.component.scss'],
})
export class BhDataGridComponent implements OnChanges {

  @Input() columnDefinitions: BhDataGridColumnDefinition[] = [];
  @Output() selectEvent = new EventEmitter<any>();
  @Input() data: any[] = [];
  parsedData: any[] = [];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [];
  @ViewChild(MatSort) sort: MatSort;

  constructor() {}
 
  ngOnChanges(changes: SimpleChanges) {
    if ("columnDefinitions" in changes && this.columnDefinitions) {
      this.setColumnDefinitions();
    }

    if ("data" in changes && this.data) {
      let i = 0;
      this.parsedData = [];
      for (const dataItem of this.data) {
        //console.log("dataItem", dataItem);
        const parsedItem = { bhGridRowIndex: i };
        for (const colDef of this.columnDefinitions) {
          let dataElement = dataItem[colDef.fieldName];
          // Check if date and format for sorting
          if (colDef.isDate && colDef.dateFormat) {
            const dataElementMoment = moment(dataElement, colDef.dateFormat);
            // Check for successful date parse
            if (dataElementMoment.isValid()) {
              dataElement = dataElementMoment.format("YYYY-MM-DD HH:mm:ss");
            } else {
              colDef.isDate = false;
            }
          } else {
            colDef.isDate = false;
          }
          parsedItem[colDef.fieldName] = dataElement;
        }
        this.parsedData.push(parsedItem);
        i += 1;
      }
      this.setDataSource();
    }
  }

  setColumnDefinitions() {
    this.displayedColumns = [];
    for (const cd of this.columnDefinitions) {
      if (cd.showColumn) {
        this.displayedColumns.push(cd.fieldName);
      }
    }
  }

  setDataSource() {
    this.dataSource = new MatTableDataSource<any>(this.parsedData);
    this.dataSource.sort = this.sort;
  }

  selectRow(dataItem) {
    this.selectEvent.emit(dataItem);
  }

  getDataItemIcon(dataItem) {
    if (this.columnDefinitions.length > 0) {
      const icon = this.columnDefinitions[0].ionIcon;
      return icon ? icon : null;
    } else {
      return null;
    }
  }

  getDataItemIconForColumn(
    colDef: BhDataGridColumnDefinition,
    dataValue: any
  ): any {
    //console.log("colDef", colDef, "dataValue", dataValue, "ionIconConditions", colDef.ionIconConditions);
    if (colDef.ionIcon) {
      let ionIcon = null;
      let iconColor = null;
      if (colDef.useIconArg) {
        //console.log("colDef", colDef, "dataValue", dataValue, colDef.showIconArg === dataValue);
        if (colDef.showIconArg === dataValue) {
          ionIcon = colDef.ionIcon;
          iconColor = colDef.ionIconColor;
        }
      } else {
        ionIcon = colDef.ionIcon;
        iconColor = colDef.ionIconColor;
      }
      return { icon: ionIcon, color: iconColor };
    } else if (
      colDef.ionIconConditions &&
      colDef.ionIconConditions.length > 0
    ) {
      const iconConditionMatch = colDef.ionIconConditions.find(
        (i) => {
          //console.log('i', i, 'dataValue', dataValue);
          return dataValue && i.value === dataValue}
      );
      //console.log('icon Condition Match', iconConditionMatch);
      
      return iconConditionMatch !== undefined
        ? { icon: iconConditionMatch.ionIcon, color: iconConditionMatch.color }
        : null;
    }
  }

  checkDataItemBadge(dataItem) {
    if (this.columnDefinitions.length > 0) {
      const hasIconBadge = this.columnDefinitions[0].hasIconBadge;
      const iconBadgeArgField = this.columnDefinitions[0].iconBadgeArgField;
      const iconBadgeArgValue = this.columnDefinitions[0].iconBadgeArgValue;
      return hasIconBadge && dataItem[iconBadgeArgField]
        ? dataItem[iconBadgeArgField] === iconBadgeArgValue
        : null;
    } else {
      return null;
    }
  }

  getDataItemTitle(dataItem) {
    if (this.columnDefinitions.length > 0) {
      const key = this.columnDefinitions[0].fieldName;
      return dataItem[key] ? dataItem[key] : null;
    } else {
      return null;
    }
  }

  getOtherDataItemProperties(dataItem) {
    if (this.columnDefinitions.length > 1) {
      let innerHtml = '<div class="font-size-small">';
      let index = 0;
      for (const cd of this.columnDefinitions) {
        if (
          Object.prototype.hasOwnProperty.call(dataItem, cd.fieldName) &&
          index > 0
        ) {
          const value = dataItem[cd.fieldName];
          // const iconName = cd.ionIcon ? cd.ionIcon : null;
          // const iconColor = cd.ionIconColor ? cd.ionIconColor : null;
          // const icon = (cd.showIconArg === dataItem[cd.fieldName] || cd.alwaysShowIcon) ?
          //   '...<ion-icon name="' + iconName + '" color="' + iconColor + '"></ion-icon>...' : '';
          // value = icon && cd.showIconOnly ?
          //   icon : icon ? value + icon : value;
          innerHtml += cd.columnLabel + ": <strong>" + value + "</strong><br>";
        }
        index += 1;
      }
      const key = this.columnDefinitions[1].fieldName;
      return index > 0 ? innerHtml + "</div>" : null;
    } else {
      return null;
    }
  }
}
