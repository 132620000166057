import { BhanalyticsService } from './../../../service/bhanalytics.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SmcTableData } from 'src/app/model/SmcTableData';
import { PrintService } from 'src/app/service/print.service';
import { StateService } from 'src/app/service/state.service';
import { HealthRecordService } from "../../../service/health-record.service";
import { VaccinationsService } from 'src/app/service/vaccinations.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-vaccinations',
  templateUrl: './vaccinations.component.html',
  styleUrls: ['./vaccinations.component.scss'],
})
export class VaccinationsComponent implements OnInit {
  env = environment;
  category: any;
  public facilitiesList: any;
  public data: SmcTableData;
  @ViewChild('printSection') printSection: ElementRef;
  @ViewChild('dateColumnTemplate') dateColumnTemplate: ElementRef;
  constructor(
    private _healthRecordService: HealthRecordService,
    private _vaccinationsService : VaccinationsService,
    private _printService : PrintService,
    private _bhAnalyticsService: BhanalyticsService
    ) { }

  async ngOnInit() {
    const category = this.env.banner_seq.vaccinations;
    console.log('cat', category);
    this.category = category;
    const rows = await this._vaccinationsService.getVaccinations();
    const facilityList = await this._healthRecordService.getFacilitiesPhoneNumber();
    this.facilitiesList = facilityList.providers;
    this.data = new SmcTableData();
    this.data.columns = [
      { name: 'Vaccine', prop: 'OrderedItemDisplay', cellClass: 'card-bold' },
      { name: '', prop: 'ProviderName', cellClass: 'card-detail' },
      { name: 'Lot Number', prop: 'Administrations.LotNumber', cellClass: 'card-detail' },
      { name: 'Date Administered', prop: 'Date', cellClass: 'card-detail', cellTemplate: this.dateColumnTemplate, minWidth: 170 },
      { name: 'Location', prop: 'LocationFromMultiple', cellClass: 'card-detail' },
      { name: 'Provider Number', prop: 'ProviderPhone', cellClass: 'card-detail' }
    ];
    this.data.rows = rows.map(row => {
      const location = (row.EnteredAt || row.EnteredBy || row.EnteringOrganization);
      let lotNumber = ''
      if(row.Administrations!=null && row.Administrations.length>0){
        lotNumber = row.Administrations[0].LotNumber;
      }

      return Object.assign(row, {
        'LocationFromMultiple': location,
        'ProviderPhone': this.getFilteredFacilityNumber(location.toLowerCase()),
        'Administrations.LotNumber':lotNumber
      })
    });
  }

  // Print vaccination table
  print(): void {
    // const vaccinationTable = this.printSection.nativeElement.innerHTML;
    // this._printService.printHtmlString(vaccinationTable);
    this._bhAnalyticsService.clickEvent('Print Upcoming Appointment', '');
    window.print();
  }

  // Return facilities phone number if locations match.
  getFilteredFacilityNumber(locationStr: string) {
    const locationName = locationStr.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
    const filteredFacility = this.facilitiesList.filter(facilityNumber => {
      const facilityName = facilityNumber.name.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
      const facilityCode = facilityNumber.code.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
      const namesMatch = (locationName.match(facilityName) || locationName.split(' ').join('').indexOf(facilityName.split(' ').join('')) > -1);
      const codeMatchesName = facilityCode.match(locationName);
      return (codeMatchesName || namesMatch);
    });
    return (filteredFacility[0] ? filteredFacility[0].phone :  '');
  }
}
