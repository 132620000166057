import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class HttpBaseService {

  constructor(private constantsService: ConstantsService,
              private httpClient: HttpClient) { }

  async getList(serviceEndpoint:string, addTimeStamp:boolean ,user?: UserIdentifier, suppressErrors?: boolean, diagnosesCheck?: boolean) {
    //Not sure if we should keep this code to swith the user
    console.log('service endpoint',serviceEndpoint );

    const headers = { 'SMC-Dont-Show-Loader': 'true' };
    if (suppressErrors === true) headers['SMC-Ignore-All-Errors-For-Request'] = 'true';
    if (user) {
      const url = `${this.constantsService.constantsData.apiUrl}/users/0/switchEffectiveUser`;
      const body = {
        TargetUserID: user.UserID || '',
        TargetStagedUserID: user.StagedUserID || ''
      };
      await this.httpClient.post(url, body, {headers}).toPromise();
    }

    var url = `${this.constantsService.constantsData.apiUrl+serviceEndpoint}`;
      const res = await this.httpClient.get(url).toPromise();
        console.log('res diagosis', res);
    // if(diagnosesCheck) {
    //   const header = 'mytest.baystatehealth.org/myhealth/api/v1'
    //   var url = `${header+serviceEndpoint}`;
    // } else {
    // }

    if(addTimeStamp){
      if(url.indexOf('?')>-1){
        url = `${url}&ts= ${Date.now()}`;
      }else{
        url = `${url}?ts= ${Date.now()}`;
      }
    }

    if(!diagnosesCheck) {
      var response = null;
      let retryCount = 0;
      while (retryCount < 3) {
        response = await this.httpClient.get(url, {headers}).toPromise() as any;
        if (!(response && response.error)) {
          break;
        }
        retryCount++;
        await this.delay(1000);
      }
    }
    return response;
  }

   getList2(serviceEndpoint:string, addTimeStamp:boolean ,user?: UserIdentifier, suppressErrors?: boolean, diagnosesCheck?: boolean) {
    //Not sure if we should keep this code to swith the user
    console.log('service endpoint',serviceEndpoint );

    const headers = { 'SMC-Dont-Show-Loader': 'true' };
    if (suppressErrors === true) headers['SMC-Ignore-All-Errors-For-Request'] = 'true';
    // if (user) {
    //   const url = `${this.constantsService.constantsData.apiUrl}/users/0/switchEffectiveUser`;
    //   const body = {
    //     TargetUserID: user.UserID || '',
    //     TargetStagedUserID: user.StagedUserID || ''
    //   };
    //   await this.httpClient.post(url, body, {headers}).toPromise();
    // }

    if(addTimeStamp){
      if(url.indexOf('?')>-1){
        url = `${url}&ts= ${Date.now()}`;
      }else{
        url = `${url}?ts= ${Date.now()}`;
      }
    }

    var url = `${this.constantsService.constantsData.apiUrl+serviceEndpoint}`;
      return this.httpClient.get(url).pipe(
        map((data: any) => {
          return data;
        }),
        catchError((err) => {

          return of(err);
        })
      );
  }

  async post(data:any,url:string, addTimeStamp?:boolean, shouldRetry:boolean=true){
    var fullUrl = this.constantsService.constantsData.apiUrl + url;
    if(addTimeStamp){
      if(fullUrl.indexOf('?')>-1){
        fullUrl = `${fullUrl}&ts= ${Date.now()}`;
      }else{
        fullUrl = `${fullUrl}?ts= ${Date.now()}`;
      }
    }

    var response = null;
    let retryCount = 0;
    while (retryCount < 3) {
      response = await (this.httpClient.post(fullUrl, data).toPromise() as any);
      if (!(response && response.error) || !shouldRetry) {
        break;
      }

      retryCount++;
      await this.delay(1000);
    }

    return response;
  }

  async delete(url:string, addTimeStamp?:boolean){
    var fullUrl = this.constantsService.constantsData.apiUrl + url;
    if(addTimeStamp){
      if(fullUrl.indexOf('?')>-1){
        fullUrl = `${fullUrl}&ts= ${Date.now()}`;
      }else{
        fullUrl = `${fullUrl}?ts= ${Date.now()}`;
      }
    }

    var response = null;
    let retryCount = 0;
    while (retryCount < 3) {
      response = await (this.httpClient.delete(fullUrl).toPromise() as any);
      if (!(response && response.error)) {
        break;
      }
      retryCount++;
      await this.delay(1000);
    }

    return response;
  }

  private delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

}

