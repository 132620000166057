import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { NewMessagePage } from '../messages/new-message/new-message.page';
import { AwsTranslateService } from 'src/app/service/aws-translate.service';

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.page.html',
  styleUrls: ['./whats-new.page.scss'],
})
export class WhatsNewPage implements OnInit {

  features = [
    {
      headline: 'Introducing the New Messaging Experience',
      image: 'nme-menu.png',
      description: 'Write messages using our reimagined New Message feature. We have introduced categories for improved routing to your care team. One-click selections for faster navigation. A more mobile-friendly experience and so much more. ',
      actionLabel: 'Try it now',
      action: 'new-message',
    },
    {
      headline: 'Request Appointments with Fewer Clicks',
      image: 'nme-new-appt.png',
      description: 'Submit Appointment Requests with fewer clicks and a more intuitive user experience. ',
      actionLabel: 'Try it now',
      action: 'appt-request'
    },
    {
      headline: 'Request Appointment Changes Online',
      image: 'nme-chg-appt.png',
      description: 'Request appointment cancellations or reschedules without having to make a call to your provider\'s practice. Simply choose \'Change an Appointment\' from the New Message topic box. ',
      actionLabel: 'Try it now',
      action: 'appt-change'
    },
    {
      headline: 'Request Multiple Prescription Refills at Once',
      image: 'nme-refill-rx.png',
      description: 'We reimagined our prescription refill request form so that it\'s easier to request multiple refills at once. ',
      actionLabel: 'Try it now',
      action: 'rx-refill'
    },
  ];

  changes = [
    'Test Results now open without losing your place in the test results list.',
    'Archive multiple inbox messages at once.',
    'Many small edits to enhance the overall user experience.'
  ]

  constructor(
    private analytics: BhanalyticsService,
    private modalCtrl: ModalController,
    public AWSTranslate: AwsTranslateService
  ) { }

  ngOnInit() {
  }

  async newMessage(mode) {
    this.analytics.clickEvent('Upcoming Visits: Change Appt: ', mode);
    const modal = await this.modalCtrl.create({
      component: NewMessagePage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        mode
      },
    });
    modal.onDidDismiss().then(async (data) => {

    });
    await modal.present();
  }

}
