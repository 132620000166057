// Array helper methods
declare global {
  interface Array<T> {
    intersection(other: Array<any>) : Array<any>;
    uniqueIntersection(other: Array<any>) : Array<any>;
    except(element: T): Array<T>;
  }
}

// Returns first list without values not present in other list
Array.prototype.intersection = function(other: Array<any>){
  return this.filter(it => other.includes(it));
}

// Returns list with unique instances of all elements that are common to both lists
Array.prototype.uniqueIntersection = function(other: Array<any>){
  return Array.from(new Set(this.intersection(other)));
}

// Returns list with specified element removed
Array.prototype.except = function(element){
  return this.filter(it => it !== element);
}

export {}
