import { Component, OnInit, Input, EventEmitter, Output, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { SmcTableData } from 'src/app/model/SmcTableData';

/** smc-table wrapper with email inbox-like styling */
@Component({
  selector: 'smc-inbox-table',
  templateUrl: './smc-inbox-table.component.html',
  styleUrls: ['./smc-inbox-table.component.scss'],
})
export class SmcInboxTableComponent implements OnInit {

  @Input() private set data(value: SmcTableData) {
    if (value) {
      // formatting and classes
      value.columns.forEach(column => {
        column.cellClass = this.setCellClasses
        if (column.prop == 'date') column.cellTemplate = this.dateColumnTemplate;
      });
      // sort rows by date descending
      value.rows.sort((a, b) => {
        if (a.date < b.date) return 1;
        if (a.date > b.date) return -1;
        return 0;
      })
    }
    this.modifiedData = value;
  }
  @Input() public AllowSearch: boolean;
  @Input() public rowDetailTemplate: TemplateRef<any>;
  @Input() public pageSizeShowMore: number = 10;
  @Input() public isSent: boolean;
  @Input() public searchBarPlaceholder: string;
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @ViewChild('dateColumnTemplate') dateColumnTemplate: ElementRef;

  public modifiedData: SmcTableData;

  constructor() { }

  ngOnInit() { }

  setRowClasses(row): { [index: string]: boolean } {
    return { 'unread': row.unread, 'clickable': true };
  }

  setCellClasses(cell: any): { [index: string]: boolean } {
    return {
      'bold': cell.row.unread && (cell.column.prop == 'to' || cell.column.prop == 'from'),
      'card-bold': cell.row.unread && (cell.column.prop == 'to' || cell.column.prop == 'from'),
      'card-detail': (cell.column.prop != 'to' && cell.column.prop != 'from')
    };
  };

  _onClick(selected) {
    this.onClick.emit(selected);
  }

  getIconName(row): string {
    if (row.isSent==true) {
      return 'MessagesSent';
    }

    return row.unread ? 'MessagesNew' : 'MessagesOpened';
  }

}
