import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SMCDataList } from 'src/app/model/SMCDataList';
import { TestResult } from 'src/app/model/TestResult';
import { ModalController } from '@ionic/angular';
import { ViewRadImageModalComponent } from './view-rad-image-modal/view-rad-image-modal.component';
import { ShareWithExternalProviderModalComponent } from './share-with-external-provider-modal/share-with-external-provider-modal.component';
import {Router} from "@angular/router";
import {BhanalyticsService} from "../../../service/bhanalytics.service";
import { ModalService } from 'src/app/service/modal.service';
import { PrintService } from 'src/app/service/print.service';

@Component({
  selector: 'app-image-test-result',
  templateUrl: './image-test-result.component.html',
  styleUrls: ['./image-test-result.component.scss'],
})
export class ImageTestResultComponent implements OnInit {

  testResult:TestResult;
  testResultData: SMCDataList;
  title: string;
  resultText: string;
  viewImageLink: string;
  imagePin: string;
  @ViewChild('printSection') printSection: ElementRef;


  constructor(public modalController: ModalController,
              private _bhAnalyticsService: BhanalyticsService,
              private _modalService: ModalService,
              private _printService: PrintService) { }

  ngOnInit() {

    this.testResult = this.getParamData(null, 'testResult') as TestResult;

    this.title =  this.testResult.OrderedItemDisplay;
    this.resultText =  this.testResult.ResultText;

    if ( this.testResult.CustomPairs) {
      this.viewImageLink = this.getCustomPairValue("eHGTURL",  this.testResult.CustomPairs);
      this.imagePin = this.getCustomPairValue("eHGTPIN",  this.testResult.CustomPairs);
    }


    this.testResultData = {
      options: {
        hideEmptyFields: false
      },
      items: [
        { label: 'Ordered by', value: [ this.testResult.OrderedBy] },
        { label: 'Test date', value: [ this.testResult.Date] },
        { label: 'Status', value: [ this.testResult.Status] },
        { label: 'Result status', value: [ this.testResult.ResultStatus] },
        { label: 'Result type', value: [ this.testResult.ResultType] },
      ]
    };

  }

  getParamData(data, storeParamName) {
    let vaue;
    if (data) {
      vaue = data;
      localStorage.setItem(storeParamName, JSON.stringify(vaue));
    } else {
      vaue = JSON.parse(localStorage.getItem(storeParamName));
    }

    return vaue;
  }

  getCustomPairValue(name, customPairs) {
    let pair = customPairs.find(pair => pair.Name == name);
    if (pair) {
      return pair.Value;
    }

    return '';
  }

  async viewImage() {
    this._bhAnalyticsService.clickEvent('View Image', '');
    const modal = await this.modalController.create({
      component: ViewRadImageModalComponent,
      componentProps: { link: this.viewImageLink, pin: this.imagePin },
      cssClass: ['view-image-modal', 'modal-limited-size']
    });
    this._modalService.register(modal);
    return await modal.present();
  }

  async shareWithExternalProvider() {
    this._bhAnalyticsService.clickEvent('Share Image', '');
    const modal = await this.modalController.create({
      component: ShareWithExternalProviderModalComponent,
      componentProps: { testResult: this.testResult },
      cssClass:"custom-modal"
    });
    this._modalService.register(modal);
    return await modal.present();
  }

  print() {
    this._bhAnalyticsService.clickEvent('Print Test Image', '');
    // const innerHTMLContent = this.printSection.nativeElement.innerHTML;
    // this._printService.printHtmlString(innerHTMLContent);
    window.print();
  }
}
