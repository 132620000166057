import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AwsTranslateService } from 'src/app/service/aws-translate.service';
import { ConstantsService } from 'src/app/service/constants.service';

@Component({
  selector: 'app-delete-request-page',
  templateUrl: './delete-request-page.page.html',
  styleUrls: ['./delete-request-page.page.scss'],
})
export class DeleteRequestPagePage implements OnInit {

  constructor(
    public modalController: ModalController,
    public constantsService: ConstantsService,
    public AWSTranslate: AwsTranslateService
  ) { }

  ngOnInit() {
  }

}
