import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import {TermsOfUseService} from "../../../service/terms-of-use.service";
import {TermsOfUse} from "../../../model/TermsOfUse";

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit {

  termsOfUse: TermsOfUse;

  constructor(private route: ActivatedRoute,
              private termsOfUseService: TermsOfUseService ) { }

  async ngOnInit() {
    this.route.paramMap.subscribe(async (params) => {
        let id = params.get('id');
        if(id){
          this.scrollTo(id);
        }
    });
    this.termsOfUse = await (this.termsOfUseService.getTermsOfUse());
  }

  scrollTo(id: string): void {
     document.getElementById(id).scrollIntoView(true);
  }
}
