import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ConstantsService } from 'src/app/service/constants.service';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  providers: [TitleCasePipe]
})
export class BreadcrumbComponent {

  urlParts: string[] = [];
  showBreadcrumb = true;
  showFakeHomeUrlPart = false;

  readonly hiddenUrlParts = [
    'authenticated'
  ];

  constructor(
    private router: Router,
    private constants: ConstantsService,
    private titleCasePipe: TitleCasePipe

  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.urlParts = event.urlAfterRedirects
          .split('/')
          .filter(urlPart => urlPart != '');

        for (let index = 0; index < this.urlParts.length; index++) {
          const element = this.urlParts[index];
          if (this.constants.constantsData.ignoreLastUrlPart.includes(element)) {
            this.urlParts.pop();
          }
        }

        let indexToRemoveAfter = -1;
        for (let index = 0; index < this.urlParts.length; index++) {
          const element = this.urlParts[index];
          if (this.constants.constantsData.ignoreEverythingAfter.includes(element)) {
            indexToRemoveAfter = index;
            break;
          }
        }

        if (indexToRemoveAfter > -1) {
          while (this.urlParts.length > indexToRemoveAfter + 1) {
            this.urlParts.pop();
          }
        }


        for (let index = 0; index < this.constants.constantsData.ignoreUrlPart.length; index++) {
          const element = this.constants.constantsData.ignoreUrlPart[index];
          let indexToRemove = this.urlParts.indexOf(element);
          if (indexToRemove > -1) {
            this.urlParts.splice(indexToRemove, 1);
          }
        }

        // display fake 'Home' link if home is not included in the url parts.
        if (!this.urlParts.includes('home')) this.showFakeHomeUrlPart = true;
        else this.showFakeHomeUrlPart = false;

        if (this.urlParts && this.urlParts.length == 2 && this.urlParts.includes('home') && this.urlParts.includes('authenticated')) {
          this.showBreadcrumb = false;
        } else {
          this.showBreadcrumb = true;
        }
      }
    });
  }

  breadcrumbNavigate(index: number): void {
    this.router.navigate([
      `${this.urlParts.slice(0, index + 1).join('/')}`
    ]);
  }

  getHumanFriendlyName(urlPart: string): string {
   urlPart = decodeURIComponent(decodeURIComponent(urlPart));
   return this.constants.constantsData.pageNameLookup[urlPart] || this.titleCasePipe.transform(urlPart.split('-').join(' '));
  }

  showIcon(urlPart) {
    return this.constants.constantsData.pageNameHideIcon.indexOf(urlPart) == -1;
  }

  showUrlPart(urlPart: string): boolean {
    return !this.hiddenUrlParts.includes(urlPart);
  }

  showPvixLogo(): boolean{
    return !this.constants.constantsData.pageNameHidePvixLogo.some(url => this.urlParts.includes(url));
  }
}
