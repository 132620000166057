import {Component, ViewChild} from '@angular/core';
import { ForgotCredentialsService } from 'src/app/service/forgot-credentials.service';
import { AlertController } from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {BhanalyticsService} from "../../../service/bhanalytics.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {PasswordRequirements} from "../../../model/PasswordRequirements";
import { PasswordChangeRequest } from '../../shared/password-change-form/password-change-form.component';

@Component({
  selector: 'smc-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent  {

  @ViewChild('newPassword') newPassword;
  @ViewChild('newPasswordConfirm') newPasswordConfirm;
  enableSubmitButton = false;
  formData: PasswordChangeRequest;

  constructor(
    private forgotService: ForgotCredentialsService,
    private alertController: AlertController,
    private _bhAnalyticsService: BhanalyticsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ionViewWillEnter() {
    let token = this.route.snapshot.queryParamMap.get("token");
    if (token) {
      let validateResponse = await this.forgotService.validateResetToken(token);
      if (!validateResponse || validateResponse.valid != 1) {
        //TODO: invalid token handler
        await this.router.navigateByUrl('/login')
      }
    } else {
      //TODO: no token handler
      await this.router.navigateByUrl('/login')
    }
  }

  checkFormStatus(event: PasswordChangeRequest) {
    this.formData = event;
    this.enableSubmitButton = this.formData.isValid;
  }

  submit() {
    this._bhAnalyticsService.clickEvent('Submit Reset Password', '');
    this.forgotService.resetPassword(this.formData.newPassword)
      .then(this.resetPasswordSuccess)
      .catch(this.callFailed);
  }

  resetPasswordSuccess = async (response) => {
    let alert;
    if (response.error) alert = await this.alertController.create({
      header: 'Error',
      message: response.error,
      buttons: [{
        text: 'OK',
      }]
    });
    else {
      this.router.navigate(["/password-reset-success"]);
    }/* alert = await this.alertController.create({
      header: 'Success',
      message: 'Your password has been reset. Please log in with your new password.',
      buttons: [{
        text: 'OK',
        handler: () => this.router.navigateByUrl('/login')
      }]
    });
    await alert.present();
    */
  }

  callFailed = async (response) => {
    const alert = await this.alertController.create({
      header: 'Error',
      message: 'Please check your network connection and try again.',
      buttons: ['OK']
    });
    await alert.present();
  }

}
