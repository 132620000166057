import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { Injectable } from '@angular/core';
import { User } from '../model/user';
import { StateService } from './state.service';
import { ConstantsService } from './constants.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { Status } from '../model/Status';

import { AuthStatus, ProxyUser } from '../model/AuthStatus';
import { ModalService } from './modal.service';
import { ModalType } from '../model/ModalTypeEnum';
import { catchError, first, map } from 'rxjs/operators';
import { XealthService } from './xealth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {

  private _isLoggedInSubject = new BehaviorSubject<boolean>(false);
  private _URL: string;
  private _userStatusSubject = new BehaviorSubject<AuthStatus>(null);
  private _currentlyLoadingUserStatus = false;

  constructor(
    private stateService: StateService,
    private constantsService: ConstantsService,
    private httpClient: HttpClient,
    private router: Router,
    private modalService: ModalService,
    private xealthService: XealthService
  ) {
    this._URL = this.constantsService.constantsData.apiUrl;
  }

  /** Returns a list of proxy users + the logged in user */
  getProxyFor(): Promise<ProxyUser[]> {
    return new Promise((resolve, reject) => {
      this.getCachedAuthStatus().pipe(first()).subscribe(authStatus => {
        if (!authStatus.LoggedIn) {
          reject('not logged in');
          return;
        }
        resolve(
          [{
            UserID: authStatus.LoggedInUserID,
            DisplayName: authStatus.UserData.FullName,
            DateCreated: null,
            DateModified: null,
            DisplayExpiration: null,
            Expiration: null,
            Expired: null,
            FirstName: null,
            LastModified: null,
            LastModifiedDisplay: null,
            LastName: null,
            ProxyID: null,
            Relation: null,
            StagedUserID: null,
            TimeCreated: null,
            TimeCreatedDisplay: null
          }].concat(authStatus.ProxyFor)
        );
      })
    });
  }

  async authenticate(user: User, isModal = false) {
    try {
      const loginObject = { Username: user.username, Password: user.password, IsPrivateDevice:user.IsPrivateDevice };
      // For login modal, add header that tells interceptor service not to navigate away from target resource on 401 error
      const options = (isModal) ? {headers: {'SMC-Stay-On-Page-With-Error': 'true'}} : {};
      // note: do not use the /auth/login response because it's not accessible in microsoft edge
      const res = await this.httpClient.post(`${this._URL}/auth/login?ts=${(new Date()).getTime()}`, loginObject, options).toPromise();

      //const res = this.loginRequest(loginObject);

      console.log('response login', res);

      // use separate auth status call instead
      const authStatus = await this.loadFreshAuthStatus().toPromise() as any;

      if (authStatus) {
        this.stateService.setPatientName(authStatus['PatientName']);
        this.stateService.setUserId(authStatus['LoggedInUserID']);
        this.stateService.setisPrivateDevice(user.IsPrivateDevice);
        this.loadFreshAuthStatus();
      }

      return authStatus;
    } catch (error) {
      // notify error message in json response if present
      if (error.error.error && this.router.url != '/login' && !isModal) {
        this.modalService.open('Notice', error.error.error, ModalType.BASIC);
      }
      throw error;
    }
  }


    loginRequest(loginObject) {
      const url = `${this._URL}/auth/login?ts=${(new Date()).getTime()}`;
      const body = loginObject;
       this.httpClient.post(url, body).subscribe( data => {
          console.log('login data', data);
      })
      // return this.httpClient.post(url, body).pipe(
      //   map((data: any) => {
      //     console.log('login data', data);
      //     return data;
      //   }),
      //   catchError((error) => of(error))
      // );
    }




  public async logout(stayOnPage = false) {
    sessionStorage.clear();
    localStorage.clear();
    // only make a log out call if logged in
    if (this._isLoggedInSubject.getValue() == true) {
      await this.httpClient.post(`${this._URL}/auth/logout?ts=${(new Date()).getTime()}`, undefined).toPromise();
      this.modalService.dismissAllOpen();
      this.modalService.open("You've been logged out", "Please sign in again to continue working", ModalType.BASIC);
    }
    this._isLoggedInSubject.next(false);
    if (!stayOnPage) {
      this.router.navigate(["/login"]);
    }
  }

  public isLoggedIn(): BehaviorSubject<boolean> {
    return this._isLoggedInSubject;
  }

  public setLoggedIn(val: boolean): void {
    this._isLoggedInSubject.next(val);
  }

  public async SwitchUser(user: UserIdentifier) {
    const url = `${this.constantsService.constantsData.apiUrl}/users/0/switchEffectiveUser`;
    const body = {
      TargetUserID: user.UserID || '',
      TargetStagedUserID: user.StagedUserID || ''
    };
    // const options = {withCredentials: true};
    await this.httpClient.post(url, body).toPromise();
  }

  /** Get the latest auth status. The returned BehaviorSubject may initially hold null. */
  loadFreshAuthStatus(): Observable<AuthStatus> {
    const url = this.constantsService.constantsData.apiUrl + '/auth/status';
    this._currentlyLoadingUserStatus = true;
    const authStatusRequest = this.httpClient.get<AuthStatus>(url, {headers: {'SMC-Dont-Show-Loader': 'true'}});
    authStatusRequest.subscribe(
      userStatus => {
        this._currentlyLoadingUserStatus = false;
        this._userStatusSubject.next(userStatus);
      },
      error => {
        this._currentlyLoadingUserStatus = false;
        throw error;
      }
    );
    return authStatusRequest;
  }

  /** Get an auth status which may be outdated. Use if you want to avoid making an additional request to the server but need the logged in user details. Proxy information may be outdated. The returned BehaviorSubject may initially hold null. */
  getCachedAuthStatus(): BehaviorSubject<AuthStatus> {
    if (this._userStatusSubject.getValue() == null && this._currentlyLoadingUserStatus == false) {
      this.loadFreshAuthStatus();
    }
    return this._userStatusSubject;
  }

}
