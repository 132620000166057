import { Component, OnInit, Input, Injector } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { ModalService } from 'src/app/service/modal.service';
import { ModalType } from 'src/app/model/ModalTypeEnum';
import { RadImageInstructionalVideoModalComponent } from '../rad-image-instructional-video-modal/rad-image-instructional-video-modal.component';
import { Router } from '@angular/router';
import {BhanalyticsService} from "../../../../service/bhanalytics.service";
import {ToastService} from "../../../../service/toast.service";

@Component({
  selector: 'app-view-rad-image-modal',
  templateUrl: './view-rad-image-modal.component.html',
  styleUrls: ['./view-rad-image-modal.component.scss'],
})
export class ViewRadImageModalComponent implements OnInit {

  @Input() link: any;
  @Input() pin: any;
  constructor(
    public modalController: ModalController, 
    private _router: Router,
    //private injector: Injector,
    private _bhAnalyticsService: BhanalyticsService,
    private _modalService: ModalService,
    private _toastService: ToastService
  ) { }

  ngOnInit() {
  }


  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  async openVideo() {
    this._bhAnalyticsService.clickEvent('Open Rad Image Video', '');
    const modal = await this.modalController.create({
      component: RadImageInstructionalVideoModalComponent,
      cssClass:"custom-rad-modal"
    });
    this._modalService.register(modal);
    return await modal.present();
  }

  openHelpPage() {
    this._router.navigate(["help"]);
    this.dismiss();
  }

  async copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
//    const toastController = this.injector.get(ToastController);
 //   let toast =await toastController.create({message:'PIN copied to the clipboard',duration:3000});
    this._toastService.openToast({
      message: 'PIN copied to the clipboard',
          position: 'top',
          header:"",
          buttons:null,
          cssClass: 'success-toast',
          color: 'tertiary',
          duration: 3000
      });

      //toast.present();
  }
}
