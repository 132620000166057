import { MessageFlow } from './../model/MessageFlow';
import { Injectable } from '@angular/core';
import { MessageFlowListItem, MSG_FLOW_TYPE_ID } from '../model/MessageFlowListItem';
import { MessageFlowSubject } from '../model/MessageFlowSubject';
import { AwsTranslateService } from './aws-translate.service';

@Injectable({
  providedIn: 'root'
})
export class MessageFlowService {

  constructor(
    private AWSTranslate: AwsTranslateService
  ) { }

  loadMainMenuItems() {
    const mainMenuListItems: MessageFlowListItem[] = [
      {
        id: MSG_FLOW_TYPE_ID.MNU_EMERGENCY_NEED,
        heading: 'Emergency Need',
        detail: 'I have a medical or mental health emergency, or other symptoms: shortness of breath; new numbness, weakness, or change in vision; and/or difficulty speaking.',
        messageType: 'general',
        icon: 'Concerns-Danger.png',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.MNU_URGENT_MEDICAL_QUESTION,
        heading: 'Urgent Medical Question',
        detail: 'I have an urgent concern that needs a response today, or I want to request an appointment to be seen in the next two days. ',
        messageType: 'general',
        icon: 'Concerns.png',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.MNU_NON_URGENT_MESSAGE,
        heading: 'Non-urgent Message',
        detail: 'I have a message about a medicine, new symptoms, a test result, or other non-urgent need.',
        messageType: 'general',
        icon: 'MessagesNew.png',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.MNU_REQUEST_APPT,
        heading: 'Request an Appointment',
        detail: 'I need to schedule a non-urgent appointment such as an annual wellness visit or a follow-up.',
        messageType: 'general',
        icon: 'Calendar.png',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.MNU_CHANGE_APPT,
        heading: 'Change an Appointment',
        detail: 'I would like to reschedule or cancel a previously scheduled appointment. ',
        messageType: 'general',
        icon: 'all-visits.png',
        active: true,
        hideFromLiteUser: true
      },
      {
        id: MSG_FLOW_TYPE_ID.MNU_REFILL_RX,
        heading: 'Refill a Prescription',
        detail: 'I need a prescription refilled. ',
        messageType: 'medical',
        icon: 'Medicines.png',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.MNU_BILLING_QUESTION,
        heading: 'Billing Question',
        detail: 'I have a question about a bill or payment.',
        messageType: 'general',
        icon: 'PayABill.png',
        active: true,
        hideFromLiteUser: false
      },
    ];

    // Translate items
    for (const li of mainMenuListItems) {
      li.heading = this.AWSTranslate.read(li.heading, this.AWSTranslate.langId);
      li.detail = this.AWSTranslate.read(li.detail, this.AWSTranslate.langId);
    }

    return mainMenuListItems;
  }

  loadMessageSubjects() {
    const subjects: MessageFlowSubject[] = [
      {
        id: MSG_FLOW_TYPE_ID.MSG_NEW_SYMPTOMS,
        heading: 'Symptoms',
        detail: 'I have symptoms that are a concern to me.',
        messageType: 'medical',
        icon: 'SymptomChecker.png',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.MSG_MED_QUESTION,
        heading: 'Medication Question',
        detail: 'I have a question about a prescription or over-the-counter medication.',
        messageType: 'medical',
        icon: 'Medicines.png',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.MSG_PAT_DATA,
        heading: 'Patient Reported Data',
        detail: 'I have medical data I would like to share with my provider.',
        messageType: 'medical',
        icon: 'HealthStats.png',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.MSG_TEST_RESULT,
        heading: 'Test Result Question',
        detail: 'I have a question about a test result, e.g., lab or radiology results.',
        messageType: 'medical',
        icon: 'TestResults.png',
        active: true,
        hideFromLiteUser: false
      },
      // {
      //   id: MSG_FLOW_TYPE_ID.MSG_LETTER_FORM,
      //   heading: 'Letter or Form',
      //   detail: 'I have a completed form, or I have a form that I need my doctor or care team to complete and return to me.',
      //   messageType: 'general',
      //   icon: 'Reports.png',
      //   active: true,
      //   hideFromLiteUser: false
      // },
      {
        id: MSG_FLOW_TYPE_ID.MSG_REFERRAL,
        heading: 'Referral Question',
        detail: 'I have a question about a referral or would like to request one.',
        messageType: 'medical',
        icon: 'Providers.png',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.MSG_OTHER,
        heading: 'Other Topic',
        detail: 'I have a question about a topic that is not listed.',
        messageType: 'general',
        icon: 'AskAQuestion.png',
        active: true,
        hideFromLiteUser: false
      },
    ];

    // Translate items
    for (const li of subjects) {
      li.heading = this.AWSTranslate.read(li.heading, this.AWSTranslate.langId);
      li.detail = this.AWSTranslate.read(li.detail, this.AWSTranslate.langId);
    }

    return subjects;
  }

  loadVisitReasons() {
    // Current types: routine, new, followup, referral, other
    const subjects: MessageFlowSubject[] = [
      {
        id: MSG_FLOW_TYPE_ID.VIS_ANNUAL_WELLNESS,
        heading: 'Annual Wellness',
        detail: 'Yearly visit to see how you are doing with your overall physical health.',
        messageType: 'general',
        value: 'annual',
        icon: 'leaf.svg',
        active: true,
        hideFromLiteUser: false,
        minAge: 18,
        maxAge: 999
      },
      {
        id: MSG_FLOW_TYPE_ID.VIS_WELL_CHILD_VISIT,
        heading: 'Well Child Visit',
        detail: 'Comprehensive assessment and screening for children to evaluate their growth and development.',
        messageType: 'general',
        value: 'child-annual',
        icon: 'leaf.svg',
        active: true,
        hideFromLiteUser: false,
        minAge: 0,
        maxAge: 17
      },
      {
        id: MSG_FLOW_TYPE_ID.VIS_MEDICARE_WELLNESS,
        heading: 'Medicare Wellness',
        detail: 'Your health care provider will go over your health risks, prevention, and screenings. This is not a comprehensive physical exam.',
        messageType: 'general',
        value: 'medicare-wellness',
        icon: 'medicare.svg',
        active: true,
        hideFromLiteUser: false,
        minAge: 18,
        maxAge: 999
      },
      {
        id: MSG_FLOW_TYPE_ID.VIS_RETURN_VISIT,
        heading: 'Return Visit',
        detail: 'Routine monitoring of chronic med conditions or known problems you and your provider have been managing.',
        messageType: 'general',
        value: 'return',
        icon: 'return-visit.svg',
        active: true,
        hideFromLiteUser: false,
        minAge: 0,
        maxAge: 999
      },
      {
        id: MSG_FLOW_TYPE_ID.VIS_INPATIENT_ER_FOLLOWUP,
        heading: 'Inpatient or Emergency Room Follow-up',
        detail: 'A visit to your provider or specialist following a hospital or emergency room discharge.',
        messageType: 'general',
        value: 'followup',
        icon: 'return-ed-2.svg',
        active: true,
        hideFromLiteUser: false,
        minAge: 0,
        maxAge: 999
      },
      {
        id: MSG_FLOW_TYPE_ID.VIS_NEW_PATIENT,
        heading: 'New Patient Visit',
        detail: 'A visit for a first time Baystate Health patient.',
        messageType: 'general',
        value: 'followup',
        icon: 'new-patient.svg',
        active: true,
        hideFromLiteUser: false,
        minAge: 0,
        maxAge: 999
      }

    ];

    // Translate items
    for (const li of subjects) {
      li.heading = this.AWSTranslate.read(li.heading, this.AWSTranslate.langId);
      li.detail = this.AWSTranslate.read(li.detail, this.AWSTranslate.langId);
    }

    return subjects;
  }

  loadBillingSubjects() {
    const subjects: MessageFlowSubject[] = [
      {
        id: MSG_FLOW_TYPE_ID.BIL_INSURANCE,
        heading: 'Insurance',
        detail: 'I have a question about insurance coverage or payments.',
        messageType: 'general',
        icon: '',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.BIL_CHARGE,
        heading: 'Charge',
        detail: 'I have a question about a charge or billed service.',
        messageType: 'general',
        icon: '',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.BIL_PAYMENTS,
        heading: 'Payment',
        detail: 'I have a question about a payment.',
        messageType: 'general',
        icon: '',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.BIL_FIN_ASST,
        heading: 'Financial Assistance',
        detail: 'I have a question about financial assistance.',
        messageType: 'general',
        icon: '',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.BIL_ADDR_CHANGE,
        heading: 'Billing Address Changes',
        detail: 'I want to update my billing address.',
        messageType: 'general',
        icon: '',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.BIL_OTHER,
        heading: 'Other',
        detail: 'I have a question about a billing topic that is not listed.',
        messageType: 'general',
        icon: '',
        active: true,
        hideFromLiteUser: false
      }
    ];

    // Translate items
    for (const li of subjects) {
      li.heading = this.AWSTranslate.read(li.heading, this.AWSTranslate.langId);
      li.detail = this.AWSTranslate.read(li.detail, this.AWSTranslate.langId);
    }

    return subjects;
  }

  loadTimeframes() {
    const subjects: MessageFlowSubject[] = [
      {
        id: MSG_FLOW_TYPE_ID.TF_NEXT_AVAILABLE,
        heading: 'Next available opening',
        detail: '',
        messageType: 'general',
        value: 'NextAvail',
        icon: '',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.TF_WITHIN_WEEK,
        heading: 'Within the week',
        detail: '',
        messageType: 'general',
        value: 'NextWeek',
        icon: '',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.TF_WITHIN_2_WEEKS,
        heading: 'Within the next 2 weeks',
        detail: '',
        messageType: 'general',
        value: 'NextTwoWeeks',
        icon: '',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.TF_WITHIN_MONTH,
        heading: 'Within the next month',
        detail: '',
        messageType: 'general',
        value: 'NextMonth',
        icon: '',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.TF_WITHIN_2_MONTHS,
        heading: 'Within the next 2 months',
        detail: '',
        messageType: 'general',
        value: 'NextTwoMonths',
        icon: '',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.TF_WITHIN_3_MONTHS,
        heading: 'Within the next 3 months',
        detail: '',
        messageType: 'general',
        value: 'NextThreeMonths',
        icon: '',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.TF_WITHIN_6_MONTHS,
        heading: 'Within the next 6 months',
        detail: '',
        messageType: 'general',
        value: 'NextSixMonths',
        icon: '',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.TF_WITHIN_YEAR,
        heading: 'Within the next year',
        detail: '',
        messageType: 'general',
        value: 'NextYear',
        icon: '',
        active: true,
        hideFromLiteUser: false
      }

    ];

    // Translate items
    for (const li of subjects) {
      li.heading = this.AWSTranslate.read(li.heading, this.AWSTranslate.langId);
      li.detail = this.AWSTranslate.read(li.detail, this.AWSTranslate.langId);
    }

    return subjects;
  }

  loadApptChangeActions() {
    const subjects: MessageFlowSubject[] = [
      {
        id: MSG_FLOW_TYPE_ID.AC_RESCHEDULE,
        heading: 'Reschedule Appointment',
        detail: 'Find another date and time for this appointment.',
        messageType: 'general',
        value: 'reschedule',
        icon: 'arrow-redo-outline.svg',
        active: true,
        hideFromLiteUser: false
      },
      {
        id: MSG_FLOW_TYPE_ID.AC_CANCEL,
        heading: 'Cancel Appointment',
        detail: 'Remove this appointment and schedule at a later time.',
        messageType: 'general',
        value: 'cancel',
        icon: 'trash-outline.svg',
        active: true,
        hideFromLiteUser: false
      },
    ];

    // Translate items
    for (const li of subjects) {
      li.heading = this.AWSTranslate.read(li.heading, this.AWSTranslate.langId);
      li.detail = this.AWSTranslate.read(li.detail, this.AWSTranslate.langId);
    }

    return subjects;
  }

}
