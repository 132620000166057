import { BhanalyticsService } from './../../../service/bhanalytics.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DocumentsService } from 'src/app/service/documents.service';
import { Document } from "../../../model/Document";
import { SmcTableData } from 'src/app/model/SmcTableData';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {


  env = environment;
  category: any;
  public data: SmcTableData;
  @ViewChild('dateColumnTemplate') dateColumnTemplate: ElementRef;
  @ViewChild('iconSelectableTemplate') iconSelectableTemplate: ElementRef;

  constructor(
    private documentsService: DocumentsService,
    private _router: Router,
    private _bhAnalyticsService: BhanalyticsService
  ) { }

  ngOnInit() {
    const category = this.env.banner_seq.report;
    console.log('cat', category);
    this.category = category;
    this.loadData()
  }

  async loadData(filter?: Date | null) {
    const response = await (this.documentsService.getAppointments());

    this.data = new SmcTableData();

    this.data.columns = [
      { name: 'Name', prop: 'DocumentName', cellClass: 'card-bold', collapseCol: 0, collapseMinWidth: 200 },
      { name: 'Date ', prop: 'EnteredTime', cellClass: 'card-detail', cellTemplate: this.dateColumnTemplate, collapseCol: 0, collapseMinWidth: 200 },
      { name: 'Type', prop: 'DocumentType', cellClass: 'card-detail', collapseCol: 0, collapseMinWidth: 200 },
      { name: 'Location', prop: 'EnteredAt', cellClass: 'card-detail', collapseCol: 0, collapseMinWidth: 200 },
      { name: "", cellTemplate: this.iconSelectableTemplate, maxWidth: 3000, cellClass: 'select-icon', collapseCol: 1 }
    ];

    this.data.rows = response && response.Documents ? response.Documents : [];
    this.data.rows.sort((a, b) => b.Date > a.Date ? 1 : -1);

  }

  selectDocument(document: any | null) {
    this._router.navigate(['authenticated/health-record/reports/report-detail'], { state: { data: document['selected'][0] } });
  }

  print() {
    this._bhAnalyticsService.clickEvent('Print Upcoming Appointment', '');
    window.print();
  }


}
