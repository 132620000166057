import { Component, ElementRef, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Workflow } from "../../../../model/Workflow";
import { MedicinesService } from "../../../../service/medicines.service";
import { SmcTableData } from "../../../../model/SmcTableData";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PrescriptionStateService } from "../../../../service/prescription-state.service";
import { SmcTableComponent } from "../../../shared/smc-table/smc-table.component";
import { FormsValidationService } from "../../../../service/forms-validation.service";
import { BhanalyticsService } from "../../../../service/bhanalytics.service";
import { DateHandlerService } from 'src/app/service/date-handler.service';
import { ConsoleContent } from 'src/app/model/ConsoleContent';
import { ConsoleDataService } from 'src/app/service/console-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rx-select-medicine',
  templateUrl: './rx-select-medicine.component.html',
  styleUrls: ['./rx-select-medicine.component.scss'],
})
export class RxSelectMedicineComponent implements OnInit {
  env = environment;
  category: any;
  public data: SmcTableData;
  public nextPageStateDisabled: boolean = false; //true;
  bannerContent: ConsoleContent;
  public workflows: Workflow[] = [
    { Title: "Select medicine", Content: "", State: "current" },
    { Title: "Select provider", Content: "", State: "future" },
    { Title: "Select pharmacy", Content: "", State: "future" },
    { Title: "Your contact info", Content: "", State: "future" },
    { Title: "Review & submit", Content: "", State: "future" }
  ];

  displaySubmitError = false;

  patientForm: FormGroup = new FormGroup(
    {
      medicineNotListed: new FormControl(),
      medicineText: new FormControl('', { validators: [], updateOn: this.formValidationService.getValidationUpdateOn() }),
      comments: new FormControl()
    }
  );

  @ViewChild(SmcTableComponent) smcTableComponent: SmcTableComponent;
  @ViewChild('radioButtonTemplate') radioButtonTemplate: ElementRef;
  constructor(private _prescriptionStateService: PrescriptionStateService,
    private _medicinesService: MedicinesService,
    public _router: Router,
    private _activatedRoute: ActivatedRoute,
    private formValidationService: FormsValidationService,
    private _bhAnalyticsService: BhanalyticsService,
    private _dateHandler: DateHandlerService,
    private consoleDataService: ConsoleDataService
    ) {
    }

  async ngOnInit() {
    const category = this.env.banner_seq.prescription;
    console.log('cat', category);
    this.category = category;
    //this.getMessageInformation();
    var rows = await this._medicinesService.getMedicines();
    this.data = new SmcTableData();
    this.data.columns = [
      { name: '', cellTemplate: this.radioButtonTemplate, maxWidth: 50, collapseCol: 0 },
      { name: 'Medication', prop: 'Medication', cellClass: 'card-bold', collapseCol: 1, collapseMinWidth: 200   },
      { name: 'Instructions', prop: 'Instructions', cellClass: 'card-detail', collapseCol: 1, collapseMinWidth: 200   },
    ];
    console.log('medicines =>', this.data);
    this.data.rows = rows.filter(medicine => medicine.EnteredAt.toLowerCase().includes('baystate') && (medicine.ToTime == "" || new Date() < this._dateHandler.parseDateString(medicine.ToTime)));

    this._prescriptionStateService.loadForm(this.patientForm);
    this._prescriptionStateService.updateWorkflowContents(this.workflows);

    this._activatedRoute.paramMap.subscribe(async (params) => {
      try {
        let medicineKey = parseInt(params.get('key'));
        if (medicineKey) {
          let selectedMedicine = this.data.rows.find(medicine => medicine['key'] == medicineKey);
          if (selectedMedicine) {
            this.smcTableComponent.selected = [selectedMedicine];
            this.selectMedicine(selectedMedicine);
          }
        }

      }
      catch (error) {
        throw error;
      }
    });

    this.formStateChange();

  }

  formStateChange() {
    //this.nextPageStateDisabled = true;
    if (this.patientForm.get("medicineNotListed").value == true) {
      this.patientForm.get("medicineText").enable();
      this.patientForm.get("medicineText").setValidators([Validators.required]);
      document.getElementById("medicineTextDiv").style.display = 'block';
      this.smcTableComponent.selected = [];
    } else {
      this.patientForm.get("medicineText").disable();
      this.patientForm.get("medicineText").setValidators([]);
      document.getElementById("medicineTextDiv").style.display = 'none';
    }
  }

  nextPage() {
    if (this._prescriptionStateService.state.medication ||
      (this.patientForm.get("medicineNotListed").value == true && this.patientForm.valid)) {
      this.displaySubmitError = false;
      this._prescriptionStateService.saveForm(this.patientForm);
      this._router.navigateByUrl('/authenticated/health-record/medicines/request-prescription/rx-select-provider');
    } else if (!this._prescriptionStateService.state.medication && this.patientForm.get("medicineNotListed").value == false) {
      this.displaySubmitError = true;
    } else {
      this.displaySubmitError = false;
      let focusDone = false;
      Object.keys(this.patientForm.controls).forEach(field => {
        const control: any = this.patientForm.get(field);
        control.markAsTouched({ onlySelf: true });
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement != undefined && typeof control.nativeElement.setFocus === "function") {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }
  }

  selectMedicine(data): void {
    console.log('data', data);
    if (data && data.selected) {
      this._bhAnalyticsService.clickEvent('select medicine', data.selected[0]);
      this._prescriptionStateService.state.medication = JSON.stringify(data.selected[0]);
      this.patientForm.get("medicineNotListed").setValue(false);
      this.formStateChange();
    }
  }

  // async getMessageInformation() {
  //   try {
  //     const res = await this.consoleDataService.getSlides(1).toPromise();
  //     const bannerData = res.x_result.filter(row => row.ps_seq === 151 && row.active === 1);
  //     this.bannerContent = bannerData[0];
  //     console.log('banner Content', this.bannerContent);
  //     //this.consoleContents = bannerDat

  //   } catch (err) {
  //     console.error("Get banner information: ", err);
  //   }
  // }
}
