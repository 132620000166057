import {Component, ElementRef, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Workflow} from "../../../../model/Workflow";
import {MedicinesService} from "../../../../service/medicines.service";
import {SmcTableData} from "../../../../model/SmcTableData";
import {Router, NavigationEnd} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {RequestAppointmentStateService} from "../../../../service/request-appointment-state.service";
import {SmcTableComponent} from "../../../shared/smc-table/smc-table.component";
import { MatDatepickerInputEvent } from '@angular/material';
import { AwsTranslateService } from '../../../../service/aws-translate.service';

@Component({
  selector: 'app-apt-schedule',
  templateUrl: './apt-schedule.component.html',
  styleUrls: ['./apt-schedule.component.scss'],
})
export class AptScheduleComponent implements OnInit {

  public nextPageStateDisabled: boolean = false;//true;
  public showTimeSlots = false;
  //minDate: Date;
  //maxDate: Date;
  //@ViewChild('from') private startDate: any;
  //@ViewChild('to') private endDate: any;
  public workflows: Workflow[] = [
    { Title: "Select Provider", Content: "", State: "past" },
    { Title: "Reason for Appointment", Content: "", State: "past" },
    { Title: "Date & Time", Content: "", State: "current" },
    { Title: "Your contact info", Content: "", State: "future" },
    { Title: "Review & submit", Content: "", State: "future" }
  ];


  patientForm: FormGroup = new FormGroup(
    {
      timeFrame: new FormControl("", Validators.required),
      //minDate: new FormControl("",Validators.required),
      //maxDate: new FormControl("",Validators.required),
      preferredTime: new FormControl("", Validators.required),
      slotSunAM: new FormControl(),
      slotMonAM: new FormControl(),
      slotTueAM: new FormControl(),
      slotWedAM: new FormControl(),
      slotThuAM: new FormControl(),
      slotFriAM: new FormControl(),
      slotSatAM: new FormControl(),
      slotSunPM: new FormControl(),
      slotMonPM: new FormControl(),
      slotTuePM: new FormControl(),
      slotWedPM: new FormControl(),
      slotThuPM: new FormControl(),
      slotFriPM: new FormControl(),
      slotSatPM: new FormControl(),
      anotherLocation: new FormControl(),
      thisLocation: new FormControl(),
      scheduleComments: new FormControl()
    }
  );

  @ViewChild(SmcTableComponent) smcTableComponent: SmcTableComponent;
  @ViewChild('radioButtonTemplate') radioButtonTemplate: ElementRef;
  constructor(private _requestAppointmentStateService : RequestAppointmentStateService, public _router : Router, public AWSTranslate: AwsTranslateService) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.id === 1 &&
        event.url === event.urlAfterRedirects
      ) {
        this._router.navigateByUrl('/authenticated/health-record/visits/request-appointment/apt-select-provider');
      }
    }
    );

   }

  async ngOnInit() {
    this._requestAppointmentStateService.loadForm(this.patientForm);
    this._requestAppointmentStateService.updateWorkflowContents(this.workflows);
    this.formStateChange();
  }

  refreshFields() {
    Object.keys(this.patientForm.controls).forEach(field => {
      const control: any = this.patientForm.get(field);
      control.updateValueAndValidity();
    });
  }

  formStateChange() {
    //this.nextPageStateDisabled = true;

    if (this.patientForm.get("preferredTime").value == "select") {
      this.showTimeSlots = true;
    } else {
      this.showTimeSlots = false;
    }

    if (!this.patientForm.get("preferredTime").errors &&
          !this.patientForm.get("timeFrame").errors) {
        //!this.patientForm.get("minDate").errors &&
        //!this.patientForm.get("maxDate").errors) {
      //this.nextPageStateDisabled = false;
    }

    //this.updateMinMax(null,null);
  }

  previousPage() {
    this._requestAppointmentStateService.saveForm(this.patientForm);
    this._router.navigateByUrl('/authenticated/health-record/visits/request-appointment/apt-reason');
  }

  nextPage() {
    Object.keys(this.patientForm.controls).forEach(field => {
      const control: any = this.patientForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    this.refreshFields();

    if (this.patientForm.valid) {
      this._requestAppointmentStateService.saveForm(this.patientForm);
      this._router.navigateByUrl('/authenticated/health-record/visits/request-appointment/apt-contact-info');
    } else {
      let focusDone = false;
      Object.keys(this.patientForm.controls).forEach(field => {
        const control: any = this.patientForm.get(field);
        control.markAsTouched({ onlySelf: true });
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement != undefined && typeof control.nativeElement.setFocus === "function") {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }
  }

}
