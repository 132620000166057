import { Component, OnInit } from '@angular/core';

/**
 * Shared component for "All done" confirmation pages.
 * Accepts children with selectors [short-message], [long-message], [continue-element].
 */
@Component({
  selector: 'smc-all-done-page',
  templateUrl: './all-done-page.component.html',
  styleUrls: ['./all-done-page.component.scss'],
})
export class AllDonePageComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
