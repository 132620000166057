import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { TermsOfUse } from 'src/app/model/TermsOfUse';
import { TermsOfUseService } from 'src/app/service/terms-of-use.service';
import {IonButton, IonCheckbox, IonContent} from "@ionic/angular";
import { EnrollmentRouteGuardService } from 'src/app/service/enrollment-route-guard.service';
import { Router } from '@angular/router';
import {FormsValidationService} from "../../../../service/forms-validation.service";
import {BhanalyticsService} from "../../../../service/bhanalytics.service";

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class EnrollmentTermsOfUseComponent  {

  @ViewChild('iReadIt') private iReadItComponent: IonCheckbox;
  @ViewChild('iAgree') private iAgreeComponent: IonButton;
  @ViewChild(IonContent) content: IonContent;

  constructor(
    private termsOfUseService: TermsOfUseService,
    private enrollmentRouteGuardService: EnrollmentRouteGuardService,
    private _bhAnalyticsService: BhanalyticsService,
    private router: Router) {}


  acceptButtonEnabled: boolean = false;

  termsOfUse: TermsOfUse;

  async ionViewWillEnter() {
    // remove starting <br> (design request)
    let terms = await (this.termsOfUseService.getTermsOfUse());
    const startingBreakRegex = /^<br>/i;
    if (startingBreakRegex.test(terms.Terms)) terms.Terms = terms.Terms.slice(4);
    this.termsOfUse = terms;
    this.enrollmentRouteGuardService.setNavigate(false);
    this.iAgreeComponent.disabled = true;
    this.iReadItComponent.checked = false;
  }

  async checkScrollHeight(event){
    let el = await (this.content.getScrollElement())
    if ((el.scrollTop + el.offsetHeight) >= el.scrollHeight){
      this.acceptButtonEnabled = true;
    }
  }

  toggleIReadIt() {
    if (this.iReadItComponent.checked) {
      this.iAgreeComponent.disabled = false;
    } else {
      this.iAgreeComponent.disabled = true;
    }
  }

  nextPage(){
    this.enrollmentRouteGuardService.setNavigate(true);
    this._bhAnalyticsService.clickEvent('Accept Terms of Use', '');
    this.router.navigate(["/enroll/2"])
  }

}
