import { environment } from 'src/environments/environment';
import { PersonalInformationService } from 'src/app/service/personal-information.service';
import { Demographics } from 'src/app/model/PersonalInformation';
import { LoadingServiceService } from './service/loading-service.service';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { Router, NavigationEnd } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { StateService } from './service/state.service';
import { AuthenticateService } from './service/authenticate.service';
import { ModalController, LoadingController, PopoverController, MenuController } from '@ionic/angular';
import { HTTPStatusService } from './service/http-status.service';
import { ModalService } from './service/modal.service';
import { ModalType } from './model/ModalTypeEnum';
import { ConstantsService } from './service/constants.service';
import { delay } from 'rxjs/operators';
import { AccountMenuComponent } from './component/shared/account-menu/account-menu.component';
import { IdleTimeoutService } from './service/idle-timeout.service';
import { PreviousRouteService } from './service/previous-route.service';
import { ResourcesDropdownComponent } from './component/shared/resources-dropdown/resources-dropdown.component';
import { SmcTableComponent } from './component/shared/smc-table/smc-table.component';
import { AwsTranslateService } from './service/aws-translate.service';
import { LanguagesDropdownComponent } from './component/shared/languages-dropdown/languages-dropdown.component';
import { Local } from 'protractor/built/driverProviders';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'baystate-patient-portal';
  translationLoadDelay = this.constantsService.constantsData["awsConfig"]["translationLoadDelay"] //[1.5, 7, 7, 0.5]; // in seconds for various iModes, with last number for non-firefox production delay.
  environment = environment;
  public isLoggedIn: boolean;
  public showLoader: boolean;
  public showManualLoader: boolean;
  public mobile: boolean;
  public nameOfLoggedInUser: string;

  aws = this.constantsService.constantsData["awsConfig"];

  constructor(
    public _stateService: StateService,
    private authService: AuthenticateService,
    public modalController: ModalController,
    private httpStatusService: HTTPStatusService,
    private modalService: ModalService,
    private constantsService: ConstantsService,
    private popoverController: PopoverController,
    private idleTimeoutService: IdleTimeoutService,
    private menu: MenuController,
    private previouseRouteService: PreviousRouteService,
    private platform: Platform,
    private analytics: BhanalyticsService,
    private loadingService: LoadingServiceService,
    private AWSTranslate: AwsTranslateService,
    private router: Router,
    private personalInformation: PersonalInformationService
  ) {
    this.subscribeToLoggedIn();
    this.initializeApp();

    /** for language translation **/
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        //navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? this.load(this.translationLoadDelay["prod"], true) : this.load(this.translationLoadDelay["other"], true);
        this.load(-1);
        this.setAwsTranslateDropdown();
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // Get device information
      this.analytics.initAnalytics();
    });
  }

  subscribeToLoggedIn() {
    this.authService.isLoggedIn().subscribe(x => {
      this.isLoggedIn = x;
    });
  }

  onActivate(event) {
    const pageContent = document.querySelector('.page-content');
    if (pageContent) {
      pageContent.scrollTo(0, 0);
    }
  }

  async onUserChange(target: HTMLSelectElement) {
    // commented because looks like it is not being used anymore
    // if (target.selectedIndex == 0) {
    //   const userId = this._stateService.userId;
    //   this._stateService.setSelectedUser({
    //     UserID: userId.toString(),
    //     StagedUserID: ''
    //   });
    // }
    // else {
    //   const user = this._stateService.proxyFor[target.selectedIndex - 1];
    //   this._stateService.setSelectedUser(user);
    // }
  }

  async ngOnInit(): Promise<void> {
    SmcTableComponent.hackToFixResizeBug();

    window.addEventListener('beforeunload', () => {
      sessionStorage.clear();
      localStorage.removeItem("testResult");
      localStorage.removeItem("testResultHistory");
    });

    this.idleTimeoutService.start();
    this.watchHTTPStatus();
    this.subscribeToLoadingService();

    this.authService.getCachedAuthStatus().subscribe(authStatus => {
      if (authStatus != null) {
        this.isLoggedIn = Boolean(authStatus.LoggedIn);
        if (this.isLoggedIn) {
          // this.nameOfLoggedInUser = authStatus.UserData.FullName;
          this.nameOfLoggedInUser = authStatus.UserData.FirstName;
          this.isLoggedIn = Boolean(authStatus.LoggedIn);
          this.analytics.analyticsData.userid = authStatus.UserID.toString();
          this.authService.setLoggedIn(true);
          this._stateService.setPatientName(authStatus.PatientName);
          this._stateService.setSelectedUser({ UserID: authStatus.UserID.toString(), StagedUserID: authStatus.StagedUserID.toString() });
        }
      }
    });
  }

  async watchHTTPStatus() {
    this.httpStatusService.getHttpStatus().pipe(delay(0)).subscribe(val => {
      this.showLoader = val;
      // const openModal = await this.loadingController.getTop()
      // if (val && !openModal){
      //   const loader = await this.loadingController.create({message: 'Please wait...'});
      //   console.log("opening the loader now")
      //   await loader.present();
      //   await loader.onDidDismiss();
      // } else {
      //   console.log("destroying the loader now")
      //   this.loadingController.dismiss();
      // }

    });
  }

  async subscribeToLoadingService() {
    this.loadingService.isLoading.subscribe((isLoading) => {
      this.showManualLoader = isLoading;
    });
  }

  public async openAccountMenu(event) {
    const popover = await this.popoverController.create({
      component: AccountMenuComponent,
      event: event,
      translucent: true,
      cssClass: 'accountMenuPopover'
    });
    return await popover.present();
  }

  public toggleDrawer() {
    this.menu.toggle('leftNavMenu');
  }

  public async openResourcesDropdown(event: MouseEvent) {
    const popover = await this.popoverController.create({
      component: ResourcesDropdownComponent,
      event: event,
      translucent: true
    });
    await popover.present();
  }

  /** for language translation **/

  /* iMode values:
   * 0 = production mode, uses cached files;
   * 1 = test mode, Real-time translation of single language. Note: single-use only. Must select English and then reload page to try a different language.
   * 2 = developer mode, logs english conversion and logs and converts translated text, used for creating cached files;
   * 3 = full translation of stored english (based on Spanish language) */
  iMode = 0;

  private setAwsTranslateDropdown() {
    document.getElementById("sp_langId") ? document.getElementById("sp_langId").innerText = this.aws.languages[this.AWSTranslate.langId] : null;
  }

  public load(s, b = false) {
    if (this.iMode > 0 && this.iMode < 3) {
      console.log('Mode ' + this.iMode + ':' + s + ", " + s + " and  " + b);
    } else if (this.iMode == 0) {
      if (s == -1) {
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? this.load(this.translationLoadDelay["prod"], true) : this.load(this.translationLoadDelay["other"], true);
      } else {
        s != 0 ? setTimeout(() => this.load(0, b), s * 1000) : this.AWSTranslate.translator(this.iMode, b);
      }
    } else {
      this.AWSTranslate.getDictionary("vi");
    }
  }

  public async openLanguagesDropdown(event: MouseEvent) {
    this.analytics.clickEventPromise('unauthenticated header: change-language', '');
    const popover = await this.popoverController.create({
      component: LanguagesDropdownComponent,
      event: event,
      translucent: true
    });
    await popover.present();
  }
}
