import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { Component, OnInit } from '@angular/core';
// import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { ConstantsService } from 'src/app/service/constants.service';
import { AwsTranslateService } from 'src/app/service/aws-translate.service';

@Component({
  selector: 'app-resources-dropdown',
  templateUrl: './languages-dropdown.component.html',
  styleUrls: ['./languages-dropdown.component.scss'],
})
export class LanguagesDropdownComponent implements OnInit {

  aws = this.constantsService.constantsData["awsConfig"];

  constructor(
    // private router: Router,
    private popoverController: PopoverController,
    private constantsService: ConstantsService,
    public AWSTranslate: AwsTranslateService,
    private analytics: BhanalyticsService
  ) { }

  ngOnInit() { }

  async linkSelected(langId) {
    // let currentLangId = localStorage.getItem("BSPP2.0LangOpt");
    await this.analytics.clickEventPromise('changed-language', langId);
    document.getElementById("sp_langId").innerText = this.aws.languages[langId];
    this.popoverController.dismiss();
    // if (location.href.indexOf("account/preferences") >= 0) {
    localStorage.setItem("BSPP2.0LangOpt", langId);
    location.reload();
    //} else {
    //  if (currentLangId != "en" && langId != "en") {
    //    localStorage.setItem("BSPP2.0LangOpt", "en");
    //    this.load(0);
    //  }
    //  localStorage.setItem("BSPP2.0LangOpt", langId);
    //  this.load(0.5);
    //}
  }

  //public load(s, b = false) {
  //    s != 0 ? setTimeout(() => this.load(0), s * 1000) : this.AWSTranslate.translator(0);
  //}
}
