import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';
import { HttpClient } from '@angular/common/http';
import { DocumentResponseModel } from '../model/DocumentResponseModel';
import { DateHandlerService } from './date-handler.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(private httpClient: HttpClient,
              private constantsService: ConstantsService,
              private dateHandler: DateHandlerService) { }

  async getAppointments() : Promise<DocumentResponseModel> {
    const url = `${this.constantsService.constantsData.apiUrl}/ehr/category/documents?ts=${Date.now()}`;
    const response = await this.httpClient.get(url).toPromise() as DocumentResponseModel;
    if (response.Documents) {
      response.Documents.forEach(doc => {
        doc.AuthorizationTime = this.dateHandler.parseDateString(doc.AuthorizationTime);
        doc.EnteredTime = this.dateHandler.parseDateString(doc.EnteredTime);
        doc.Timestamp = this.dateHandler.parseDateString(doc.Timestamp);
        doc.TranscriptionTime = this.dateHandler.parseDateString(doc.TranscriptionTime);
        doc.UpdatedTime = this.dateHandler.parseDateString(doc.UpdatedTime);
      });
    }
    return response;
  }
}
