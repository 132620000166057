import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SymptomCheckerStateService } from '../../../../service/symptom-checker-state.service';
import { Workflow } from '../../../../model/Workflow';
import { ConstantsService } from 'src/app/service/constants.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SymptomCheckerService } from 'src/app/service/symptom-checker.service';
import { AuthenticateService } from 'src/app/service/authenticate.service';
import { MessagesService } from 'src/app/service/messages.service';
import { RequestAppointmentService } from 'src/app/service/request-appointment.service';
import { ScrollingUtilitiesService } from 'src/app/service/scrolling-utilities.service';
import { PrintService } from 'src/app/service/print.service';
import { ModalService } from 'src/app/service/modal.service';
import { questionTextMap } from '../sc-utils/questionTextMapMethods';
import { generateDiagnosesList } from '../sc-utils/generateDiagnosesList';
import { ModalType } from 'src/app/model/ModalTypeEnum';

type PageState =  'careOptions' | 'symptoms' | 'location' | 'covidWarning' | 'covidAppointmentConfirmation';
type DiagnosisFilters = 'show-all' | 'common' | 'red-flag';
type FormState = 'send-symptoms' | 'find-care';

@Component({
  selector: 'app-sc-findcare',
  templateUrl: './sc-findcare.component.html',
  styleUrls: ['./../symptom-checker.component.scss'],
})

export class ScFindcareComponent implements OnInit {

  expandList = false;
  showDesc: boolean;
  showDisc: boolean;
  questionTextMap = questionTextMap();
  currentFormState: FormState;
  showSymptomError = false;
  selectedFilter: DiagnosisFilters;
  showLoadingSpinner: boolean = false;
  authId: number;
  showDiagnosesList: boolean;
  showEmptyFieldsError: boolean;
  showIsabelEmptyFieldsError: boolean;
  ageCanConceive = false;
  symptomCheckerUrl: SafeResourceUrl;
  // Hardcode 'Primary Care Providers' and pass into provider url.
  defaultSymptomValue = 'primary care providers';
  basicInfo: any;
  symptoms: any[];
  dropDownValues: any;
  suggestedDiagnosis: any;
  triageResults: any;
  symptomString = '';
  predictiveText: string[] = [];
  suggestions: string[] = [];
  @ViewChild('covidMedChat') covidMedChat: HTMLElement;
  private _newSymptomInput;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private symptomCheckerStateService: SymptomCheckerStateService,
    private sanitizer: DomSanitizer,
    private constantsService: ConstantsService,
    private symptomCheckerService: SymptomCheckerService,
    private scrollingUtilities: ScrollingUtilitiesService,
    private auth: AuthenticateService,
    private modalService: ModalService,
    private printService: PrintService,
    private messagesService: MessagesService,
    private requestAppointmentService: RequestAppointmentService
  ) {
    // Get Form state from url and set it to state service
    this.activatedRoute.params.subscribe(params => { this.currentFormState = params.formState; });
    this.symptomCheckerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.constantsService.constantsData.symptomCheckerUrl);
    // If being redirected within the form wizard, send user back to first section
    this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd && event.id === 1 &&
          event.url === event.urlAfterRedirects
        ) {
          this.router.navigateByUrl('/authenticated/resources/symptom-checker/sc-basic-info/' +  this.currentFormState);
        }
      });
   }

  private pageStateInternal: PageState;
  get pageState() {
    return this.pageStateInternal;
  }
  set pageState(value: PageState) {
    this.pageStateInternal = value;
    this.scrollingUtilities.scrollContentToTop();
  }

  public workflows: Workflow[] = [
    { Title: 'Tell us about your symptoms', Content: '', State: 'past' },
    { Title: 'Possible causes', Content: '', State: 'past' },
    { Title: 'Where to get care?', Content: '', State: 'past' },
  ];

  async ngOnInit() {
    // Check Formstate and change workflow.
    this.changeForm();

    // Get all data from state service and return triage results.
    const existingFormData = this.symptomCheckerStateService.getCurrentState();
    this.basicInfo = existingFormData;
    this.symptoms = existingFormData.symptoms.value;

    this.auth.loadFreshAuthStatus().subscribe(async authStatus => {
      this.authId = authStatus.EffectiveUserID;
      const suggestedDiagnosis = this.symptomCheckerStateService.getDiagnosesList();
      this.suggestedDiagnosis = suggestedDiagnosis;

      if (this.authId !== undefined && this.suggestedDiagnosis) {
        const returnedTriageUrl: string = this.suggestedDiagnosis.diagnosis_checklist.triage_api_url;
        const dx = returnedTriageUrl.match(/&dx=[0-9,]*&/)[0].slice(4, -1);
        const currentFormObj =  this.symptomCheckerStateService.getCurrentState();
        this.triageResults = await this.symptomCheckerService.triage(currentFormObj, authStatus.EffectiveUserID, dx);
        if (!Array.isArray(this.suggestedDiagnosis.diagnosis_checklist.diagnosis)) {
          this.suggestedDiagnosis.diagnosis_checklist.diagnosis = [this.suggestedDiagnosis.diagnosis_checklist.diagnosis];
        }

        const covidId = '14964';
        const covidDiagnoses = this.suggestedDiagnosis.diagnosis_checklist.diagnosis.filter(diagnosis => diagnosis.diagnoses_id == covidId);
        covidDiagnoses.forEach(covidDiagnosis => covidDiagnosis.knowledge_window_url = 'https://providers.baystatehealth.org/search?unified=baystate%20telehealth&sort=networks%2Crelevance%2Cavailability_density_best');
        // check for covid in top 10
        for(let i = 0; i < 10; i++) {
          if (this.suggestedDiagnosis.diagnosis_checklist.diagnosis[i].diagnoses_id === covidId) {
            this.pageState = 'covidWarning';
            // Apply medchat widget.
            this.showLoadingSpinner = true;
            this.styleMedChat();
            return;
          }
        }

        // Default filters to 'show all'
        this.selectedFilter = 'show-all';
      }
    });

  }

  styleMedChat() {
    // Get MedChat elements.
    const medChatElements = this.symptomCheckerStateService.getMedChatElements();
    const chatBtn = (medChatElements.medChatBtn as HTMLElement);
    const chatRoom = (medChatElements.medChatRoom as HTMLElement);

    // Move MedChat elements into component.
    // Allow time to load.
    setTimeout(() => {
      // Display widget.
      chatRoom.style.display = 'block';
      chatBtn.style.display = 'block';

      document.querySelector('#chat-box').appendChild(chatBtn);
      document.querySelector('#chat-box').appendChild(chatRoom);
      this.showLoadingSpinner = false;
    }, 100);
  }


  changeForm() {
    if (this.currentFormState.toString() === 'find-care') {
      this.workflows[1].Title = 'Where to get care?';
      this.workflows[2].Title = 'Possible causes';
    }
  }

  getBasicInfoText(answer: string, infoId: string) {
    let returnTxt = '';
    const dropdownObj = this.symptomCheckerStateService.getDropdownList();
    if (dropdownObj !== undefined) {
      switch (infoId) {
        case 'age_groups':
          dropdownObj.age_groups.age_group.forEach((age) => {
            if (age.agegroup_id === answer) {
              returnTxt = age.name + ' (' + age.yr_from + ' - ' + age.yr_to + ')';
            }
          });
          break;
        case 'travel_history':
          dropdownObj.travel_history.region.forEach((region) => {
            if (region.region_id === answer) {
              returnTxt = region.region_name;
            }
          });
          break;
      }
    }
    return returnTxt;
  }


  openUrlInNewTab(url: string) {
    window.open(url, '_blank', 'noopener');
  }

  requestTeleheathAppointment(email: string, phone: string, comments: string) {
    this.showIsabelEmptyFieldsError = !(email || phone);
    if (this.showIsabelEmptyFieldsError) {
      return;
    }
    this.auth.loadFreshAuthStatus().subscribe(async authStatus => {
      await this.requestAppointmentService.requestAppointment(JSON.stringify({
        To: this.constantsService.constantsData.symptomCheckerCovid19PoolID,
        From: authStatus.PatientName,
        Subject: `COVID-19 Telehealth Appointment Request for ${authStatus.PatientName}`,
        Attachments: [],
        Text:
          `${authStatus.PatientName} has requested a Telehealth appointment due to symptoms of COVID-19
          Email: ${email}
          Phone: ${phone}
          Symptoms provided by patient: ${this.symptoms.join(', ')}
          Additional Comments:
          ${comments}`
      }))
      .then(success => {
        this.modalService.open(
          'Telehealth Request Sent',
          `Thank you for requesting an appointment! You will see an appointment in your Baystate Health Connect app shortly.
          If you don't see your scheduled appointment within 24 hours, please call your provider to find out the status of your appointment.
          Here are some additional materials for your review:
          <ul>
            <li>
              <a href="https://www.baystatehealth.org/medical-services/covid19" target="_blank">Baystate Health COVID-19 Information Page</a>
            </li>
            <li>
              <a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html" target="_blank">CDC Covid-19
              Symptom Checker</a>
            </li>
          </ul>
          `,
          ModalType.BASIC
        );
      })
      .catch(err => {
        console.log(err);
      });
      this.pageState = 'covidAppointmentConfirmation';
    });
  }

  // TODO: Refactor redundant logic, move into utils.
  // Send message to provider.
  generateProviderMsg() {
    // Update state and direct user to next form section.
    const savedAnswers = this.symptomCheckerStateService.getMappedAnswers();
    let symptomStr = '';
    this.symptoms.forEach((symptom) => {
      const symptoms = symptom.value + ',';
      symptomStr += symptoms;
    });

    const getQuestionResponse = (n: number) => this.questionTextMap.get(n).options.find(option => option.code == savedAnswers.get(n)).text;
    this.messagesService.openPrepopulatedMessage({
      subject: 'My Symptoms',
      body:
        `I am experiencing the following symptoms: ${symptomStr}.

        Questionnaire:
        1. How quickly did your symptoms develop?
        ${getQuestionResponse(1)}
        2. How long have you had your symptoms?
        ${getQuestionResponse(2)}
        3. How have your symptoms changed over the last few hours/days?
        ${getQuestionResponse(3)}
        4. How much pain or discomfort are you in?
        ${getQuestionResponse(4)}
        5. How are your symptoms affecting your daily activities?
        ${getQuestionResponse(5)}
        6. Do you feel better after taking medication for your symptoms?
        ${getQuestionResponse(6)}
        7. Do you have any other serious, long term conditions such as diabetes, cancer, heart condition etc?
        ${getQuestionResponse(7)}


        Additional Comments:

        `
    });
  }

  previousPage() {
    this.router.navigateByUrl('/authenticated/resources/symptom-checker/sc-causes/' + this.currentFormState);
  }

  printDiagnosis() {
    // Get list filtered selected and check what to list by.
    const generatedList = generateDiagnosesList(this.suggestedDiagnosis.diagnosis_checklist.diagnosis, this.selectedFilter);
    this.printService.printHtmlString(generatedList);
  }
}
