import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AuthenticateService } from './authenticate.service';
import { RolesService } from './roles.service';
import { RoleType } from '../model/RoleType';

@Injectable({
  providedIn: 'root'
})
export class LoginRouteGuardService implements CanActivate {

  private _isLoggedIn: boolean;

  constructor(private _authService: AuthenticateService,
              private _router: Router,
              private _rolesService: RolesService) {
                _authService.isLoggedIn().subscribe(val => this._isLoggedIn = val);
              }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const userRoles: RoleType[] = await this._rolesService.getRoles();
    if (userRoles[0] == 'UNAUTHENTICATED') return this._router.parseUrl('/login');
    const blockedRolesForRoute = this._rolesService.getBlockedRolesByComponent(route.url.map(it => it.path));
    const intersection = userRoles.uniqueIntersection(blockedRolesForRoute);
    const allowNavigate = intersection.length == 0;
    if (!allowNavigate){
      this._router.navigate(["/authenticated/home"]);
    }
    // if (!this._isLoggedIn) this._router.navigate(["/login"]);
    // return this._isLoggedIn;
    return allowNavigate;
  }

}
