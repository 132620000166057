import { Injectable } from '@angular/core';
import {ConstantsService} from "./constants.service";
import {HttpClient} from "@angular/common/http";
import { AppleHealthRequest } from '../model/AppleHealthRequest';

@Injectable({
  providedIn: 'root'
})
export class RequestAppleHealthService {

  constructor(private constantsService: ConstantsService,
              private httpClient: HttpClient) { }

  async requestConnection(appleHealthRequest: AppleHealthRequest): Promise<Object> {

    const url = `${this.constantsService.constantsData.apiUrl}/customapi/requestHealthAppConnection`;
    /*
    {"data":
      {"firstName":"bob",
      "lastName":"jones",
      "dob":"01/01/2001",
      "phone":"5555555555",
      "email":"fdsjkl@fdsjk.com",
      "appNames":"applehealth"}
    }
    */

    return await this.httpClient.post(url, appleHealthRequest).toPromise() as any;
  }
}
