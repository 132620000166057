import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticateService } from './authenticate.service';

/** Block authenticated users from seeing anything other than /change-password if their password needs to be reset */
@Injectable({
  providedIn: 'root'
})
export class ResetPasswordGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _authenticateSevice: AuthenticateService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve, reject) => {
      this._authenticateSevice.loadFreshAuthStatus().toPromise()
        .then(authStatus => {
          if (authStatus && authStatus.ChangePassword == 1) resolve(this._router.parseUrl('/change-password'));
          else if (authStatus && authStatus.LoggedIn == 0) resolve(false);
          else resolve(true);
        })
        .catch(reason => reject(reason));
    });
  }
  
}