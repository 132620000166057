import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, ActivatedRoute } from '@angular/router';
import { BhanalyticsService } from './bhanalytics.service';

@Injectable({
  providedIn: 'root'
})
export class BhanalyticsRouteGuardService implements CanActivate {

  constructor(
    private bhanalyticsService: BhanalyticsService,
    private activatedRoute: ActivatedRoute
    ) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

    const totalSegments = (route.url) ? route.url.length : null;
    const lastSegment = (totalSegments) ? route.url[totalSegments - 1] : null;
    // console.log('BHANALYTICS: ' + lastSegment.toString(), route.url.toString());

    if (lastSegment) {
      this.bhanalyticsService.customEvent('page-view', lastSegment.toString(), 'Url: ' + route.url.toString());
    }

    return true;
  }

}
