import { BhanalyticsService } from './../../../service/bhanalytics.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SmcTableData } from 'src/app/model/SmcTableData';
import { ConcernsService } from 'src/app/service/concerns.service';
import { DateHandlerService } from 'src/app/service/date-handler.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-concerns',
  templateUrl: './concerns.component.html',
  styleUrls: ['./concerns.component.scss'],
})
export class ConcernsComponent implements OnInit {
  env = environment;
  category: any;
  public data: SmcTableData;
  public data2: SmcTableData;
  @ViewChild('effectiveTimeTemplate') effectiveTimeTemplate: ElementRef;

  constructor(
    private _concernsService : ConcernsService,
    private dateHandler: DateHandlerService,
    private _bhAnalyticsService: BhanalyticsService
  ) { }

  async ngOnInit() {
    const category = this.env.banner_seq.concern;
    console.log('cat', category);
    this.category = category;
    var rows = await this._concernsService.getConcerns();
    this.data = new SmcTableData();
    this.data.Title = 'Active';
    this.data.columns = [
      { name: 'Description', prop: 'Description', cellClass: 'card-bold' },
      { name: 'Effective Time', prop: 'FromTime', cellClass: 'card-detail', cellTemplate: this.effectiveTimeTemplate }
    ];
    if (rows) {
      this.data.rows = rows.filter(concern => concern.ToTime == "" || new Date() < this.dateHandler.parseDateString(concern.ToTime));
      this.data2 = new SmcTableData();
      this.data2.Title = 'Historical';
      this.data2.columns = this.data.columns;
      this.data2.rows = rows.filter(concern => concern.ToTime != "" && new Date() > this.dateHandler.parseDateString(concern.ToTime));
    }
  }

  print() {
    this._bhAnalyticsService.clickEvent('Print Concerns', '');
    window.print();
  }

}
