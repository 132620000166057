import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-reset-success',
  templateUrl: './password-reset-success.component.html',
  styleUrls: ['./password-reset-success.component.scss'],
})
export class PasswordResetSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
