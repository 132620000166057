import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { ConstantsService } from 'src/app/service/constants.service';
import { Component, OnInit, ElementRef, ViewChild, Output } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { FormGroup, FormControl, Validator, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { AuthenticateService } from '../../../service/authenticate.service';
import { User } from '../../../model/user';
import { trigger, transition, animate, style } from '@angular/animations';
import { BhanalyticsService } from '../../../service/bhanalytics.service';
import { ConsoleContent } from 'src/app/model/ConsoleContent';
import { ConsoleDataService } from 'src/app/service/console-data.service';
import moment from 'moment';
import { Article } from 'src/app/model/Article';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ]
})
export class LoginPageComponent {
  env = environment;
  consoleContents: ConsoleContent[] = [];
  bannerContent: ConsoleContent;
  seasonalImage = '';
  interval = null;
  format: string = '';
  articles: Article[] = [];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticateService: AuthenticateService,
    private _bhAnalyticsService: BhanalyticsService,
    public constantsService: ConstantsService,
    public platform: Platform,
    public consoleDataService: ConsoleDataService
  ) {
    this.version = this.env.ENV + '-' + this.env.APPVERSION;
    console.log('Running: ' + this.version);
  }
  queryParams: Params;
  targetResource: string;
  showBadges = false;
  version: string;

  ngOnInit() {
    this.setSeasonalBackground();
    this.getBannerInformation();
    this.getWhatsNewArticles('login');
  }

  ionViewWillEnter() {
    this.authenticateService.loadFreshAuthStatus().subscribe(authStatus => {
      if (authStatus != null && authStatus.LoggedIn === 1) {
        let nextRoute = 'authenticated';
        // Check for target resources in queryparams
        if (this.targetResource) {
          switch (this.targetResource.toLowerCase()) {
            // Request appointment
            case 'request-appt':
              nextRoute = '/authenticated/health-record/visits/request-appointment/apt-select-provider';
              break;

            // Test Results
            case 'test-results':
              nextRoute = '/authenticated/health-record/test-results';
              break;
          }
        }
        this.router.navigate([nextRoute]);
      }
    });
    // Get target resource (from mobile)
    this.activatedRoute.paramMap.subscribe(
      (params: ParamMap) => {
        this.targetResource = params.get('targetResource');
      }
    );
  }

  killTimer() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  initTimer() {
    if (!this.interval) {
      this.interval = setInterval(() => {
        this.refreshBannerInfo();
      }, 100000);
    }
  }


  ionViewWillLeave() {
    this.killTimer();
  }

  setSeasonalBackground() {
    let season = 'spring';
    const today = moment();
    // const today = moment('2021-12-31', 'YYYY-MM-DD');
    const year = today.year();
    const startOfEndYearWinter = moment(year + '-12-01', 'YYYY-MM-DD');
    const startOfNewYearWinter = moment(year + '-01-01', 'YYYY-MM-DD');
    const startOfSpring =  moment(year + '-03-01', 'YYYY-MM-DD');
    const startOfSummer = moment(year + '-06-01', 'YYYY-MM-DD');
    const startOfFall = moment(year + '-09-01', 'YYYY-MM-DD');

    // Determine Season Image
    if (today >= startOfEndYearWinter || (today >= startOfNewYearWinter && today < startOfSpring)) {
      season = 'winter';
      this.seasonalImage = 'winter.jpg';
    } else if (today >= startOfSpring && today < startOfSummer) {
      season = 'spring';
      this.seasonalImage = 'spring.jpg';
    } else if (today >= startOfSummer && today < startOfFall) {
      season = 'summer';
      this.seasonalImage = 'summer.jpg';
    } else if (today >= startOfFall && today < startOfEndYearWinter) {
      season = 'fall';
      this.seasonalImage = 'fall.jpg';
    } else {
      season = 'unknown';
      this.seasonalImage = 'spring.jpg';
    }

    // console.log('today', today.format('MM/DD/YYYY HH:mm:ss'));
    // console.log('startOfEndYearWinter', startOfEndYearWinter.format('MM/DD/YYYY HH:mm:ss'));
    // console.log('startOfNewYearWinter', startOfNewYearWinter.format('MM/DD/YYYY HH:mm:ss'));
    // console.log('startOfSpring', startOfSpring.format('MM/DD/YYYY HH:mm:ss'));
    // console.log('startOfSummer', startOfSummer.format('MM/DD/YYYY HH:mm:ss'));
    // console.log('startOfFall', startOfFall.format('MM/DD/YYYY HH:mm:ss'));
    // console.log('setSeasonalBackground: ', season, this.seasonalImage);
  }

  captureAnalytics(eventName, eventDetail) {
    this._bhAnalyticsService.clickEvent(eventName, eventDetail);
  }

  openCDCSymptomChecker() {
    this._bhAnalyticsService.clickEvent('CDCSymptomChecker', 'Opened from LoginPage');
    location.href = 'https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html';
  }

  async getWhatsNewArticles(articleFormat: string): Promise<boolean> {
    this.format = articleFormat;
    console.log('format', this.format);
    try {
      const res = await this.consoleDataService.getSlides(1).toPromise();
      if(res && res.x_result) {
        this.articles  = res.x_result.filter(row => row.item_type === 'A');
        console.log('active slides news articles', this.articles);
        return Promise.resolve(true);
      }
      // this.bannerContent = this.translateBannerValue(this.consoleContents);
    } catch (err) {
      console.error("Get Whats News Article: ", err);
    }
  }


  async getBannerInformation() {
    try {
      const res = await this.consoleDataService.getSlides(1).toPromise();
      const bannerData = res.x_result.filter(row => row.ps_seq === 84)
      this.consoleContents = bannerData;
      this.bannerContent = this.translateBannerValue(this.consoleContents);
      console.log("inital banner data:", this.bannerContent);
    } catch (err) {
      console.error("Get banner information: ", err);
    }
  }

  async refreshBannerInfo() {
    try {
      const res = await this.consoleDataService.getSlides(1).toPromise();
      if (res) {
        const bannerData = res.x_result.filter(row => row.ps_seq === 84)
        this.consoleContents = bannerData;
        this.bannerContent = this.translateBannerValue(this.consoleContents);
        console.log("refreshed banner data:", this.bannerContent);
      } else {
        console.error("There is an error");
      }
    } catch (err) {
      console.error(err);
    }
  }

  translateBannerValue(contents): ConsoleContent {
    const matches = contents;
    return matches.length > 0 ? matches[0] : null;
  }

}
