import { Component, OnInit } from '@angular/core';

/** Displays contents only on small screens */
@Component({
  selector: 'smc-small-screen-only',
  templateUrl: './small-screen-only.component.html',
  styleUrls: ['./small-screen-only.component.scss'],
})
export class SmallScreenOnlyComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
