export interface MessageFlowListItem {
  id?: MSG_FLOW_TYPE_ID;
  Name?: string;
  heading?: string;
  detail?: string;
  icon?: string;
  type?: MessageWorkflowStep;
  active?: boolean;
  navigateToProviderStep?: boolean;
  reachedThisStep?: boolean;
  value?: string;
  hideFromLiteUser: boolean;
  otherSubject?: string;
  messageType?: 'general' | 'medical';
}

export type MessageWorkflowStep =
  'message-type' |
  'message-subject' |
  'appt-change-action' |
  'upcoming-appts' |
  'visit-reason' |
  'medicines' |
  'test-results' |
  'symptoms' |
  'symptoms-questions' |
  'triage' |
  'provider' |
  'redirect-find-provider' |
  'timeframe' |
  'preferred-times' |
  'pharmacy' |
  'write-message' |
  'billing-subject' |
  'general' |
  'medical';

export enum MSG_FLOW_TYPE_ID {
  // Main Menu / Type ID
  MNU_EMERGENCY_NEED = 1,
  MNU_URGENT_MEDICAL_QUESTION = 2,
  MNU_NON_URGENT_MESSAGE = 3,
  MNU_REQUEST_APPT = 4,
  MNU_CHANGE_APPT = 5,
  MNU_REFILL_RX = 6,
  MNU_BILLING_QUESTION = 7,

  // Message Subject
  MSG_NEW_SYMPTOMS = 100,
  MSG_MED_QUESTION = 101,
  MSG_TEST_RESULT = 102,
  MSG_LETTER_FORM = 103,
  MSG_REFERRAL = 104,
  MSG_OTHER = 105,
  MSG_PAT_DATA = 106,

  // Reason for Visit
  VIS_ANNUAL_WELLNESS = 200,
  VIS_MEDICARE_WELLNESS = 201,
  VIS_WELL_CHILD_VISIT = 202,
  VIS_RETURN_VISIT = 203,
  VIS_INPATIENT_ER_FOLLOWUP = 204,
  VIS_NEW_PATIENT = 205,

  // Billing Subject
  BIL_INSURANCE = 300,
  BIL_PAYMENTS = 301,
  BIL_FIN_ASST = 302,
  BIL_ADDR_CHANGE = 303,
  BIL_OTHER = 304,
  BIL_CHARGE = 305,

  // Timeframes
  TF_NEXT_AVAILABLE = 400,
  TF_WITHIN_WEEK = 401,
  TF_WITHIN_2_WEEKS = 402,
  TF_WITHIN_MONTH = 403,
  TF_WITHIN_2_MONTHS = 404,
  TF_WITHIN_3_MONTHS = 405,
  TF_WITHIN_6_MONTHS = 406,
  TF_WITHIN_YEAR = 407,

  // Appt Change Actions
  AC_RESCHEDULE = 500,
  AC_CANCEL = 501

}
