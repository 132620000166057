import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import { SmcTableComponent } from './smc-table.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    NgxDatatableModule
  ],
  exports: [ SmcTableComponent ],
  declarations: [ SmcTableComponent]
})
export class SmcTableModule {}
