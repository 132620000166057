import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Workflow} from "../../../../model/Workflow";
import {SmcTableData} from "../../../../model/SmcTableData";
import {ActivatedRoute, Router} from "@angular/router";
import {RequestAppointmentStateService} from "../../../../service/request-appointment-state.service";
import {FormControl, FormGroup} from "@angular/forms";
import {RequestAppointmentService} from "../../../../service/request-appointment.service";
import {Recipients} from "../../../../model/Recipients";
import {SmcTableComponent} from "../../../shared/smc-table/smc-table.component";
import { ProviderService } from 'src/app/service/provider.service';
import {BhanalyticsService} from "../../../../service/bhanalytics.service";
import { AwsTranslateService } from '../../../../service/aws-translate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-apt-select-provider',
  templateUrl: './apt-select-provider.component.html',
  styleUrls: ['./apt-select-provider.component.scss'],
})
export class AptSelectProviderComponent implements OnInit {
  env = environment;
  category: any;
  public data: SmcTableData;
  public nextPageStateDisabled: boolean = false; //true;
  public search:string;

  displaySubmitError = false;

  public workflows: Workflow[] = [
    { Title: "Select Provider", Content: "", State: "current" },
    { Title: "Reason for Appointment", Content: "", State: "future" },
    { Title: "Date & Time", Content: "", State: "future" },
    { Title: "Your contact info", Content: "", State: "future" },
    { Title: "Review & submit", Content: "", State: "future" }
  ];

  patientForm: FormGroup = new FormGroup(
    {
    }
  );

  @ViewChild(SmcTableComponent) smcTableComponent: SmcTableComponent;
  @ViewChild('radioButtonTemplate') radioButtonTemplate: ElementRef;
  @ViewChild('favoriteProviderTemplate') favoriteProviderTemplate: ElementRef;
  @ViewChild('providerFullName') providerFullName: ElementRef;
  @ViewChild('providerLocation') providerLocation: ElementRef;

  constructor(
    private _requestAppointmentService: RequestAppointmentService,
    private _requestAppointmentStateService: RequestAppointmentStateService,
    public _router: Router,
    public _providerService: ProviderService,
    private _bhAnalyticsService: BhanalyticsService,
    private AWSTranslate: AwsTranslateService) { }

  async ngOnInit() {
    const category = this.env.banner_seq.appointment;
    console.log('cat', category);
    this.category = category;
    this.data = new SmcTableData();
    this.data.columns = [
      { name:'', cellTemplate:this.radioButtonTemplate , maxWidth:50, collapseCol: 0 },
      { name:'Provider', cellClass: 'card-bold', cellTemplate:this.providerFullName, collapseCol: 1, collapseMinWidth: 200 },
      { name:'Location', cellClass: 'card-detail', cellTemplate:this.providerLocation, collapseCol: 1, collapseMinWidth: 200 },
      { name:'Favorite?', prop:'Favorite', cellTemplate:this.favoriteProviderTemplate , width:100, cellClass: 'favorite-column', collapseCol: 2  }
    ];
    this.data.rows = [];

    this._requestAppointmentStateService.loadForm(this.patientForm);
    this._requestAppointmentStateService.updateWorkflowContents(this.workflows);
    this.formStateChange();

    await this.searchProvider('');
  }

  formStateChange() {
    //this.nextPageStateDisabled = true;
    if (this.smcTableComponent.selected.length > 0) {
      //this.nextPageStateDisabled = false;
    }
  }

  nextPage() {
    if (!this._requestAppointmentStateService.state.provider) {
      this.displaySubmitError = true;
    } else {
      this.displaySubmitError = false;
      this._requestAppointmentStateService.saveForm(this.patientForm);
      this._router.navigateByUrl('/authenticated/health-record/visits/request-appointment/apt-reason');
    }
  }

  async searchProvider(data) {
    if(data){
      this._bhAnalyticsService.searchEvent(data);
      this.search = data;
      // console.log("SEARCH: " + data);
       this.smcTableComponent.rows = await this._requestAppointmentService.getProviders(data);
       this.formStateChange();
    }else{
      this.smcTableComponent.rows = await this._providerService.getFavoriteProviders()
    }
  }

  selectProvider(data) : void {
    this._bhAnalyticsService.clickEvent('select provider', data.selected[0]);
    this._requestAppointmentStateService.state.provider = JSON.stringify(data.selected[0]);
    this.formStateChange();
  }

  async addToFavorites(row){
    this._bhAnalyticsService.clickEvent('add to favorites', row.Provider.ID);
    await this._providerService.addFavoriteProvider([row.Provider.ID]);
    await this.searchProvider(this.search );
  }

  async removeFromFavorites(row){
    this._bhAnalyticsService.clickEvent('remove from favorites', row.Provider.ID);
    await this._providerService.deleteFavoriteProvider([row.Provider.ID]);
    await this.searchProvider(this.search );
  }

  getProviderName(row){
    let providerFullName = "";
    if(row.Provider.Title){
      providerFullName = row.Provider.Title + " ";
    }
    providerFullName = providerFullName + row.Provider.FirstName + " " + row.Provider.LastName;

    if(row.Provider.Suffix){
      providerFullName = providerFullName + " " + row.Provider.Suffix;
    }

    return providerFullName;
  }

}
