import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, NgZone, ApplicationRef,ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from 'src/app/service/state.service';
import { TestResult } from 'src/app/model/TestResult';
import { HealthRecordService } from '../../../service/health-record.service';
import { TestResultsService } from 'src/app/service/test-results.service';
import { DatePipe } from '@angular/common';
import { SmcTableData } from 'src/app/model/SmcTableData';
import { SMCDataList } from 'src/app/model/SMCDataList';
import { Options } from 'ng5-slider';
import { ModalController } from '@ionic/angular';
import { PastResultsModalComponent } from './past-results-modal/past-results-modal.component';
import { BhanalyticsService } from "../../../service/bhanalytics.service";
import { ModalService } from 'src/app/service/modal.service';
import { PrintService } from 'src/app/service/print.service';
import { AwsTranslateService } from 'src/app/service/aws-translate.service';
import { MessagesService } from 'src/app/service/messages.service';

interface PrintableTableData {
  columns: string[];
  rows: Array<string[]>;
}

@Component({
  selector: 'app-test-result-details',
  templateUrl: './test-result-details.component.html',
  styleUrls: ['./test-result-details.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestResultDetailsComponent implements OnInit {


  public TestResult: TestResult;
  public data: SmcTableData;
  lastCode: string;
  public historyHasStrings: boolean;
  public currentLocation: string;
  showTestResults: boolean = false;
  facilitiesList: any[];
  testResultData: SMCDataList = null;
  printableTableData: PrintableTableData;

  @ViewChild('rowDetailTemplate') rowDetailTemplate: ElementRef;
  @ViewChild('resultValueTemplate') resultValueTemplate: ElementRef;
  @ViewChild('iconSelectableTemplate') iconSelectableTemplate: ElementRef;
  @ViewChild('printSection') printSection: ElementRef;

  // options for the chart
  view: any[] = [1200, 200];

  colorScheme = {
    domain: ['#55b5e6']
  };

  // line, area
  autoScale = true;

  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private _testResultsService: TestResultsService,
    private _healthRecordService: HealthRecordService,
    public modalController: ModalController,
    private _bhAnalyticsService: BhanalyticsService,
    private zone: NgZone,
    private applicationRef: ApplicationRef,
    private _datePipe: DatePipe,
    private modalService: ModalService,
    private _printService: PrintService,
    private changeDetectorRef: ChangeDetectorRef,
    public AWSTranslate: AwsTranslateService,
    private messageService: MessagesService
  ) { }

  async ngOnInit() {
    this.TestResult = this.getParamData(null, 'testResult');
    if (this.TestResult) {
      this.showTestResults = true;
      this.loadProviderData();
      this.loadData();
    } else {
      this._router.navigateByUrl('/authenticated/health-record/test-results');
    }
  }

  async loadProviderData() {
    const facilitiesList = await this._healthRecordService.getFacilitiesPhoneNumber();
    this.facilitiesList = facilitiesList.providers;

    this.currentLocation = (this.TestResult.EnteredAt || this.TestResult.EnteredBy || this.TestResult.EnteringOrganization || this.TestResult.PerformedAt);
    this.testResultData = {
      options: {
        hideEmptyFields: false
      },
      items: [
        { label: 'Ordered by', value: [this.TestResult.OrderedBy] },
        { label: 'Test date', value: [this._datePipe.transform(this.TestResult.Date, 'MMM dd, yyyy')] },
        { label: 'Test location', value: [this.currentLocation] },
        //  { label: 'Have Questions?', value: [this.getFilteredFacilityNumber(this.currentLocation.toLowerCase())] }
      ]
    };

    if(this.TestResult.ResultItems===undefined || this.TestResult.ResultItems==null){
      this.changeDetectorRef.detectChanges();
    }

  }

  loadData() {
    this.data = new SmcTableData();
    this.data.columns = [
      { name: 'Component Test', prop: 'Test', cellClass: 'card-bold', collapseCol: 0, collapseMinWidth: 200 },
      { name: 'Result', prop: 'ResultValue', cellClass: this._testResultsService.getResultClass, cellTemplate: this.resultValueTemplate, collapseCol: 0, collapseMinWidth: 200 },
      { name: 'Interpretation', prop: 'ResultInterpretation', cellClass: this._testResultsService.getResultClass, collapseCol: 0, collapseMinWidth: 200 },
      { name: 'Normal Range', prop: 'ReferenceRange', cellClass: 'card-detail', collapseCol: 0, collapseMinWidth: 200 },
      { name: "", cellTemplate: this.iconSelectableTemplate, maxWidth: 3000, cellClass: 'select-icon', collapseCol: 1 }
    ];

    if (this.TestResult.ResultItems === undefined) {
      this.TestResult.ResultItems = null;
    }

    this.data.rows = this.TestResult.ResultItems;
    if (this.data.rows) {
      // construct data fir print-friendly table
      this.printableTableData = {
        columns: this.data.columns.map(column => column.name),
        rows: (() => {
          const rows = [];
          this.data.rows.forEach((row, index) => {
            const arrayRow = [];
            this.data.columns.forEach(column => {
              arrayRow.push(row[column.prop]);
            });
            rows.push(arrayRow);
          });
          return rows;
        })()
      };
    }


  }


  getParamData(data, storeParamName) {
    let value;
    if (data) {
      value = data;

      localStorage.setItem(storeParamName, JSON.stringify(value));
    } else {
      value = JSON.parse(localStorage.getItem(storeParamName));
    }
    return value;
  }

  ionViewWillLeave() {
    this.TestResult = null;
    this.data.rows = [];
  }


  setTestResult(data) {
    this.messageService.testResults = data;
    this._router.navigateByUrl('/authenticated/messages/compose-message');
  }

  getMinValue(referenceRange) {
    let minRange = 0;
    if (referenceRange.indexOf("-") > -1) {
      minRange = referenceRange.split("-")[0];
    } else {
      if (referenceRange.indexOf(">") > -1) {
        minRange = referenceRange.split(">")[1];
      }
    }
    return Number(minRange);
  }

  getMaxValue(referenceRange) {
    let maxRange = 1000;
    if (referenceRange.indexOf("-") > -1) {
      maxRange = referenceRange.split("-")[1];
    } else {
      if (referenceRange.indexOf("<") > -1) {
        return referenceRange.split("<")[1];
      }
    }
    return Number(maxRange);
  }

  // Return facilities phone number if locations match.
  getFilteredFacilityNumber(locationStr: string) {
    const locationName = locationStr.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
    const filteredFacility = this.facilitiesList.filter(facilityNumber => {
      const facilityName = facilityNumber.name.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
      const facilityCode = facilityNumber.code.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
      const namesMatch = (locationName.match(facilityName) || locationName.split(' ').join('').indexOf(facilityName.split(' ').join('')) > -1);
      const codeMatchesName = facilityCode.match(locationName);
      return (codeMatchesName || namesMatch);
    });
    return (filteredFacility[0] ? filteredFacility[0].phone : '');
  }
  async print() {
    const innerHTMLContent = this.printSection.nativeElement.innerHTML;
    this._printService.printHtmlString(innerHTMLContent);
    this._bhAnalyticsService.clickEvent('Print Test Result Detail', '');
    // window.print();
  }

  yFormat = (e) => {
    return e;
  }

  selectComponent(component) {
    const selected = component['selected'][0];
    let selectedTest = selected.Test;

    if (typeof selected.Test !== 'string') {
      selectedTest = selected.Test.toString();
    }

    selected.Date = this.TestResult.Date;

   selectedTest = encodeURIComponent(selectedTest)
    this._router.navigate([selectedTest], { relativeTo: this._route, state: { data: selected, orderedBy: this.TestResult.OrderedBy, mainTest: this.TestResult.OrderedItemDisplay } });
  }
}
