import { Component, OnInit, HostListener } from '@angular/core';
import { Workflow } from "../../../../model/Workflow";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { RequestAppointmentStateService } from "../../../../service/request-appointment-state.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FormsValidationService } from "../../../../service/forms-validation.service";
import { filter } from 'rxjs/operators';
import { AwsTranslateService } from '../../../../service/aws-translate.service';

@Component({
  selector: 'app-apt-contact-info',
  templateUrl: './apt-contact-info.component.html',
  styleUrls: ['./apt-contact-info.component.scss'],
})
export class AptContactInfoComponent implements OnInit {

  public nextPageStateDisabled: boolean = false; //true;

  public workflows: Workflow[] = [
    { Title: "Select Provider", Content: "", State: "past" },
    { Title: "Reason for Appointment", Content: "", State: "past" },
    { Title: "Date & Time", Content: "", State: "past" },
    { Title: "Your contact info", Content: "", State: "current" },
    { Title: "Review & submit", Content: "", State: "future" }
  ];

  patientForm: FormGroup = new FormGroup(
    {
      contactType: new FormControl('', [Validators.required]),
      //contactPhoneNumber: new FormControl(),
      contactPhoneNumber: new FormControl('', { validators: [], updateOn: this.formValidationService.getValidationUpdateOn() })
      /* ,
      contactPhoneNumber: new FormControl('', { validators: [Validators.required, this.formValidationService.getPhoneNumberValidator()], updateOn: this.formValidationService.getValidationUpdateOn()})
      contactPhoneNumberExt: new FormControl(),
      contactBestTime: new FormControl(), // morning, afternoon, evening
      contactLeaveVoicemail: new FormControl(),
      contactSaveContact: new FormControl() //TODO: how is this loaded?
      */
    }
  );

  constructor(
    private _requestAppointmentStateService: RequestAppointmentStateService,
    public _router: Router,
    private formValidationService: FormsValidationService,
    private AWSTranslate: AwsTranslateService
  ) {
    this._router.events.subscribe((event) => {

      if (event instanceof NavigationEnd && event.id === 1 &&
        event.url === event.urlAfterRedirects
      ) {
        this._router.navigateByUrl('/authenticated/health-record/visits/request-appointment/apt-select-provider');
      }
    }
    );

  }




  async ngOnInit() {

    // this._router.events
    // .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
    // .subscribe(event => {

    //   if (
    //     event.id === 1 &&
    //     event.url === event.urlAfterRedirects
    //   ) {
    //     this._router.navigateByUrl('/authenticated/health-record/visits/request-appointment/apt-select-provider');
    //   }
    // });

    this._requestAppointmentStateService.loadForm(this.patientForm);
    this._requestAppointmentStateService.updateWorkflowContents(this.workflows);
    this.formStateChange();
  }

  formStateChange() {
    //console.log("formstatechange");
    //this.nextPageStateDisabled = true;
    if (this.patientForm.get("contactType").value == "message") {
      this.patientForm.get("contactPhoneNumber").disable();
      this.patientForm.get("contactPhoneNumber").setValidators([]);
      document.getElementById("contactPhoneNumberDiv").style.display = 'none';
      //this.nextPageStateDisabled = false;
    } else if (this.patientForm.get("contactType").value == "phone") {
      this.patientForm.get("contactPhoneNumber").enable();
      document.getElementById("contactPhoneNumberDiv").style.display = 'block';
      this.patientForm.get("contactPhoneNumber").setValidators([Validators.required, this.formValidationService.getPhoneNumberValidator()]);
      if (this.patientForm.get("contactPhoneNumber").value != "" && !this.patientForm.get("contactPhoneNumber").errors) {
        //this.nextPageStateDisabled = false;
      }
    } else {
      this.patientForm.get("contactPhoneNumber").disable();
      this.patientForm.get("contactPhoneNumber").setValidators([]);
      document.getElementById("contactPhoneNumberDiv").style.display = 'none';
    }
  }

  previousPage() {
    this._requestAppointmentStateService.saveForm(this.patientForm);
    this._router.navigateByUrl('/authenticated/health-record/visits/request-appointment/apt-schedule');
  }

  nextPage() {
    if (this.patientForm.valid) {
      this._requestAppointmentStateService.saveForm(this.patientForm);
      this._router.navigateByUrl('/authenticated/health-record/visits/request-appointment/apt-review');
    } else {
      let focusDone = false;
      Object.keys(this.patientForm.controls).forEach(field => {
        const control: any = this.patientForm.get(field);
        control.markAsTouched({ onlySelf: true });
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement != undefined && typeof control.nativeElement.setFocus === "function") {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }
  }

}
