import { Component, OnInit } from '@angular/core';

/** Displays contents only on large screens */
@Component({
  selector: 'smc-large-screen-only',
  templateUrl: './large-screen-only.component.html',
  styleUrls: ['./large-screen-only.component.scss'],
})
export class LargeScreenOnlyComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
