import { PatientCheckinData, PciQueueArgs } from './../model/PatientCheckinData';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ConstantsService } from './constants.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientCheckinService {
  token: string;
  env = environment;
  public checkinSubject: BehaviorSubject<PatientCheckinData> = new BehaviorSubject<PatientCheckinData>({});

  constructor(
    private httpClient: HttpClient,
  ) { }

  /**
   * Setter for Patient Checkin subject
   * @param data Patient Check-in data
   */
  setCheckinData(data: PatientCheckinData) {
    this.checkinSubject.next(data);
  }

  /**
   * Getter for Patient Checkin subject
   */
  getCheckinData(): PatientCheckinData {
    return this.checkinSubject.getValue();
  }

  /**
   * Add patient to queue
   * @param body Patient check-in data collected from PCI workflow
   */
  registerPatient(body: PatientCheckinData): Observable<any> {
    const headers = this.getRequestHeaders();
    const url = this.env.PCI_URL + '/registerPatient';
    body.apiToken = this.env.PCI_API_TOKEN;
    return this.httpClient.post(url, body, { withCredentials: false, headers });
  }

  /**
   * Get number of people in line.
   * @param body Queue arguments. Include pc_seq to get patient's place in line. Exclude pc_seq to get total number of patients in line
   */
  getQueueCount(body: PciQueueArgs): Observable<any> {
    const headers = this.getRequestHeaders();
    const url = this.env.PCI_URL + '/getQueueCount';
    body.apiToken = this.env.PCI_API_TOKEN;
    return this.httpClient.post(url, body, { withCredentials: false, headers });
  }

  /**
   * Get location info
   * @param dliSeq Location Seq
   */
  getLocationInfo(dliSeq: number): Observable<any> {
    const headers = this.getRequestHeaders();
    const url = this.env.PCI_URL + '/getLocationInfo';
    const body = {
      dli_seq: dliSeq,
      apiToken: this.env.PCI_API_TOKEN
    };
    return this.httpClient.post(url, body, { withCredentials: false, headers });
  }

  /**
   * Returns leading number from string
   * @param num String that contains a leading number
   */
  extractNumber(num: string) {
    let val = '';
    for (let i = 0; i < num.length; i++) {
      // alert(num.charAt(i));
      if (!isNaN(Number(num.charAt(i)))) {
        val += num.charAt(i);
      } else {
        break;
      }
    }
    return Number(val);
  }

  /**
   * Get Patient Check-in API Request headers
   */
  getRequestHeaders() {
    const headers = {
      'Content-Type': 'application/json',
      'SMC-Exclude-Credentials': 'true',
      'SMC-Dont-Set-Content-Type': 'false',
      authorization: 'Bearer ' + this.token
    };
    return headers;
  }
}
