export function generateDiagnosesList(suggestedDiagnoses: any, selectedFilter: string) : string {
    // Generates ordered list of diagnoses, based on filters applied.
    let diagnosesList = `<ol>`;
    let currentList = suggestedDiagnoses;

    // Get list filtered selected and check what to list by.
    switch (selectedFilter) {
      case 'show-all' :
        break;
      case 'common' :
        const commonList = currentList.filter(diagnosis => diagnosis.common_diagnoses == 'true')
        currentList = commonList;
        break;
      case 'red-flag' :
        const flagList = currentList.filter(diagnosis => diagnosis.red_flag == 'true')
        currentList = flagList;
        break;
    }
    
    currentList.forEach((diagnosis) => {
      const listItem = `<li> ${diagnosis.diagnoses_name} </li>`;
      diagnosesList += listItem;
    });

    diagnosesList += `</ol>`;
    return diagnosesList;
}