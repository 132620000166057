import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, TemplateRef, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { SmcTableData } from "../../../model/SmcTableData";
import { DataTableColumnDirective } from '@swimlane/ngx-datatable';
import { AwsTranslateService } from '../../../service/aws-translate.service';

@Component({
  selector: 'smc-table',
  templateUrl: './smc-table.component.html',
  styleUrls: ['./smc-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SmcTableComponent implements OnChanges, OnInit {

  @ViewChild('table') table: any;
  @ViewChild('expandRowTemplate') expandRowTemplate: ElementRef;
  @ViewChild('iconColumnTemplate') iconColumnTemplate: ElementRef;
  @ViewChild('iconColumnTemplate2') iconColumnTemplate2: ElementRef;

  @Input() data: SmcTableData;
  @Input() data2: SmcTableData;
  @Input() AllowSearch: boolean;
  @Input() AllowLiveSearch: boolean;
  @Input() AllowStaticSearch: boolean;
  @Input() rowDetailTemplate: TemplateRef<any>;
  @Input() ShowFooter: boolean;
  @Input() pageSize: number;
  /** Override customIconColumnTemplate if you need to do more than change the icon name */
  @Input() customIconColumnTemplate: ElementRef;
  @Input() pageSizeShowMore: number;
  @Input() pageSizeShowMore2: number;
  @Input() setRowClasses: (row: any) => { [index: string]: boolean };
  @Input() iconName: string | Function;
  @Input() iconName2: string | Function;
  @Input() selection: string;
  @Input() selection2: string;
  @Input() messages: any;
  @Input() messages2: any;
  @Input() noRecordsMessageDataType: string;
  @Input() noRecordsMessageHeader: string;
  @Input() noRecordsMessageBody: string;
  @Input() noRecordsMessageHeader2: string;
  @Input() noRecordsMessageBody2: string;
  @Input() columnMode: string;
  @Input() columnMode2: string;
  @Input() searchBarPlaceholder: string;
  @Input() boldColumns: string[];
  @Input() showMeMoreText = 'Show me more';
  @Input() usePrinterFriendlyTables = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onClick2: EventEmitter<any> = new EventEmitter();
  @Output() onSearchKey: EventEmitter<any> = new EventEmitter();

  private _timeComponentInitialized = Date.now();
  private _minimumPerceivedLoadTime = 1000; // the minimum time to display the loading indicator, delay added to prevent quick flashing
  public perceivedLoadingComplete = false;
  public setCellClasses: (cell: any) => { [index: string]: boolean };
  public staticSearch: string;
  public selectionType: string;
  public selectionType2: string;
  public selected: any[] = [];
  public collapsedColList: Array<Array<number>>;

  footerSize: number;

  /**
   * See https://github.com/swimlane/ngx-datatable/issues/923
   * There is a know bug where ngx-datatable does not correctly resize.
   * We had an issue where the table would not resize correctly when window snapping was used.
   * This fixes the table size issue by triggering an extra resize event if it looks like window snapping occurred.
   */
  static hackToFixResizeBug() {
    let lastWidth = window.innerWidth;
    let ignoreNextEvent = false;
    window.addEventListener('resize', () => {
      if (!ignoreNextEvent && Math.abs(window.innerWidth - lastWidth) > 100) {
        ignoreNextEvent = true;
        setTimeout(() => window.dispatchEvent(new Event('resize')));
      }
      else {
        ignoreNextEvent = false;
      }
      lastWidth = window.innerWidth;
    });
  }

  submitStaticSearch() {
    this.selected = [];
    this.onSearchKey.emit(this.staticSearch);
    this.rows = [...this.rows]; // hack to get the table to update.
    return false;
  }

  updateStaticSearch(event) {
    if (event.code == "Enter") {
      this.submitStaticSearch();
    } else {
      this.staticSearch = event.target.value;
    }
    return false;
  }

  updateStaticSearchAuto(event) {
    if (event.code == "Enter") {
      this.updateFilter();
    } else {
      this.staticSearch = event.target.value;
    }
    return false;
  }

  updateLiveSearch(event) {
    this.onSearchKey.emit(event.target.value);
    this.rows = [...this.rows]; // hack to get the table to update.
  }

  rowSelected(selected): void {
    console.log('row selected', selected);
    
    if (this.rowDetailTemplate != null) {
      this.toggleExpandRow(selected.selected[0]);
    } else {
      this.onClick.emit(selected);
    }

  }

  cardSelected(selected): void {
    this.onClick.emit({selected: [selected]})
  }

  rowSelectedTable2(selected): void {
    this.onClick2.emit(selected);
  }

  cardSelectedTable2(selected): void {
    this.onClick.emit({selected: [selected]})
  }

  public search: string;
  public allowSearch: boolean;
  public allowLiveSearch: boolean;
  public allowStaticSearch: boolean;

  public columns: DataTableColumnDirective[];
  public rows: object[];
  public temp: object[];
  public title: string;


  public columns2: DataTableColumnDirective[];
  public rows2: object[];
  public temp2: object[];
  public title2: string;

  CurrentPage: number = 1;
  displayShowMoreButton: boolean;

  CurrentPage2: number = 1;
  displayShowMoreButton2: boolean;

  customSorting: boolean = false;

  constructor(private _changeDetectorRef: ChangeDetectorRef, private AWSTranslate: AwsTranslateService) { }

  ngOnInit() {

    this.allowSearch = this.AllowSearch;
    this.allowLiveSearch = this.AllowLiveSearch;
    this.allowStaticSearch = this.AllowStaticSearch;
    if (this.ShowFooter || this.pageSize > 0) {
      this.footerSize = 20;
    } else {

      this.footerSize = 0;
    }

    if (this.selection) {
      if (this.selection != "disabled") {
        console.log('in component', this.selection);
        
        this.selectionType = this.selection;
      }
    } else {
      this.selectionType = 'single';
    }

    if (this.selection2) {
      if (this.selection2 != "disabled") {
        this.selectionType2 = this.selection2;
      }
    } else {
      this.selectionType2 = 'single';
    }

    if (!this.messages) {
      this.messages = {
        emptyMessage: 'No information currently available',
        totalMessage: 'total'
      };
    }
    if (!this.messages2) {
      this.messages2 = {
        emptyMessage: 'No information currently available',
        totalMessage: 'total'
      };
    }

    if (this.columnMode == null) {
      this.columnMode = 'force';
    }
    if (this.columnMode2 == null) {
      this.columnMode2 = 'force';
    }

    if (!this.searchBarPlaceholder) {
      this.searchBarPlaceholder = "Search this list";
    }

  }



  ngOnChanges(changes: SimpleChanges) {
    const dataChanged = changes.data;
    if (dataChanged && dataChanged.currentValue) {

      this._handlePerceivedLoading();

      this.temp = dataChanged.currentValue.rows;

      if (this.pageSizeShowMore) {
        let qntItems = this.pageSizeShowMore * this.CurrentPage;
        if (this.temp) {
          this.rows = this.temp.slice(0, qntItems);
          this.displayShowMoreButton = qntItems < this.temp.length;
        }
        this.customSorting = true;

      }
      else {
        this.rows = this.temp;
        this.displayShowMoreButton = false;
      }

      if (dataChanged.currentValue.columns) {
        this.columns = dataChanged.currentValue.columns.slice();
      }

      if (this.rowDetailTemplate) {
        // todo: review why typechecking had to be disabled on this call
        this.columns.push({ name: ' ', cellTemplate: this.expandRowTemplate } as any);
      }

      if (this.iconName) {
        this.columns.unshift({ name: ' ', cellTemplate: this.iconColumnTemplate, headerClass: 'header-icon', cellClass: 'cell-icon' } as any);
      }

      if (this.customIconColumnTemplate) {
        this.columns.unshift({ name: ' ', cellTemplate: this.customIconColumnTemplate, headerClass: 'header-icon', cellClass: 'cell-icon' } as any)
      }

      this.title = dataChanged.currentValue.Title;
    }

    const dataChanged2 = changes.data2;
    if (dataChanged2 && dataChanged2.currentValue) {

      this.temp2 = dataChanged2.currentValue.rows;

      if (this.pageSizeShowMore2) {
        let qntItems = this.pageSizeShowMore2 * this.CurrentPage2;
        if (this.temp2) {
          this.rows2 = this.temp2.slice(0, qntItems);
          this.displayShowMoreButton2 = qntItems < this.temp2.length;
        }

        this.customSorting = true;
        if (this.table != null) {
          this.table.sort = this.onCustomLoadMoreSort2;
        }
      }
      else {
        this.rows2 = this.temp2;
        this.displayShowMoreButton2 = false;
      }

      if (dataChanged2.currentValue.columns) {
        this.columns2 = dataChanged2.currentValue.columns.slice();
      }

      if (this.iconName2) {
        this.columns2.unshift({ name: ' ', cellTemplate: this.iconColumnTemplate2, headerClass: 'header-icon', cellClass: 'cell-icon' } as any);
      }

      if (this.customIconColumnTemplate) {
        this.columns2.unshift({ name: ' ', cellTemplate: this.customIconColumnTemplate, headerClass: 'header-icon', cellClass: 'cell-icon' } as any)
      }

      this.title2 = dataChanged2.currentValue.Title;
    }

    // style any columns that are specified to be bold
    if (this.boldColumns) {
      for (const dataset of [this.data, this.data2]) {
        if (dataset) {
          dataset.columns.forEach(column => {
            if (this.boldColumns.includes(column.prop)) {
              column.cellClass += ' bold';
            }
          });
        }
      }
    }
    this.collapsedColList = this.getCollapsedColList();
  }

  // ensures that the loader does not appear and disappear so quickly that it is disorienting
  _handlePerceivedLoading() {
    const now = Date.now()
    const earliestTimeToDisplay = this._timeComponentInitialized + this._minimumPerceivedLoadTime;
    if (now >= earliestTimeToDisplay) {
      this.perceivedLoadingComplete = true;
      this._changeDetectorRef.detectChanges();
      window.dispatchEvent(new Event('resize')); // fix for table sizing issue
    }
    else setTimeout(() => {
      this.perceivedLoadingComplete = true;
      this._changeDetectorRef.detectChanges();
      window.dispatchEvent(new Event('resize')); // fix for table sizing issue
    }, earliestTimeToDisplay - now);
  }

  updateFilter() {
    const val = this.staticSearch;
    this.rows = this.FilerTable(this.columns, this.temp, val);
    if (this.columns2) {
      this.rows2 = this.FilerTable(this.columns2, this.temp2, val);
    }
  }

  public FilerTable(columns, tableFullData, filter): any {
    // filter our data
    const temp = tableFullData.filter(function (rowFields) {

      if (!filter) {
        return true;
      }
      //apply filter on all columns
      for (let index = 0; index < columns.length; index++) {
        const element = columns[index];
        const cellValue = rowFields[element.prop];
        if (typeof cellValue === 'string' || cellValue instanceof String) {
          if (cellValue.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
            return true;
          }
        }
      }
      return false;
    });

    // return updated rows
    return temp;
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  showMore() {
    this.CurrentPage++;
    let qntItems = this.pageSizeShowMore * this.CurrentPage;
    this.rows = this.temp.slice(0, qntItems);
    this.displayShowMoreButton = qntItems < this.temp.length;
  }

  showMore2() {
    this.CurrentPage2++;
    let qntItems = this.pageSizeShowMore2 * this.CurrentPage2;
    this.rows2 = this.temp2.slice(0, qntItems);
    this.displayShowMoreButton2 = qntItems < this.temp2.length;
  }


  getIconName(row): string {
    if (typeof this.iconName == 'function') return this.iconName(row);
    return this.iconName;
  }

  getIconName2(row): string {
    if (typeof this.iconName2 == 'function') return this.iconName2(row);
    return this.iconName2;
  }

  onCustomLoadMoreSort(event) {
    if (this.pageSizeShowMore) {
      this.temp = this.sortLoadMore([...this.temp], event.sorts[0]);
    }

  }

  onCustomLoadMoreSort2(event) {
    if (this.pageSizeShowMore2) {
      this.temp2 = this.sortLoadMore([...this.temp2], event.sorts[0]);
    }
  }

  sortLoadMore(rows, sort) {
    rows.sort((a, b) => {
      if (a[sort.prop] instanceof Date && b[sort.prop] instanceof Date) return a[sort.prop].toISOString().localeCompare(b[sort.prop].toISOString()) * (sort.dir === 'desc' ? -1 : 1);
      return a[sort.prop].toString().localeCompare(b[sort.prop].toString()) * (sort.dir === 'desc' ? -1 : 1);
    });

    let qntItems = this.pageSizeShowMore * this.CurrentPage;

    this.rows = rows.slice(0, qntItems);
    return rows;
  }

  getCollapsedColList(): Array<Array<number>> {
    let collapsedcollist = Array<Array<number>>();

    if (this.data != null && this.data.columns != null) {
      for (let i = 0; i < this.data.columns.length; i++) {
        let cc = this.data.columns[i].collapseCol;

        if (cc == null) {
          cc = 0;
        }

        if (collapsedcollist[cc] == null) {
          collapsedcollist.push(Array<number>());
        }
        collapsedcollist[cc].push(i);
      }
    }

    return collapsedcollist;
  }

  isFunction(v) {
    if (v instanceof Function) {
      return true;
    } else {
      return false;
    }
  }

  /** trigger click on radio button when mobile card is clicked */
  mobileRadioItemClicked(mobileRadioGroup, mobileRadioIndex) {
    mobileRadioGroup.el.querySelectorAll('ion-radio')[mobileRadioIndex].dispatchEvent(new Event('click'));
  }

}

