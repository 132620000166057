import { Demographics } from 'src/app/model/PersonalInformation';
import { ModalController } from '@ionic/angular';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticateService } from 'src/app/service/authenticate.service';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { ConstantsService } from 'src/app/service/constants.service';
import { Platform } from '@angular/cdk/platform';
import { PersonalInformationService } from 'src/app/service/personal-information.service';
import { AwsTranslateService } from '../../../service/aws-translate.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @ViewChild('username') usernameInput: any;
  @ViewChild('password') passwordInput: any;
  @ViewChild('publicDevice') publicDeviceCheckbox: any;
  @Input() targetResource = null;
  @Input() mode = 'page';

  version: string;
  error: string;
  connectionError: boolean;
  incorrectCredentials: boolean;

  user = '';
  pwd = '';

  passwordType = 'password';
  passwordIcon = 'eye';
  passwordIconText = 'Show';

  missingUsername = false;
  missingPassword = false;

  showBadges = false;

  usernameFieldChanged = () => this.missingUsername = false;
  passwordFieldChanged = event => event.key !== 'Enter' && (this.missingPassword = false);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticateService: AuthenticateService,
    private _bhAnalyticsService: BhanalyticsService,
    public constantsService: ConstantsService,
    public platform: Platform,
    private modalCtrl: ModalController,
    private AWSTranslate: AwsTranslateService
  ) { }

  ngOnInit() {
  }

  public async login() {
    const username: string = this.user;
    const password: string = this.pwd;

    this.missingUsername = !username;
    this.missingPassword = !password;
    if (this.missingUsername || this.missingPassword) { return; }

    try {
      console.log(this.publicDeviceCheckbox);
      const isModal = (this.mode === 'modal');
      const login = await this.authenticateService.authenticate(
        {
          username,
          password,
          IsPrivateDevice: !this.publicDeviceCheckbox.checked
        },
        isModal
      );
      if (login) {
        if (login.LoggedIn === 1) {
          if (this.mode === 'modal') {
            this.authenticateService.setLoggedIn(true);
            this.modalCtrl.dismiss({ isLoggedIn: true });
          } else {
            // Go to target page
            let nextRoute = '/authenticated/home';
            // Check for target resources in queryparams
            if (this.targetResource) {
              switch (this.targetResource.toLowerCase()) {
                // Request appointment
                case 'request-appt':
                  nextRoute = '/authenticated/health-record/visits/request-appointment/apt-select-provider';
                  break;

                // Test Results
                case 'test-results':
                  nextRoute = '/authenticated/health-record/test-results';
                  break;

              }
            }
            await this.router.navigate([nextRoute]);
            this.authenticateService.setLoggedIn(true);
          }
        } else {
          console.log('User not logged in');
          this.user = '';
          this.pwd = '';
          if (this.mode !== 'modal') {
            this.router.navigate(['/login']);
          }
        }
      } else {
        console.log('No login object');
      }
    } catch (e) {
      this.error = e.error.error || e.error || e;
      this.connectionError = !navigator.onLine;
      this.incorrectCredentials = (this.error === 'You entered an invalid username or password');
    }
  }

  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
    //this.passwordIconText = this.passwordIconText === 'Show' ? 'Hide' : 'Show';
    this.passwordIconText = this.passwordIcon === 'eye-off' ? this.AWSTranslate.read("Hide", this.AWSTranslate.langId) : this.AWSTranslate.read("Show", this.AWSTranslate.langId);
  }


  ngOnDestroy() {
    this.user = '';
    this.pwd = '';
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
