import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConstantsService } from 'src/app/service/constants.service';
import { AwsTranslateService } from '../../../../service/aws-translate.service';

@Component({
  selector: 'app-request-achange-modal',
  templateUrl: './request-achange-modal.component.html',
  styleUrls: ['./request-achange-modal.component.scss'],
})
export class RequestAChangeModalComponent implements OnInit {

  constructor( 
    public modalController: ModalController,
    public constantsService: ConstantsService,
    public AWSTranslate: AwsTranslateService
  ) { }

  ngOnInit() {}

  dismiss() {
    this.modalController.dismiss();
  }

}
