import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'symptom-checker',
  templateUrl: './symptom-checker-page.component.html',
  styleUrls: ['./symptom-checker-page.component.scss'],
})
export class SymptomCheckerPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
