import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { HttpBaseService } from 'src/app/service/http-base.service';

@Injectable({
  providedIn: 'root'
})
export class HealthRecordService {

  constructor(
    private httpClient: HttpClient,
    private httpBaseService: HttpBaseService,
    private constantsService: ConstantsService
  ) { }

  async getCounts(user?: UserIdentifier) {
    return await this.httpClient.get("").toPromise();
  }

  async getEvents(user?: UserIdentifier){
    return await this.httpClient.get("").toPromise();
  }

  async getAppointments(user?: UserIdentifier){
    return await this.httpClient.get("").toPromise();
  }

  async getFacilitiesPhoneNumber() {
    const facilityList = await this.httpBaseService.getList('/customapi/facilityList', false);
    return facilityList;
  }

  // // Return facilities phone number if locations match.
  // getFilteredFacilityNumber(locationStr: string, facilityList: any) {
  //   const locationName = locationStr.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
  //   const filteredFacility = facilityList.filter(facilityNumber => {
  //     const facilityName = facilityNumber.name.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
  //     const facilityCode = facilityNumber.code.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
  //     const namesMatch = (locationName.match(facilityName) || locationName.split(' ').join('').indexOf(facilityName.split(' ').join('')) > -1);
  //     const codeMatchesName = facilityCode.match(locationName);
  //     return (codeMatchesName || namesMatch);
  //   });
  //   return (filteredFacility[0] ? filteredFacility[0].phone :  '');
  // }
}