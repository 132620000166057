import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TestResult } from 'src/app/model/TestResult';
import { MatDatepickerInputEvent } from '@angular/material/typings';
import { TestResultsService } from 'src/app/service/test-results.service';
import { DatePipe, formatDate } from '@angular/common';
import {Router} from "@angular/router";
import {BhanalyticsService} from "src/app/service/bhanalytics.service";
import { svgAsPngUri } from "save-svg-as-png";
import { DateHandlerService } from 'src/app/service/date-handler.service';
import { SmcTableData } from 'src/app/model/SmcTableData';
import { PrintService } from 'src/app/service/print.service';


interface PrintableTableData {
  columns: string[];
  rows: Array<string[]>;
}


/** View result history (was PastResultsModalComponent in a past life) */
@Component({
  selector: 'smc-result-history',
  templateUrl: './result-history.component.html',
  styleUrls: ['./result-history.component.scss'],
})

export class ResultHistoryComponent implements OnInit {
  @ViewChild('from') private from: any;
  @ViewChild('to') private to: any;
  @ViewChild('printSection') private printSection: any;
  @ViewChild('dateColumnTemplate') dateColumnTemplate: ElementRef;
  TestResultHistory: TestResult[];
  @Input() selectedResult: any;
  @Input() showGraph: boolean;
  view: any[] = [1300, 200];
  modalChartData: any;
  modalTableData: SmcTableData;
  printableTableData: PrintableTableData;
  data: any;
  /** Error string to display below date range selector. */
  invalidDateRangeError: string = null;

  colorScheme = {
    domain: ['#55b5e6']
  };
  // line, area
  autoScale = true;

  @ViewChild('resultValueTemplate') resultValueTemplate: ElementRef;
  allResultsCache: any[];

  constructor(
    public modalController: ModalController,
    private _testResultsService: TestResultsService,
    private _datePipe: DatePipe,
    private _bhAnalyticsService: BhanalyticsService,
    private _dateHandler: DateHandlerService,
    private _printService: PrintService
  ) { }

  ngOnInit() {
    this.TestResultHistory = this.getParamData(null, 'testResultHistory');
    console.log('result-history.component, TestResultHistory', this.TestResultHistory);
    this.onResize();
    this.loadData(null, null);
  }

  dismiss() {
    this.selectedResult = null;
    this.modalController.dismiss();
  }

  displayChart() {
     if(this.showGraph != null && this.showGraph == false) {
        return false;
     } else {
       return true;
     }
  }

  /** Check if date range is valid. If invalid disable "Get results" button and show error message. */
  dateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    const fromString = this.from.nativeElement.value;
    const toString = this.to.nativeElement.value;
    if (!fromString) {
      this.invalidDateRangeError = 'Please select a start date';
      return;
    }
    if (!toString) {
      this.invalidDateRangeError = 'Please select an end date';
      return;
    }
    const fromDate = new Date(this.from.nativeElement.value);
    const toDate = new Date(this.to.nativeElement.value);
    if (toDate.getTime() <= fromDate.getTime()) {
      this.invalidDateRangeError = 'Please select an end date that is later than the selected start date';
      return;
    }
    this.invalidDateRangeError = null;
  }

  loadData(from, to) {
    this.allResultsCache = this.TestResultHistory; //[].concat.apply([], this.TestResultHistory.map(x => x.ResultItems));
    let allResultItems = this.allResultsCache.filter(item => item !== undefined ? (item.Test === this.selectedResult.Test) : false);
    if (from != null) {
      allResultItems = allResultItems.filter(result => this._dateHandler.parseDateString(result.ObservationTime) >= this._dateHandler.parseDateString(from));
    }
    if (to != null) {
      allResultItems = allResultItems.filter(result => this._dateHandler.parseDateString(result.ObservationTime) <= this._dateHandler.parseDateString(to));
    }

    allResultItems.sort((a, b) => b.ObservationTime < a.ObservationTime ? 1 : -1);
    const resultToDate = (allResultItems[0].ObservationTime || allResultItems[0].ObservationTimeDisplay);
    const resultFromDate = (allResultItems[allResultItems.length - 1].ObservationTime|| allResultItems[allResultItems.length - 1].ObservationTimeDisplay);

    this.modalTableData = this.getModalTableData(allResultItems);
    this.modalChartData = this.getModalChartData(allResultItems);

    this.createTableDataForPrinting(allResultItems);

    // Set "from" and "to" fields based on response data (if user has not manually selected a date range yet).
    if (allResultItems && allResultItems.length > 0) {
      if (from == null) {
        this.from.nativeElement.value = this._datePipe.transform(this._dateHandler.parseDateString(resultToDate), 'MM/dd/yyyy');
      }
      if (to == null) {
        this.to.nativeElement.value = this._datePipe.transform(this._dateHandler.parseDateString(resultFromDate), 'MM/dd/yyyy');
      }
    }
  }

  getParamData(data, storeParamName) {
    let value;
    if (data) {
      value = data;
      localStorage.setItem(storeParamName, JSON.stringify(value));
    } else {
      value = JSON.parse(localStorage.getItem(storeParamName));
    }

    return value;
  }

  onResize() {
    this.view = [window.innerWidth / 1.35, 200];
  }

  getModalChartData(items: any[]) {
    return [
      {
        "name": "",
        "series": items.map(item => ({
          name:  this._datePipe.transform(this._dateHandler.parseDateString(item.ObservationTime || item.ObservationTimeDisplay), 'MM/dd/yyyy'),
          value: item.ResultValue
        }))
      }
    ];
  }

  ChekcIfHasString(code) {
    return this._testResultsService.ChekcIfHasString(code);
  }

  getModalTableData(items: any[]): SmcTableData {
    return {
      columns: [
        { name: 'Time', prop: 'ObservationTimeDisplay', cellClass: 'card-detail', cellTemplate: this.dateColumnTemplate },
        { name: 'Result', prop: 'ResultValue', cellClass: 'card-detail', cellTemplate: this.resultValueTemplate },
        { name: 'Location', prop: 'LocationFromMultiple', cellClass: 'card-detail' },
      ],
      rows: items.map(row => Object.assign(row, {
        ObservationTimeDisplay: formatDate(this._dateHandler.parseDateString(row.ObservationTime), 'short', 'en-us'),
        LocationFromMultiple: row.EnteredAt || row.EnteredBy || row.PerformedAt
      }))
    };
  }

  createTableDataForPrinting(allResultItems) {
    this.data = this.getModalTableData(allResultItems);
    if (this.data.rows) {
      // construct data fir print-friendly table
      this.printableTableData = {
        columns: this.data.columns.map((column) => column.name),
        rows: (() => {
          const rows = [];
          this.data.rows.forEach((row, index) => {
            const arrayRow = [];
            this.data.columns.forEach((column) => {
              arrayRow.push(row[column.prop]);
            });
            rows.push(arrayRow);
          });
          return rows;
        })(),
      };
    }
    console.log('data printing table', this.data);

  }


  updateResults(from, to) {
    this._bhAnalyticsService.searchEvent(from.value + ' ' + to.value);
    this.loadData(from.value, to.value + " 11:59 pm");
  }

  async getPrintableHtml(): Promise<string> {
    const chartPng = await svgAsPngUri(this.printSection.nativeElement.querySelector('svg.ngx-charts'));
    const innerHTMLContent = this.printSection.nativeElement.innerHTML;
    const tableContent = this.printSection.nativeELement
    const printContent = `
      <img alt="chart" src="${chartPng}"><br>
      ${innerHTMLContent} <br>
      ${this.data}
    `;
    return printContent;
  }

}
