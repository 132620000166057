import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingServiceService {
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  setLoading(isLoading: boolean) {
    this.isLoading.next(isLoading);
    if (isLoading) {
      let timer = setTimeout(() => {
        isLoading = false;
        clearTimeout(timer);
        timer = null;
      }, 5000);
    }
  }

  getLoading(): boolean {
    return this.isLoading.getValue();
  }
}
