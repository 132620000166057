import { Injectable } from '@angular/core';
import { Workflow } from "../model/Workflow";
import { FormGroup } from "@angular/forms";
import { Recipients } from "../model/Recipients";
import { StateService } from './state.service';

export interface PrescriptionState {
  medication: any;
  medicineNotListed: boolean;
  medicineText: string;
  comments: string;
  pharmacy: any;
  pharmacyNotListed: boolean;
  pharmacyText: string;
  provider: any;
  contactType: string;
  contactPhoneNumber: string;
  contactPhoneNumberExt: string;
  contactBestTime: string;
  contactLeaveVoicemail: boolean;
  contactSaveContact: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PrescriptionStateService {

  state: PrescriptionState = {
    medication: "",
    medicineNotListed: false,
    medicineText: "",
    comments: "",
    pharmacy: "",
    pharmacyNotListed: false,
    pharmacyText: "",
    provider: "",
    contactType: "",
    contactPhoneNumber: "",
    contactPhoneNumberExt: "",
    contactBestTime: "",
    contactLeaveVoicemail: false,
    contactSaveContact: false
  };

  constructor(
    private stateService: StateService
  ) { }

  updateState(key: string, value: any) {
    this.state[key] = value;
  }

  saveForm(patientForm: FormGroup) {
    for (let formControlName in patientForm.controls) {
      this.state[formControlName] = patientForm.get(formControlName).value;
    }
  }

  loadForm(patientForm: FormGroup) {
    for (let formControlName in patientForm.controls) {
      patientForm.get(formControlName).setValue(this.state[formControlName]);
    }
  }

  buildPrescriptionRequest(state: PrescriptionState): string {
    var medication = null;
    if (state.medicineNotListed) {
      medication = { Medication: state.medicineText }; //"{ \"Medication\": \"" + state.medicineText + "\" }";
    } else if (state.medication != null && state.medication != "") {
      medication = state.medication;
    }

    var pharmacy = "";
    if (state.pharmacyNotListed) {
      pharmacy = "";
    } else if (state.pharmacy != null && state.pharmacy != "") {
      pharmacy = state.pharmacy.id;
    }

    var sendMessage = false; // contactType == 'phone'
    if (state.contactType == 'message') {
      sendMessage = true;
    }

    var provider = "";
    if (state.provider != null && state.provider != "") {
      console.log('processing provider: ', state.provider, state);
      provider = state.provider.Provider.ID;
    }

    var json =
    {
      Subject: 'Refill Request: ' + (state.medicineNotListed ? 'Other Prescription' : medication.Medication),
      Text: state.comments ? state.comments : '',
      To: [provider],
      Pharmacy: pharmacy,
      PharmacyText: state.pharmacyText,
      //ContactType: value,
      ContactInfo: {
        ClearDefault: false, //TODO: Default??
        Extension: state.contactPhoneNumberExt,
        IsDefault: state.contactSaveContact,
        PhoneNumber: state.contactPhoneNumber,
        SendMessage: sendMessage,
        TimeToCall: state.contactBestTime,
        VoiceMailOK: state.contactLeaveVoicemail
      },
      //ContactInstructions: value,
      currentPage: 1, //TODO: Default??
      isComplete: true, //TODO: Default??
      step: 0, //TODO: Default??
      MedicationText: state.medicineText,
      Medications: [medication],
      selectedMedication: [medication]
    };
    return JSON.stringify(json);
  }

  // buildPrescriptionRequestAlt(selectedPrescriptions, prescriptionList, selectedPharmacy, selectedProvider, contactInfo, textAreaData) : string {

  //   var medication = null;
  //   if (selectedPrescriptions != null && selectedPrescriptions !== "") {
  //     medication = selectedPrescriptions;
  //   }

  //   var pharmacy = "";
  //   if (selectedPharmacy != null && selectedPharmacy != "") {
  //     pharmacy = selectedPharmacy.id;
  //   }

  //   var sendMessage = false; // contactType == 'phone'
  //   if (this.state.contactType == 'message') {
  //     sendMessage = true;
  //   }

  //   var provider = "";
  //   if(selectedProvider != null && selectedProvider != "") {
  //     provider = selectedProvider.Provider.ID;
  //   }

  //   var json =
  //   {
  //     Subject: "Refill Request",
  //     Text: textAreaData,
  //     To: [ provider ],
  //     Pharmacy: pharmacy,
  //     PharmacyText: this.state.pharmacyText,
  //     //ContactType: value,
  //     ContactInfo: {
  //       ClearDefault: false, //TODO: Default??
  //       Extension: this.state.contactPhoneNumberExt,
  //       IsDefault: this.state.contactSaveContact,
  //       PhoneNumber: contactInfo,
  //       SendMessage: true,
  //       TimeToCall: this.state.contactBestTime,
  //       VoiceMailOK: this.state.contactLeaveVoicemail
  //      },
  //     //ContactInstructions: value,
  //     currentPage: 1, //TODO: Default??
  //     isComplete: true, //TODO: Default??
  //     step: 0, //TODO: Default??
  //     MedicationText: this.state.medicineText,
  //     Medications: [ medication ],
  //     selectedMedication: [ medication ]
  //   };
  //   return JSON.stringify(json);
  // }

  getMedicationSummary(): string {
    var medication = "";
    if (this.state.medicineNotListed) {
      medication = this.state.medicineText;
    } else if (this.state.medication != "") {
      medication = JSON.parse(this.state.medication).Medication;
    }
    return medication;
  }

  getPharmacySummary(): string {
    var pharmacy = "";
    if (this.state.pharmacyNotListed) {
      pharmacy = this.state.pharmacyText;
    } else if (this.state.pharmacy != "") {
      let pharmacyObj = JSON.parse(this.state.pharmacy);
      pharmacy = pharmacyObj.Name + "<br>";
      if (pharmacyObj.Address) {
        pharmacy = pharmacy + pharmacyObj.Address.Line1 + " " + pharmacyObj.Address.Line2 + "<br>" + pharmacyObj.Address.City + ", " + pharmacyObj.Address.State + " " + pharmacyObj.Address.PostalCode;
      }
    }
    return pharmacy;
  }

  getProviderSummary(): string {
    var provider = "";
    if (this.state.provider != "") {
      //console.log("MY PROVIDER: " + this.state.provider);
      var providerJSON = JSON.parse(this.state.provider);
      provider = providerJSON.Provider.FirstName + " " + providerJSON.Provider.LastName
    }
    return provider;
  }

  getContactSummary(): string {
    var contact = "";
    if (this.state.contactType == 'message') {
      contact = "Contact by Message";
    } else if (this.state.contactType == 'phone') {
      contact = "Call me at " + this.state.contactPhoneNumber + " " + this.state.contactPhoneNumberExt;

    }
    return contact;
  }

  updateWorkflowContents(workflows: Workflow[]) {
    workflows[0].Content = this.getMedicationSummary();
    workflows[1].Content = this.getProviderSummary();
    workflows[2].Content = this.getPharmacySummary();
    workflows[3].Content = this.getContactSummary();
  }

  clearState() {
    this.state.medication = "";
    this.state.medicineNotListed = false;
    this.state.medicineText = "";
    this.state.comments = "";
    this.state.pharmacy = "";
    this.state.pharmacyNotListed = false;
    this.state.pharmacyText = "";
    this.state.provider = "";
    this.state.contactType = "";
    this.state.contactPhoneNumber = "";
    this.state.contactPhoneNumberExt = "";
    this.state.contactBestTime = "";
    this.state.contactLeaveVoicemail = false;
    this.state.contactSaveContact = false;
  }
}
