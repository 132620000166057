import { Injectable } from '@angular/core';
import {AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {FormHooks} from "@angular/forms/src/model";

@Injectable({
  providedIn: 'root'
})
export class FormsValidationService {

  constructor() { }

  getErrorMessageByLabel(label: string): string {
    if (label != undefined) {
      if (label.toLowerCase().indexOf("phone") != -1) {
        return "Invalid phone number (xxx-xxx-xxxx)";
      } else if (label.toLowerCase().indexOf("date") != -1) {
        return "Invalid date (mm/dd/yyyy)";
      } else if (label.toLowerCase().indexOf("zip") != -1) {
        return "Invalid zip code (xxxxx)";
      } else if (label.toLowerCase().indexOf("email") != -1) {
        return "Invalid email address (xyz@xyz.com)";
      } else if (label.toLowerCase() == "number") {
        return "Invalid number format (0-9)"
      }
    }

    return "";
  }

  getValidatorByLabel(label: string): ValidatorFn {
    if (label != undefined) {
      if (label.toLowerCase().indexOf("phone") != -1) {
        return this.getPhoneNumberValidator();
      } else if (label.toLowerCase().indexOf("date") != -1) {
        return this.getDateValidator();
      } else if (label.toLowerCase().indexOf("zip") != -1) {
        return this.getZipCodeValidator();
      } else if (label.toLowerCase().indexOf("email") != -1) {
        return this.getEmailValidator();
      }
    }

    return null;
  }

  getNumberValidator(): ValidatorFn {
    return Validators.pattern(/^\d*$/);
  }

  getPhoneNumberValidator(): ValidatorFn {
    return Validators.pattern(/^\(?\d\d\d\)?-?\d\d\d-?\d\d\d\d$/);
  }

  getZipCodeValidator(): ValidatorFn {
    return Validators.pattern(/^\d{5}$/);
  }

  getEmailValidator(): ValidatorFn {
    return Validators.pattern(/^.+@.+\..+$/);
  }

  getDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value == null || control.value == "") {
        return null;
      }
      const date = new Date(control.value);
      const invalidDate = !control.value || date.getMonth === undefined || isNaN(date.getMonth());
      return invalidDate ? { 'pattern': { value: control.value } } : null;
    };
     //return Validators.pattern(/^\d\d\/\d\d\/\d\d\d\d$/);
  }

  getValidationUpdateOn(): FormHooks {
    return "blur"; //"change", "submit"
  }
}
