import { Component, OnInit, Input } from '@angular/core';
import { HealthStatsService } from 'src/app/service/health-stats.service';
import { StateService } from 'src/app/service/state.service';
import { Vitals } from 'src/app/model/Vitals';
import { Observation } from 'src/app/model/Observation';
import { VitalsChart } from 'src/app/model/VitalsChart';
import { Router } from '@angular/router';
import { DatePipe, DecimalPipe } from "@angular/common";
import { BhanalyticsService } from "../../../service/bhanalytics.service";
import { DateHandlerService } from 'src/app/service/date-handler.service';
import { AwsTranslateService } from '../../../service/aws-translate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-health-stats',
  templateUrl: './health-stats.component.html',
  styleUrls: ['./health-stats.component.scss'],
  providers: [DecimalPipe]
})
export class HealthStatsComponent implements OnInit {

  @Input('date-range') dateRange: Date | null = null;
  env = environment;
  category: any;
  vitals: Vitals;
  leftVitals:any[];
  rightVitals:any[];

  selectedObservation: Observation = null;

  constructor(private _healthStatsService: HealthStatsService,
    private _stateService: StateService,
    private _router: Router,
    private _bhAnalyticsService: BhanalyticsService,
    private dateHandler: DateHandlerService,
    private _decimalPipe: DecimalPipe,
    private AWSTranslate: AwsTranslateService  ) {
  }

  async ngOnInit() {
    const category = this.env.banner_seq.health_stat;
    console.log('cat', category);
    this.category = category;
    this.loadData();
  }

  async loadData(filter?: Date | null) {
    var vitals = await (this._healthStatsService.getVitals())
    if (filter && filter != null) {
      vitals.Observations = vitals.Observations.filter((observation: Observation) =>
        (this.dateHandler.parseDateString(observation.EnteredTime)).isSameDay(filter)
      )
    }
    for (let index = 0; index < vitals.Observations.length; index++) {
      const element = vitals.Observations[index];
      // since unit field is not always type string, only convert to lowercase if it is
      const units = typeof element.ObservationUnits == "string" ? element.ObservationUnits.toLowerCase() : element.ObservationUnits;
      if (units == "kg") {
        element.ObservationValue = this._decimalPipe.transform(( element.ObservationValue * 2.20462), "1.0");
        element.ObservationUnits = "lbs.";
      }

      if (units == "cm"){
        element.ObservationValue = this.toFeet(element.ObservationValue);
        element.ObservationUnits ="";
      }
    }

    this.vitals = vitals;
    if(this.vitals && this.vitals.Observations && this.vitals.Observations.length>0){
      var half_length = Math.ceil(this.vitals.Observations.length / 2);
      this.leftVitals = this.vitals.Observations.splice(0,half_length);
      this.rightVitals = this.vitals.Observations;
    }

  }

  selectObservation(observation: Observation): void {
    this._bhAnalyticsService.clickEvent('Select Observation', observation.Observation);
    // if (this.selectedObservation != observation)
    this.selectedObservation = this.selectedObservation != observation ? observation : null;
  }

  async getChart(observation: Observation) {
    const obs = [observation].map((ob) => ob.Observation);
    const chart = await (this._healthStatsService.getChart(obs));
    const vitalHistory = await (this._healthStatsService.getVitalHistory(obs));
    this._router.navigate(['/authenticated/health-record/health-stats/health-stat-detail'], { state: { data: chart, vitals: vitalHistory } });
  }

  toFeet(height) {
    if (height) {
      let n = Number(height);
      var realFeet = ((n * 0.393700) / 12);
      var feet = Math.floor(realFeet);
      var inches = Math.round((realFeet - feet) * 12);
      return feet + "'" + inches + '"';
    }
  }

  print() {
    this._bhAnalyticsService.clickEvent('Print Health Stats', '');
    window.print();
  }


}
