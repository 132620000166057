import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class ForgotCredentialsService {

  constructor(
    private httpClient: HttpClient,
    private constantsService: ConstantsService
  ) {}

  async forgotPassword(username: string): Promise<any> {
    return this.httpClient.post(
      this.constantsService.constantsData.apiUrl + '/users/forgotPassword',
      {Username: username}
    ).toPromise();
  }

  async forgotUsername(email: string): Promise<any> {
    return this.httpClient.post(
      this.constantsService.constantsData.apiUrl + '/users/forgotUsername',
      {Email: email}
    ).toPromise();
  }

  async validateResetToken(token: string): Promise<any> {
    return this.httpClient.post(
      this.constantsService.constantsData.apiUrl + '/users/validateResetToken',
      {Token: token}
    ).toPromise();
  }

  async passwordRequirements(): Promise<any> {
    return this.httpClient.get(
      this.constantsService.constantsData.apiUrl + '/users/passwordRequirements?type=password'
    ).toPromise();
  }

  async resetPassword(password: string): Promise<any> {
    return this.httpClient.post(
      this.constantsService.constantsData.apiUrl + '/users/resetPassword',
      {Password: password}
    ).toPromise();
  }

  changePassword(currentPassword: string, newPassword: string): Promise<any> {
    return this.httpClient.post(
      this.constantsService.constantsData.apiUrl + '/users/0/changePassword',
      { CurrentPassword: currentPassword, NewPassword: newPassword }
    ).toPromise();
  }
}
