import { Component, OnInit } from '@angular/core';
import {Workflow} from "../../../../model/Workflow";
import {RequestAppointmentStateService} from "../../../../service/request-appointment-state.service";
import {Router, NavigationEnd} from "@angular/router";
import {RequestAppointmentService} from "../../../../service/request-appointment.service";

@Component({
  selector: 'app-apt-review',
  templateUrl: './apt-review.component.html',
  styleUrls: ['./apt-review.component.scss'],
})
export class AptReviewComponent implements OnInit {

  providerText: string;
  reasonText: string;
  scheduleText: string;
  contactInfoText: string;

  public workflows: Workflow[] = [
    { Title: "Select Provider", Content: "", State: "past" },
    { Title: "Reason for Appointment", Content: "", State: "past" },
    { Title: "Date & Time", Content: "", State: "past" },
    { Title: "Your contact info", Content: "", State: "past" },
    { Title: "Review & submit", Content: "", State: "current" }
  ];

  constructor(private _requestAppointmentService: RequestAppointmentService, private _requestAppointmentStateService: RequestAppointmentStateService, public _router : Router) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.id === 1 &&
        event.url === event.urlAfterRedirects
      ) {
        this._router.navigateByUrl('/authenticated/health-record/visits/request-appointment/apt-select-provider');
      }
    }
    );

   }

  async ngOnInit() {
    this._requestAppointmentStateService.updateWorkflowContents(this.workflows);
    this.providerText = this._requestAppointmentStateService.getProviderSummary();
    let reasonText = this._requestAppointmentStateService.getReasonSummary().split(":");
    if (reasonText[1]) {
      this.reasonText = reasonText[0] + "<br>" + reasonText[1];
    } else {
      this.reasonText = this._requestAppointmentStateService.getReasonSummary();
    }
    // this.reasonText = this._requestAppointmentStateService.getReasonSummary();
    this.scheduleText = this._requestAppointmentStateService.getScheduleSummary(true);
    this.contactInfoText = this._requestAppointmentStateService.getContactSummary();
  }

  previousPage() {
    this._router.navigateByUrl('/authenticated/health-record/visits/request-appointment/apt-contact-info');
  }

  async requestAppointment() {
    var requestData = this._requestAppointmentStateService.buildRequestAppointmentRequest();
    await this._requestAppointmentService.requestAppointment(requestData);
    this._requestAppointmentStateService.clearState();
    this._router.navigateByUrl('/authenticated/health-record/visits/request-appointment/apt-complete');
  }

}
