import { Component, OnInit } from '@angular/core';
import {Workflow} from "../../../../model/Workflow";
import {PrescriptionStateService} from "../../../../service/prescription-state.service";
import {Router, NavigationEnd} from "@angular/router";
import {PrescriptionService} from "../../../../service/prescription.service";

@Component({
  selector: 'app-rx-review',
  templateUrl: './rx-review.component.html',
  styleUrls: ['./rx-review.component.scss'],
})
export class RxReviewComponent  implements OnInit {

  medicineText: string;
  providerText: string;
  pharmacyText: string;
  contactInfoText: string;

  public workflows: Workflow[] = [
    { Title: "Select medicine", Content: "", State: "past" },
    { Title: "Select provider", Content: "", State: "past" },
    { Title: "Select pharmacy", Content: "", State: "past" },
    { Title: "Your contact info", Content: "", State: "past" },
    { Title: "Review & submit", Content: "", State: "current" }
  ];

  constructor(private _prescriptionService: PrescriptionService, private _prescriptionStateService: PrescriptionStateService, public _router : Router) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.id === 1 &&
        event.url === event.urlAfterRedirects
      ) {
        this._router.navigateByUrl('/authenticated/health-record/medicines/request-prescription/rx-select-medicine');
      }
    }
    );

  }

  async ngOnInit () {
    this._prescriptionStateService.updateWorkflowContents(this.workflows);
    this.medicineText = this._prescriptionStateService.getMedicationSummary();
    this.providerText = this._prescriptionStateService.getProviderSummary();
    this.pharmacyText = this._prescriptionStateService.getPharmacySummary();
    this.contactInfoText = this._prescriptionStateService.getContactSummary();
  }

  previousPage() {
    this._router.navigateByUrl('/authenticated/health-record/medicines/request-prescription/rx-contact-info');
  }

  requestPrescription() {
    // var requestData = this._prescriptionStateService.buildPrescriptionRequest();
    // this._prescriptionService.refillRequest(requestData);
    this._prescriptionStateService.clearState();
    this._router.navigateByUrl('/authenticated/health-record/medicines/request-prescription/rx-complete');
  }

}
