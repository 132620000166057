// Additional utility methods for dates

declare global {
  interface Date {
    isSameDay(other: Date) : boolean;
  }
}

// Returns true if other date is same day as this date
Date.prototype.isSameDay = function(other: Date){
  return this.getFullYear() === other.getFullYear() &&
        this.getMonth() === other.getMonth() &&
        this.getDate() === other.getDate();
}

export {}
