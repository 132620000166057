import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { ValidateNewAccountRequest } from '../model/ValidateNewAccountRequest';
import { PasswordRequirements } from '../model/PasswordRequirements';
import { ActivateAccountRequest } from '../model/ActivateAccountRequest';

@Injectable({
  providedIn: 'root'
})
export class ActivateAccountService {

  constructor(private httpClient : HttpClient,
              private constantsService : ConstantsService) { }

  async submitAccessToken(request: ValidateNewAccountRequest){
    const url = `${this.constantsService.constantsData.apiUrl}/users/validateActivationCode?ts=${Date.now()}`;
    const response = await (this.httpClient.post(url, request).toPromise());
    return response;
  }

  async validateStagedUser(request: ValidateNewAccountRequest){
    const url = `${this.constantsService.constantsData.apiUrl}/users/validateStagedUser?ts=${Date.now()}`;
    const response = await (this.httpClient.post(url, request).toPromise());
    return response;
  }

  async getPasswordRequirements() {
    const url = `${this.constantsService.constantsData.apiUrl}/users/passwordRequirements?ts=${Date.now()}&type=password`;
    const response = await (this.httpClient.get(url).toPromise()) as PasswordRequirements;
    return response;
  }

  async activateAccount(request: ActivateAccountRequest) {
    const url = `${this.constantsService.constantsData.apiUrl}/users/activateUser?ts=${Date.now()}`;
    const response = await (this.httpClient.post(url, request).toPromise());
    return response;
  }

}
