import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rad-image-instructional-video-modal',
  templateUrl: './rad-image-instructional-video-modal.component.html',
  styleUrls: ['./rad-image-instructional-video-modal.component.scss'],
})
export class RadImageInstructionalVideoModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
