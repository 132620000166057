import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

/**
 * ID: bh-content-search
 * Name: BH Content Search
 * Description: Displays standardized search bar
 * Version: 2
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-10-11 - MW - v2: Added focus style and clear-focus
 */
@Component({
  selector: 'bh-content-search',
  templateUrl: './bh-content-search.component.html',
  styleUrls: ['./bh-content-search.component.scss'],
})
export class BhContentSearchComponent implements AfterViewInit, OnChanges {

  @Input() placeholder = 'Search rosters';
  @Input() layout: 'full' | 'compact' = 'full';
  @Input() showSearchButton = true;
  @Input() disabled = false;
  @Input() searchTerm = '';
  @Input() animated = false;
  @Output() searchEvent = new EventEmitter();
  @ViewChild('searchInput') searchInput: any;
  @ViewChild('searchAnimated') searchAnimated: any;
  isFocused = false;
  timer = null;
  filled = false;

  constructor() { }

  ngAfterViewInit() {
    setTimeout(() => {
      console.log('searchInput', this.searchInput.nativeElement);
      this.searchInput.nativeElement.focus();
      // this.searchAnimated.nativeElement.focus();
      //console.log('searchInput', this.searchAnimated.nativeElement);
    }, 400);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('searchTerm' in changes) {
      this.searchEvent.emit(this.searchTerm);
    }
  }

  search() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.searchEvent.emit(this.searchTerm);
    }, 500);
  }

  clearSearch() {
    this.searchTerm = '';
    this.searchInput.nativeElement.focus();
    // this.searchAnimated.nativeElement.focus();
    this.search();
  }

  onFocus(isFocused) {
    this.isFocused = isFocused;
  }

}
