import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { HealthDictionaryFreeText } from '../model/HealthDictionary';

@Injectable({
  providedIn: 'root'
})
export class HealthDictionaryService {

  constructor(private _httpBaseService: HttpBaseService
    ) { }

  async searchDictionary(term:string, user?: UserIdentifier) {
    const response = await this._httpBaseService.getList('/ehr/reference/freetext?context=freetext&term='+term, true, user);
    return response as HealthDictionaryFreeText;
  }
}
