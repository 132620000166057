import { Component, OnInit, Input, HostBinding, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { StateService } from 'src/app/service/state.service';
import { AccountSummaryService } from 'src/app/service/account-summary.service';
import { Tile } from "../../../model/Tile";
import { HomeService } from 'src/app/service/home.service';
import { NewsService } from 'src/app/service/news.service';
import { EventsService } from 'src/app/service/events.service';
import { MessagesService } from 'src/app/service/messages.service';
import { BhanalyticsService } from "../../../service/bhanalytics.service";
import { AuthenticateService } from 'src/app/service/authenticate.service';
import { formatDate } from '@angular/common';

import { RolesService } from 'src/app/service/roles.service';
import { Subscription } from 'rxjs';
import { TestResultsService } from
  'src/app/service/test-results.service';

import { AwsTranslateService } from 'src/app/service/aws-translate.service';
import { ModalController, NavController } from '@ionic/angular';
import { FeedbackPageComponent } from '../../shared/feedback/feedback.component';
import { ConsoleDataService } from 'src/app/service/console-data.service';
import { ConsoleContent } from 'src/app/model/ConsoleContent';
import moment from 'moment';
import { TaskListState } from 'src/app/model/Task-List-State';
import { DateHandlerService } from 'src/app/service/date-handler.service';
import { environment } from 'src/environments/environment';
import { Article } from 'src/app/model/Article';
import { DocumentsService } from 'src/app/service/documents.service';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {
  patientFullName: string;
  accountSummary: any;
  allNews: any[];
  news: any[];
  newsCurrentPage = 1;
  newsCurrentNumberOfItems: number;
  pageSize = 3;
  hasMoreNews: boolean = false;
  consoleContents: ConsoleContent[] = [];
  bannerContent: ConsoleContent;
  allEvents: any[];
  eventsCurrentNumberOfItems: number;
  events: any[];
  eventsCurrentPage = 1;
  hasMoreEvents: boolean = false;
  messageDate: string;
  unreadMessagesNumber: number;
  taskMessageDate: string;
  unreadResultsNumber: number;
  taskResultDate: string;
  taskResults: any[] = [];
  taskEvents: any[] = [];
  private _proxyOnlyUserSubscription: Subscription;
  private _tilesSubscription: Subscription;
  @Input() selectedUser: UserIdentifier;
  readonly cols = 4;
  tiles: Tile[] = [];
  showFeedbackFab = false;
  interval = null;
  lastCheckedTime: string;
  checkedTime: string;
  environment: any;
  format: string = '';
  articles: Article[] = [];
  taskEducation: any[] = [];
  taskNotes: any[] = [];
  subs: Subscription[] = [];
  xealthUrl = '';
  isLiteUser = false;
  constructor(
    private _stateService: StateService,
    private _accountSummaryService: AccountSummaryService,
    private _newsService: NewsService,
    private _eventsService: EventsService,
    private messagesService: MessagesService,
    private _bhAnalyticsService: BhanalyticsService,
    private authService: AuthenticateService,
    private _rolesService: RolesService,
    private _testResultsService: TestResultsService,
    public AWSTranslate: AwsTranslateService,
    private modalCtrl: ModalController,
    private consoleDataService: ConsoleDataService,
    private dateHandlerService: DateHandlerService,
    private documentsService: DocumentsService,
    private navCtrl: NavController
  ) {

  }

  async ngOnInit() {
    this.initTimer();
    this.getBannerInformation();
    this.getWhatsNewArticles('landing');
    // this.subscribeToXealthLink();
    await this.checkForLiteUser();
    // show different set of tiles depending on if it's a proxy only user
    this._tilesSubscription = this._rolesService.isProxyOnlyUser.subscribe(isProxyOnlyUser => {
      console.log('proxy Only user', isProxyOnlyUser);

      if (isProxyOnlyUser) {
        this.tiles = [
          { image: 'PayABill', text: 'Pay a<br/>medical bill', link: environment.PAY_URL },
          { image: 'Account', text: 'View<br/>account', link: '/authenticated/account' },
        ];
      } else {
        this.tiles = [
          { image: 'Messages', text: 'View messages', link: '/authenticated/messages/inbox', disabledForProxyOnlyUser: true },
          { image: 'SendMessage', text: 'Send a message<br/>to my care team', link: 'newMessage: default' }, // /authenticated/messages/compose-message
          { image: 'RequestAppointment', text: 'Request an<br/>appointment', link: 'newMessage: appt-request' }, // /authenticated/health-record/visits/request-appointment/apt-select-provider
          { image: 'Medicines', text: 'Request a<br/>prescription refill', link: 'newMessage: rx-refill', hideForLiteUser: this.isLiteUser }, // /authenticated/health-record/medicines/request-prescription/rx-select-medicine
          { image: 'PayABill', text: 'Pay a<br/>medical bill', link: environment.PAY_URL },
          { image: 'HealthRecord', text: 'View<br/>health record', link: '/authenticated/health-record' },
          { image: 'TestResults', text: 'View test<br/>results', link: '/authenticated/health-record/test-results' },
          { image: 'ShareRecord', text: 'Share my <br>health record', link: '/authenticated/health-record/share/options' },
          { image: 'Vaccinations', text: 'Vaccinations', link: '/authenticated/health-record/vaccinations' },
          // Old form: PtPortalProxy2020Eng
          { image: 'ManageProxy5-FINAL', text: 'Request proxy access', link: '/authenticated/resources/forms-list/Updated22patportproxyen' },
          { image: 'digital-care', text: 'View Digital Care and Education', link: 'xealth', isExternal: true, hideForLiteUser: false }
        ];
      }
    });

    this._stateService.getPatientName().subscribe(val => this.patientFullName = val);
    this.accountSummary = await this._accountSummaryService.getSummary();
    this._stateService.selectedUser.subscribe(async selectedUser => {
      this.accountSummary = await this._accountSummaryService.getSummary(selectedUser);
    });

    let news = await this._newsService.getAllnews();

    if (news) {
      this.allNews = news["children"];
      this.newsCurrentNumberOfItems = this.newsCurrentPage * this.pageSize;
      this.news = this.allNews.slice(0, this.newsCurrentNumberOfItems);
      this.hasMoreNews = this.allNews.length > this.newsCurrentNumberOfItems;
    }

    let events = await this._eventsService.getUpcomingEvents();

    if (events) {
      this.allEvents = events;
      this.eventsCurrentNumberOfItems = this.eventsCurrentPage * this.pageSize;
      this.events = this.allEvents.slice(0, this.eventsCurrentNumberOfItems);
      this.hasMoreEvents = this.allEvents.length > this.eventsCurrentNumberOfItems;
      this.taskEvents = this.events;
      this.checkedTime = moment().format("MM/DD/YYYY hh:mm a");
      this.updateTimeStamp(this.checkedTime);
      console.log('checking timer of event', this.checkedTime);
    }
    try {
      this.setUnreadMessagesQuantity();
      this.setNewTestResultsQuantity();
      this.loadReports();
      this.loadNotes();
    } catch (err) {
      console.log('Error: Unable to load data', err);
    }
    /* for language translation of news items */

  }

  ngOnDestroy(): void {
    this.subs.forEach(s => {
      s.unsubscribe();
      s = null;
    });
    if (this._proxyOnlyUserSubscription) this._proxyOnlyUserSubscription.unsubscribe();
    if (this._tilesSubscription) this._tilesSubscription.unsubscribe();
    this.killTimer();
  }

  // subscribeToXealthLink() {
  //   this.subs.push(
  //     this._stateService.xealthLink.subscribe(s => {
  //       this.xealthUrl = s;
  //       this.checkTile();
  //     })
  //   );
  // }

  async checkForLiteUser(): Promise<boolean> {
    try {
      const userRoles = await this._rolesService.getRoles();
      console.log('checkForLiteUser: userRoles', userRoles);
      if (userRoles) {
        const intersection = userRoles.uniqueIntersection(['LITEUSER']);
        this.isLiteUser = intersection.length > 0;
        // this.isLiteUser = true; // Testing only
      }
      return Promise.resolve(true);
    } catch (err) {
      console.error(err);
    }
  }

  ionViewWillEnter() {

  }

  killTimer() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  initTimer() {
    if (!this.interval) {
      this.interval = setInterval(() => {
        this.updateTimeStamp(this.checkedTime);
        console.log('timer has been reset', this.checkedTime);
      }, 60000);
    }
  }

  ionViewWillLeave() {
  }

  loadMoreNews() {
    this._bhAnalyticsService.clickEvent('Load More News', '');
    this.newsCurrentPage++;
    this.newsCurrentNumberOfItems = this.newsCurrentPage * this.pageSize;
    this.news = this.allNews.slice(0, this.newsCurrentNumberOfItems);
    this.hasMoreNews = this.allNews.length > this.newsCurrentNumberOfItems;
  }

  loadMoreEvents() {
    this._bhAnalyticsService.clickEvent('Load More Events', '');
    this.eventsCurrentPage++;
    this.eventsCurrentNumberOfItems = this.eventsCurrentPage * this.pageSize;
    this.events = this.allEvents.slice(0, this.eventsCurrentNumberOfItems);
    this.hasMoreEvents = this.allEvents.length > this.eventsCurrentNumberOfItems;
  }

  async setUnreadMessagesQuantity() {
    this.authService.getCachedAuthStatus().subscribe(async authStatus => {
      if (authStatus != null && authStatus.TermsAccepted == 1) {
        // only check for unread messages if it's not a proxy only user
        this._proxyOnlyUserSubscription = this._rolesService.isProxyOnlyUser.subscribe(async isProxyOnlyUser => {
          if (isProxyOnlyUser) return;
          let unreadMessages = (await this.messagesService.inbox()).children.map(item => ({
            unread: !item.IsRead,
            from: item.From,
            subject: item.Subject,
            date: item.Time,
            id: item.id
          })).filter(message => message.unread);
          this.tiles[0].quantityIcon = unreadMessages.length;
          if (unreadMessages.length > 0) {
            this.unreadMessagesNumber = unreadMessages.length;
            this.taskMessageDate = unreadMessages.sort((a, b) => b.date > a.date ? 1 : -1).toString();
            this.taskMessageDate = unreadMessages.slice(0, 1).shift().date;
          }
          this.checkedTime = moment().format("MM/DD/YYYY hh:mm a");
          this.updateTimeStamp(this.checkedTime);
        });
      }
    });
  }

  openWhatsNew() {
    this._bhAnalyticsService.clickEvent('Home: whats-new', '');
    this.navCtrl.navigateRoot('/authenticated/whats-new');
  }

  async loadReports(filter?: Date | null) {
    const res = await (this.documentsService.getAppointments());
    console.log('task education', res.Documents);
    if (res && res.Documents) {
      const nowMoment = moment();
      this.taskEducation = res.Documents.filter(
        d => d.DocumentName.trim().toLowerCase().indexOf('krames patient education') > -1 &&
          nowMoment.diff(moment(d.Timestamp, 'YYYY-MM-DD HH:mm:ss'), 'days') < 30
      );
    }
  }

  async loadNotes(filter?: Date | null) {
    const res = await (this.documentsService.getAppointments());
    if (res && res.Documents) {
      const nowMoment = moment();
      this.taskNotes = res.Documents.filter(
        d => nowMoment.diff(moment(d.Timestamp, 'YYYY-MM-DD HH:mm:ss'), 'days') <= 14
      );
    }
  }

  async setNewTestResultsQuantity() {
    const testResultsTile = this.tiles.find(tile => tile.link == '/authenticated/health-record/test-results');
    if (testResultsTile) testResultsTile.quantityIcon = (await this._testResultsService.getNewTestResultsCount());
    this.unreadResultsNumber = (await this._testResultsService.getNewTestResultsCount());
    this.taskResults = (await this._testResultsService.getTestResults());
    let dateArr = [];
    this.taskResults.map(obj => {
      dateArr.push(obj.EnteredTime);
      dateArr.sort((a, b) => b > a ? 1 : -1);
    });
    dateArr.slice(0, 1).shift();
    this.taskResultDate = dateArr[0];
    const checkedTime = moment().format("MM/DD/YYYY hh:mm a");
    this.updateTimeStamp(checkedTime);
    //console.log('task result', this.taskResults);
  }

  formatEventDate(string: string): string {
    const iso = string.slice(0, 10) + 'T' + string.slice(11, 19);
    return formatDate(iso, 'MMMM dd, yyyy', 'en-us');
  }

  captureAnalytics(eventName, eventDetail) {
    this._bhAnalyticsService.clickEvent(eventName, eventDetail);
  }

  async openFeedback() {
    this._bhAnalyticsService.clickEvent('test-result-page: give feedback', 'testing');
    this.showFeedbackFab = false;
    const modal = await this.modalCtrl.create({
      component: FeedbackPageComponent,
      componentProps: {
        feedbackHeading: 'How likely is it that you would recommend this experience to a friend or family member?',
        feedbackCode: 'myBaystate',
        //userId: this.appStateService.covidTestResultState.analyticsPrefix
      },
      backdropDismiss: true
    });

    modal.present();
  }

  async getBannerInformation() {
    try {
      const res = await this.consoleDataService.getSlides(1).toPromise();
      const bannerData = res.x_result.filter(row => row.ps_seq === 99)
      this.consoleContents = bannerData;
      this.bannerContent = this.translateBannerValue(this.consoleContents);
    } catch (err) {
      console.error("Get banner information: ", err);
    }
  }

  async refreshBannerInfo() {
    try {
      const res = await this.consoleDataService.getSlides(1).toPromise();
      if (res) {
        const bannerData = res.x_result.filter(row => row.ps_seq === 99)
        this.consoleContents = bannerData;
        this.bannerContent = this.translateBannerValue(this.consoleContents);
      } else {
        console.error("There is an error");
      }
    } catch (err) {
      console.error(err);
    }
  }

  translateBannerValue(contents): ConsoleContent {
    const matches = contents;
    return matches.length > 0 ? matches[0] : null;
  }

  updateTimeStamp(checkedTime) {
    this.lastCheckedTime = this.dateHandlerService.getElapsedTime(checkedTime).toString();
  }

  async getWhatsNewArticles(articleFormat: string): Promise<boolean> {
    this.format = articleFormat;
    console.log('format', this.format);
    try {
      const res = await this.consoleDataService.getSlides(1).toPromise();
      if (res && res.x_result) {
        this.articles = res.x_result.filter(row => row.item_type === 'A');
        console.log('active slides news articles', this.articles);
        return Promise.resolve(true);
      }
      // this.bannerContent = this.translateBannerValue(this.consoleContents);
    } catch (err) {
      console.error("Get Whats News Article: ", err);
    }
  }

}
