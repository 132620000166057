import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ConstantsService } from 'src/app/service/constants.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent  {

  constructor(
    private location: Location,
    public constantsService: ConstantsService
  ) { }

  ionViewWillEnter() {}

}
