import { Injectable } from '@angular/core';
import { PersonalCommunityUser } from '../model/PersonalCommunityUser';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { BhanalyticsService } from './bhanalytics.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  public patientName = new BehaviorSubject<string>('');
  public isPrivateDevice = new BehaviorSubject<boolean>(false);
  public xealthLink = new BehaviorSubject<string>('');

  private _proxyFor = new BehaviorSubject<PersonalCommunityUser[]>(null);

  private selectedUserSubject = new Subject<UserIdentifier>();
  public selectedUser = this.selectedUserSubject.asObservable();

  constructor(
    private analyticsService: BhanalyticsService
  ) { }

  public setPatientName(name: string): void {
    this.patientName.next(name);
  }

  public getPatientName(): Observable<string> {
    return this.patientName.asObservable();
  }

  public setisPrivateDevice(isPrivateDevice: boolean): void {
    this.isPrivateDevice.next(isPrivateDevice);
  }

  public getisPrivateDevice(): Observable<boolean> {
    return this.isPrivateDevice.asObservable();
  }

  public setSelectedUser(userIdentifier: UserIdentifier) {
    this.selectedUserSubject.next(userIdentifier);
  }

  public setUserId(userId: number): void {
    if (userId) {
      this.analyticsService.loginEvent(userId);
      sessionStorage.setItem('userid', userId.toString());
    } else {
      this.analyticsService.analyticsData.userid = null;
      sessionStorage.setItem('userid', "");
    }

  }

  public getUserId() {
    return parseInt(sessionStorage.getItem('userid'));
  }

  public getXealthLink() {
    return this.xealthLink.getValue();
  }

  public setXealthLink(url: string) {
    this.xealthLink.next(url);
  }

}
