import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Workflow} from "../../../../model/Workflow";
import {SmcTableData} from "../../../../model/SmcTableData";
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {PrescriptionStateService} from "../../../../service/prescription-state.service";
import {FormControl, FormGroup} from "@angular/forms";
import {PrescriptionService} from "../../../../service/prescription.service";
import {Recipients} from "../../../../model/Recipients";
import {SmcTableComponent} from "../../../shared/smc-table/smc-table.component";
import { ProviderService } from 'src/app/service/provider.service';
import {BhanalyticsService} from "../../../../service/bhanalytics.service";

@Component({
  selector: 'app-rx-select-provider',
  templateUrl: './rx-select-provider.component.html',
  styleUrls: ['./rx-select-provider.component.scss'],
})
export class RxSelectProviderComponent  implements OnInit {

  public data: SmcTableData;
  public nextPageStateDisabled: boolean = false; //true;
  public search:string;

  displaySubmitError = false;

  public workflows: Workflow[] = [
    { Title: "Select medicine", Content: "", State: "past" },
    { Title: "Select provider", Content: "", State: "current" },
    { Title: "Select pharmacy", Content: "", State: "future" },
    { Title: "Your contact info", Content: "", State: "future" },
    { Title: "Review & submit", Content: "", State: "future" }
  ];

  patientForm: FormGroup = new FormGroup(
    {
    }
  );

  @ViewChild(SmcTableComponent) smcTableComponent: SmcTableComponent;
  @ViewChild('radioButtonTemplate') radioButtonTemplate: ElementRef;
  @ViewChild('favoriteProviderTemplate') favoriteProviderTemplate: ElementRef;
  @ViewChild('providerFullName') providerFullName: ElementRef;
  @ViewChild('providerLocation') providerLocation: ElementRef;


  constructor(private _prescriptionService: PrescriptionService,
              private _prescriptionStateService: PrescriptionStateService,
              public _router : Router,
              public _providerService:ProviderService,
              private _bhAnalyticsService: BhanalyticsService) {
                this._router.events.subscribe((event) => {
                  if (event instanceof NavigationEnd && event.id === 1 &&
                    event.url === event.urlAfterRedirects
                  ) {
                    this._router.navigateByUrl('/authenticated/health-record/medicines/request-prescription/rx-select-medicine');
                  }
                });
              }

  async ngOnInit() {
    this.data = new SmcTableData();
    this.data.columns = [
      { name:'', cellTemplate:this.radioButtonTemplate  ,maxWidth:50, collapseCol: 0   },
      { name:'Provider',cellTemplate:this.providerFullName, cellClass: 'card-bold', collapseCol: 1, collapseMinWidth: 200  },
      { name:'Location',cellTemplate:this.providerLocation, cellClass: 'card-detail', collapseCol: 1, collapseMinWidth: 200  },
      { name:'Favorite?', prop:'Favorite', cellTemplate:this.favoriteProviderTemplate, width:100, cellClass: 'favorite-column', collapseCol: 2   }
    ];
    this.data.rows = [];

    await this.searchProvider('');

    //this._prescriptionStateService.state.provider = "45";
    this._prescriptionStateService.loadForm(this.patientForm);
    this._prescriptionStateService.updateWorkflowContents(this.workflows);
    this.formStateChange();

  }

  formStateChange() {
    //this.nextPageStateDisabled = true;
    if (this.smcTableComponent.selected.length > 0) {
      //this.nextPageStateDisabled = false;
    }
  }

  previousPage() {
    this._prescriptionStateService.saveForm(this.patientForm)
    this._router.navigateByUrl('/authenticated/health-record/medicines/request-prescription/rx-select-medicine');
  }

  nextPage() {

    if (!this._prescriptionStateService.state.provider) {
      this.displaySubmitError = true;
    } else {
      this.displaySubmitError = false;
      this._prescriptionStateService.saveForm(this.patientForm)
      this._router.navigateByUrl('/authenticated/health-record/medicines/request-prescription/rx-select-pharmacy');
    }
  }


  async searchProvider(data) {
    if(data){
      this.search = data;
      this._bhAnalyticsService.searchEvent(data);
      // console.log("SEARCH: " + data);
       this.smcTableComponent.rows = await this._prescriptionService.getProviders(data);
       this.formStateChange();
    }else{
      this.smcTableComponent.rows = await this._providerService.getFavoriteProviders()
    }
  }

  selectProvider(data) : void {
    this._bhAnalyticsService.clickEvent('select provider', data.selected[0]);
    //console.log("PROVIDER: " + JSON.stringify(data.selected[0].Provider));
    //console.log("PROVIDER: " + data.selected[0].Provider.ID);
    //this._prescriptionStateService.state.provider = data.selected[0].Provider.ID;
    this._prescriptionStateService.state.provider = JSON.stringify(data.selected[0]);
    this.formStateChange();
  }

  async addToFavorites(row){
    this._bhAnalyticsService.clickEvent('add to favorites', row.Provider.ID);
    await this._providerService.addFavoriteProvider([row.Provider.ID]);
    await this.searchProvider(this.search );
  }

  async removeFromFavorites(row){
    this._bhAnalyticsService.clickEvent('remove from favorites', row.Provider.ID);
    await this._providerService.deleteFavoriteProvider([row.Provider.ID]);
    await this.searchProvider(this.search );
  }

  getProviderName(row){
    let providerFullName = "";
    if(row.Provider.Title){
      providerFullName = row.Provider.Title + " ";
    }
    providerFullName = providerFullName + row.Provider.FirstName + " " + row.Provider.LastName;

    if(row.Provider.Suffix){
      providerFullName = providerFullName + " " + row.Provider.Suffix;
    }

    return providerFullName;
  }
}
