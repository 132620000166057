import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private _toastController: ToastController,
              private _constantsService: ConstantsService) { }

  public async openToast(options: ToastOptions = {}){
    const defaults: ToastOptions = {};
    defaults.position = options.position || 'middle';
    defaults.header = options.header != null ? options.header : "Success";
    defaults.message = options.message != null ? options.message : "Success";
    defaults.color = options.color || 'success';
    defaults.duration = options.duration || this._constantsService.constantsData.defaultToastLength;
    defaults.cssClass = options.cssClass || [];
    defaults.buttons = [];

    if (defaults.cssClass == 'success-toast') defaults.buttons.push({
      side: 'start',
      icon: 'checkmark'
    });

    if (options.buttons) defaults.buttons.push(...options.buttons);
    const toast = await this._toastController.create(defaults);
    toast.present();
    return toast;
  }
}
