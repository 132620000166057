import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PasswordRequirements } from 'src/app/model/PasswordRequirements';
import { ActivateAccountService } from 'src/app/service/activate-account.service';
import { AccountService } from 'src/app/service/account.service';
import { ModalService } from 'src/app/service/modal.service';
import { ModalType } from 'src/app/model/ModalTypeEnum';
import {BhanalyticsService} from "../../../service/bhanalytics.service";
import {StateService} from "../../../service/state.service";
import { PasswordChangeRequest, PasswordChangeFormComponent } from '../../shared/password-change-form/password-change-form.component';
import { AwsTranslateService } from '../../../service/aws-translate.service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent  {

  @ViewChild('currentPassword') currentPassword;
  @ViewChild('newPassword') newPassword;
  @ViewChild('newPasswordConfirm') newPasswordConfirm;
  @ViewChild('passwordChangeForm') private passwordChangeForm: PasswordChangeFormComponent;

  enableSubmitButton = false;
  formData: PasswordChangeRequest;

  constructor(public modalController: ModalController
    , private activateAccountService: ActivateAccountService
    , public accountService: AccountService
    , private stateService: StateService
    , private modalService: ModalService
    , private _bhAnalyticsService: BhanalyticsService
    , public AWSTranslate: AwsTranslateService
  ) { }

  async ngOnInit() {}

  async ionViewWillEnter() {
  }

  checkFormStatus(event: PasswordChangeRequest) {
    this.formData = event;
    this.enableSubmitButton = this.formData.isValid;
  }


  dismiss() {
    this.passwordChangeForm.clear();
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  async submit() {
    this._bhAnalyticsService.clickEvent('Submit Change Password', this.stateService.getUserId());
    const response = await this.accountService.changePassword({
      CurrentPassword: this.formData.currentPassword,
      NewPassword: this.formData.newPassword,
      NewPasswordConfirm: this.formData.newPassword
    });
    if (response) {
      this.modalService.open("Success", "Your password has been updated.", ModalType.BASIC);
      this.dismiss();
    }
  }

  // async changePassword() {
  //   Object.keys(this.changePasswordForm.controls).forEach(field => {
  //     const control: any = this.changePasswordForm.get(field);
  //     control.markAsTouched({ onlySelf: true });
  //   });

  //   if (this.changePasswordFormComplete()) {
  //     const response = await this.accountService.changePassword({
  //       CurrentPassword: this.changePasswordForm.controls.CurrentPassword.value,
  //       NewPassword: this.changePasswordForm.controls.NewPassword.value,
  //       NewPasswordConfirm: this.changePasswordForm.controls.NewPasswordConfirm.value
  //     });
  //     if (response) {
  //       this.modalService.open("Success", "Your password has been updated.", ModalType.BASIC);
  //       this.dismiss();
  //     }
  //     this._bhAnalyticsService.clickEvent('Submit Change Password', this.stateService.getUserId());
  //   } else {
  //     let focusDone = false;
  //     Object.keys(this.changePasswordForm.controls).forEach(field => {
  //       const control: any = this.changePasswordForm.get(field);
  //       control.markAsDirty({ onlySelf: true });
  //       control.markAsTouched({ onlySelf: true });
  //       control.updateValueAndValidity();
  //       if (!focusDone && control.errors) {
  //         setTimeout(() => {
  //           if (control.nativeElement != undefined && typeof control.nativeElement.setFocus === "function") {
  //             // safe to use the function
  //             control.nativeElement.setFocus();
  //           }
  //         }, 500);
  //         focusDone = true;
  //       }
  //     });
  //   }

  // }

}
