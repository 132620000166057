import { Injectable } from '@angular/core';
import {HttpBaseService} from "./http-base.service";

@Injectable({
  providedIn: 'root'
})
export class HealthStatsService {

  constructor(private _httpBaseService: HttpBaseService) {}

  async getVitals() {
    const response = this._httpBaseService.getList('/ehr/category/vitals', false);
    return response;
  }

  async getChart(observations: string[]){
    const body = { 'Biomarkers' : observations };
    const response = this._httpBaseService.post(body, `/ehr/vitalsChart`, true);
    return response;
  }

  async getVitalHistory(selectedObs: string[]) {
    const response = this._httpBaseService.getList(`/ehr/vitals?obs=${selectedObs}`, true);
    return response;
  }

  getVitalValueByCode(code: string, vitals: any[], returnUnit: boolean) {
    let vital = this.getVitalByCode(code, vitals);
    if (vital) {
      if (returnUnit) {
        return vital.ObservationValue + " " + vital.ObservationUnits;
      } else {
        return vital.ObservationValue;
      }

    }

    return '';
  }

  getVitalByCode(code: string, vitals: any[]) {
    if (vitals != null) {
      return vitals.find(h => h.Code === code);
    } else {
      return [];
    }
  }
}
