import {Component, OnInit, ViewChild} from '@angular/core';
import { Location } from '@angular/common';
import {IonTabs} from "@ionic/angular";

@Component({
  selector: 'app-form',
  templateUrl: './forms-list.component.html',
  styleUrls: ['./forms-list.component.scss'],
})
export class FormsListComponent  {

  tabs = [
    {
      path: '/authenticated/resources/forms-list/forms-submission-tab',
      label: 'Find a form'
    },
    {
      path: '/authenticated/resources/forms-list/forms-submission-history-tab',
      label: 'Sent forms'
    }
  ]

  constructor(private location: Location) { }

  goBack() : void {
    this.location.back();
  }

}
