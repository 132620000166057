import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})
export class AccountSummaryService {

  constructor(
    private _httpBaseService: HttpBaseService
  ) { }

  async getSummary(user?: UserIdentifier) {
    const response = await this._httpBaseService.getList('/users/0/accountSummary', true, user);
    return response;
  }

}
