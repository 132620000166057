import { Component, OnInit, ViewChild } from '@angular/core';
import { HealthDictionaryService } from 'src/app/service/health-dictionary.service';
import { HealthDictionaryFreeText } from 'src/app/model/HealthDictionary';
import {FormsValidationService} from "../../../service/forms-validation.service";
import {BhanalyticsService} from "../../../service/bhanalytics.service";

@Component({
  selector: 'app-health-dictionary',
  templateUrl: './health-dictionary.component.html',
  styleUrls: ['./health-dictionary.component.scss'],
})
export class HealthDictionaryComponent implements OnInit {
  public results:HealthDictionaryFreeText;
  @ViewChild('searchBar') searchBar;

  constructor(private _healthDictionaryService:HealthDictionaryService,
              private _bhAnalyticsService: BhanalyticsService) { }

  ngOnInit() {

  }

  async searchDictionary(){
      this.results =await this._healthDictionaryService.searchDictionary(this.searchBar.value);
      this.searchBar.value = '';
      this._bhAnalyticsService.searchEvent(this.searchBar.value);
  }
}
