import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ShareTestResult } from 'src/app/model/ShareTestResult';
import { share } from 'rxjs/operators';
import { TestResult } from 'src/app/model/TestResult';
import { TestResultsService } from 'src/app/service/test-results.service';
import { ModalService } from 'src/app/service/modal.service';
import { ModalType } from 'src/app/model/ModalTypeEnum';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { FormsValidationService } from 'src/app/service/forms-validation.service';
import {BhanalyticsService} from "../../../../service/bhanalytics.service";
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-share-with-external-provider-modal',
  templateUrl: './share-with-external-provider-modal.component.html',
  styleUrls: ['./share-with-external-provider-modal.component.scss'],
})
export class ShareWithExternalProviderModalComponent implements OnInit {

  @ViewChild('email') email;

  shareForm: FormGroup;
  statusAllDone = false;

  @Input() testResult: TestResult;

  constructor(private _testResultsService: TestResultsService,
    private _modalService: ModalService,
    private _modalController: ModalController,
    private _router: Router,
    private formValidationService: FormsValidationService,
    private _bhAnalyticsService: BhanalyticsService,
    private _toastService: ToastService) { }

  ngOnInit() {
    this.shareForm = new FormGroup({
      Email: new FormControl('', [Validators.required,Validators.email]),
    });

    this.onFormChange();
  }

  onFormChange() {
  }

  async sendEmail() {
    this._bhAnalyticsService.clickEvent('Share Results w/Provider', this.shareForm.controls.Email.value);
    const shareEmail = new ShareTestResult();
    shareEmail.data = {email:'',HealthRecordData:null};
    shareEmail.data.email =this.shareForm.controls.Email.value;
    shareEmail.data.HealthRecordData = this.testResult;
    const response = await this._testResultsService.shareTestResultsWithProvider(shareEmail);
    if (response) {
      this.statusAllDone = true;
    }
  }

  dismiss() {
    this._modalController.dismiss();
  }

  changeFormComplete(): boolean {
    return this.shareForm.valid;
  }

  openHelpPage() {
    this._router.navigate(["help"]);
    this.dismiss();
  }

  continueFromAllDone() {
    this.statusAllDone = false;
    this.dismiss();
  }
}
