import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ConstantsService } from 'src/app/service/constants.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SymptomCheckerService } from 'src/app/service/symptom-checker.service';
import { SymptomCheckerStateService } from '../../../../service/symptom-checker-state.service';
import { AuthenticateService } from 'src/app/service/authenticate.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { insertStylesIntoIonSegmentShadowRoot } from '../sc-utils/ionSegmentShadowMethod';
import { ModalType } from 'src/app/model/ModalTypeEnum';
import { ModalService } from 'src/app/service/modal.service';
import { Workflow } from '../../../../model/Workflow';
import { MatSelectChange } from '@angular/material';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AwsTranslateService } from 'src/app/service/aws-translate.service';

type FormState = 'send-symptoms' | 'find-care';

@Component({
  selector: 'app-sc-basic-info',
  templateUrl: './sc-basic-info.component.html',
  styleUrls: ['./../symptom-checker.component.scss'],
})

export class ScBasicInfoComponent implements OnInit {

  private pregnancyElement;
  @ViewChild('pregnancy') private set _setPregnancyElement(value) {
    this.pregnancyElement = value;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private symptomCheckerStateService: SymptomCheckerStateService,
    private auth: AuthenticateService,
    private modalService: ModalService,
    private constantsService: ConstantsService,
    private sanitizer: DomSanitizer,
    private symptomCheckerService: SymptomCheckerService,
    private router: Router,
    private AWSTranslate: AwsTranslateService
  ) {
    // Get Form state from url and set it to state service
    this.activatedRoute.params.subscribe(params => { this.currentFormState = params.formState; });
    this.symptomCheckerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.constantsService.constantsData.symptomCheckerUrl);
    // If being redirected within the form wizard, send user back to first section
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.id === 1 &&
        event.url === event.urlAfterRedirects
      ) {
        this.router.navigateByUrl('/authenticated/resources/symptom-checker/sc-basic-info/' +  this.currentFormState);
      }
    });
  }

  @ViewChild('gender') private set _setSex(component)             { insertStylesIntoIonSegmentShadowRoot(component); }
  @ViewChild('pregnancy') private set _setPregnancy(component) { insertStylesIntoIonSegmentShadowRoot(component); }

  // TODO: Set form values by FormGroup.
  currentFormState: FormState;
  selectedSex: string;
  authId: number;
  selectedPregnancy: string;
  selectedAgeGroup: string;
  selectedRegion: string;
  invalidSymptoms: string[];
  showDesc: boolean;
  showDisc: boolean;
  disableNextBtn = true;
  // Default region to 'North America'.
  defaultRegion = '12';
  showEmptyFieldsError: boolean;
  showIsabelEmptyFieldsError: boolean;
  ageCanConceive = false;
  symptomCheckerUrl: SafeResourceUrl;
  // Hardcode 'Primary Care Providers' and pass into provider url.
  symptoms: any[];
  dropDownValues: any;
  suggestedDiagnosis: any;
  triageResults: any;
  predictiveText: string[] = [];
  suggestions: string[] = [];
  symptomString = '';
  _newSymptomInput;

  // Basic Info FormGroup
  public basicInfo: FormGroup = new FormGroup({
    age: new FormControl('', Validators.required),
    gender: new FormControl('', Validators.required),
    region: new FormControl('', Validators.required),
    pregnancy: new FormControl('', Validators.required),
    symptoms: new FormControl('', Validators.required)
  });

  public workflows: Workflow[] = [
    { Title: 'Tell us about your symptoms', Content: '', State: 'current' },
    { Title: 'Possible causes', Content: '', State: 'future' },
    { Title: 'Where to get care?', Content: '', State: 'future' },
  ];

  @ViewChild('newSymptom') set newSymptomInput(value) {
    this._newSymptomInput = value;
  }

  async ngOnInit() {
    // Check Formstate and change workflow.
    if (this.currentFormState === 'find-care') {
      this.workflows[1].Title = 'Where to get care?';
      this.workflows[2].Title = 'Possible causes';
    }

    this.symptoms = [];
    this.dropDownValues = await this.symptomCheckerService.getDropdownValues();
    this.symptomCheckerStateService.getCurrentSectionFormData(this.basicInfo);
    this.symptomCheckerStateService.storeDropDownlist(this.dropDownValues);
    this.getAgeCanConceive(this.basicInfo.controls.age.value);
    this.symptoms = this.basicInfo.controls.symptoms.value;

    // Put north america first and sort other regions.
    const northAmericaId = this.defaultRegion;
    this.dropDownValues.travel_history.region.sort((a, b) => a.region_name.localeCompare(b.region_name));
    const northAmericaIndex = this.dropDownValues.travel_history.region.findIndex(region => region.region_id === northAmericaId);

    if (northAmericaIndex !== -1) {
      const northAmericaObject = this.dropDownValues.travel_history.region.splice(northAmericaIndex, 1);
      this.dropDownValues.travel_history.region.splice(0, 0, northAmericaObject[0]);
      this.basicInfo.controls.region.setValue(this.defaultRegion);
    }

    this.symptomCheckerService.getPredictiveText().then((response) => {
      this.predictiveText = response.predictive_text;
    }).catch(() => {});
  }

  addSymptom(valueString: string) {
    this.suggestions = [];
    let values = valueString.split(',');

    // strip spaces at start or end
    values = values.map(value => {
      let result = value.replace(/^ */, '');
      result = result.replace(/ *$/, '');
      return result;
    });

    for (const value of values) {
      this._newSymptomInput.el.value = '';
      for (const symptom of this.symptoms) {
        if (value.toLowerCase() === symptom.value.toLowerCase()) {
          return;
        }
      }

      // Highlight misspelled or incorrect symptoms.
      if (value !== '') {
        this.symptoms.push({ value , isValid: true });
        this.basicInfo.controls.symptoms.setValue(this.symptoms);
      }
    }
  }

  addSymptomKeyup(event: KeyboardEvent, value: string) {
    if (event.key == 'Enter') {
      this.addSymptom(value);
    } else if (this.predictiveText.length > 0) {
      if (value.length < 3) {
        this.suggestions = []; return;
      }
      const lowerCaseSearch = value.toLowerCase();
      let matchingSuggestions = this.predictiveText.filter(suggestion => suggestion.toLowerCase().includes(lowerCaseSearch));
      matchingSuggestions.sort((a, b) => a.length - b.length);
      matchingSuggestions = matchingSuggestions.slice(0, 5);
      this.suggestions = matchingSuggestions;
    }
  }

  deleteSymptom(index: number) {
    this.symptoms.splice(index, 1);
  }

  matSelectChange(event: MatSelectChange) {
    event.source._elementRef.nativeElement.parentElement.parentElement.parentElement.classList.add('value-selected');
  }

  nextPage() {
    // 0 is isabel code for not specified
    if (this.basicInfo.controls.gender.value !== '1' || !this.ageCanConceive) {
      this.basicInfo.controls.pregnancy.setValue('0');
    }

    if (!this.basicInfo.valid) {
      this.showEmptyFieldsError = true;
    } else {
      this.symptomCheckerStateService.setCurrentSectionFormData(this.basicInfo);
      this.navigateToNextSection();
    }
  }

  navigateToNextSection() {
    this.auth.loadFreshAuthStatus().subscribe(async authStatus => {
      this.authId = authStatus.EffectiveUserID;
      this.symptoms.forEach((symptom) => {
        const symptoms = symptom.value + ',';
        this.symptomString += symptoms;
      });

      const loadSuggestedDiagnosis = async () => {
        const returnValue = await this.symptomCheckerService.getSuggestedDiagnoses(this.basicInfo.controls, this.authId);
        if (!returnValue || !returnValue.diagnosis_checklist) {
          return null;
        } else {
          return returnValue;
        }
      };

      // try call twice because isabel occasionally returns an invalid response on initial call
      let suggestedDiagnosis = await loadSuggestedDiagnosis();
      if (suggestedDiagnosis === null) {
        suggestedDiagnosis = await loadSuggestedDiagnosis();
        if (suggestedDiagnosis === null) {
          this.modalService.open('Encountered an issue', 'Could not process your request, please try again in a few minutes', ModalType.BASIC);
          return;
        }
      }

      // handle no diagnoses returned by isabel
      if (suggestedDiagnosis.diagnosis_checklist.NoResult) {
        const noResultsTxtArr = suggestedDiagnosis.diagnosis_checklist.NoResult.information;
        this.getInvalidSymptoms(noResultsTxtArr);
        this.modalService.open('Encountered an issue', suggestedDiagnosis.diagnosis_checklist.NoResult.information, ModalType.BASIC);
        return;
      }
      // Highlight invalid symptoms.
      if (suggestedDiagnosis.diagnosis_checklist.please_note) {
        const pleaseNoteText = suggestedDiagnosis.diagnosis_checklist.please_note;
        this.getInvalidSymptoms(pleaseNoteText);
      }

      this.symptomCheckerStateService.setDiagnosesList(suggestedDiagnosis);
      this.router.navigateByUrl('/authenticated/resources/symptom-checker/sc-causes/' + this.currentFormState);
    });
  }

  getInvalidSymptoms(errorStr: string) {
    this.symptoms.forEach((symptom) => {
      const symptoms = symptom.value;
      if (errorStr.includes(symptoms)) {
        symptom.isValid = false;
      }
    });
  }

  ageSelected(event, id) {
    this.ageCanConceive = this.getAgeCanConceive(event.value);
  }

  getAgeCanConceive(id: string) {
    if (id) {
      const selectedAgeGroup = this.dropDownValues.age_groups.age_group.find(item => item.agegroup_id === id);
      this.ageCanConceive = true;
      return selectedAgeGroup.can_conceive === 'true';
    }
  }

}
