import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smc-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent implements OnInit {
  fullYear = new Date().getFullYear();

  constructor() { }

  ngOnInit() {}

}
