import { Injectable } from '@angular/core';
var jsep = require("jsep");

@Injectable({
  providedIn: 'root'
})
export class ExpressionParserService {

  constructor() { }

  public evalExpression(expression: string, patientForm: any): boolean {
    let evalState = false;
    //expression =  "!(model.dataField16&&model.dataField17)";
    if (expression != null) {
      //console.log("PRE: " + expression);
      let parsedExpression = expression.replace(/model.(dataField[0-9]+)/g, (a, b) => {
        //console.log("TEST");
        //console.log(patientForm);
        //(document.getElementById(fieldkey) as HTMLInputElement).checked
        let htmlInput = document.getElementById(b) as HTMLInputElement;
        if (patientForm != null && htmlInput != null && htmlInput.checked != null) {
          //console.log("VALUE: " + patientForm.get(b).value);
          return htmlInput.checked.toString();
        } else {
          return 'false';
        }
      });
      //console.log("POST: " + parsedExpression);
      //evalState = eval(parsedExpression); // eval code -- security risk -- replace with boolean expression parser.
      evalState = this.evaluateBooleanExpressionTree(jsep(parsedExpression));
      //console.log("EVAL2: " + evalState);
    }


    return evalState;
  }

  private evaluateBooleanExpressionTree(tree) {
    if (tree.type == "Literal") {
      return tree.value;
    } else if (tree.type == "LogicalExpression") {
      if (tree.operator == "&&") {
        return this.evaluateBooleanExpressionTree(tree["left"]) && this.evaluateBooleanExpressionTree(tree["right"]);
      } else {
        return this.evaluateBooleanExpressionTree(tree["left"]) || this.evaluateBooleanExpressionTree(tree["right"]);
      }
    } else if (tree.type == "BinaryExpression") {
      if (tree.operator == "&") {
        return this.evaluateBooleanExpressionTree(tree["left"]) && this.evaluateBooleanExpressionTree(tree["right"]);
      } else {
        return this.evaluateBooleanExpressionTree(tree["left"]) || this.evaluateBooleanExpressionTree(tree["right"]);
      }
    } else if (tree.type == "UnaryExpression") {
      return !(this.evaluateBooleanExpressionTree(tree["argument"]));
    } else {
      // Unexpected node parsed
      return null;
    }
  }

}
