import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from 'src/app/service/constants.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SymptomCheckerStateService } from '../../../service/symptom-checker-state.service';

type FormState = 'send-symptoms' | 'find-care';

@Component({
  selector: 'app-symptom-checker',
  templateUrl: './symptom-checker.component.html',
  styleUrls: ['./symptom-checker.component.scss'],
})
export class SymptomCheckerComponent implements OnInit {

  formState: FormState;
  symptomCheckerUrl: SafeResourceUrl;

  constructor(
    private router: Router,
    private symptomCheckerStateService: SymptomCheckerStateService,
    private constantsService: ConstantsService,
    private sanitizer: DomSanitizer
    ) {
    this.symptomCheckerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.constantsService.constantsData.symptomCheckerUrl);
  }

  ngOnInit() {
    this.symptomCheckerStateService.clearState();
  }

  goToScForms(formState: FormState) {
    // Check Form state passed and change workflow based on selection
    this.symptomCheckerStateService.setFormState(formState);
    this.router.navigateByUrl('/authenticated/resources/symptom-checker-old/sc-basic-info/' + formState);
  }
}
