import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ForgotCredentialsService } from 'src/app/service/forgot-credentials.service';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ActivateAccountService } from 'src/app/service/activate-account.service';
import { PasswordRequirements } from 'src/app/model/PasswordRequirements';
import { PasswordChangeRequest } from '../../shared/password-change-form/password-change-form.component';

/** Page for resetting password. ResetPasswordComponent is for user initiatied resets, whereas this page is for portal initiated ones, such as expiration, which do not use a token since the user already logged in. */
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

  @ViewChild('oldPassword') oldPasswordInput;
  @ViewChild('newPassword') newPasswordInput;
  @ViewChild('passwordChangeForm') passwordChangeForm;

  changePasswordForm: FormGroup;
  passwordRequirements: PasswordRequirements;
  passwordValidationIssues: string[] = [];
  enableSubmit = false;
  formData: PasswordChangeRequest;

  constructor(
    private _forgotService: ForgotCredentialsService,
    private _alertController: AlertController,
    private _router: Router,
    private _activateAccountService: ActivateAccountService
  ) { 
    this.changePasswordForm = new FormGroup({
      currentPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      newPasswordConfirm: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {}

  async ionViewWillEnter() {
    this.passwordRequirements = await (this._activateAccountService.getPasswordRequirements());
  }

  ionViewWillLeave() {
    this.passwordChangeForm.clear();
  }

  async submit() {
    const currentPassword = this.formData.currentPassword;
    const newPassword = this.formData.newPassword;
    // send request to server
    const serverResponse = await this._forgotService.changePassword(currentPassword, newPassword);
    // navigate to done page
    if (serverResponse) this._router.navigate(['/change-password-done']);
  }

  checkFormStatus(event) {
    this.formData = event;
    this.enableSubmit = this.formData.isValid;
  }

}

