import { Component, OnInit } from '@angular/core';
import { Workflow } from 'src/app/model/Workflow';
import { ConstantsService } from 'src/app/service/constants.service';

@Component({
  selector: 'app-rx-complete',
  templateUrl: './rx-complete.component.html',
  styleUrls: ['./rx-complete.component.scss'],
})
export class RxCompleteComponent  implements OnInit {

  constructor(
    public constantsService: ConstantsService
  ) {}

  public workflows: Workflow[] = [
    { Title: "Select medicine", Content: "Dr. Jones", State: "past" },
    { Title: "Select provider", Content: "", State: "current" },
    { Title: "Select pharmacy", Content: "", State: "future" },
    { Title: "Your contact info", Content: "", State: "future" },
    { Title: "Review & submit", Content: "", State: "future" }
  ];

  async ngOnInit() {}

}
