import { Component, OnInit, SecurityContext, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { HelpTopic } from '../../../model/HelpTopic';
import { HelpCategory } from '../../../model/HelpCategory';
import { forEach } from '@angular-devkit/schematics';
import { Router } from '@angular/router';
import { BhanalyticsService } from '../../../service/bhanalytics.service';
import { PopoverController } from '@ionic/angular';
import { CallUsPopoverComponent } from './call-us-popover/call-us-popover.component';
import { ConstantsService } from 'src/app/service/constants.service';
import { AuthenticateService } from '../../../service/authenticate.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpBaseService } from 'src/app/service/http-base.service';
import { ActivatedRoute } from '@angular/router';
import { PersonalInformationService } from 'src/app/service/personal-information.service';
import { AwsTranslateService } from '../../../service/aws-translate.service';


@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent {

  selectedHelpTopic: HelpTopic = null;
  consoledata: String = null;
  facilityContactInfo: FacilityContactInfoList;
  isLoggedIn: Boolean = false;
  affiliatedFacilities: any;
  flag = true;
  helpCategories: HelpCategory[] = [];

  constructor(
      private location: Location,
      private authService: AuthenticateService,
      private _bhAnalyticsService: BhanalyticsService,
      private _popoverController: PopoverController,
      public constantsService: ConstantsService,
      private _domSanitizer: DomSanitizer,
      private personalInformationService: PersonalInformationService,
      private _httpBaseService: HttpBaseService,
      private _changeDetectorRef: ChangeDetectorRef,
      private _activatedRoute: ActivatedRoute,
      private AWSTranslate: AwsTranslateService
    ) { }

  private createHelpCategories() {
    return [
      {
        CategoryId: 'helpcat1',
        CategoryName: 'About MyBaystate',
        Enabled: true,
        HelpTopics: [
          {
            Question: 'What is MyBaystate?',
            Answer: 'MyBaystate is a free and secure service for you to easily manage your health ' +
              'and the health of your family or your dependents. You can conveniently communicate with your providers ' +
              ' and access your health information anytime from a computer or mobile device.\n' +
              '<p>&nbsp;</p><p>With MyBaystate you can:</p>\n' +
              '<ul>\n' +
              '<li>Send secure messages and images to your providers</li>\n' +
              '<li>Request new appointments</li>\n' +
              '<li>Request prescription refills</li>\n' +
              '<li>Pay your medical bills</li>\n' +
              '<li>View lab and radiology results, medicines, health issues, allergies, immunizations, and discharge summaries</li>\n' +
              '<li>Fill out forms and print useful paperwork</li>\n' +
              '<li>Use symptom checker</li>\n' +
              '<li>Ask a Community Outreach Librarian medical questions to receive information from the Health Sciences Library</li>\n' +
              '<li>Search our health and wellness resources</li>\n' +
              '</ul>',
            Enabled: true
          },
          // {
          //   Question: 'Can I use MyBaystate on my phone or tablet?',
          //   Answer: `Yes! There are two ways you can easily use MyBaystate on your Apple or Android mobile device.\
          //   <ol>\
          //   <li>Recommend: Download the MyBaystate app from the <a href=\'http://bit.ly/BaystateAppStore\'>Baystate App Store</a> or using the app store on your mobile device.\
          //   \
          //      <div> <a href="${this.constantsService.constantsData.appStoreURL}" class=\'image-container\'> <img src=\'assets/img/app store.jpg\' /></a>
          //      <a href="${this.constantsService.constantsData.googlePlayURL}" class=\'image-container\'><img src=\'assets/img/google play.jpg\' /> </a><br/><br/><br/></li> \
          //   \
          //   <li>Use MyBaystate the same way you would on your desktop computer. Open a browser on your phone or tablet and type in: https://mybaystate.org</li>\
          //   <ol>`,
          //   Enabled: true
          // },
          {
            Question: 'How will using MyBaystate change the way I communicate with my providers?',
            Answer: 'Your providers and their care team will use MyBaystate to talk to you about lab and radiology results, prescriptions requests, appointment updates, and your overall health status. You can ask them questions, request appointments, and request prescription refills through MyBaystate. This means you\'ll make and receive fewer phone calls than before joining the portal, and will have improved communication with your provider and care team.',
            Enabled: true
          }
        ]
      },
      {
        CategoryId: 'helpcat2',
        CategoryName: 'Signing up',
        Enabled: true,
        HelpTopics: [
          {
            Question: 'Who can sign up for MyBaystate?',
            // Answer: 'MyBaystate is available to Baystate Health patients who are 18 years and older. A parent, legal guardian, or adult child of an elderly parent can apply for MyBaystate proxy access to manage care for a child that is under the age of 18, for a dependent, or for an elderly parent.' +
            //         ' Patients aged 12 to 18 will have access to a basic version which will provide them with resources and messaging their provider, but not clinical information, e.g. their health record, lab results, etc.',
            Answer: '<p>MyBaystate is available to Baystate Health patients who are 18 years and older.</p>' +
                    '<p>&nbsp;</p>' +
                    '<p>' +
                    'A parent, legal guardian, or adult child of an elderly parent can apply for' +
                    'MyBaystate proxy access to manage care for:' +
                    '</p>' +
                    '<ul>' +
                    '<li>a child that is under the age of 18</li>' +
                    '<li>a dependent</li>' +
                    '<li>or for an elderly parent</li>' +
                    '</ul>' +
                    '<p>' +
                    'Now, when viewing the MyBaystate account of a patient aged 12 to 18 for who you are a proxy,' +
                    'you will be able to send a secure message to their provider, request an appointment, and access' +
                    'Resources. Note that you will not have access to their clinical information on MyBaystate e.g.' +
                    'their health record, lab results, etc. during this time.' +
                    '</p>',
            Enabled: true
          },
          {
            Question: 'How do I sign up for MyBaystate?',
            Answer: `If you are an adult who is 18 years and older, you can <a href='/#/enroll/1'>sign up here</a>. Follow the instructions, then you will receive an email containing an access token and information to complete the sign-up process. You can also sign up when you check-in for a doctor's appointment at a Baystate Medical Practice, or during the registration process if you're admitted to a Baystate Health hospital.`,
            Enabled: true
          },
          {
            Question: 'How do I sign up a child (under the age of 18) or an adult whose care I manage?',
            Answer: `If you are a parent or legal guardian for (a child under the age of 18) or for an adult, you can apply for access by filling out the MyBaystate Patient Portal Proxy Request Form. Contact the patient's practice for a copy of the form, or contact the Baystate Health Information Management (HIM) Department, by calling ${this.constantsService.constantsData.contactPhoneFormatted}. You can also download and print an electronic version of the Proxy access Request Form by clicking the following links: <a href='https://mobile.baystatehealth.org/images/Misc/PatientPortalProxyaccessForm.pdf'>Patient Portal Proxy access Form (English)</a> or <a href='https://mobile.baystatehealth.org/images/Misc/PatientPortalProxyAccessFormSpanish.pdf'>Patient Portal Proxy access Form (Spanish)</a>. A parent or legal guardian does not have to be a Baystate patient in order to apply for proxy access. Submit the completed form (and any supporting guardianship documentation) to the HIM department in one of the following ways:\
              <ul>\
              <li>Fax the documents to ${this.constantsService.constantsData.faxNumberFormatted}</li>\
              <li>Scan and email the documents to the Baystate HIM Department: <a href='mailto:${this.constantsService.constantsData.proxyEmail}'>${this.constantsService.constantsData.proxyEmail}</a></li>\
              <li>Mail the documentation to the Baystate HIM Department: 361 Whitney Ave, Holyoke MA 01040 and write \"Attention Correspondence Dept\" on the envelope</li>\
              <li>Drop-off the documents at the Baystate HIM Department: 759 Chestnut Street, Springfield MA 01199 (Open 9-5 Mon-Fri) or 361 Whitney Ave, Holyoke MA 01040 (Open any time of day)</li>\
              </ul>You will receive an email from the HIM Department when you've been granted access to view the patient's account on MyBaystate.`,
            Enabled: true
          }
        ]
      },
      {
        CategoryId: 'helpcat3',
        CategoryName: 'Signing in',
        Enabled: true,
        HelpTopics: [
          {
            Question: 'I can’t remember my username or password. How do I sign in?',
            Answer: `<a href='/#/forgot-credentials'>Click here to get started</a>. This same “Forgot your username and password?” link can also be found on the patient portal login screen.`,
            Enabled: true
          },
          {
            Question: 'If I have access to a child’s account or an adult’s whose care I manage, do I need to use a different login to see their information?',
            Answer: 'No. Log in using your own username and password. You can view their account by clicking the “Switch Patient” button on the upper right side of the portal.',
            Enabled: true
          }
        ]
      },
      {
        CategoryId: 'helpcat4',
        CategoryName: 'Changing my information',
        Enabled: true,
        HelpTopics: [
          {
            Question: 'What if I can\'t see my information or if it\'s incorrect?',
            Answer: `If you can’t view your information please call the MyBaystate Patient Portal Support Team at 413-322-4357 or send an email to <a href='mailto:MyBaystate@baystatehealth.org'>MyBaystate@baystatehealth.org</a>. If your information appears to be incorrect, please request a change by completing the %LOGGEDIN1%%LOGGEDIN2% If your information from another healthcare organization is incorrect, please contact the appropriate support number listed below.${ this.generateFacilityContactInfo() }`,
            Enabled: true
          }
        ]
      },
      {
        CategoryId: 'helpcat5',
        CategoryName: 'Technology',
        Enabled: true,
        HelpTopics: [
          {
            Question: 'Which internet browser should I use to access MyBaystate?',
            Answer: 'For the best experience, we recommend using leading web and mobile browsers such as Chrome, Firefox, Safari, and Microsoft Edge.',
            Enabled: true
          },
          {
            Question: 'Trouble printing on mobile devices?',
            Answer: 'To enable printing on mobile devices, users will need to disable the pop up blocker on their mobile devices browsers settings.',
            Enabled: true
          },
          // {
          //   Question: 'Can I use MyBaystate on my mobile phone or tablet?',
          //   Answer: 'Yes! There are two ways you can easily use MyBaystate on your Apple or Android mobile device.\
          // <ol>\
          // <li>Recommend: Download the MyBaystate app from the <a href=\'http://bit.ly/BaystateAppStore\'>Baystate App Store</a> or using the app store on your mobile device.\
          // \
          //    <div> <div class=\'image-container\'> <img src=\'assets/img/app store.jpg\' /></div><div class=\'image-container\'><img src=\'assets/img/google play.jpg\' /> </div><br/><br/><br/></li> \
          // \
          // <li>Use MyBaystate the same way you would on your desktop computer. Open a browser on your phone or tablet and type in: https://mybaystate.org</li>\
          // <ol>',
          //   Enabled: true
          // }
          {
            Question: 'What is PVIX?',
            Answer: 'The Pioneer Valley Information Exchange(PVIX), is a regional Health Information Exchange that connects the Western Massachusetts Care Community providing the best possible, single patient record that optimizes the care delivery at all transitions of care across the continuum.',
            Enabled: true
          }
        ]
      },
      {
        CategoryId: 'helpcat6',
        CategoryName: 'Account Deletion',
        Enabled: true,
        HelpTopics: [
          {
            Question: 'How Do I Request Deleting My MyBaystate Account?',
            Answer: 'To request the deletion of your MyBaystate account, please reach out to Baystate Health Information Management (HIM) Department, by calling 413-322-4357.',
            Enabled: true
          },
        ]
      }
    ];
  }

  async ngOnInit() {
    // Fetch affiliated facilities and then call help categories.
    this.affiliatedFacilities = await this.patientFacilityList();
    this.helpCategories = this.createHelpCategories();
    this.facilityContactInfo = await this._httpBaseService.getList('/customapi/facilityList', false);
    // re-render content after loading facility contact information
    this.helpCategories = this.createHelpCategories();
    this._changeDetectorRef.detectChanges();
    this.checkUrlFragment();
  }

  ionViewWillEnter() {
    this.authService.getCachedAuthStatus().subscribe(authStatus => {
      if (authStatus != null) {
        this.isLoggedIn = Boolean(authStatus.LoggedIn);
        console.log(this.isLoggedIn);
      }
    });
  }

  generateFacilityContactInfo() {
    if (!this.facilityContactInfo) return '';
    let returnString = '<ul>';

    let filteredList = this.facilityContactInfo.providers.filter((provider) => !provider.name.toLowerCase().includes("baystate") && this.affiliatedFacilities.includes(provider.code));
    filteredList.sort((a,b) => { if (a.name < b.name) { return -1 } });

    for (const facility of filteredList) {
      returnString += `<li>${this._domSanitizer.sanitize(SecurityContext.HTML, facility.name)}: ${this._domSanitizer.sanitize(SecurityContext.HTML, (typeof facility.phone == 'string' && facility.phone.length > 0) ? facility.phone : 'no phone number available')}</li>`;
    }
    returnString += '</ul>';
    return returnString;
  }

  async patientFacilityList() {
    const affiliatedFacilities = [];
    // Only get patient demographics if user is logged in.
    if (this.isLoggedIn) {
        this.personalInformationService.demographics().then(demographics => {
          demographics.Patient.Identifiers.forEach((authCode) => {
            const code = authCode.AssigningAuthorityCode;
            if (code) {
              affiliatedFacilities.push(code);
            }
        });
      },
      (err) => console.log(err) );
    }
    return affiliatedFacilities;
  }

  scrollToCategory(categoryId: string): void {
    document.getElementById(categoryId).scrollIntoView(true);
  }

  onSearchbarInput(evt) {
    this._bhAnalyticsService.searchEvent(evt.target.value);
    for (let i = 0; i < this.helpCategories.length; i++) {
      for (let j = 0; j < this.helpCategories[i].HelpTopics.length; j++) {
        if ((this.helpCategories[i].HelpTopics[j].Question.toLowerCase().indexOf(evt.target.value.toLowerCase()) != -1) ||
          (this.helpCategories[i].HelpTopics[j].Answer.toLowerCase().indexOf(evt.target.value.toLowerCase()) != -1)) {
          this.helpCategories[i].HelpTopics[j].Enabled = true;
        } else {
          this.helpCategories[i].HelpTopics[j].Enabled = false;
        }
      }
    }

  }

  selectHelpTopic(helpTopic: HelpTopic): void {
    this._bhAnalyticsService.clickEvent('Select Help Topic', helpTopic.Question);
    this.selectedHelpTopic = this.selectedHelpTopic != helpTopic ? helpTopic : null;
  }

  async openContact() {
    const popover = await this._popoverController.create({
      component: CallUsPopoverComponent,
      event,
      translucent: true
    });

    return await popover.present();
  }

  getLoggedIn1Text() {
    return 'Personal Information Change Request Form.';
  }

  getLoggedIn2Text() {
    return '<a href="/#/authenticated/resources/forms-list/PtDemogChng">Personal Information Change Request Form</a>.';
  }

  updateHelpContentOnLogin(answerString: string, loggedOutReplacement: string, loggedInReplacement: string): string {
    let returnValue;
    if (this.isLoggedIn) {
      returnValue = answerString.replace('%LOGGEDIN2%', loggedInReplacement);
      returnValue = returnValue.replace('%LOGGEDIN1%', '');
    }
    else {
      returnValue = answerString.replace('%LOGGEDIN1%', loggedOutReplacement);
      returnValue = returnValue.replace('%LOGGEDIN2%', '');
    }
    // console.log("\"" + returnValue + "\"");
    return this.AWSTranslate.read(returnValue, this.AWSTranslate.langId);
  }

  checkUrlFragment(): void {
    // Check url fragment, if not undefined, scroll and open section.
    this._activatedRoute.fragment.subscribe(fragment => {
      if (fragment) {
        const selectCat = this.helpCategories.find(cat  => cat.CategoryId == fragment);
        const selectedTopic = selectCat.HelpTopics[2];
        setTimeout(() => {
          this.scrollToCategory(fragment);
          this.selectHelpTopic(selectedTopic);
        }, 500);
      }
    });
  }

}
