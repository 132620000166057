import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})
export class MedicinesService {

  constructor(private _httpBaseService: HttpBaseService
    ) { }

  async getMedicines(user?: UserIdentifier) {
    const response = await this._httpBaseService.getList('/ehr/medications?which=all', false, user);
    //console.log("getMedicines: " + JSON.stringify(response));
    return response['Medications'];
  }

}
