import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})
export class HealthConditionsService {

  constructor(private _httpBaseService: HttpBaseService
  ) { }

  async getHealthConditions(user?: UserIdentifier) {
    const response = await this._httpBaseService.getList('/ehr/category/problems', true, user);
    return response['Problems'];
  }
}
