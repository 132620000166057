import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { IonInput } from '@ionic/angular';
import { FormGroup } from "@angular/forms";
import { group } from "@angular/animations";
import { AwsTranslateService } from '../../../service/aws-translate.service';

@Component({
  selector: 'smc-text-input',
  templateUrl: './smc-text-input.component.html',
  styleUrls: ['./smc-text-input.component.scss'],
})
export class SmcTextInputComponent implements OnInit {

  public maxDate: Date = new Date();
  @Input() public formControlNameAtr: string;
  @Input() public placeholderText: string;
  @Input() public extraClass: string;
  @Input() public inputType: string;
  @Input() public sizeCategory: 'small' | 'normal' | 'large' = 'normal';
  @Input() public group: FormGroup;
  @Input() public iconName: string;
  @Input() public iconPosition: string;
  @Input() public label: string;
  @Input() public labelColumnWidth: string;
  @Input() public inputColumnWidth: string;
  @Input() public inputWidth: string;
  @Input() public alignInputRight: boolean;//for item with label only
  @Input() public required: string; // display a red asterisk with label only
  @Input() public displayErrors: string;
  @Input() public requiredError: string;
  @Input() public patternError: string;
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  inputAlignRight: string;
  /** Whether a password should be displayed as text or dots */
  showPassword = false;
  /** Whether to display the show/hide toggle */
  showPasswordControls: boolean;

  constructor(private _platform: Platform, private AWSTranslate: AwsTranslateService) { }

  ngOnInit() {
    if (!this.inputType) {
      this.inputType = "text";
    }

    if (!this.extraClass) {
      this.extraClass = "";
    }


    if (this.iconName) {
      this.extraClass = this.extraClass + " smc-input-with-icon";
    }

    if (this.alignInputRight) {
      this.inputAlignRight = "input-align-right";
    }

//    if (!this.labelColumnWidth) {
      this.labelColumnWidth = "200px";
 //   }
  //  if (!this.inputColumnWidth) { 
      this.inputColumnWidth = "70%";
   // }

    if(!this.inputWidth){
      this.inputWidth = "100%";
    }

    // If input type is initially password, always display the hide/show toggle even if the type changes to text later
    this.showPasswordControls = this.inputType == 'password';
  }

  dataChanged(event): void {
    this.onChange.emit(event);
  }

  grabFocus() {
    if (this.group) {
      const control: any = this.group.get(this.formControlNameAtr);
      if (control.nativeElement != undefined)  {
        const formInput = (control.nativeElement as HTMLElement);
        formInput.focus();
      }
    }
  }

  checkDateInput(datepicker: any) {
    const parentEl = datepicker.target._elementRef.nativeElement.closest('ion-item');
    const isDatepickerValid = this.group.get(this.formControlNameAtr).valid;
    // If datepicker is valid, get parent (ion-item) and style accordingly.
    if (isDatepickerValid) {
      parentEl.classList.add('ng-valid');
      parentEl.classList.add('ion-valid');
    } else {
      parentEl.classList.remove('ng-valid');
      parentEl.classList.remove('ion-valid');
      parentEl.classList.add('ng-invalid');
      parentEl.classList.add('ion-invalid');
    }
  }

  /** Toggle revealing the password */
  hideOrShowPassword() {
    this.showPassword = !this.showPassword;
    this.inputType = this.showPassword ? 'text' : 'password';
  }

}
