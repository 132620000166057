import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { first } from 'rxjs/operators';
import { Subscription, interval } from 'rxjs';
import { Route, Router } from '@angular/router';
import { PatientCheckinData, PciQueueArgs } from './../../../../model/PatientCheckinData';
import { PatientCheckinService } from './../../../../service/patient-checkin.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'bh-pci-confirmation',
  templateUrl: './pci-confirmation.component.html',
  styleUrls: ['./pci-confirmation.component.scss'],
})
export class PciConfirmationComponent implements OnInit {
  checkinData: PatientCheckinData = {};
  queueSub: Subscription;
  totalPatients: number = null;
  patientLabel = 'People';
  sub: Subscription = null;

  constructor(
    private pciService: PatientCheckinService,
    private router: Router,
    private analytics: BhanalyticsService
  ) {
    this.sub = this.pciService.checkinSubject.subscribe((data) => {
      this.checkinData = data;
      if (!this.checkinData || !this.checkinData.waiting_area || !this.checkinData.is_complete) {
        // No checkinData found, return to first step of check-in process
        this.router.navigateByUrl('/patient-checkin/checkin-now');
      } else {
        this.monitorQueue();
      }
    });
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
  }

  /**
   * Check patient's place in line every minute
   */
  monitorQueue() {
    if (!this.queueSub) {
      this.getQueue();
      const timer = interval(30000);
      this.queueSub = timer.subscribe(() => {
        this.getQueue();
      });
    }
  }

  /**
   * Get number in queue ahead of patient
   */
  getQueue() {
    const body: PciQueueArgs = {
      dli_seq: this.checkinData.dli_seq,
      pc_seq: this.checkinData.pc_seq
    };
    this.pciService.getQueueCount(body).pipe(first()).subscribe((val) => {
      this.totalPatients = this.pciService.extractNumber(val.inqueue);
      this.totalPatients = (this.totalPatients > 0) ? this.totalPatients - 1 : 0;
      this.patientLabel = (this.totalPatients === 1) ? 'Person' : 'People';
    });
  }


  /**
   * Unsubscribe from timer
   */
  ionViewWillLeave() {
    if (this.queueSub) {
      this.queueSub.unsubscribe();
      this.queueSub = null;
    }
  }

  captureAnalytics(eventName, eventDetail) {
    this.analytics.clickEvent(eventName, eventDetail);
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
    }
  }

}
