import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})
export class VaccinationsService {

  constructor(private _httpBaseService: HttpBaseService
    ) { }

  async getVaccinations(user?: UserIdentifier) {
    const response = await this._httpBaseService.getList('/ehr/category/immunizations', true, user);
    return response['Immunizations'];
  }
}