import { environment } from 'src/environments/environment';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { AuthenticateService } from 'src/app/service/authenticate.service';
import { AlertController, IonContent } from '@ionic/angular';
import { PatientCheckinService } from './../../../../service/patient-checkin.service';
import { Router } from '@angular/router';
import { PatientCheckinData, PciLocation, PciLocationGroup } from './../../../../model/PatientCheckinData';
import { Component, OnInit, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-pci-checkin-now',
  templateUrl: './pci-checkin-now.component.html',
  styleUrls: ['./pci-checkin-now.component.scss'],
})
export class PciCheckinNowComponent implements AfterViewInit {
  env = environment;
  workflowStep = 1;
  checkinData: PatientCheckinData = {};
  isLoggedIn = false;
  groups: PciLocationGroup[] = [];

  constructor(
    public router: Router,
    private pciService: PatientCheckinService,
    private alertCtrl: AlertController,
    private authenticateService: AuthenticateService,
    private analytics: BhanalyticsService
  ) {
    // this.checkForPriorRegistration();
    this.workflowStep = 1;
  }

  ngAfterViewInit() {
  }

  ionViewWillEnter() {
  }


  /**
   * Check for existing PCI registration
   */
  async checkForPriorRegistration() {
    this.checkinData = this.pciService.getCheckinData();
    if (this.checkinData.is_complete) {
      const alert = await this.alertCtrl.create({
        cssClass: 'bh-alert',
        header: 'Get in line again?',
        message: 'You recently got in line for ' + this.checkinData.group.group_display_name +
          ' work. Do you want to get in line again?',
        buttons: [
          {
            text: 'Yes, get in line again',
            handler: () => {
              this.pciService.setCheckinData({});
              this.workflowStep = 1;
              this.analytics.clickEvent('PCI-CheckInNow: Get in line again', '');
            }
          },
          {
            text: 'No, view my place in line',
            handler: () => {
              this.navigateToConfirmation();
              this.analytics.clickEvent('PCI-CheckInNow: Going to confirm page', '');
            }
          }
        ]
      });

      await alert.present();

    } else {
      // this.loadLocations();
    }
  }

  /**
   * Return to previous page
   * @param newData Patient Check-in data to update global variable
   */
  prevStep(newData: PatientCheckinData) {
    // Check for appt prompt
    if (this.workflowStep === 4) {
      this.workflowStep = newData.prompt_appointment ? 3 : 2;
    } else {
      this.workflowStep -= 1;
    }
    this.checkinData = newData;
    this.pciService.setCheckinData(this.checkinData);
    switch (this.workflowStep) {

      // Instructions
      // case 0:
      //   this.scrollToTop();
      //   break;

      // Service lines
      case 1:
        this.scrollToTop();
        break;

      // Service questions
      case 2:
        this.scrollToTop();
        break;

      // Appointment prompt
      case 3:
        this.scrollToTop();
        break;

      // Location select
      case 4:
        this.scrollToTop();
        break;
    }

  }

  /**
   * Continue to next page
   * @param newData Patient Check-in data to update global variable
   */
  nextStep(newData: PatientCheckinData) {
    if (this.workflowStep < 6) {
      // Check for appt prompt
      if (this.workflowStep === 2) {
        this.workflowStep = newData.prompt_appointment ? 3 : 4;
      } else {
        this.workflowStep += 1;
      }
    }
    this.checkinData = newData;
    this.pciService.setCheckinData(this.checkinData);
    switch (this.workflowStep) {

      // Service lines
      case 1:
        this.scrollToTop();
        break;

      // Service questions
      case 2:
        this.scrollToTop();
        break;

      // Appointment prompt
      case 3:
        this.scrollToTop();
        break;

      // Location select
      case 4:
        this.scrollToTop();
        break;

      // Patient information
      case 5:
        this.scrollToTop();
        break;

      // Confirmation
      case 6:
        // this.workflowStep = 1;
        this.navigateToConfirmation();
        break;
    }
  }

  /**
   * Scroll to top of page
   */
  scrollToTop() {
    const content = document.getElementById('contentContainer');
    if (content) {
      content.scrollTo(0, 0);
    }
  }

  navigateToConfirmation() {
    const url = (this.checkinData.is_logged_in) ?
      '/authenticated/patient-checkin/confirmation' : '/patient-checkin/confirmation';
    this.router.navigateByUrl(url);
  }

}
