import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { Storage } from '@ionic/storage';

export interface AnalyticsData {
  app_name?: string;
  app_version?: string;
  app_environment?: string;
  userid?: string;
  event_type?: string;
  event_name?: string;
  event_detail?: string;
  device_platform?: string;
  device_id?: string;
  device_os?: string;
  device_os_version?: string;
  device_orientation?: string;
  session_token?: string;
  data?: string;
  doc_type?: string;
}

@Injectable({
  providedIn: 'root'
})
export class BhanalyticsService {

  public analyticsData: AnalyticsData = {};
  private browser: any;
  private browserVersion: any;

  constructor(
    public http: Http,
    public platform: Platform,
    private device: Device,
    public storage: Storage
  ) {
    const ms = Date.parse(new Date().toISOString());
    this.analyticsData.session_token = ms.toString();
    this.getbrowserinfo();
  }

  // ******ANALYTICS Calls*******
  public screenView(screen: any) {
    // console.log('Logging screen view', screen);
    this.saveAnalytics('page-view', screen, '').subscribe(data => {
    });
  }
  public searchEvent(searchStr: any) {
    this.saveAnalytics('click', 'search', searchStr).subscribe(data => {
    });
  }
  public clickEvent(eventname: any, eventDetail: any) {
    this.saveAnalytics('click', eventname, eventDetail).subscribe(data => {
    });
  }

  async clickEventPromise(eventname: any, eventDetail: any): Promise<boolean> {
    await this.saveAnalytics('click', eventname, eventDetail).toPromise();
    return Promise.resolve(true);
  }

  public switchUser(page: string, targetUserId: any) {
    this.saveAnalytics('switch-user', page, this.analyticsData.userid + ' => ' + targetUserId).subscribe(data => {
    });
  }
  public loginEvent(userid: any) {
    this.analyticsData.userid = userid;
    this.saveAnalytics('click', 'login', userid).subscribe(data => {
    });
  }
  public customEvent(eventType: any, eventname: any, eventDetail: any) {
    this.saveAnalytics(eventType, eventname, eventDetail).subscribe(data => {
    });
  }

  // ******ANALYTICS*******
  public initAnalytics() {
    this.analyticsData.app_name = environment.APPNAME;
    this.analyticsData.app_version = environment.APPVERSION;
    this.analyticsData.app_environment = environment.ENV;
    //
    // SET THE USER ID FROM AUTH - For worktips done in home-ts
    //
    let platform_info = this.platform.platforms();
    let fullplatform = '';
    platform_info.forEach((value) => {
      // console.log(value);
      fullplatform += value + ' ';
    });
    if (fullplatform.includes('cordova')) {
      this.analyticsData.device_platform = this.device.manufacturer + ' ' + this.device.model;
      this.analyticsData.device_id = this.device.uuid;
      this.analyticsData.device_os = this.device.platform;
      this.analyticsData.device_os_version = this.device.version;
    } else {
      // Get browser info
      this.analyticsData.device_platform = fullplatform;
      this.analyticsData.device_os = this.browser;
      this.analyticsData.device_os_version = this.browserVersion;
    }
  }
  public saveAnalytics(eventtype: any, eventName: any, eventdetail: any): Observable<any> {
    if (!this.analyticsData.app_name) {
      this.initAnalytics();
    }
    if (this.platform.isLandscape()) {
      this.analyticsData.device_orientation = 'LANDSCAPE';
    } else if (this.platform.isPortrait()) {
      this.analyticsData.device_orientation = 'PORTRAIT';
    }
    //
    this.analyticsData.event_type = eventtype;
    this.analyticsData.event_detail = eventdetail;
    this.analyticsData.event_name = eventName;
    const body = this.analyticsData;
    const headers = new Headers({
      'Content-Type': 'application/json'
    });
    const options = new RequestOptions({ headers });
    const url = environment.VERLOCKER_URL + '/analytics';
    // console.log("analytics body", body);

    // return new Observable(null);
    return this.http.post(url, body, options)
      .map((res) => this.extractanalytics(res))
      .catch((err) => this.handleError(err as any));
  }

  public extractanalytics(res: Response) {
    const body = res.json();
    // console.log("analytics return body", body);
    return body || {};
  }

  // ******Error Handler*******
  handleError(error: any): Observable<any> {
    console.log(error);
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.log(errMsg);
    // do not throw error, analytics errors are not important enough to notify the user
    return new Observable();
  }
  // ******Browser Info*******
  public getbrowserinfo() {
    const unknown = '-';
    // screen
    let screenSize = '';
    let width;
    let height;
    if (screen.width) {
      width = (screen.width) ? screen.width : '';
      height = (screen.height) ? screen.height : '';
      screenSize += '' + width + ' x ' + height;
    }
    // browser
    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    let browser = navigator.appName;
    let version = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;
    // Opera
    if ((verOffset = nAgt.indexOf('Opera')) != -1) {
      browser = 'Opera';
      version = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf('Version')) != -1) {
        version = nAgt.substring(verOffset + 8);
      }
    }
    // Opera Next
    if ((verOffset = nAgt.indexOf('OPR')) != -1) {
      browser = 'Opera';
      version = nAgt.substring(verOffset + 4);
    } else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
      browser = 'Microsoft Edge';
      version = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
      browser = 'Chrome';
      version = nAgt.substring(verOffset + 7);
    } else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
      browser = 'Safari';
      version = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) != -1) {
        version = nAgt.substring(verOffset + 8);
      }
    } else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
      browser = 'Firefox';
      version = nAgt.substring(verOffset + 8);
    } else if (nAgt.indexOf('Trident/') != -1) {
      browser = 'Microsoft Internet Explorer';
      version = nAgt.substring(nAgt.indexOf('rv:') + 3);
    } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
      browser = nAgt.substring(nameOffset, verOffset);
      version = nAgt.substring(verOffset + 1);
      if (browser.toLowerCase() == browser.toUpperCase()) {
        browser = navigator.appName;
      }
    }
    // trim the version string
    if ((ix = version.indexOf(';')) != -1) { version = version.substring(0, ix); }
    if ((ix = version.indexOf(' ')) != -1) { version = version.substring(0, ix); }
    if ((ix = version.indexOf(')')) != -1) { version = version.substring(0, ix); }

    majorVersion = parseInt('' + version, 10);
    if (isNaN(majorVersion)) {
      version = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
    // mobile version
    const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);
    // cookie
    let cookieEnabled = (navigator.cookieEnabled) ? true : false;

    if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = (document.cookie.indexOf('testcookie') != -1) ? true : false;
    }
    // system
    let os = unknown;
    const clientStrings = [
      { s: 'Windows 11', r: /(Windows 13.0|Windows NT 13.0)/ },
      { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
      { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
      { s: 'Windows Vista', r: /Windows NT 6.0/ },
      { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
      { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
      { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
      { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
      { s: 'Windows 98', r: /(Windows 98|Win98)/ },
      { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
      { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: 'Windows CE', r: /Windows CE/ },
      { s: 'Windows 3.11', r: /Win16/ },
      { s: 'Android', r: /Android/ },
      { s: 'Open BSD', r: /OpenBSD/ },
      { s: 'Sun OS', r: /SunOS/ },
      { s: 'Linux', r: /(Linux|X11)/ },
      { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
      { s: 'Mac OS X', r: /Mac OS X/ },
      { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: 'QNX', r: /QNX/ },
      { s: 'UNIX', r: /UNIX/ },
      { s: 'BeOS', r: /BeOS/ },
      { s: 'OS/2', r: /OS\/2/ },
      { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
    ];
    for (const id in clientStrings) {
      const cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }

    let osVersion = '';

    let regExpResult = null;
    if (/Windows/.test(os)) {
      regExpResult = /Windows (.*)/.exec(os)
      osVersion = (regExpResult && Array.isArray(regExpResult) && regExpResult[1]) ? regExpResult[1] : 'Unknown';
      os = 'Windows';
    }

    switch (os) {
      case 'Mac OS X':
        // osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
        regExpResult = /Mac OS X ([\.\_\d]+)/.exec(nAgt);
        osVersion = (regExpResult && Array.isArray(regExpResult) && regExpResult[1]) ? regExpResult[1] : 'Unknown';
        break;

      case 'Android':
        regExpResult = /Android ([\.\_\d]+)/.exec(nAgt);
        osVersion = (regExpResult && Array.isArray(regExpResult) && regExpResult[1]) ? regExpResult[1] : 'Unknown';
        break;

      case 'iOS':
        regExpResult = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion = (regExpResult && Array.isArray(regExpResult) && regExpResult[1]) ? regExpResult[1] : 'Unknown';
        osVersion = (osVersion && regExpResult && Array.isArray(regExpResult) && regExpResult[2]) ? osVersion + '.' + regExpResult[2] : osVersion;
        break;
    }
    const browserInfo = {
      screen: screenSize,
      browser,
      browserVersion: version,
      browserMajorVersion: majorVersion,
      mobile,
      os,
      osVersion
    };
    this.browser = browser;
    this.browserVersion = version;
  }
}

