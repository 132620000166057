import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SmcTableData } from 'src/app/model/SmcTableData';
import { AccountService } from 'src/app/service/account.service';
import {BhanalyticsService} from "../../../../service/bhanalytics.service";

@Component({
  selector: 'app-account-history',
  templateUrl: './account-history.component.html',
  styleUrls: ['./account-history.component.scss'],
})
export class AccountHistoryComponent  {

  public data: SmcTableData;
  @ViewChild('dateColumnTemplate') dateColumnTemplate: ElementRef;
  constructor(private _accountService: AccountService, private _bhAnalyticsService: BhanalyticsService) { }
  showLoadMore: boolean;

  async ngOnInit() {
    this.loadData(null);
  }

  async loadData(existingRows:object[]){
    let result;
    if(existingRows){
      result = await this._accountService.getUserHistory(existingRows.length+1);
    }else{
      result = await this._accountService.getUserHistory(0);
    }

    let resultWithoutDuplicates =[];

    for (let index = 0; index < result['Events'].length; index++) {
      const element = result['Events'][index];
      if(!resultWithoutDuplicates.find(x=>x.DisplayTime==element.DisplayTime && x.User==element.User && x.DisplayType==element.DisplayType)){
        resultWithoutDuplicates.push(element);
      }
    }

    this.data = new SmcTableData();
    this.data.columns = [
      { name: 'Timestamp', prop: 'DisplayTime', cellClass: 'card-bold', cellTemplate: this.dateColumnTemplate },
      { name: 'Performed by', prop: 'User', cellClass: 'card-detail' },
      { name: 'Event', prop: 'DisplayType', cellClass: 'card-detail' }
    ];

    let rows:object[]=[];
    if(existingRows){
      rows = existingRows;
    }
    rows= rows.concat(resultWithoutDuplicates);

    this.data.rows = rows;
    this.showLoadMore = result['HasMoreRecords'];
  }

  async loadMore() {
    this.loadData(this.data.rows);
    this._bhAnalyticsService.clickEvent('more account history', '');
  }

}
