import { BhanalyticsService } from './../../../service/bhanalytics.service';
import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { SmcTableData } from 'src/app/model/SmcTableData';
import { HealthRecordService } from '../../../service/health-record.service';
import { HealthConditionsService } from 'src/app/service/health-conditions.service';
import { PrintService } from 'src/app/service/print.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-health-conditions',
  templateUrl: './health-conditions.component.html',
  styleUrls: ['./health-conditions.component.scss'],
})
export class HealthConditionsComponent implements OnInit {
  env = environment;
  category: any;
  public data: SmcTableData;
  public data2: SmcTableData;
  public facilitiesList: any[];
  @ViewChild('printSection') printSection: ElementRef;

  constructor(
    private _healthRecordService: HealthRecordService,
    private _healthConditionsService : HealthConditionsService,
    private _printService : PrintService,
    private _bhAnalyticsService: BhanalyticsService
  ) { }

  async ngOnInit() {
    const category = this.env.banner_seq.health_condition;
    console.log('cat', category);
    this.category = category;
    const rows = await this._healthConditionsService.getHealthConditions();
    const facilitiesList = await this._healthRecordService.getFacilitiesPhoneNumber();
    this.facilitiesList = facilitiesList.providers;

    this.data = new SmcTableData();
    this.data.Title = "Active";
    this.data.columns = [
      { name: 'Problem', prop: 'Problem', cellClass: 'card-bold' },
      { name: 'Category', prop: 'Category', cellClass: 'card-detail' },
      { name: 'Location', prop: 'EnteredAt', cellClass: 'card-detail' },
      { name: 'Provider Phone', prop: 'ProviderPhone', cellClass: 'card-detail' }
    ];
    this.data.rows = rows.map(row => {
      return Object.assign(row, {
        ProviderPhone: this.getFilteredFacilityNumber(row.EnteredAt.toLowerCase()),
        Problem : (typeof row.Problem === 'number' || row.Problem == '' ?  row.Details != null ?  row.Details : row.Code : row.Problem)
      });
    });
    this.data.rows = rows.filter(hc => hc.Status == "Active");

    this.data2 = new SmcTableData();
    this.data2.Title = "Historical";
    this.data2.columns = this.data.columns;
    this.data2.rows = rows.filter(hc => hc.Status != "Active");
  }

   // Return facilities phone number if locations match.
   getFilteredFacilityNumber(locationStr: string) {
    const locationName = locationStr.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
    const filteredFacility = this.facilitiesList.filter(facilityNumber => {
      const facilityName = facilityNumber.name.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
      const facilityCode = facilityNumber.code.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
      const namesMatch = (locationName.match(facilityName) || locationName.split(' ').join('').indexOf(facilityName.split(' ').join('')) > -1);
      const codeMatchesName = facilityCode.match(locationName);
      return (codeMatchesName || namesMatch);
    });
    return (filteredFacility[0] ? filteredFacility[0].phone :  '');
  }

  print(): void {
    // const table = this.printSection.nativeElement.innerHTML;
    // this._printService.printHtmlString(table);
    this._bhAnalyticsService.clickEvent('Print Health Conditions', '');
    window.print();
  }
}
