import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { HttpClient } from "@angular/common/http";
import { ConstantsService } from "./constants.service";

@Injectable({
  providedIn: 'root'
})
export class ProviderService {


  constructor(private _httpBaseService: HttpBaseService, private httpClient: HttpClient, private constantsService: ConstantsService
  ) { }

  async getAllProviders(user?: UserIdentifier) {
    const response = await this._httpBaseService.getList('/ehr/category/providers', true, user);
    return response['Providers'];
  }

  async getPrimaryProvider() {
    let allProviders = await this.getAllProviders();
    if (allProviders) {
      return allProviders.find(provider => provider.Role == "Primary Provider");
    }
    return "";
  }

  async getPrimaryProviderName() {
    let primaryProvider = await this.getPrimaryProvider();
    if (primaryProvider) {
      return primaryProvider.Name;
    }
    return "";
  }

  async deleteFavoriteProvider(providerIds: number[]) {
    let url = "/users/0/favoriteProviders?Providers=" + providerIds.join(',');
    await this._httpBaseService.delete(url);
  }

  async addFavoriteProvider(providerIds: number[]) {
    let url = "/users/0/favoriteProviders";
    await this._httpBaseService.post({ Providers: providerIds }, url);
  }

  async getFavoriteProviders() {
    const url = `${this.constantsService.constantsData.apiUrl}/messages/search/recipients`;
    const response = await this.httpClient.post(url,
      "{Favorites: true, MessageType: \"appointment\"}",
      { headers: { 'SMC-Ignore-500-Error': 'true' } }).toPromise() as any;
    if (response == undefined) {
      return [];
    }
    return response['children'];
  }
}
