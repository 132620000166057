import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { Allergy } from '../model/Allergy';
import { DateHandlerService } from './date-handler.service';


@Injectable({
  providedIn: 'root'
})
export class AllergiesService {

    constructor(
      private _httpBaseService: HttpBaseService,
      private _dateHandler: DateHandlerService
    ) { }

  async getAllergies(user?: UserIdentifier) {
    const response = await this._httpBaseService.getList('/ehr/category/allergies',true,user);
    const allergies = response['Allergies'];
    allergies.forEach((element: Allergy) => {
      if (element.EnteredTime) element.EnteredTime = this._dateHandler.parseDateString(element.EnteredTime);
    });
    return allergies as Allergy[];
  }
}
