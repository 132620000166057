import { Component, OnInit } from '@angular/core';
import { MessagesService } from 'src/app/service/messages.service';
import { SmcTableData } from 'src/app/model/SmcTableData';
import { ModalController } from '@ionic/angular';
import { NewMessagePage } from './new-message/new-message.page';
import { AwsTranslateService } from 'src/app/service/aws-translate.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {

  tabs = [
    {
      path: '/authenticated/messages/inbox',
      label: 'Inbox',
      quantityIcon:0
    },
    {
      path: '/authenticated/messages/sent',
      label: 'Sent'
    },
    {
      path: '/authenticated/messages/archived',
      label: 'Archived'
    }
  ];

  constructor(
    private messagesService: MessagesService,
    private modalCtrl: ModalController,
    public AWSTranslate: AwsTranslateService
  ) { }

  async ngOnInit() {
    this.tabs[0].quantityIcon = await this.getUnreadMessagesQuantity();
  }

  async getUnreadMessagesQuantity() {
    let unreadMessages = (await this.messagesService.inbox()).children.map(item => ({
       unread: !item.IsRead,
     })).filter(message=> message.unread);
     return unreadMessages.length;
   }

   async newMessage() {
    const modal = await this.modalCtrl.create({
      component: NewMessagePage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {

      },
    });
    modal.onDidDismiss().then(async (data) => {

    });
    await modal.present();
   }
}
