import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'bh-msg-flow-expanded-step',
  templateUrl: './bh-msg-flow-expanded-step.component.html',
  styleUrls: ['./bh-msg-flow-expanded-step.component.scss'],
})
export class BhMsgFlowExpandedStepComponent implements OnInit {
  @Input() stepNumber: number;
  @Input() heading = '';
  @Input() bannerHtml = '';
  @Input() activeSelection: number;
  @Input() displayPopover: boolean;
  
  @Output() stepNumOutput = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {
    this.stepNumberOutPut();
  }

  stepNumberOutPut(): void {
    this.stepNumOutput.emit(this.stepNumber);
  }

}
