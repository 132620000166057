import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'bh-spinner',
  templateUrl: './bh-spinner.component.html',
  styleUrls: ['./bh-spinner.component.scss'],
})
export class BhSpinnerComponent implements OnChanges {
  @Input() type: 'spinner' | 'skeleton' = 'spinner';
  @Input() color = 'primary';
  @Input() skeletonItemCount = 3;
  @Input() message = null;
  skeletonArray = [];

  constructor() {
  }

  ngOnChanges() {
    for (let i = 0; i < this.skeletonItemCount; i++) {
      this.skeletonArray.push({});
    }
  }


}
