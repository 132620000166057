import {Component, OnInit, Input, ViewChild} from '@angular/core';
import { TermsOfUseService } from 'src/app/service/terms-of-use.service';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import {Router} from "@angular/router";
import {IonButton, IonCheckbox, IonContent} from "@ionic/angular";

@Component({
  selector: 'app-login-terms-and-conditions-modal',
  templateUrl: './login-terms-and-conditions-modal.component.html',
  styleUrls: ['./login-terms-and-conditions-modal.component.scss'],
})
export class LoginTermsAndConditionsModalComponent {


  @Input() termsOfUse: any;

  @ViewChild('iReadIt') private iReadItComponent: IonCheckbox;
  @ViewChild('iAgree') private iAgreeComponent: IonButton;
  @ViewChild(IonContent) content: IonContent;

  acceptButtonEnabled: boolean = false;

  constructor(  private termsOfUseService:TermsOfUseService, private _bhAnalyticsService: BhanalyticsService, private router: Router) { }

  async ionViewWillEnter() {
    this.termsOfUse = await (this.termsOfUseService.getTermsOfUse());
    this.iAgreeComponent.disabled = true;
    this.iReadItComponent.checked = false;
  }

  async checkScrollHeight(event){
    let el = await (this.content.getScrollElement())
    if ((el.scrollTop + el.offsetHeight) >= el.scrollHeight){
      this.acceptButtonEnabled = true;
    }
  }

  toggleIReadIt() {
    if (this.iReadItComponent.checked) {
      this.iAgreeComponent.disabled = false;
    } else {
      this.iAgreeComponent.disabled = true;
    }
  }

  acceptTerms(){
    this._bhAnalyticsService.clickEvent('Accept Terms and Conditions on Login', '');
    this.termsOfUseService.updateTermsOfUse(this.termsOfUse);
    this.router.navigate(['/authenticated/home']);
  }
}
