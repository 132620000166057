import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AwsTranslateService } from 'src/app/service/aws-translate.service';

@Component({
  selector: 'app-medical-urgent-modal',
  templateUrl: './medical-urgent-modal.page.html',
  styleUrls: ['./medical-urgent-modal.page.scss'],
})
export class MedicalUrgentModalPage implements OnInit {

@Input() type: string;

  constructor(
    private modalCtrl: ModalController,
    public AWSTranslate: AwsTranslateService
  ) { }

  ngOnInit() {
  }


  dismiss() {
      this.modalCtrl.dismiss();
  }
}
