import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthenticateService } from 'src/app/service/authenticate.service';
import { Location } from '@angular/common';
import { StateService } from 'src/app/service/state.service';
import { ConstantsService } from 'src/app/service/constants.service';
import { AwsTranslateService } from '../../../service/aws-translate.service';


// import Exceptions from 'src/assets/translations/exceptions.json';

@Component({
  selector: 'account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent implements OnInit {

  //languages = this.constantsService.constantsData["awsConfig"]["availableLanguages"];
  //languageName = this.constantsService.constantsData["awsConfig"]["languages"];
  // translationOverride = Exceptions.accountMenu;
  // langId = localStorage.getItem("BSPP2.0LangOpt") || "en";

  constructor(
    private popoverController: PopoverController,
    private router: Router,
    private authService: AuthenticateService,
    private locationService: Location,
    private stateService: StateService,
    private constantsService: ConstantsService,
    public AWSTranslate: AwsTranslateService
  ) { }

  ngOnInit() {}

  goTo(url: string) {
    this.router.navigate([url]);
    this.popoverController.dismiss();
  }

  async signOff() {
    await this.authService.logout();
    this.stateService.setUserId(null);
    // reload discarding hash
    window.location.assign('/');
  }

}
