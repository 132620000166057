import { BhanalyticsService } from './../../../service/bhanalytics.service';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Article } from 'src/app/model/Article';

@Component({
  selector: 'bh-whats-new-article',
  templateUrl: './bh-whats-new-article.component.html',
  styleUrls: ['./bh-whats-new-article.component.scss'],
})
export class BhWhatsNewArticleComponent implements OnChanges {

  @Input() format: string = '';
  @Input() title: string = '';
  @Input() articles: Article;

  topArticle: Article;
  miniArticles: Article;
  constructor(
    private analytics: BhanalyticsService
  ) {
  }

  ngOnChanges() {
    console.log('articles', this.articles);
    if(this.articles) {
      if(this.format === 'login') {
        this.filterArticles(this.articles);
      }
      else if(this.format === 'landing') {
        this.splitArticles(this.articles);
      }
    }
  }

  filterArticles(articles) {
    this.articles = articles.slice(0,3);
  }

  splitArticles(articles) {
    this.topArticle = articles[0];
    console.log('top article', this.topArticle);
    this.miniArticles = articles.slice(1,4);
    console.log('mini articles', this.miniArticles);
  }

  navigateToArticle(analyticsId, article) {
    this.analytics.clickEvent(analyticsId + '-' + article.title, '');
    location.href = article.redirect_url;
  }


}
