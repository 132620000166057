import { Component, OnInit } from '@angular/core';
import { PersonalInformationService } from 'src/app/service/personal-information.service';
import { Demographics } from 'src/app/model/PersonalInformation';
import { RangeValueAccessor } from '@angular/forms/src/directives';
import { SMCDataList, SMCDataListItem } from 'src/app/model/SMCDataList';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
})
export class PersonalInformationComponent  {

  personalInformation: SMCDataList;
  contactInformation: SMCDataList;
  myContacts: SMCDataList;
  enteredAt: string;

  constructor(private personalInformationService: PersonalInformationService) { }

  ionViewWillEnter() {
    this.loadData();
  }

  loadData() {
    this.personalInformationService.demographics().then(demographics => {

      this.enteredAt = demographics.Patient.EnteredAt;

      this.personalInformation = {
        options: {
          hideEmptyFields: true
        },
        items:[
          { label: 'Name', value: [demographics.Patient.Name || 'not specified'] },
          { label: 'Blank Name Reason', value: demographics.Patient.BlankNameReason },
          { label: 'Aliases', value: (demographics.Patient.Aliases || []).map(alias => alias.Name) },
          { label: 'Primary Language', value: [demographics.Patient.PrimaryLanguage] },
          { label: 'Patient Languages', value: (demographics.Patient.Languages || []).map(language => language.PreferredLanguage) },
          { label: 'Marital Status', value: [demographics.Patient.MaritalStatus] },
          { label: 'Gender', value: [demographics.Patient.Gender] },
          { label: 'Religion', value: demographics.Patient.Religion },
          { label: 'Ethnicity', value: (demographics.Patient.Races || []).map(race => race.Race) },
          { label: 'Ethnic Group', value: [demographics.Patient.Ethnicity] },
          { label: 'Date of Birth', value: [demographics.Patient.DateOfBirth] },
          { label: 'Age', value: [demographics.Patient.PatientAge] },
          { label: 'Smoking Status', value: demographics.Patient.SmokingStatus },
          { label: 'Birth Order', value: demographics.Patient.BirthOrder },
          { label: 'Birth Place', value: demographics.Patient.BirthPlace },
          { label: 'Death Location', value: demographics.Patient.DeathLocation },
          { label: 'Provider at Death', value: demographics.Patient.ProviderAtDeath },
          { label: 'Citizenship', value: demographics.Patient.Citizenship },
          { label: 'Occupation', value: demographics.Patient.Occupation },
          { label: 'Comments', value: demographics.Patient.Comments },
          { label: 'Communication Preference', value: demographics.Patient.CommunicationPref },
          { label: 'Mother\'s Full Name', value: demographics.Patient.MothersFullName },
          { label: 'Mother\'s Maiden Name', value: demographics.Patient.MothersMaidenName },
          { label: 'Protected', value: demographics.Patient.Protected },
        ]
      };

      this.contactInformation = {
        options: {
          hideEmptyFields: true
        },
        items:[
          { label: 'Mobile Phone', value: demographics.Patient.MobilePhone },
          { label: 'Home Phone', value: [demographics.Patient.HomePhone] },
          { label: 'Work Phone', value: demographics.Patient.WorkPhone },
          { label: 'Email Address', value: [demographics.Patient.Email] },
          {
            label: 'Address(es)', value: (demographics.Patient.Addresses || []).reduce((accumulator, currentValue) => {
              accumulator.push(
                currentValue.Address,
                this.formatAddressTimeframe(currentValue)
              );
              return accumulator;
            }, [])
          }
        ]
      };

    });

    this.personalInformationService.contacts().then(contacts => {

      let list = []
      contacts.Contacts.forEach(contact => {
        list = list.concat([
          { label: 'Contact', value: contact.Name },
          { label: 'Relationship', value: contact.Relationship },
          { label: 'Contact Type', value: contact.ContactType },
          { label: 'Primary Contact', value: contact.PrimaryContact },
          { label: 'Primary Language', value: contact.PrimaryLanguage },
          { label: 'Mobile Phone', value: contact.MobilePhone },
          { label: 'Home Phone', value: contact.HomePhone },
          { label: 'Work Phone', value: contact.WorkPhone },
          { label: 'Email', value: contact.Email },
          { label: 'Emergency Contact', value: (() => {
            if (parseInt(contact.EmergencyContact) === 0) return 'no';
            else if (parseInt(contact.EmergencyContact) === 1) return 'yes';
            else return contact.EmergencyContact;
          })() },
          { label: 'Address', value: [
            contact.Address,
            this.formatAddressTimeframe(contact)
          ]},
          { label: 'Gender', value: contact.Gender },
        ]);
      })

      this.myContacts = {items: list, options: {hideEmptyFields: true}};

    })
  }

  formatAddressTimeframe = (addressObject: {AddressFromTime: string, AddressToTime: string}) =>
    `(Address Start Date: ${addressObject.AddressFromTime || 'not specified'}, Address End Date: ${addressObject.AddressToTime || 'not specified'})`;

  ionViewWillLoad() {
    this.loadData();
  }

}
