import { BhanalyticsService } from './../../../service/bhanalytics.service';
import { filter } from 'rxjs/operators';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MedicinesService } from 'src/app/service/medicines.service';
import { SmcTableData } from 'src/app/model/SmcTableData';
import { DateHandlerService } from 'src/app/service/date-handler.service';
import { ConsoleContent } from 'src/app/model/ConsoleContent';
import { ConsoleDataService } from 'src/app/service/console-data.service';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { NewMessagePage } from '../messages/new-message/new-message.page';

@Component({
  selector: 'app-medicines',
  templateUrl: './medicines.component.html',
  styleUrls: ['./medicines.component.scss'],
})
export class MedicinesComponent implements OnInit {

  public data: SmcTableData;
  public data2: SmcTableData;
  @ViewChild('refillMedicationTemplate') refillMedicationTemplate: ElementRef;
  bannerContent: ConsoleContent;
  env = environment;
  category: any;
  constructor(
    private _medicinesService: MedicinesService,
    private dateHandler: DateHandlerService,
    private _bhAnalyticsService: BhanalyticsService,
    private consoleDataService: ConsoleDataService,
    private modalCtrl: ModalController
  ) { }

  async ngOnInit() {
    const category = this.env.banner_seq.medicine;
    console.log('cat', category);
    this.category = category;
    const rows = await this._medicinesService.getMedicines();
    const historicalRows = rows.filter(medicine => medicine.ToTime != "" && new Date() > this.dateHandler.parseDateString(medicine.ToTime));
    this.data = new SmcTableData();
    this.data.Title = 'Active';
    this.data.columns = [
      { name: 'Medication', prop: 'Medication', cellClass: 'card-bold bold', width: 150, minWidth: 150, maxWidth: 330 },
      { name: 'Instructions', prop: 'Instructions', cellClass: 'card-detail', width: 450, minWidth: 450 },
      { name: 'Refill prescription', prop: 'key', cellTemplate: this.refillMedicationTemplate, width: 175, minWidth: 175, maxWidth: 175, cellClass: 'center-refill-icon', sortable: false },
    ];
    this.data.rows = rows.filter(medicine => medicine.ToTime == "" || new Date() < this.dateHandler.parseDateString(medicine.ToTime));

    // Only show historical table if rows are present.
    if (historicalRows.length > 0) {
      this.data2 = new SmcTableData();
      this.data2.Title = 'Historical';
      this.data2.columns = this.data.columns;
      this.data2.rows = historicalRows;
    }
    this.getMessageInformation();
  }

  print() {
    this._bhAnalyticsService.clickEvent('Print Medicines', '');
    window.print();
  }

  async getMessageInformation() {
    try {
      // const res = await this.consoleDataService.getSlides(1).toPromise();
      // const bannerData = res.x_result.filter(row => row.ps_seq === 150 && row.active === 1);
      // this.bannerContent = bannerData[0];
      // console.log('banner Content', this.bannerContent);
      //this.consoleContents = bannerDat

    } catch (err) {
      console.error("Get banner information: ", err);
    }
  }

  async refill(prescription) {
    this._bhAnalyticsService.clickEvent('Medicines: Refill selected: ', '');
    const modal = await this.modalCtrl.create({
      component: NewMessagePage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        mode: 'rx-refill',
        prescription
      },
    });
    modal.onDidDismiss().then(async (data) => {

    });
    await modal.present();
  }

  async requestRefill() {
    this._bhAnalyticsService.clickEvent('Medicines: Request new refill: ', '');
    const modal = await this.modalCtrl.create({
      component: NewMessagePage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        mode: 'rx-refill'
      },
    });
    modal.onDidDismiss().then(async (data) => {

    });
    await modal.present();
  }


}
