import { Component, OnInit, AfterViewInit, Input, HostBinding, HostListener, ElementRef, Renderer, TemplateRef, OnChanges, OnDestroy } from '@angular/core';
import { element } from "protractor";
import { Tile } from "../../../model/Tile";
import { Router } from "@angular/router";
import { RolesService } from 'src/app/service/roles.service';
import { RoleType } from 'src/app/model/RoleType';
import { BhanalyticsService } from "../../../service/bhanalytics.service";
import { LockedTileModalComponent } from './locked-tile-modal/locked-tile-modal.component';
import { AlertController, ModalController } from '@ionic/angular';
import { ModalService } from 'src/app/service/modal.service';
import { AwsTranslateService } from '../../../service/aws-translate.service';
import { NewMessagePage } from '../../page/messages/new-message/new-message.page';
import { StateService } from 'src/app/service/state.service';
import { Subscription } from 'rxjs';
import { XealthService } from 'src/app/service/xealth.service';

@Component({
  selector: 'smc-navigation-grid',
  templateUrl: './smc-navigation-grid.component.html',
  styleUrls: ['./smc-navigation-grid.component.scss']
})
export class SMCNavigationGridComponent implements OnInit {

  @Input() tiles: Tile[];
  @Input() cols: string;
  subs: Subscription[] = [];

  //public colSpan: number;

  public innerWidth: any;

  public currentSize = "sizeNormal";
  public sizeNormal = 3;
  public sizeXl = 3;
  public sizeLg = 4;
  public sizeMd = 4;
  public sizeSm = 4;
  public sizeXs = 4;

  constructor(private el: ElementRef,
    private renderer: Renderer,
    private router: Router,
    private rolesService: RolesService,
    private _bhAnalyticsService: BhanalyticsService,
    private _modalController: ModalController,
    private _modalService: ModalService,
    private modalCtrl: ModalController,
    private stateService: StateService,
    private AWSTranslate: AwsTranslateService,
    private alertCtrl: AlertController,
    private xealthService: XealthService
  ) {
  }

  async ngOnInit() {

  }

  async filterOutNavLinksByRoles() {
    const userRoles: RoleType[] = await this.rolesService.getRoles();
    this.tiles = this.tiles.filter(tile => {
      const blockedRolesForRoute = this.rolesService.getBlockedRolesByComponent(tile.link.split("/"));
      const intersection = userRoles.uniqueIntersection(blockedRolesForRoute);
      const allowNavigate = intersection.length == 0 || tile.text == 'Vaccinations' || tile.text == 'View test<br/>results' || tile.text == 'View Digital Care and Education';
      return allowNavigate;
    });
    this.tiles = this.tiles.filter( tile => !tile.hideForLiteUser);
    //await this.checkXealthAccess();
    this.onResize();
  }

  async ngOnChanges() {
    await this.filterOutNavLinksByRoles();
    let colCount = parseInt(this.cols);
    this.sizeNormal = 12 / colCount;
    this.sizeXl = 12 / colCount;
    this.currentSize = "sizeNormal";
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth >= 0 && this.innerWidth < 576) {
      if (this.currentSize != "sizeXs") {
        this.resizeGrid(12 / this.sizeXs);
        this.currentSize = "sizeXs";
      }
    } else if (this.innerWidth >= 567 && this.innerWidth < 768) {
      if (this.currentSize != "sizeSm") {
        this.resizeGrid(12 / this.sizeSm);
        this.currentSize = "sizeSm";
      }
    } else if (this.innerWidth >= 768 && this.innerWidth < 992) {
      if (this.currentSize != "sizeMd") {
        this.resizeGrid(12 / this.sizeMd);
        this.currentSize = "sizeMd";
      }
    } else if (this.innerWidth >= 992 && this.innerWidth < 1200) {
      if (this.currentSize != "sizeLg") {
        this.resizeGrid(12 / this.sizeLg);
        this.currentSize = "sizeLg";
      }
    } else if (this.innerWidth >= 1200) {
      if (this.currentSize != "sizeXl") {
        this.resizeGrid(12 / this.sizeXl);
        this.currentSize = "sizeXl";
      }
    }
    /*
    console.log("WIDTH: " + this.innerWidth);
    xs	0
    sm	576
    md	768
    lg	992
    xl	1200
    */
  }

  async openXealth() {
    try {
      const xealthLink = await this.xealthService.getXealthLink().toPromise();
      if (xealthLink && xealthLink.url) {
        const xealthUrl = xealthLink.url;
        this.stateService.setXealthLink(xealthUrl);
        location.href = xealthLink.url;
        // this.tiles.push({ image: 'digital-care', text: 'View Digital Care and Education', link: this.xealthUrl, isExternal: true, hideForLiteUser: false });
        // this.checkTile(xealthLink.url);
      } else {
        this.showNoXealthAccess();
      }
      return Promise.resolve();
    } catch (err) {
      console.error('An error occurred generating the Xealth link.');
      this.showNoXealthAccess();
    }
  }

  async showNoXealthAccess() {
    const alert = await this.alertCtrl.create({
      header: 'View Digital Care and Education',
      message: 'You do not have Digital Care and Education content at this time. Try again later.',
      buttons: [ { text: 'Got it'}]
    });

    await alert.present();
  }

  async checkXealthAccess() {
    try {
      const xealthLink = await this.xealthService.getXealthLink().toPromise();
      if (xealthLink && xealthLink.url) {
        const xealthUrl = xealthLink.url;
        this.stateService.setXealthLink(xealthUrl);
        // this.tiles.push({ image: 'digital-care', text: 'View Digital Care and Education', link: this.xealthUrl, isExternal: true, hideForLiteUser: false });
        this.checkTile(xealthLink.url);
      }
      return Promise.resolve();
    } catch (err) {
      console.error('An error occurred generating the Xealth link.');
    }
  }

  checkTile(xealthUrl) {
    const xealthTileFound = this.tiles.find(t => t.image === 'digital-care');
    console.log('smc-nav-grid: ', xealthUrl, xealthTileFound);
    // Link available, no tile found, add it
    if (xealthUrl && !xealthTileFound) {
      this.tiles.push({ image: 'digital-care', text: 'View Digital Care and Education', link: xealthUrl, isExternal: true, hideForLiteUser: false });
    }
    // No link, but tile is available, remove it
    else if (!xealthUrl && xealthTileFound) {
      this.tiles = this.tiles.filter(t => t.image !== 'digital-care');
    }
  }


  public async click(link: string, locked: boolean) {
    this._bhAnalyticsService.clickEvent('nav grid', link);
    if (!locked) {
      if (link.startsWith('http')) {
        window.open(link, '_system');
        // window.location.href = link;
      } else if (link.startsWith('newMessage:')) {
        const msgLinkParts = link.split('newMessage: ');
        const msgMode = (msgLinkParts[1]) ? msgLinkParts[1].trim() : '';
        this.openNewMessage(msgMode);
      } else if (link === 'xealth') {
        this.openXealth();
      } else {
        this.router.navigateByUrl(link);
      }
    } else {
      await this.openLockedModal();
    }
  }

  // async openXealth() {
  //   try {
  //     const xealthLink = await this.xealth.getXealthLink().toPromise();
  //     if (xealthLink && xealthLink.url) {
  //       window.open(xealthLink.url, '_system');
  //     } else {
  //       const alert = await this.alertCtrl.create({
  //         header: 'Digital Care unavailable',
  //         message: 'You have not been assigned any Digital Care assignments at this time',
  //         backdropDismiss: false,
  //         buttons: ['Got it']
  //       });
  //       await alert.present();
  //     }
  //   } catch (err) {
  //     console.error('An error occurred generating the Xealth link.');
  //   }
  // }

  async openNewMessage(mode) {
    const modal = await this.modalCtrl.create({
      component: NewMessagePage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        mode
      },
    });
    modal.onDidDismiss().then(async (data) => {

    });
    await modal.present();

  }

  async openLockedModal() {
    const modal = await this._modalController.create({
      component: LockedTileModalComponent,
      cssClass: "modal-tile-locked"
    });
    this._modalService.register(modal);

    return await modal.present();
  }

  private resizeGrid(colCount: number) {
    let element = Array.from(this.el.nativeElement.querySelectorAll('.tile'));

    let tooltipElements = this.el.nativeElement.querySelectorAll('.tooltip');
    if (tooltipElements) {
      element = Array.from(tooltipElements).concat(element);
    }
    if (element.length != this.tiles.length) {
      element = element.filter(el => this.tiles.some(function (item) {
        return item.text.includes(el["innerText"].trim());
      }));
    }
    let elementCount = element.length;
    let rowCount = parseInt((elementCount / colCount).toFixed(0));

    let mod = ((elementCount % colCount) / colCount);
    if (mod > 0 && mod < .50) {
      rowCount++;
    }

    for (let i = 1; i <= elementCount; i++) {
      let part = element[i - 1];

      this.renderer.setElementStyle(part, 'border-right', '1px solid var(--smc-color-border)');
      if (rowCount > 1) {
        this.renderer.setElementStyle(part, 'border-bottom', '1px solid var(--smc-color-border)');
      }
      if (i % colCount == 0) {
        this.renderer.setElementStyle(part, 'border-right', 'none');
      }

      if ((i / colCount) > (rowCount - 1)) {
        this.renderer.setElementStyle(part, 'border-bottom', '1px solid var(--smc-color-border)');
      }
    }
  }
}
