import { Component, Input, OnInit } from '@angular/core';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';

@Component({
  selector: 'bh-covid-info',
  templateUrl: './bh-covid-info.component.html',
  styleUrls: ['./bh-covid-info.component.scss'],
})
export class BhCovidInfoComponent implements OnInit {
  @Input() sourcePage: string = 'undefined';

  constructor(
    private _bhAnalyticsService: BhanalyticsService,
  ) { }

  ngOnInit() {}


  openPage(url, linkName) {
    this._bhAnalyticsService.clickEvent(`${this.sourcePage}: ${linkName}`, '');
    location.href = url;
  }

}
