import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';
import { HttpClient } from '@angular/common/http';
import { Demographics } from '../model/PersonalInformation';
import { Contact, ContactList } from '../model/Contact';

@Injectable({
  providedIn: 'root'
})
export class PersonalInformationService {
  demoSubject = new BehaviorSubject<any>({});

  constructor(
    private constantsService: ConstantsService,
    private httpClient: HttpClient
  ) { }

  demographics(): Promise<Demographics> {
    const url = this.constantsService.constantsData.apiUrl + '/ehr/category/demographics';
    return this.httpClient.get<Demographics>(url, { headers: { 'SMC-Dont-Show-Loader': 'true' }}).toPromise();
  }

  contacts(): Promise<ContactList> {
    const url = this.constantsService.constantsData.apiUrl + '/ehr/category/contacts';
    return this.httpClient.get<ContactList>(url).toPromise();
  }

}
