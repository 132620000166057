import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PatientCheckinData } from 'src/app/model/PatientCheckinData';
import { Workflow } from 'src/app/model/Workflow';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormsValidationService } from 'src/app/service/forms-validation.service';
import { PatientCheckinService } from 'src/app/service/patient-checkin.service';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { AwsTranslateService } from '../../../../service/aws-translate.service';

@Component({
  selector: 'bh-pci-appointment-prompt',
  templateUrl: './pci-appointment-prompt.component.html',
  styleUrls: ['./pci-appointment-prompt.component.scss'],
})
export class PciAppointmentPromptComponent implements OnChanges {
  @Input() checkinData: PatientCheckinData;
  @Output() next: EventEmitter<PatientCheckinData> = new EventEmitter();
  @Output() back: EventEmitter<PatientCheckinData> = new EventEmitter();
  public workflows: Workflow[] = [
    { Title: 'Service', Content: '', State: 'past' },
    { Title: 'Reason for visit', Content: '', State: 'past' },
    { Title: 'Appointment', Content: '', State: 'current' },
    { Title: 'Location', Content: '', State: 'future' },
    { Title: 'Patient Info', Content: '', State: 'future' }
  ];

  form1: FormGroup = new FormGroup(
    {
      hasAppointment: new FormControl('', [Validators.required]),
    }
  );
  constructor(
    public router: Router,
    private formValidationService: FormsValidationService,
    private pciService: PatientCheckinService,
    private analytics: BhanalyticsService,
    public AWSTranslate: AwsTranslateService
  ) {
  }

  /**
   * On load, update has-appointment selection from global variable
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.checkinData) {
      this.form1.controls.hasAppointment.setValue(this.checkinData.has_appointment);
    }
  }

  /**
   * On selection of has-appointment, update check-in data
   */
  formStateChange() {
    this.checkinData.has_appointment = this.form1.controls.hasAppointment.value;
  }

  /**
   * Return to previous page
   */
  previousPage() {
    this.analytics.clickEvent('PCI-AppointmentPrompt: PreviousButton', '');
    this.back.emit(this.checkinData);
  }

  /**
   * Check form validity, continue to next page
   */
  nextPage() {
    if (this.form1.valid) {
      this.analytics.clickEvent('PCI-AppointmentPrompt: NextButton', 'Form valid');
      this.next.emit(this.checkinData);
    } else {
      this.analytics.clickEvent('PCI-AppointmentPrompt: NextButton', 'Form invalid');
      let focusDone = false;
      Object.keys(this.form1.controls).forEach(field => {
        const control: any = this.form1.get(field);
        control.markAsTouched({ onlySelf: true });
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement !== undefined && typeof control.nativeElement.setFocus === 'function') {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }
  }
}
