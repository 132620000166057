import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TestResult } from 'src/app/model/TestResult';
import { MatDatepickerInputEvent } from '@angular/material/typings';
import { TestResultsService } from 'src/app/service/test-results.service';
import { DatePipe } from '@angular/common';
import {Router} from "@angular/router";
import {BhanalyticsService} from "../../../../service/bhanalytics.service";
import { svgAsPngUri } from "save-svg-as-png";
import { DateHandlerService } from 'src/app/service/date-handler.service';
import { SmcTableData } from 'src/app/model/SmcTableData';
import { PrintService } from 'src/app/service/print.service';


@Component({
  selector: 'app-past-results-modal',
  templateUrl: './past-results-modal.component.html',
  styleUrls: ['./past-results-modal.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PastResultsModalComponent {

  @ViewChild('from') private from: any;
  @ViewChild('to') private to: any;
  @ViewChild('printSection') private printSection: any;
  @ViewChild('dateColumnTemplate') dateColumnTemplate: ElementRef;
  @Input() TestResultHistory: TestResult[];
  @Input() selectedResult: any;
  view: any[] = [1300, 200];
  modalChartData: any;
  modalTableData: SmcTableData;
  /** Error string to display below date range selector. */
  invalidDateRangeError: string = null;

  colorScheme = {
    domain: ['#55b5e6']
  };
  // line, area
  autoScale = true;

  @ViewChild('resultValueTemplate') resultValueTemplate: ElementRef;
  allResultsCache: any[];

  constructor(
    public modalController: ModalController,
    private _testResultsService: TestResultsService,
    private _datePipe: DatePipe,
    private _bhAnalyticsService: BhanalyticsService,
    private _dateHandler: DateHandlerService,
    private _printService: PrintService,
  ) { }

  ionViewWillEnter() {
    this.onResize()
    this.loadData(null, null);
  }

  dismiss() {
    this.selectedResult = null;
    this.modalController.dismiss();
  }

  /** Check if date range is valid. If invalid disable "Get results" button and show error message. */
  dateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    const fromString = this.from.nativeElement.value;
    const toString = this.to.nativeElement.value;
    if (!fromString) {
      this.invalidDateRangeError = 'Please select a start date';
      return;
    }
    if (!toString) {
      this.invalidDateRangeError = 'Please select an end date';
      return;
    }
    const fromDate = new Date(this.from.nativeElement.value);
    const toDate = new Date(this.to.nativeElement.value);
    if (toDate.getTime() <= fromDate.getTime()) {
      this.invalidDateRangeError = 'Please select an end date that is later than the selected start date';
      return;
    }
    this.invalidDateRangeError = null;
  }

  loadData(from, to) {
    this.allResultsCache = [].concat.apply([], this.TestResultHistory.map(x => x.ResultItems));
    let allResultItems = this.allResultsCache.filter(item => item.Test == this.selectedResult.Test);
    if (from != null) {
      allResultItems = allResultItems.filter(result => this._dateHandler.parseDateString(result.ObservationTime) >= this._dateHandler.parseDateString(from));
    }

    if (to != null) {
      allResultItems = allResultItems.filter(result => this._dateHandler.parseDateString(result.ObservationTime) <= this._dateHandler.parseDateString(to));
    }
    allResultItems.sort((a, b) => b.ObservationTime < a.ObservationTime ? 1 : -1);
    this.modalChartData = this.getModalChartData(allResultItems);
    this.modalTableData = this.getModalTableData(allResultItems);

    // Set "from" and "to" fields based on response data (if user has not manually selected a date range yet).
    if (allResultItems && allResultItems.length > 0) {
      if (from == null)
        this.from.nativeElement.value = this._datePipe.transform(this._dateHandler.parseDateString(allResultItems[0].ObservationTime), 'MM/dd/yyyy');
      if (to == null)
        this.to.nativeElement.value = this._datePipe.transform(this._dateHandler.parseDateString(allResultItems[allResultItems.length - 1].ObservationTime), 'MM/dd/yyyy');
    }
  }

  getParamData(data, storeParamName) {
    let vaue;
    if (data) {
      vaue = data;
      localStorage.setItem(storeParamName, JSON.stringify(vaue));
    } else {
      vaue = JSON.parse(localStorage.getItem(storeParamName));
    }

    return vaue;
  }

  onResize() {
    this.view = [window.innerWidth / 1.35, 200];
  }

  getModalChartData(items) {
    return [
      {
        "name": "",
        "series": items.map(item => ({
          name: this._datePipe.transform(this._dateHandler.parseDateString(item.ObservationTime), 'MM/dd/yy'),
          value: item.ResultValue
        }))
      }
    ];
  }

  ChekcIfHasString(code) {
    return this._testResultsService.ChekcIfHasString(code);
  }

  getModalTableData(items: any[]): SmcTableData {
    return {
      columns: [
        { name: 'Date', prop: 'ObservationTime', cellClass: 'card-bold', cellTemplate: this.dateColumnTemplate },
        { name: 'Result', prop: 'ResultValue', cellClass: this._testResultsService.getResultClass, cellTemplate: this.resultValueTemplate },
        { name: 'Interpretation', prop: 'ResultInterpretation', cellClass: this._testResultsService.getResultClass },
        { name: 'Normal Range', prop: 'ReferenceRange', cellClass: 'card-detail' }
      ],
      rows: items
    };
  }


  updateResults(from, to) {
    this._bhAnalyticsService.searchEvent(from.value + ' ' + to.value);
    this.loadData(from.value, to.value + " 11:59 pm");
  }

  async print() {
    this._bhAnalyticsService.clickEvent('Print Past Results', '');

    const chartPng = await svgAsPngUri(this.printSection.el.querySelector('svg.ngx-charts'));
    const innerHTMLContent = this.printSection.el.innerHTML;
    const printContent = `
      <style> .screen-only { display: none } </style>
      <img alt="chart" src="${chartPng}"><br>
      ${innerHTMLContent}
    `;
    this._printService.printHtmlString(printContent);

  }
}
