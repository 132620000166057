import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-request-appointment',
  templateUrl: './request-appointment.component.html',
  styleUrls: ['./request-appointment.component.scss'],
})
export class RequestAppointmentComponent implements OnInit {
          /*
          <ngx-datatable-row-detail [rowHeight]="auto">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
              <div style="padding-left:35px;">
                {{ data | json }}
              </div>
            </ng-template>
          </ngx-datatable-row-detail>
          */
  @ViewChild('table') table: any;
  rows = [];
  selected = [];

  //@ViewChild(DatatableComponent)      // import {DatatableComponent} from '@swimlane/ngx-datatable';
  //private readonly table: DatatableComponent;

  /*
  public getRowIndex(row: any): number {
    return this.table.bodyComponent.getRowIndex(row);   // row being data object passed into the template
  }
  */

  constructor() {
    this.rows = [
      { name: "name1", gender: "gender1", company: "company1", age: "age1" },
      { name: "name1", gender: "gender1", company: "company1", age: "age1" },
      { name: "name1", gender: "gender1", company: "company1", age: "age1" },
      { name: "name1", gender: "gender1", company: "company1", age: "age1" }
      ];
  }

  async ngOnInit(){}

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
    console.log("TABLE: " + JSON.stringify(this.table));
  }

  onActivate(event) {
    console.log('Activate Event', event);
    if (event.type == "click") {
      //console.log("ROWIDX: " + this.getRowIndex(event.row)) ;
      this.selected = [];
      this.selected.push(event.row);
    }
  }

}
