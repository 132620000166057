export const environment = {
  production: false,
  APPVERSION: '2.5.16',
  APPNAME: 'MYBAYSTATE',
  ENV: 'DEV',
  API_URL: 'https://mytest.baystatehealth.org/myhealth/api/v1',
  CONSOLE_URL: 'https://mobiledev.api.baystatehealth.org/mybaystate',
  DOC_REPO_URL: 'https://mytest.baystatehealth.org/myhealth',
  VERLOCKER_URL: 'https://mobiledev.api.baystatehealth.org/verlocker',
  PCI_URL: 'https://mobiledev.api.baystatehealth.org/patientcheckin',
  PCI_API_TOKEN: '2a671352-c429-4622-b727-a1d7b47e2bda', // Needed for /registerPatient
  PCI_LOC_LOOKUP_ID: 8, // Temp variable for looking up location groups
  PCI_LAB_LOC_ID: 6, // Temp variable for 3300 BRL
  PCI_RAD_LOC_ID: 7, // Temp variable for 3300 radiology
  region: 'us-east-1',  // AWS Translation
  identityPoolId: '7764ec90-c58d-4d4d-af56-cce0d5f4820b',  // AWS Translation
  PAY_URL: 'https://baystatehealth.ci.healthpay24.cloud',
  banner_seq: {
    allergy: 159,
    appointment: 170,
    concern: 160,
    health_stat: 157,
    health_condition: 158,
    goals: 162,
    medicine: 150,
    radiology: 152,
    report: 156,
    prescription: 151,
    share: 171,
    test_result: 154,
    vaccinations: 161,
    visit: 155,
  },
};
