import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bh-pci-message-banner',
  templateUrl: './pci-message-banner.component.html',
  styleUrls: ['./pci-message-banner.component.scss'],
})
export class PciMessageBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
