import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { AppointmentsResponse } from '../model/AppointmentsResponse';
import { DateHandlerService } from './date-handler.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {

  constructor(private httpClient: HttpClient,
              private constantsService: ConstantsService,
              private _dateHandler: DateHandlerService) { }

  async getAppointments() : Promise<AppointmentsResponse> {
    const url = `${this.constantsService.constantsData.apiUrl}/ehr/category/appointments?ts=${Date.now()}`;
    const response = await this.httpClient.get(url).toPromise() as AppointmentsResponse;
    response.Appointments.forEach(app => {
      app.FromTime = this._dateHandler.parseDateString(app.FromTime);
    })
    return response;
  }
}
