import { Component, OnInit, ViewChild } from '@angular/core';
import { DocumentTypeList, DownloadType, DownloadableFileReference } from 'src/app/model/ShareMyRecords';
import { ShareHealthRecordService } from 'src/app/service/share-health-record.service';
import { AlertController, IonDatetime, IonRadioGroup, IonCheckbox, ToastController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material';
import { AuthenticateService } from 'src/app/service/authenticate.service';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BhanalyticsService} from "../../../../service/bhanalytics.service";
import { ToastService } from 'src/app/service/toast.service';
import { DateHandlerService } from 'src/app/service/date-handler.service';
import * as JSZip from 'jszip';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { AwsTranslateService } from '../../../../service/aws-translate.service';
import { environment } from 'src/environments/environment';

interface DownloadReference {
  fileName: string;
  url: string;
}

interface DownloadedFile {
  fileName: string;
  file: Blob;
}

@Component({
  selector: 'app-share-health-record-options',
  templateUrl: './share-health-record-options.component.html',
  styleUrls: ['./share-health-record-options.component.scss'],
})
export class ShareHealthRecordOptionsComponent implements OnInit {
  env = environment;
  category: any;
  documentTypeList: DocumentTypeList;
  recordType: DownloadType[] = [];

  @ViewChild('startDate') private startDate: any;
  @ViewChild('endDate') private endDate: any;
  @ViewChild('recordFormat') private recordFormat: IonRadioGroup;
  @ViewChild('html') private html: IonCheckbox;
  @ViewChild('ccd') private ccd: IonCheckbox;

  form: FormGroup;
  minDate: Date;
  maxDate: Date;
  userName:string="My";
  submitType: string;

  constructor(
    private shareHealthRecordService: ShareHealthRecordService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticateService,
    private _bhAnalyticsService: BhanalyticsService,
    private toastService: ToastService,
    private dateHandler: DateHandlerService,
    private http: HttpClient,
    private AWSTranslate: AwsTranslateService
  ) {
    this.form = new FormGroup({
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      recordFormat: new FormControl('', Validators.required),
      html: new FormControl(),
      ccd: new FormControl()
    });
  }

  ngOnInit() {
    const category = this.env.banner_seq.share;
    console.log('cat', category);
    this.category = category;
    this.authService.getCachedAuthStatus().subscribe(authStatus => {
      if (authStatus != null && authStatus.PatientName) {
        if(authStatus.PatientName.indexOf(" ")>-1){
          this.userName = this.toPossessive(authStatus.PatientName.split(" ")[0]);
        }else{
          this.userName = this.toPossessive(authStatus.PatientName);
        }

      }

    });
  }

  formatNotSelected() {
    let status = (((this.form.get('html').dirty || this.form.get('html').touched) && !this.html.checked) &&
      ((this.form.get('ccd').dirty || this.form.get('ccd').touched) && !this.ccd.checked));
    //console.log("STATUS: " + status);
    return status;
  }

  async submit() {
    Object.keys(this.form.controls).forEach(field => {
      const control: any = this.form.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if(this.form.valid) {
      if (this.submitType == 'provider') {
        this.sendToProvider();
      } else {
        this.download();
      }
    } else {
      let focusDone = false;
      Object.keys(this.form.controls).forEach(field => {
        const control: any = this.form.get(field);
        control.markAsTouched({onlySelf: true});
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement != undefined && typeof control.nativeElement.setFocus === "function") {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }

  }

  async download() {

    this._bhAnalyticsService.clickEvent('download health record', '');
    let startDate:Date = this.form.get("startDate").value;
    let endDate:Date = this.form.get("endDate").value;
    let type:string = this.form.get("recordFormat").value;


    const documentTypes = type.split(":");
    const downloadReferences: DownloadReference[] = [];
    if (this.html.checked) {
      const downloadReference = await this.shareHealthRecordService.prepareDownload(startDate, endDate, documentTypes[0]);
      downloadReferences.push({
        fileName: downloadReference.Filename,
        url: this.shareHealthRecordService.getDownloadUrl(downloadReference.DocumentID)
      });
    }
    if (this.ccd.checked) {
      const downloadReference = await this.shareHealthRecordService.prepareDownload(startDate, endDate, documentTypes[1]);
      downloadReferences.push({
        fileName: downloadReference.Filename,
        url: this.shareHealthRecordService.getDownloadUrl(downloadReference.DocumentID)
      });
    }
    const downloadedFiles: DownloadedFile[] = []
    for (const downloadReference of downloadReferences) {
      const download = await this.downloadResource(downloadReference.url);
      downloadedFiles.push({
        fileName: downloadReference.fileName,
        file: download
      });
    }
    const zip = new JSZip();
    for (const file of downloadedFiles) zip.file(file.fileName, file.file);
    zip.generateAsync({ type: "blob" })
      .then(content => saveAs(content, 'records.zip'));


    this.router.navigate(['/authenticated/health-record/share/download-done']);


  }

  async sendToProvider() {
    this._bhAnalyticsService.clickEvent('share health record', '');
    let startDate:Date = this.form.get("startDate").value;
    let endDate:Date = this.form.get("endDate").value;
    let type:string = this.form.get("recordFormat").value;

    let types = type.split(":");
    const selectedTypesArray = []
    if(this.html.checked){
      selectedTypesArray.push(types[0]);
    }

    if(this.ccd.checked){
      selectedTypesArray.push(types[1]);
    }
    const selectedType = selectedTypesArray.join(':');


    this.router.navigate(
      [
        '../share-with-provider',
        selectedType,
        formatDate(startDate, 'yyyy-MM-dd', 'en-us'),
        formatDate(endDate, 'yyyy-MM-dd', 'en-us')
      ],
      { relativeTo: this.route }
    );
  }

  ionViewDidLeave() {
    this.startDate.value = null;
    this.endDate.value = null;
  }

  updateMinMax(type: string, event: MatDatepickerInputEvent<Date>) {
    this.minDate = this.dateHandler.parseDateString(this.startDate.nativeElement.value);
    this.maxDate = this.dateHandler.parseDateString(this.endDate.nativeElement.value);
  }

  toPossessive(name: string) {
    if (!name) return '';
    const endsWithS = name.toLowerCase()[name.length - 1] == 's';
    return name + (endsWithS ? "'" : "'s");
  }

  public async downloadResource(url: string): Promise<Blob> {
    const file = await this.http.get<Blob>(
      url,
      { responseType: 'blob' as 'json' }).toPromise();
    return file;
  }
}
