import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentRouteGuardService implements CanActivate{

  private _canNavigate: boolean = false;

  canActivate(route: import("@angular/router").ActivatedRouteSnapshot, state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
    if (!this._canNavigate) this._location.back();
    return this._canNavigate;
  }

  constructor(private _router: Router,
              private _location: Location) { }

  public setNavigate(val : boolean) : void {
    this._canNavigate = val;
  }

}
