import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticateService } from './authenticate.service';
import { ConstantsService } from './constants.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SymptomCheckerService {

  env = environment;

  constructor(
    private _http: HttpClient,
    private _httpBaseService: HttpBaseService,
  ) { }

  getDropdownValues() {
    return this._httpBaseService.getList(
      '/isabel/dropdowns', false
    );
  }

  getSuggestedDiagnoses(basicInfo: any, effectiveUserId: number) {
    console.log('basic info', basicInfo);

    let symptoms = '';
    basicInfo.symptoms.value.forEach((symptom) => {
      const sympStr = symptom.value + ',';
      symptoms += sympStr;
    });

    return this._httpBaseService.getList(`/isabel/diagnoses?dob=${basicInfo.age.value}&sex=${basicInfo.gender.value}&mybaystateuserID=${effectiveUserId}&region=${basicInfo.region.value}&querytext=${symptoms}&flag=sortbyRW_advanced&pregnant=${basicInfo.pregnancy.value}`, true, null, true);
  }

  getDropdownValues2() {
    const url = `mytest.baystatehealth.org/myhealth/api/v1/isabel/dropdowns`;
    return this._http.get(url).pipe(
      map((data: any) => data),
      catchError((err) => {
        console.log('error values', err);
        return of(err);
      })
    );
  }

  getSuggestedDiagnoses2(basicInfo: any, effectiveUserId: number) {
    console.log('basic info', basicInfo);
    //const patientAgeNumber = this.getPatientAgeRange(basicInfo.patAge);
    let symptoms = '';
    basicInfo.symptoms.forEach((symptom) => {
      const sympStr = symptom + ',';
      symptoms += sympStr;
    });
    //console.log('basic info', basicInfo);
    return this._httpBaseService.getList2(`/isabel/diagnoses?dob=${basicInfo.patAgeGroupID}&sex=${basicInfo.sex}&mybaystateuserID=${effectiveUserId}&region=${basicInfo.region}&querytext=${basicInfo.symptomsString}&flag=sortbyRW_advanced&pregnant=${basicInfo.pregnancy}`, false, null, false);
  }

  getPatientAgeRange(patAge: number): number {
    console.log('patAge', patAge);
    let patientAge = 0;
    if(patAge <= 0 ) {
        patientAge = 2;
    }
    else if(patAge <= 5 && patAge >= 1 ) {
      patientAge = 3;
    }
    else if(patAge <= 12 && patAge >= 6 ) {
      patientAge = 10;
    }
    else if(patAge <= 16 && patAge >= 13 ) {
      patientAge = 4;
    }
    else if(patAge <= 29 && patAge >= 17 ) {
      patientAge = 7;
    }
    else if(patAge <= 39 && patAge >= 30 ) {
      patientAge = 5;
    }
    else if(patAge <= 49 && patAge >= 40 ) {
      patientAge = 8;
    }
    else if(patAge <= 64 && patAge >= 50 ) {
      patientAge = 9;
    }
    else if(patAge > 64 ) {
      patientAge = 6;
    }
    return patientAge;
  }

  sexConversion(patientSex: string) {
    if(patientSex.toLowerCase() === 'm') {
        return 2;
    }
    else {
        return 1;
    }
  }

  triage(scFormObj: any, userId: number, dx: string) {
    let symptoms = '';
    scFormObj.symptoms.value.forEach((symptom) => {
      const sympStr = symptom.value + ',';
      symptoms += sympStr;
    });

    if (userId != undefined) {
      return this._httpBaseService.getList(`/isabel/triage?age=${scFormObj.age.value}&sex=${scFormObj.gender.value}&region=${scFormObj.region.value}&text=${symptoms}&pregnancy=${scFormObj.pregnancy.value}&mybaystateuserid=${userId}&Q1=${scFormObj.q1.value}&Q2=${scFormObj.q2.value}&Q3=${scFormObj.q3.value}&Q4=${scFormObj.q4.value}&Q5=${scFormObj.q5.value}&Q6=${scFormObj.q6.value}&Q7=${scFormObj.q7.value}&flag=sortbyRW_advanced${dx ? '&dx='+dx : ''}`, false);
    }
  }

  getTriageScore(scFormObj: any, userId: number, dx: string,  selectedSymptoms: any, patientData: any) {
    console.log('patient data', patientData);
    //const patientAgeNumber = this.getPatientAgeRange(patientData.patAge);
    const genderNumber = this.sexConversion(patientData.sex);
    //const convertedPregnantValue = this.convertPregnantValue(patientData.pregnancy);
    if (userId !== undefined) {
      return this._httpBaseService.getList(`/isabel/triage?age=${patientData.patAgeGroupID}&sex=${genderNumber}&region=${patientData.region}&text=${selectedSymptoms}&pregnancy=${patientData.pregnancy}&mybaystateuserid=${userId}&Q1=${scFormObj.answer1}&Q2=${scFormObj.answer2}&Q3=${scFormObj.answer3}&Q4=${scFormObj.answer4}&Q5=${scFormObj.answer5}&Q6=${scFormObj.answer6}&Q7=${scFormObj.answer7}&flag=sortbyRW_advanced${dx ? '&dx='+dx : ''}`, false);
    }
  }

  convertPregnantValue(pregnancyValue) {
    if(pregnancyValue === 'y') {
      return '2';
    }
    else if(pregnancyValue === 'n') {
      return '1';
    }
    else {
      return '0';
    }
  }

  getPredictiveText(): Promise<{predictive_text: string[], updated_date: string}> {
    const httpOptions =
    new HttpHeaders();
    const url =`https://apiscsandbox.isabelhealthcare.com/v3/predictive-text?language=en`;
    return this._http.get(url, {withCredentials: false, headers: {'Key':'authorization', 'Value': 'CnR0t5V4Cfa7i1zsJpH3rtO5n6476c8i', 'SMC-Exclude-Credentials' : 'true', 'SMC-Stay-On-Page-With-Error': 'true'}, responseType: 'json'}).toPromise() as any;
    // const id = '2158';
    // const pass = '15abel2023'
    // const options = {headers: {'SMC-Stay-On-Page-With-Error': 'true'}};
    // OLD: const url = 'https://apisc.isabelhealthcare.com/predictive-text';
    //const url =`https://symptomchecker.isabelhealthcare.com/predictive-text?format=json&user_id=${id}&password=${pass}`;
    //return this._http.get(url, {withCredentials: false, headers: {'SMC-Exclude-Credentials' : 'true'}, responseType: 'json'}).toPromise() as any;
  }

}
