import { Observation } from './../../../model/Observation';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { SmcTableData } from 'src/app/model/SmcTableData';
import * as shape from 'd3-shape';
import * as d3 from 'd3';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {FormsValidationService} from "../../../service/forms-validation.service";
import {BhanalyticsService} from "../../../service/bhanalytics.service";
import { DateHandlerService } from 'src/app/service/date-handler.service';
import { PrintService } from 'src/app/service/print.service';
@Component({
  selector: 'app-health-stat-detail',
  templateUrl: './health-stat-detail.component.html',
  styleUrls: ['./health-stat-detail.component.scss'],
  providers: [DatePipe,DecimalPipe]
})

export class HealthStatDetailComponent implements OnInit {

  public data: SmcTableData;
  public Title: string;
  public chartData: any[];
  seriesChart: any[];
  public errorMessage: string;
  @ViewChild('dateColumnTemplate') dateColumnTemplate: ElementRef;
  @ViewChild('from') private from: any;
  @ViewChild('to') private to: any;
  view: any[];


  // options for the chart
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Number';
  showYAxisLabel = true;
  yAxisLabel: string;
  timeline = false;
  xScaleMin: Date;
  xScaleMax: Date;
  yScaleMin: number;
  yScaleMax: number;

  /** Error string to display below date range selector. */
  invalidDateRangeError: string = null;

  colorScheme = {
    domain: ['#55b5e6']
  };

  // line, area
  autoScale = false;

  //pie
  showLabels = true;
  explodeSlices = false;
  doughnut = false;

  constructor(private _datePipe: DatePipe,
              private _bhAnalyticsService: BhanalyticsService,
              private dateHandler: DateHandlerService,private _decimalPipe: DecimalPipe,
              private printService: PrintService,
              private _changeDetectorRef: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.load(null, null);
  }

  onResize() {
    const chartWidth = document.querySelector('.page-content').clientWidth - (innerWidth > 992 ? 130 : 50);
    const chartHeight = 200;
    this.view = [chartWidth, chartHeight];
  }

  updateResults(from, to) {
    this._bhAnalyticsService.searchEvent(from + ' ' + to);
    this.load(this.dateFromIntersystemsFormat(from.value), this.dateFromIntersystemsFormat(to.value));
  }

  load(from, to) {
    this.data = new SmcTableData();
    this.data.columns = [
      { name: 'Time', prop: 'Date', cellClass: 'card-bold', cellTemplate: this.dateColumnTemplate  },
      { name: 'Result', prop: 'ObservationValue', cellClass: 'card-detail' },
      { name: 'Location', prop: 'EnteredAt', cellClass: 'card-detail' }
    ];

    // Set Chart and table data.
    let vitalData,
        tableData;

    if(history.state.data) {
      vitalData = history.state.data;
      tableData = history.state.vitals;

      sessionStorage.setItem('vitalData', JSON.stringify(vitalData));
      sessionStorage.setItem('tableData', JSON.stringify(tableData));

    } else {
      vitalData = JSON.parse(sessionStorage.getItem('vitalData'));
      tableData = JSON.parse(sessionStorage.getItem('tableData'));
    }

    for (let index = 0; index < tableData.Observations.length; index++) {
      const element = tableData.Observations[index];
      element.ObservationValue = element.ObservationValue.toString() + (element.ObservationValue.toString().indexOf(element.ObservationUnits)==-1? ' ' + element.ObservationUnits:'');
      tableData.Observations[index] = element;
    }

    this.Title = tableData.ObservationTranslated;

    this.chartData = [];
    this.seriesChart = [];

    let sortedData;
    const series = tableData.Observations;
    for (let index = 0; index < series.length; index++) {
      const element = series[index];
      // since unit field is not always type string, only convert to lowercase if it is
      const units = typeof element.ObservationUnits == "string" ? element.ObservationUnits.toLowerCase() : element.ObservationUnits;
      if (units == "kg") {
        element.ObservationValue = element.ObservationValue.toString().replace(element.ObservationUnits,"").trim();
        element.ObservationValue = this._decimalPipe.transform(( element.ObservationValue * 2.20462), "1.0") +  " lbs.";
        element.ObservationUnits = "lbs.";
      }

      if (units == "cm"){
        element.ObservationValue = element.ObservationValue.toString().replace(element.ObservationUnits,"").trim();
        element.ObservationValue = this.toFeet(element.ObservationValue);
        element.ObservationUnits ="ft.";
      }
    }

    if (from != null) {
      this.data.rows = series.sort((a, b) => b.Date > a.Date ? 1 : -1).filter(row => to >= this.dateHandler.parseDateString(row.Date) && from <= this.dateHandler.parseDateString(row.Date));
      sortedData = series.sort((a, b) => b.Date < a.Date ? 1 : -1).filter(row => to >= this.dateHandler.parseDateString(row.Date) && from <= this.dateHandler.parseDateString(row.Date));
    } else {
      this.data.rows = series.sort((a, b) => b.Date > a.Date ? 1 : -1);
      sortedData = series.sort((a, b) => b.Date < a.Date ? 1 : -1);
      this.from.nativeElement.value = this._datePipe.transform(this.dateFromIntersystemsFormat(sortedData[0].ObservationTime) , 'MM/dd/yyyy');
      const toDate = this.dateFromIntersystemsFormat(sortedData[sortedData.length - 1].Date);
      toDate.setDate(toDate.getDate() + 1); // increase the toDate by one day to include all results
      this.to.nativeElement.value = this._datePipe.transform(toDate, 'MM/dd/yyyy');
    }

    if (sortedData && sortedData.length > 0) {
      this.yAxisLabel = sortedData[0].Unit;
      for (let index = 0; index < sortedData.length; index++) {
        const element = sortedData[index];
        let value = element.ObservationValue.split(' ')[0] //some values are coming with the unit, so I am removing it
        if (element.ObservationUnits == 'ft.') {
          value = this.fromFeetToDecimal(value);
        }

        this.seriesChart.push({
          "name": this.dateFromIntersystemsFormat(element.Date),
          "value": value
        });
      }

      this.setAxisMinMax(this.seriesChart);

      this.chartData.push(
        {
          "name": vitalData.children[0].DisplaySummary,
          "series": this.seriesChart
        }
      );
    }
    this._changeDetectorRef.detectChanges();
    this.onResize();
  }

  /** Check if date range is valid. If invalid disable "Get results" button and show error message. */
  dateChange(type: string, event: MatDatepickerInputEvent<Date>) {
    const fromString = this.from.nativeElement.value;
    const toString = this.to.nativeElement.value;
    if (!fromString) {
      this.invalidDateRangeError = 'Please select a start date';
      return;
    }
    if (!toString) {
      this.invalidDateRangeError = 'Please select an end date';
      return;
    }
    const fromDate = new Date(this.from.nativeElement.value);
    const toDate = new Date(this.to.nativeElement.value);
    if (toDate.getTime() <= fromDate.getTime()) {
      this.invalidDateRangeError = 'Please select an end date that is later than the selected start date';
      return;
    }
    this.invalidDateRangeError = null;
  }

  dateFromIntersystemsFormat(dateString: string): Date {
    return this.dateHandler.parseDateString(dateString);
  }

  yFormat = (e) => {
    return e;
  }

  toFeet(height) {
    if (height) {
      let n = Number(height);
      var realFeet = ((n * 0.393700) / 12);
      var feet = Math.floor(realFeet);
      var inches = Math.round((realFeet - feet) * 12);
      return feet + "'" + inches + '"';
    }
  }

  fromFeetToDecimal(height) {
    let feet = Number(height.split("'")[0]);
    let inches = Number(height.split("'")[1].split('"')[0]);
    let decimal = inches/12.0;

    return feet + decimal;
  }

  /** Adjust axis min/max so points are not at graph edge */
  setAxisMinMax(points: {name: Date, value: string}[]) {

    if (points.length == 0) return;

    const xValues = points.map(point => point.name.getTime());
    const yValues = points.map(point => parseFloat(point.value));

    let lowestXValue;
    let highestXValue;
    for (const xValue of xValues) {
      if (!lowestXValue || xValue < lowestXValue) lowestXValue = xValue;
      if (!highestXValue || xValue > highestXValue) highestXValue = xValue;
    }

    const lowestYValue = Math.min(...yValues);
    const highestYValue = Math.max(...yValues);

    const xRange = Math.abs(lowestXValue - highestXValue);
    const yRange = Math.abs(lowestYValue - highestYValue);

    const xMarginRatio = 0.03;
    const yMarginRatio = 0.1;

    const xMargin = ((xRange == 0) ? 1 : xRange * xMarginRatio)
    const yMargin = ((yRange == 0) ? 1 : yRange * yMarginRatio);

    this.xScaleMin = new Date(lowestXValue - xMargin);
    this.xScaleMax = new Date(highestXValue + xMargin);
    this.yScaleMin = lowestYValue - yMargin;
    this.yScaleMax = highestYValue + yMargin;

  }

  print() {
    this._bhAnalyticsService.clickEvent('Print Stat Detail', this.Title);
    // const printContent = document.getElementById('print-section');
    // this.printService.printHtmlString(printContent.innerHTML);
    window.print();
  }
}
