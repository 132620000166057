<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'Feedback' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button><ion-icon name="close-outline"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <bh-feedback-form
    class="margin-top-48"
    [feature]="feature"
    [showConfirmationButton]="true"
    confButtonLabel="Dismiss"
    (dismissEvent)="dismiss()"
  ></bh-feedback-form>
</ion-content>
