import { Injectable } from '@angular/core';
import { TranslateClient, TranslateTextCommand } from "@aws-sdk/client-translate";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { ConstantsService } from 'src/app/service/constants.service';
import { environment } from 'src/environments/environment';

import Dictionary from 'src/assets/translations/dictionary.json';
import Exceptions from 'src/assets/translations/exceptions.json';

@Injectable({
  providedIn: 'root'
})
export class AwsTranslateService {

  constructor(private constants: ConstantsService) { }

  allData: string;
  availableLanguages = this.constants.constantsData["awsConfig"]["availableLanguages"]
  aws = this.constants.constantsData["awsConfig"];
  cipid = environment.identityPoolId;
  cipr = environment.region;
  counter = 0;
  dataBack: string;
  default = [];
  dictionary = Dictionary;
  exceptionList = " MyBaystate ^ Baystate "; // ^ separated values here will never be translated, wherever found, tagged or not.
  excp = Exceptions;
  isFirstLoad: boolean;
  langId: string = (localStorage.getItem("BSPP2.0LangOpt") || "en");
  languages = this.aws["languages"];

  client = new TranslateClient({
    region: this.cipr,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: this.cipr }),
      identityPoolId: this.cipr + ":" + this.cipid
    })
  })

  public async translator(iMode = 0, isFirstLoad = false) {
    this.langId = localStorage.getItem("BSPP2.0LangOpt") || "en"
    switch (iMode) {
      case 0:
        this.isFirstLoad = isFirstLoad;
        !isFirstLoad || (this.langId != "en" && isFirstLoad) ? console.log("Switching to " + this.languages[this.langId]) : null;
        await this.process();
        this.counter = 0;
        break;
      case 1:
        this.allData = this.langId + ":\n";
        localStorage.setItem("BSPP2.0LangLetterCount", "0");
        await this.process(1);
        console.log(this.allData + "\n(" + localStorage.getItem("BSPP2.0LangLetterCount") + ")");
        break;
      case 2:
        localStorage.setItem("BSPP2.0LangLetterCount", "0");
        for (let i = 0; i < this.availableLanguages.length; i++) {
          //if (this.availableLanguages[i] != "en") {
          localStorage.setItem("BSPP2.0LangLetterCount", "0");
          this.langId = this.availableLanguages[i];
          this.allData = this.langId + ":\n";
          await this.process(2);
          console.log(this.allData + "\n(" + localStorage.getItem("BSPP2.0LangLetterCount") + ")");
          //}
        }
      default:

    }
  }

  private async process(iMode = 0, o: Node = document.body, path = "ROOT") {
    var block: string;
    var isClass: boolean;
    const root = o;
    for (let i = 0; i < root.childNodes.length; i++) {
      if (root.childNodes[i].nodeType == 3) {
        let Element = (root.childNodes[i] as HTMLElement);
        try { isClass = Element.parentElement.className.indexOf("skipTranslation") < 0 } catch (err) { isClass = false }
        if ((Element.textContent.replace(/\s/g, "") != "" && this.exceptionList.indexOf(Element.textContent) < 0 && isClass)) {
          switch (iMode) {
            case 0:
              if (this.langId == 'en') {
                if (this.isFirstLoad) {
                  this.default[this.counter] = Element.textContent;
                } else {
                  this.default[this.counter] ? Element.textContent = this.default[this.counter] : null;
                }
                this.counter += 1;
              } else {
                if (this.isFirstLoad) {
                  this.default[this.counter] = Element.textContent;
                  this.counter += 1;
                }
                block = this.excp.All[this.langId][this.dictionary[this.langId][Element.textContent]] ? this.excp.All[this.langId][this.dictionary[this.langId][Element.textContent]] : this.dictionary[this.langId][Element.textContent];
                block ? Element.textContent = block : null;
              }
              break;
            case 1:
              if (this.langId != "en") {
                await this.translate(Element.textContent, this.langId);
                block = this.dataBack;
                localStorage.setItem("BSPP2.0LangLetterCount", (parseInt(localStorage.getItem("BSPP2.0LangLetterCount")) + Element.textContent.length).toString());
                this.allData = this.allData + "\"" + Element.textContent + "\":\"" + block + "\",\n";
                Element.textContent = block;
              } else if (iMode == 1) {
                localStorage.setItem("BSPP2.0LangLetterCount", (parseInt(localStorage.getItem("BSPP2.0LangLetterCount")) + Element.textContent.length).toString());
                this.allData = this.allData + Element.textContent + "\n";
              }
              break;
            case 2:
              if (this.langId != "en") {
                await this.translate(Element.textContent, this.langId);
                block = this.dataBack
                localStorage.setItem("BSPP2.0LangLetterCount", (parseInt(localStorage.getItem("BSPP2.0LangLetterCount")) + Element.textContent.length).toString());
                this.allData = this.allData + "\"" + Element.textContent + "\":\"" + block + "\",\n";
              }
            default:

          }
        }
      } else if (root.childNodes[i].nodeType == 1 && ((root.childNodes[i] as HTMLElement).tagName == "INPUT" || (root.childNodes[i] as HTMLElement).tagName == "TEXTAREA")) {
        let Element = (root.childNodes[i] as HTMLElement);
        try { block = Element.attributes.getNamedItem("placeholder").value || ""; } catch (err) { block = ""; }
        if ((block.replace(/\s/g, "") != "" && this.exceptionList.indexOf(block) < 0 && Element.parentElement.className.indexOf("skiptranslation") < 0)) {
          switch (iMode) {
            case 0:
              if (this.langId == 'en') {
                if (this.isFirstLoad) {
                  this.default[this.counter] = block;
                } else {
                  this.default[this.counter] ? block = this.default[this.counter] : null;
                }
                this.setText(Element, block, "placeholder");
                this.counter += 1;
              } else {
                if (this.isFirstLoad) {
                  this.default[this.counter] = block;
                  this.counter += 1;
                }
                block = this.dictionary[this.langId][block] ? this.dictionary[this.langId][block] : block;
                this.setText(Element, block, "placeholder");
              }
              break;
            default:

          }
        }
      }
      root.childNodes[i].hasChildNodes() ? await this.process(iMode, root.childNodes[i], path + "/" + root.childNodes[i].nodeName) : null;
    }
  }

  private setText(e, o, s) {
    var attr = document.createAttribute(s);
    attr.value = o;
    e.attributes.setNamedItem(attr);
  }

  private async translate(text, lang, isCached = true) {
    var command = new TranslateTextCommand({
      Text: text,
      SourceLanguageCode: "en",
      TargetLanguageCode: lang
    })
    await this.client.send(command)
      .then((data) => {
        this.dataBack = data.TranslatedText
      }).catch((reason) => {
        console.log(reason);
      });
  }

  public read(text, langId) {
    if (text && text.length > 0 && langId !== 'en') {
      // return this.dictionary[langId][text] || text;
      text = text.replace(/\n/g, ' ');
      if (this.dictionary[langId][text] === undefined) {
        console.log('Missing Tx (' + langId + '): \'' + text + '\'');
      }
      return this.excp.All[this.langId][this.dictionary[this.langId][text]] ?
        (this.excp.All[this.langId][this.dictionary[langId][text]] || text) :
        (this.dictionary[langId][text] || text);
    } else {
      return text;
    }
  }

  public async getDictionary(langId) {
    await this.delay(2);
    this.statusPage(langId);
    var d = new Date().getTime();
    var t = setInterval(function () {
      var now = new Date().getTime();
      var dif = now - d;
      var secs = Math.floor((dif % (60000)) / 1000);
      var mins = Math.floor((dif % (3600000)) / 60000);
      document.getElementById("sp_timer").innerText = " (" + mins + 'm ' + secs + 's)';
    }, 1000);
    // var oStatus = document.getElementById("sp_status");
    var oStatusPercent = document.getElementById("sp_statuspercent");
    var index = [];
    // var data = "";

    for (let i in Dictionary["new"]) {
      index.push(i);
    }
    var oDiv = document.getElementById("div_status");
    oDiv.style.color = "#CDCDCD";
    oDiv.style.fontSize = ".7em";
    oDiv.style.overflowY = "auto";

    for (let i = 1; i < index.length; i++) { //index.length; i++) {
      // data = data + "\"" + index[i] + "\": \"\",\n";
      if (i % 100 == 0) {
        console.log("pausing for 5 seconds...");
        await this.delay(5);
      } else {
        // await this.delay(2);

        await this.translate(index[i], langId);

        var oSpan = document.createElement("span");
        oSpan.innerHTML = "\"<span id='sp_status_" + i + "' style='color: DarkKhaki; font-style: italic;'></span>\": <span id='sp_trans_" + i + "' style='color:LightCoral; font-style: italic;'></span><br>";
        oDiv.appendChild(oSpan);
        document.getElementById("sp_status_" + i).textContent = index[i].replace(/\"/g, "{[quote]}").replace(/\n/g, "{[newline]}");
        document.getElementById("sp_trans_" + i).textContent = "\"" + this.dataBack.replace(/\"/g, "{[quote]}").replace(/\n/g, "{[newline]}") + "\",";

        oDiv.scrollTo(0, oDiv.scrollHeight);
        oStatusPercent.innerText = ((i / index.length) * 100).toFixed(1);
        oSpan.style.display = "none";
        oSpan.offsetHeight;
        oSpan.style.display = "block";
      }
    }

    document.getElementById("div_title").style.color = "LawnGreen";
    oStatusPercent.style.color = "LawnGreen";
    oStatusPercent.innerText = "100"
    clearInterval(t);
  }

  statusPage(langId) {
    var oBody = document.getElementsByTagName("BODY")[0] as HTMLElement;
    oBody.style.color = "white";
    oBody.innerHTML = "<div id='div_title' style='width: 100%; padding: 15px 5px 15px 5px; background: black; '>&nbsp;&nbsp;&nbsp;Translating to \'" + langId + "\'... <span id='sp_statuspercent' style='color: #CDCDCD;'></span>% complete.&nbsp;&nbsp;<span id='sp_timer' style='font-size: .9em;'></span></div><div style='position: relative; height: 92%' id='div_status'></div>"
  }

  delay(s: number) {
    return new Promise(resolve => setTimeout(resolve, (s * 1000)));
  }
}
