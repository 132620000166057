import { Injectable } from '@angular/core';
import {HttpBaseService} from "./http-base.service";

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(private _httpBaseService: HttpBaseService) { }

  async getAllForms() {
    //TODO: new forms api to pull back both printable and electronic forms with categories/subcategories.
    const response = await this._httpBaseService.getList("/forms/list", false);
    /*
      const response = {Forms:[
        {Title:"Personal Information Change Request Form", Type:"Electronic", Identifier:"PtDemogChngE", Language:"", Department:"", CategoryPath:[ "General"]},
        {Title:"myBaystateHealth Patient Portal Proxy Authorization Form", Type:"Electronic", Identifier:"PtPortalProxyFormE", Language:"", Department:"", CategoryPath:[ "General"]},
        {Title:"Baystate Health Authorization for Release Of Information", Type:"Electronic", Identifier:"PatientPortalROI", Language:"", Department:"", CategoryPath:[ "General"]},
        {Title:"Baystate Health Authorization for Release Of Information", Type:"Electronic", Identifier:"PatientPortalROI", Language:"", Department:"", CategoryPath:[ "General", "Specific"]},
        {Title:"Authorization for Release of Information", Type:"Printable", Identifier:"forms/authorization for release 11-20-13.doc", Language:"", Department:"", CategoryPath:[ "General", "Specific"]},
        {Title:"Authorization for Release of Information", Type:"Printable", Identifier:"forms/authorization for release 11-20-13.doc", Language:"", Department:"", CategoryPath:[ "General"]},
        {Title:"Health Care Proxy Instructions & Document", Type:"Printable", Identifier:"forms/health care proxy instructions document baystate health (2).pdf", Language:"", Department:"", CategoryPath:[ "General"]},
        {Title:"Personal Directive Short Form", Type:"Printable", Identifier:"forms/personal directive short form baystate.docx", Language:"", Department:"", CategoryPath:[ "General"]},
        {Title:"Gastroenterology- Colonoscopy MoviPrep Instructions", Type:"Printable", Identifier:"forms/colonoscopy prep- movi prep.pdf", Language:"", Department:"", CategoryPath:[ "Gastroenterology", "Colonoscopy"]},
        {Title:"Gastroenterology- Colonoscopy MoviPrep Instructions", Type:"Printable", Identifier:"forms/colonoscopy prep- movi prep.pdf", Language:"", Department:"", CategoryPath:[ "Gastroenterology", "Instructions"]},
        {Title:"Gastroenterology- Split Method Colonoscopy Prep", Type:"Printable", Identifier:"forms/colonoscopy prep- split method.pdf", Language:"", Department:"", CategoryPath:[ "Gastroenterology", "Colonoscopy"]},
        {Title:"Gastroenterology- Split Method Colonoscopy Prep- Espanola", Type:"Printable", Identifier:"forms/colonoscopy prep- split method-espanola.pdf", Language:"", Department:"", CategoryPath:[ "Gastroenterology", "Colonoscopy"]},
        {Title:"Gastroenterology- SuPrep Conoloscopy Prep Instructions", Type:"Printable", Identifier:"forms/colonoscopy prep- suprep.pdf", Language:"", Department:"", CategoryPath:[ "Gastroenterology", "Colonoscopy"]},
        {Title:"Gastroenterology- SuPrep Conoloscopy Prep Instructions", Type:"Printable", Identifier:"forms/colonoscopy prep- suprep.pdf", Language:"", Department:"", CategoryPath:[ "Gastroenterology", "Instructions"]},
        {Title:"Gastroenteroloy- Colonoscopy Preparation Tips", Type:"Printable", Identifier:"forms/colonoscopy preparation tips.pdf", Language:"", Department:"", CategoryPath:[ "Gastroenterology", "Colonoscopy"]},
        {Title:"Gastroenterology Colonoscopy Preparation Tips- Espanola", Type:"Printable", Identifier:"forms/colonoscopy preparation tips-espanola.pdf", Language:"", Department:"", CategoryPath:[ "Gastroenterology", "Colonoscopy"]},
        {Title:"Gastroenterology- Clear Fluids List", Type:"Printable", Identifier:"forms/gastroenterology clear fluids list.pdf", Language:"", Department:"", CategoryPath:[ "Gastroenterology", "Other"]},
        {Title:"Gastroenterology- Upper Endoscopy Prep Instructions", Type:"Printable", Identifier:"forms/upper endoscopy prep.pdf", Language:"", Department:"", CategoryPath:[ "Gastroenterology", "Other"]},
        {Title:"Gastroenterology Upper Endoscopy Prep- Espanola", Type:"Printable", Identifier:"forms/upper endoscopy prep- espanola.pdf", Language:"", Department:"", CategoryPath:[ "Gastroenterology", "Other"]}
      ]};
      */

    return response["Forms"];
    /*
    return [
      {
        Title: "Baystate Health Authorization for Release Of Information",
        Type: "Electronic",
        Identifier: "PatientPortalROI",
        Category: "General",
        Subcategory: "Specific"
      },
      {
        Title: "Authorization for Release of Information",
        Type: "Printable",
        Identifier: "forms\/authorization for release 11-20-13.doc",
        Category: "General",
        Subcategory: "Specific"
      }
    ];
    */
  }


  async getPrintableForms() {
      const response = this._httpBaseService.getList("/library/public/forms", false);
      return response;
    }

    async getElectronicForms() {
      const response = this._httpBaseService.getList("/forms/definitions/hscomm", false);
      return response;
    }

    async getElectronicForm(formKey: string) {
      const response = this._httpBaseService.getList(`/forms/definitions/hscomm/${formKey}`, false);
      return response;
    }

    async getSubmittedFormsList() {
      const response = this._httpBaseService.getList("/forms/data/hscomm/user/getSubmittedForm/list", false);
      return response;
    }

    async getSubmittedForm(formKey: string) {
      const response = this._httpBaseService.getList(`/forms/data/hscomm/user/getSubmittedForm/id?id=${formKey}`, false);
      return response;
    }

    async getSubmittedForms() {
      //TODO: need to fix url, since it only pulls 2019
      //const response = this._httpBaseService.getList("/forms/data/hscomm/getForms/Active/2019", false);
      const response = this._httpBaseService.getList("/forms/data/hscomm/getForms/user/2019", false);
      return response;
    }

    async submitForm(userID: string, formData: string)  {
      //const response = this._httpBaseService.post(formData, `/forms/data/hscomm/active/${userID}/_submit`, false);
      const response =await this._httpBaseService.post(formData, `/forms/data/hscomm/user/${userID}/_submit`, false);
      return response;
   }

}
