import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { ConsoleDataService } from 'src/app/service/console-data.service';

@Component({
  selector: 'bh-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class BhInfoCardComponent implements OnInit {
  @Input() cardTitle = '';
  @Input() category;
  @Input() type: 'information' | 'warning' | 'success' | 'danger';
  bannerContent;
  nowMoment = moment();
  postDate = null;
  isPosted = false;

  constructor(
    private consoleDataService: ConsoleDataService
  ) {
   // console.log('bannerContent', this.bannerContent);
    //console.log('date', this.nowMoment);
    //console.log('cat', this.category);
   }

  ngOnInit() {
    this.getSlideInfo(this.category);
  }

  async getSlideInfo(cat) {
    try {
      const res = await this.consoleDataService.getSlides(1).toPromise();
      const bannerData = res.x_result.filter(row => row.ps_seq === cat && row.active === 1);
      this.bannerContent = bannerData[0];
      //console.log('banner content', res, this.bannerContent);
      if (this.bannerContent.start_date) {
        this.postDate = moment(this.bannerContent.start_date, 'MM/DD/YYYY');
        this.isPosted = this.postDate < this.nowMoment;
      }
    } catch (err) {
      console.error("Get banner information: ", err);
    }
  }

}
