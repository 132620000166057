import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bh-urgent-disclaimer',
  templateUrl: './bh-urgent-disclaimer.component.html',
  styleUrls: ['./bh-urgent-disclaimer.component.scss'],
})
export class BhUrgentDisclaimerComponent implements OnInit {
  @Input() subject = '';
  @Input() leadStatement = '';

  constructor() { }

  ngOnInit() {}

}
