import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountService } from 'src/app/service/account.service';
import { StateService } from 'src/app/service/state.service';
import { AuthenticateService } from 'src/app/service/authenticate.service';
import { ModalController } from '@ionic/angular';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { ModalService } from 'src/app/service/modal.service';
import { RequestAChangeModalComponent } from './request-achange-modal/request-achange-modal.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  showChangePasswordForm = false;
  tabs = [
    {
      path: '/authenticated/account/preferences',
      label: 'Preferences'
    },
    {
      path: '/authenticated/account/history',
      label: 'Recent account history'
    },
  ];
  userName: string;
  constructor(
    public accountService: AccountService,
    public stateService: StateService,
    private authService: AuthenticateService,
    public modalController: ModalController,
    private _modalService: ModalService,
    private _bhAnalyticsService: BhanalyticsService,
  ) {}

  ngOnInit() {
    this.authService.getCachedAuthStatus().subscribe(authStatus => {
      if (authStatus != null && authStatus.PatientName) {
        if(authStatus.PatientName.indexOf(" ")>-1){
          this.userName = this.toPossessive(authStatus.PatientName.split(" ")[0]);
        }else{
          this.userName = this.toPossessive(authStatus.PatientName);
        }

      }
    });
  }

  toPossessive(name: string) {
    if (!name) return '';
    const endsWithS = name.toLowerCase()[name.length - 1] == 's';
    return name + (endsWithS ? "'" : "'s");
  }

  async requestChange() {
    this._bhAnalyticsService.clickEvent('Request account change', '');
    const modal = await this.modalController.create({
      component: RequestAChangeModalComponent,
      //componentProps: { testResult: this.testResult },
      cssClass: ["request-change-modal", "modal-limited-size"]
    });
    this._modalService.register(modal);
    return await modal.present();
  }

}
