import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, IonButton, IonTextarea, ModalController } from '@ionic/angular';
import { Message, UploadedDocument } from 'src/app/model/Messages';
import { SmcTableData } from 'src/app/model/SmcTableData';
import { AwsTranslateService } from 'src/app/service/aws-translate.service';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { ConstantsService } from 'src/app/service/constants.service';
import { MessagesService } from 'src/app/service/messages.service';
import { ModalService } from 'src/app/service/modal.service';
import { PreviousRouteService } from 'src/app/service/previous-route.service';
import { PrintService } from 'src/app/service/print.service';
import { StateService } from 'src/app/service/state.service';
import { ToastService } from 'src/app/service/toast.service';
import { ModalType } from 'src/app/model/ModalTypeEnum';
import { RadImageInstructionalVideoModalComponent } from '../../image-test-result/rad-image-instructional-video-modal/rad-image-instructional-video-modal.component';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
})

export class MessageModalComponent implements OnInit {


  @ViewChild('messageBody') private messageBody: IonTextarea;
  @ViewChild('fileInput') private fileInput: ElementRef<HTMLInputElement>;
  @ViewChild('messageSubmit') private messageSubmit: IonButton;

  @Input() message;
  messageData: any;
  messageId: number;
  isNarrowVp = false;

  patientForm = new FormGroup({
    messageBody: new FormControl('', Validators.required),
    fileInput: new FormControl(),
  });

  attachments: UploadedDocument[] = [];
  showAttachmentTooltip = false;
  bodyCharCount = 0;
  bodyCharLimit = 600;
  bodyCharLeft = this.bodyCharLimit;
  showRatingTextAreaInput = false;
  showSubmit = false;
  condition = 0;
  newValue = '';
  list: any[] = new Array(5);
  seeReplyMessage = false;

  currentView:
    | "loading"
    | "message"
    | "reply"
    | "message-sent"
    = "message";

  constructor(
    public messagesService: MessagesService,
    private router: Router,
    private previousRouteService: PreviousRouteService,
    private _bhAnalyticsService: BhanalyticsService,
    private _printService: PrintService,
    private stateService: StateService,
    private alertCtrl: AlertController,
    public AWSTranslate: AwsTranslateService,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    private modalService: ModalService,
    public constantsService: ConstantsService,
    private modalController: ModalController,
    private _router: Router,
    private _toastService: ToastService
  ) { }

  async ngOnInit() {
    try {
      this.onWindowResize();
      this.messageId = this.message.id;
      this.messageData = await this.messagesService.message(this.messageId);
      console.log('message Data', this.messageData);

      if (this.messageData && this.messageData.AcceptsAttachments) {
        this.showAttachmentTooltip = true;
        setTimeout(() => {
          this.showAttachmentTooltip = false;
        }, 2000);
      }
    }
    catch (error) {
      throw error;
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    console.log('Viewport size changed', window.innerHeight, window.innerWidth);
    if (window.innerWidth <= 800) {
      this.isNarrowVp = true;
    }
  }


  archiveMessage() {
    this._bhAnalyticsService.clickEvent('Archive Message', this.messageId);
    this.messagesService.archiveMessages([this.messageId]);
    this.presentAlert('Message archived');
    this.modalCtrl.dismiss({ reload: true });
    //this.presentAlert('Message was successfully archived.');
    //this.location.back();
  }

  unarchiveMessage() {
    this._bhAnalyticsService.clickEvent('UnArchive Message', this.messageId);
    this.messagesService.unarchiveMessages([this.messageId]);
    this.presentAlert('Message unarchived');
    this.modalCtrl.dismiss({ reload: true });
    //this.presentAlert('Message was successfully unarchived.');
    //this.location.back();
  }

  async presentAlert(msg) {
    const alert = await this.alertCtrl.create({
      message: msg,
      buttons: [
        'Ok'
      ]
    });
    alert.present();
  }

  noReply() {
    if (this.messageData) {
      return this.messageData.NoReply || (this.messageData.Folder != null && this.messageData.Folder == 'Sent');
    } else {
      return true;
    }
  }

  getLocationBackDescription() {
    var prevUrl = this.previousRouteService.getPreviousUrl();
    if (prevUrl.endsWith("/inbox") || prevUrl.endsWith("/messages")) {
      return " to Inbox";
    } else if (prevUrl.endsWith("/sent")) {
      return " to Sent";
    } else if (prevUrl.endsWith("/archive")) {
      return " to Archive";
    } else {
      return "";
    }
  }

  reply() {
    this.currentView = 'reply';
    this.seeReplyMessage = false;
    this.patientForm.controls.messageBody.setValue('');
    this.patientForm.controls.messageBody.markAsPristine();
    this.patientForm.controls.messageBody.markAsUntouched();
    setTimeout(() => {
      if (this.messageBody != undefined && typeof this.messageBody.setFocus === "function") {
        // safe to use the function
        this.messageBody.setFocus();
      }
    }, 500);
  }

  print() {
    this._bhAnalyticsService.clickEvent('Print Message', this.messageId);
    let params = [
      'height=' + screen.height,
      'width=' + screen.width,
      'fullscreen=yes'
    ].join(',');
    const printContent = document.getElementById("print-section").innerHTML;
    this._printService.printHtmlString(printContent);
  }

  // async presentAlert(msg) {
  //   const alert = await this.alertCtrl.create({
  //     message: msg,
  //     buttons: [
  //       'Ok'
  //     ]
  //   });

  //   alert.present();

  // }

  dismiss() {
    if (this.currentView === 'reply') {
      this.discard();
    } else {
      this.modalCtrl.dismiss();
    }
  }

  /////reply section

  formStateChange() {
    if (this.stateService.patientName != null
      && this.stateService.patientName.getValue() != null
      && this.stateService.patientName.getValue() != ""
      && this.messageBody.value != null
      && this.messageBody.value != "") {
      //this.messageSubmit.disabled = false;
    } else {
      //this.messageSubmit.disabled = true;
    }

    this.bodyCharCount = this.messageBody.value.length;
    this.bodyCharLeft = this.bodyCharLimit - this.bodyCharCount;

  }

  send() {

    Object.keys(this.patientForm.controls).forEach(field => {
      const control: any = this.patientForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if (this.patientForm.valid) {
      this._bhAnalyticsService.clickEvent('Reply Message', this.messageId);
      const subject = this.messagesService.cleanMessage(this.messageData.Subject);
      const body = this.messagesService.cleanMessage(this.messageBody.value);
      this.messagesService.sendReplyMessage({
        OriginalID: this.messageId,
        To: { text: "", value: "", Name: (this.message.isSent) ? this.messageData.ToList[0].Name : this.messageData.From },
        Type: "reply",
        From: this.stateService.patientName.getValue(),
        Subject: "RE: " + subject,
        Text: body,
        Attachments: this.attachments.map(attachment => attachment.DocumentID)
      })
        .then(() => {
          // this.modalCtrl.dismiss();
          //this._router.navigate(['/authenticated/messages/message-sent']);
          this.currentView = 'message-sent';
        })
        .catch(problem => this.modalService.open(
          'Problem sending message',
          problem,
          ModalType.BASIC
        ));
    } else {
      let focusDone = false;
      Object.keys(this.patientForm.controls).forEach(field => {
        const control: any = this.patientForm.get(field);
        control.markAsTouched({ onlySelf: true });
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement != undefined && typeof control.nativeElement.setFocus === "function") {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }
  }

  async discard() {
    const alert = await this.alertCtrl.create({
      header: 'Discard message',
      message: 'Are you sure you want to discard this reply?',
      buttons: [
        {
          text: 'Discard',
          cssClass: 'danger-text',
          handler: () => {
            this.currentView = 'message';
          }
        },
        {
          text: 'Cancel',
          handler: () => { }
        },
      ]
    });
    alert.present();
  }

  uploadImages() {
    this._bhAnalyticsService.clickEvent('upload images', '');
    let fileInput = document.getElementById("fileInput") as HTMLElement;
    fileInput.click();
  }

  async updateAttachments(event) {
    for (const file of event.target.files) {
      this._bhAnalyticsService.clickEvent('update attachment', file.name);
      if (this.attachments.length < this.constantsService.constantsData.maxNumberOfPhotos) {
        if (file.size > this.constantsService.constantsData.maxPhotoUploadSize) {
          const modal = await this.modalService.open("File Upload Failed", `File (${file.name}) size exceeds the allowable 10MB limit. Please resize and try again.`, ModalType.BASIC);
          await modal.onDidDismiss();
        }
        else {
          try {
            const uploaded = await this.messagesService.uploadDocument(file)
            this.attachments.push(uploaded);
          } catch (error) {
            const modal = await this.modalService.open(
              `Problem uploading file ${file.name}`,
              error,
              ModalType.BASIC
            )
            await modal.onDidDismiss();
          }
        }
      } else {
        const modal = await this.modalService.open(
          "Max number of attachments reached",
          `You may only upload up to ${this.constantsService.constantsData.maxNumberOfPhotos} attachments`,
          ModalType.BASIC
        )
        await modal.onDidDismiss()
        break;
      }
    }
  }

  removeAttachment(index: number) {
    this._bhAnalyticsService.clickEvent('remove attachment', index);
    this.attachments.splice(index, 1);
  };

  submitReview(i) {
    this.condition = i + 1;
    this.showRatingTextAreaInput = true;
    if(this.showRatingTextAreaInput) {
      //this.focusTextArea();
    }
  }

  focusTextArea() {
    this.showSubmit = true;
    console.log('event test');
    const textarea ='' ;
    console.log('textarea', textarea);
    const len = this.newValue.length;
    //textarea.focus();
    if (len > 0) {
    //textarea.se(len, len, 'forward');
    }
  }

}
