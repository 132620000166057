import { BhanalyticsService } from './../../../service/bhanalytics.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SmcTableData } from 'src/app/model/SmcTableData';
import { GoalsService } from 'src/app/service/goals.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss'],
})
export class GoalsComponent implements OnInit {
  env = environment;
  category: any;
  public data: SmcTableData;
  @ViewChild('dateColumnTemplate') dateColumnTemplate: ElementRef;
  constructor(
    private _goalsService: GoalsService,
    private _bhAnalyticsService: BhanalyticsService
    ) { }

  async ngOnInit() {
    const category = this.env.banner_seq.goals;
    console.log('cat', category);
    this.category = category;
    var rows = await this._goalsService.getGoals();
    if (rows !== undefined && rows) {
      this.data = new SmcTableData();
      this.data.columns = [
        { name: 'Goal', prop: 'Description', cellClass: 'card-bold' },
        { name: 'Date', prop: 'Date', cellClass: 'card-detail', cellTemplate: this.dateColumnTemplate }
      ];
      this.data.rows = rows;
    }
  }

  print() {
    this._bhAnalyticsService.clickEvent('Print Goals', '');
    window.print();
  }


}
