import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-apple-health-setup',
  templateUrl: './public-apple-health-setup.component.html',
  styleUrls: ['./public-apple-health-setup.component.scss'],
})
export class PublicAppleHealthSetupComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
