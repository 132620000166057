import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-health-record-with-provider-done',
  templateUrl: './share-health-record-with-provider-done.component.html',
  styleUrls: ['./share-health-record-with-provider-done.component.scss'],
})
export class ShareHealthRecordWithProviderDoneComponent  implements OnInit {

  async ngOnInit() {}

}
