import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalType } from 'src/app/model/ModalTypeEnum';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit{

  @Input() title: string;
  @Input() body: string;
  @Input() modalType: ModalType

  isConfirmationModal: boolean;

  constructor(private controller: ModalController) { }

  ngOnInit() {
    this.isConfirmationModal = this.modalType == ModalType.CONFIRMATION
  }

  async confirm(){
    this.controller.dismiss({confirmation : true});
  }

  async cancel(){
    this.controller.dismiss({cancel : true});
  }

}
