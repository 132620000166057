function insertStylesIntoIonSegmentShadowRoot(ionSegment: any) {
    try {
      const options = ionSegment.el.querySelectorAll('ion-segment-button');
      for (const option of options) {
        const css = new CSSStyleSheet;
        css.insertRule('.segment-button-indicator { height: 1px !important }', 0);
        option.shadowRoot.adoptedStyleSheets = [css, ...option.shadowRoot.adoptedStyleSheets];
      }
    }
    catch (error) {
      // Not all browsers support the adoptedStyleSheets property.
      // This is minor graphical fix so failure is acceptable.
    }
  }

  export { insertStylesIntoIonSegmentShadowRoot }  