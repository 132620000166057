import { Injectable } from '@angular/core';
import {HttpBaseService} from "./http-base.service";

@Injectable({
  providedIn: 'root'
})
export class VisitsService {

  constructor(private _httpBaseService: HttpBaseService) { }

  async getVisists(){
    const response = await this._httpBaseService.getList('/ehr/category/encounters', true);
    return response;
  }
}
