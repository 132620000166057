import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { EnrollmentRouteGuardService } from 'src/app/service/enrollment-route-guard.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TermsOfUse } from 'src/app/model/TermsOfUse';
import { TermsOfUseService } from 'src/app/service/terms-of-use.service';
import { CreateAnAccountRequest } from "../../../../model/CreateAnAccountRequest";
import { CreateAccountService } from 'src/app/service/create-account.service';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/service/modal.service';
import { OverlayEventDetail } from '@ionic/core';
import { ModalResponseOptions } from 'src/app/model/ModalResponseOptions';
import { ModalType } from 'src/app/model/ModalTypeEnum';
import { FormsValidationService } from 'src/app/service/forms-validation.service';
import {BhanalyticsService} from "../../../../service/bhanalytics.service";
import { AwsTranslateService } from '../../../../service/aws-translate.service';

@Component({
  selector: 'app-create-an-account',
  templateUrl: './create-an-account.component.html',
  styleUrls: ['./create-an-account.component.scss'],
})
export class CreateAnAccountComponent  {

  form : FormGroup;
  accountInformation : CreateAnAccountRequest = {};

  constructor(private enrollmentRouteGuardService: EnrollmentRouteGuardService,
              private termsOfUseService: TermsOfUseService,
              private createAccountService: CreateAccountService,
              private router: Router,
              private _bhAnalyticsService: BhanalyticsService,
              private modalService: ModalService, private formValidationService: FormsValidationService,
              public AWSTranslate: AwsTranslateService
            )
              {
                this.form = new FormGroup( {
                  FirstName: new FormControl('',Validators.required),
                  MiddleName: new FormControl(''),
                  LastName: new FormControl('',Validators.required),
                  Sex: new FormControl('',Validators.required),
                  DateOfBirth: new FormControl('', Validators.required),
                  RegistrationField1: new FormControl('', Validators.required),
                  Address1: new FormControl('',Validators.required),
                  City: new FormControl('',Validators.required),
                  State: new FormControl('',Validators.required),
                  ZipCode: new FormControl('',[Validators.required,this.formValidationService.getZipCodeValidator()]),
                  Email: new FormControl('',[Validators.required,this.formValidationService.getEmailValidator()])
                });
              }

  async ionViewWillEnter() {
    this.enrollmentRouteGuardService.setNavigate(false);
    this.accountInformation.TermsAccepted = await (this.termsOfUseService.getTermsOfUse());
    this.onFormChange();
  }

  onFormChange(){
    this.form.valueChanges.subscribe( val => {
      Object.keys(val).forEach(key => {
        this.accountInformation[key] = val[key];
      })
    })
  }

  async createAccount() {
    Object.keys(this.form.controls).forEach(field => {
      const control: any = this.form.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if (this.formComplete()) {
      const formattedDate = formatDate(this.accountInformation.DateOfBirth, 'yyyy-MM-dd', 'en-US');
      this.accountInformation.DateOfBirth = formattedDate;
      const response = await (this.createAccountService.createAccount(this.accountInformation));
      if (response && response.RequestID){
         this.router.navigate(["/enroll-success"]);
      }
      this._bhAnalyticsService.clickEvent('Submit Create Account', this.accountInformation.Email);
    } else {
      let focusDone = false;
      Object.keys(this.form.controls).forEach(field => {
        const control: any = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement != undefined && typeof control.nativeElement.setFocus === "function") {
              // safe to use the function
              control.nativeElement.focus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }
  }

  formComplete(): boolean {
    return !!(this.form.valid &&
      this.accountInformation.FirstName &&
      this.accountInformation.LastName &&
      this.accountInformation.Sex &&
      this.accountInformation.RegistrationField1 &&
      this.accountInformation.DateOfBirth &&
      this.accountInformation.Address1 &&
      this.accountInformation.City &&
      this.accountInformation.State &&
      this.accountInformation.ZipCode &&
      this.accountInformation.Email &&
      (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.accountInformation.Email)) &&
      this.accountInformation.TermsAccepted
    );
  }

  now(){
    return (new Date()).toLocaleDateString()
  }
}
