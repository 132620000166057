import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'bh-msg-flow-collapsed-step',
  templateUrl: './bh-msg-flow-collapsed-step.component.html',
  styleUrls: ['./bh-msg-flow-collapsed-step.component.scss'],
})
export class BhMsgFlowCollapsedStepComponent implements OnInit, OnChanges {
  @Input() stepNumber: number;
  @Input() stepName: string;
  @Input() heading = '';
  @Input() icon = '';
  @Input() value = '';
  @Input() isFutureStep;
  @Output() backEvent = new EventEmitter();
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
  }

  goToStep() {
    this.backEvent.emit(this.stepName);
  }

}
