import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Tile } from 'src/app/model/Tile';
import { StateService } from 'src/app/service/state.service';
import { AccountSummaryService } from 'src/app/service/account-summary.service';
import { HealthStatsService } from 'src/app/service/health-stats.service';
import { PersonalInformationService } from 'src/app/service/personal-information.service';
import { DecimalPipe } from '@angular/common';
import { ProviderService } from 'src/app/service/provider.service';
import { AuthenticateService } from "../../../service/authenticate.service";
import { SocialHabitsService } from 'src/app/service/social-habits.service';
import { TestResultsService } from 'src/app/service/test-results.service';
import StatCodes from '../../../../assets/json/vitalCodes.json';

@Component({
  selector: 'app-health-record',
  templateUrl: './health-record.component.html',
  styleUrls: ['./health-record.component.scss'],
  providers: [DecimalPipe]
})
export class HealthRecordComponent implements OnInit {
  pageTitle = '';
  patientFullName: string;
  accountSummary: any;

  DateOfBirth: string;
  Height: string;
  Weight: string;
  BloodPressure: string;
  Pulse: string;
  LastUpdated: Date;
  Pcp: string;
  smokingStatus: string = "-";
  showSummary: boolean = true;


  @Input() selectedUser: UserIdentifier;

  readonly tiles: Tile[] = [
    { image: 'TestResults', text: 'Test results', link: '/authenticated/health-record/test-results' },
    { image: 'all-visits', text: 'Visits', link: '/authenticated/health-record/my-visits' },
    { image: 'Reports', text: 'Reports', link: '/authenticated/health-record/reports' },
    { image: 'Medicines', text: 'Medicines', link: '/authenticated/health-record/medicines' },
    { image: 'HealthStats', text: 'Health stats', link: '/authenticated/health-record/health-stats' },
    { image: 'Conditions', text: 'Health conditions', link: '/authenticated/health-record/health-conditions' },
    { image: 'Allergies', text: 'Allergies', link: '/authenticated/health-record/allergies' },
    { image: 'Concerns', text: 'Concerns', link: '/authenticated/health-record/concerns' },
    { image: 'Vaccinations', text: 'Vaccinations', link: '/authenticated/health-record/vaccinations' },
    { image: 'Goals', text: 'Goals', link: '/authenticated/health-record/goals' },
    { image: 'ShareRecord', text: 'Share my <br>health record', link: '/authenticated/health-record/share/options' }
    /* { image: 'trophy', text: 'Social Habits', link: '/authenticated/health-record/social-habits' }, */
    // remove social habits for now.
    /*
    //TODO: remove timeline and personal information pages.
    { image: 'time', text: 'Timelines', link: '/authenticated/timeline' },
    { image: 'person', text: 'Personal Information', link: '/authenticated/health-record/personal-information' },
    { image: 'heart', text: 'Connect Mobile Apps to your Record', link: '/authenticated/resources/apple-health-setup' },
    */
  ];

  userName: string;

  constructor(
    private _healthStatsService: HealthStatsService,
    private _stateService: StateService,
    private _accountSummaryService: AccountSummaryService,
    private personalInformationService: PersonalInformationService,
    private _decimalPipe: DecimalPipe,
    private _providerService: ProviderService,
    private authService: AuthenticateService,
    private _socialHabitsService: SocialHabitsService,
    private _testResultsService: TestResultsService
  ) { }

  async ngOnInit() {
    this.resetShowSummary(window.innerWidth);

    this.authService.getCachedAuthStatus().subscribe(authStatus => {
      if (authStatus != null && authStatus.PatientName != null) {
        if (authStatus.PatientName.indexOf(" ") > -1) {
          this.userName = authStatus.PatientName.split(" ")[0];
        } else {
          this.userName = authStatus.PatientName;
        }

      }

    });

    // Set Page Title
    this.pageTitle = '<span class="skiptranslation">' + this.toPossessive(this.userName) + '</span> health record';

    this.setNewTestResultsQuantity();

    this._stateService.getPatientName().subscribe(val => this.patientFullName = val);
    this.accountSummary = await this._accountSummaryService.getSummary();
    this._stateService.selectedUser.subscribe(async selectedUser => {
      this.accountSummary = await this._accountSummaryService.getSummary(selectedUser);
    });


    let vitals = await (this._healthStatsService.getVitals());
    if (vitals) {
      vitals = vitals.Observations;
      const wieght = this._healthStatsService.getVitalValueByCode(StatCodes.VitalCodes.Weight, vitals, false);
      const height = this._healthStatsService.getVitalValueByCode(StatCodes.VitalCodes.Height, vitals, false);
      this.Height = this.toFeet(height);
      this.Weight = this._decimalPipe.transform((wieght * 2.20462), "1.0") + " lbs.";
      this.Pulse = this._healthStatsService.getVitalValueByCode(StatCodes.VitalCodes.Pulse, vitals, true);

      let diastolic = this._healthStatsService.getVitalByCode(StatCodes.VitalCodes.DiastolicBP, vitals);
      if (diastolic) {
        let systolic = this._healthStatsService.getVitalByCode(StatCodes.VitalCodes.SystolicBP, vitals);
        if (systolic) {
          this.BloodPressure = systolic.ObservationValue + " / " + diastolic.ObservationValue + " " + diastolic.ObservationUnits;
        }
      }


      let socialHabits = await this._socialHabitsService.getSocialHabits();
      if (socialHabits) {
        let activeHabit = socialHabits.filter(habit => habit.Status == "Active");
        if (activeHabit && activeHabit.length > 0) {
          this.smokingStatus = activeHabit[0].Habit;
        }
      }
    }

    // hack to fix lastupdated error
    try {
      this.LastUpdated = vitals.sort((a, b) => b.ObservationTime > a.ObservationTime ? 1 : -1)[0].ObservationTime;
    } catch (error) {
      this.LastUpdated = null;
    }


    this.personalInformationService.demographics().then(demographics => {
      this.DateOfBirth = demographics.Patient.DateOfBirth;
    });

    this.Pcp = await this._providerService.getPrimaryProviderName();

  }

  async setNewTestResultsQuantity() {
    const testResultsTile = this.tiles.find(tile => tile.link == '/authenticated/health-record/test-results');
    if (testResultsTile) testResultsTile.quantityIcon = (await this._testResultsService.getNewTestResultsCount());
  }

  toPossessive(name: string) {
    if (!name) return '';
    const endsWithS = name.toLowerCase()[name.length - 1] == 's';
    return name + (endsWithS ? "'" : "'s");
  }

  toFeet(height) {
    if (height) {
      let n = Number(height);
      var realFeet = ((n * 0.393700) / 12);
      var feet = Math.floor(realFeet);
      var inches = Math.round((realFeet - feet) * 12);
      return feet + "'" + inches + '"';
    }
  }

  toggleShowSummary() {
    this.showSummary = !this.showSummary;
  }

  @HostListener("window:resize", ['$event'])
  private onResize(event) {
    this.resetShowSummary(event.target.innerWidth);
  }

  private resetShowSummary(width) {
    if (width > 800) {
      this.showSummary = true;
    } else {
      this.showSummary = false;
    }
  }
}
