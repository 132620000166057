import { BhanalyticsService } from './../../../service/bhanalytics.service';
import { Component, OnInit } from '@angular/core';
import { SmcTableData } from 'src/app/model/SmcTableData';
import { HealthRecordService } from '../../../service/health-record.service';
import { AllergiesService } from 'src/app/service/allergies.service';
import { Allergy } from 'src/app/model/Allergy';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-allergies',
  templateUrl: './allergies.component.html',
  styleUrls: ['./allergies.component.scss'],
})
export class AllergiesComponent implements OnInit {

  public data: SmcTableData;
  public data2: SmcTableData;
  public facilitiesList: any[];
  env = environment;
  category: any;
  constructor(
    private _healthRecordService: HealthRecordService,
    private _allergiesService : AllergiesService,
    private _bhAnalyticsService: BhanalyticsService
  ) {}

  async ngOnInit(){
    const category = this.env.banner_seq.allergy;
    console.log('cat', category);
    this.category = category;
    this.loadData();
  }

  async loadData(filter? : Date | null){
    const rows = await this._allergiesService.getAllergies();
    const facilitiesList = await this._healthRecordService.getFacilitiesPhoneNumber();
    this.facilitiesList = facilitiesList.providers;

    this.data = new SmcTableData();
    this.data.Title = 'Active';
    this.data.columns = [
      { name:'Allergy', prop: 'Allergy', cellClass:'card-bold bold'  },
      { name:'Severity', prop:'Severity', cellClass: 'card-detail' },
      { name:'Category',prop:'Category', cellClass: 'card-detail' },
      { name:'Reaction',prop:'Reaction', cellClass: 'card-detail' },
      { name: 'Location', prop: 'LocationFromMultiple', cellClass: 'card-detail' },
      { name: 'Provider Phone', prop: 'ProviderPhone', cellClass: 'card-detail' },
    ];
    this.data.rows = rows
      .filter(a=>a.Status=="Active")
      .map(row => {
        const location = (row.EnteredAt || row.EnteredBy);
        return Object.assign(row, {
          'LocationFromMultiple': location,
          'ProviderPhone': this.getFilteredFacilityNumber(location.toLowerCase())
        });
      });

    if (filter && filter != null){
      this.data.rows = this.data.rows.filter((allergy: Allergy) =>
        allergy.EnteredTime.isSameDay(filter)
      )
    }

    this.data2 = new SmcTableData();
    this.data2.Title = 'Historical';
    this.data2.columns = this.data.columns;
    this.data2.rows = rows
      .filter(a=>a.Status!="Active")
      .map(row => {
        const location = (row.EnteredAt || row.EnteredBy);
        return Object.assign(row, {
          'LocationFromMultiple': location,
          'ProviderPhone': this.getFilteredFacilityNumber(location.toLowerCase())
        });
      });
    if (filter && filter != null){
      this.data2.rows = this.data2.rows.filter((allergy: Allergy) =>
        allergy.EnteredTime.isSameDay(filter)
      )
    }
  }

  // Return facilities phone number if locations match.
  getFilteredFacilityNumber(locationStr: string) {
    const locationName = locationStr.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
    const filteredFacility = this.facilitiesList.filter(facilityNumber => {
      const facilityName = facilityNumber.name.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
      const facilityCode = facilityNumber.code.toLowerCase().replace(/[^a-zA-Z0-9] | \s+ /g, '');
      const namesMatch = (locationName.match(facilityName) || locationName.split(' ').join('').indexOf(facilityName.split(' ').join('')) > -1);
      const codeMatchesName = facilityCode.match(locationName);
      return (codeMatchesName || namesMatch);
    });
    return (filteredFacility[0] ? filteredFacility[0].phone :  '');
  }

  print() {
    this._bhAnalyticsService.clickEvent('Print Allergies', '');
    window.print();
  }
}
