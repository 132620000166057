import { Component, OnInit } from '@angular/core';

/** Confirmation page for change-password */
@Component({
  selector: 'app-change-password-done',
  templateUrl: './change-password-done.component.html',
  styleUrls: ['./change-password-done.component.scss'],
})
export class ChangePasswordDoneComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
