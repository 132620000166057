import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, RouteConfigLoadEnd } from '@angular/router';
import { MatTabGroup } from '@angular/material';

interface Tab {
  path: string;
  label: string;
  quantityIcon?: number;
}

@Component({
  selector: 'smc-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {

  @Input() tabs: Tab[];
  activeLinkIndex = -1;

  constructor(private router: Router) { }

  onTabChange(event) {
    const path = this.tabs[event.index].path;
    this.router.navigate([path]);
  }

  ngOnInit(): void {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.tabs.indexOf(this.tabs.find(tab => tab.path === '.' + this.router.url));
    });
  }

  onActivate(event) {
    const pageContent = document.querySelector('.page-content');
    if (pageContent) {
      pageContent.scrollTo(0, 0);
    }
  }

  trimStart(text, char) {
    while (text.charAt(0) === char) {
      text = text.substr(1);
    }
    return text;
  }

}
