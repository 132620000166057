import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bh-star-rating',
  templateUrl: './bh-star-rating.component.html',
  styleUrls: ['./bh-star-rating.component.scss'],
})
export class BhStarRatingComponent implements OnInit {

  @Input() index: number;
  @Input() selected: number;
  @Input() hover: number;


  constructor() { }

  ngOnInit() {}

}
