import { Component, OnInit, Input } from '@angular/core';
import { RoleType } from 'src/app/model/RoleType';
import { RolesService } from 'src/app/service/roles.service';

@Component({
  selector: 'smc-role-hide',
  templateUrl: './smc-role-hide.component.html',
  styleUrls: ['./smc-role-hide.component.scss'],
})
export class SmcRoleHideComponent implements OnInit {

  @Input('roleType') roleType : RoleType[];
  userRoles : RoleType[];

  constructor(private rolesService: RolesService) { }

  async ngOnInit() {
    this.userRoles = await this.rolesService.getRoles();
  }

  public showComponent(){
    if (this.userRoles){
      const intersection = this.userRoles.uniqueIntersection(this.roleType);
      const show = intersection.length == 0;
      return show;
    }
    return false;
  }

}
