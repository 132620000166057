import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { SMCDataList } from 'src/app/model/SMCDataList';
import { Document } from "../../../../model/Document";
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { PrintService } from 'src/app/service/print.service';


@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.scss'],
  providers: [DatePipe]
})
export class ReportDetailComponent implements OnInit {

  @ViewChild('printSection') private printSectionElement: ElementRef<HTMLDivElement>;

  selectedDocument: Document | null = null;
  constructor(
    private _location: Location,
    private _datePipe: DatePipe,
    private _bhAnalyticsService: BhanalyticsService,
    private _printService: PrintService
  ) { }

  documentData: SMCDataList;

  ngOnInit() {
    if (history.state.data) {
      this.selectedDocument = history.state.data;
      this.formatReport();
      this._bhAnalyticsService.clickEvent('report-detail-type', this.selectedDocument.DocumentType);

      this.documentData = {
        options: { hideEmptyFields: true },
        items: [
          { label: 'Date', value: this._datePipe.transform(this.selectedDocument.EnteredTime, 'MMM dd, yyyy') },
          { label: 'Type', value: this.selectedDocument.DocumentType },
          { label: 'Provider', value: this.selectedDocument.Provider }
        ]
      };


    } else {
      this._location.back();
    }
  }

  formatReport() {
    if (this.selectedDocument && this.selectedDocument.Document) {
      console.log('selectedDocument', this.selectedDocument.Document);
      const splitArray = this.selectedDocument.Document.split(/<br\/?>/i);

      console.log('split lines:', splitArray);
      let formattedText = '';

      for (const line of splitArray) {
        if (line) {
          if (line.length < 40) {
            formattedText += '<h3 class="report-heading">'+ line +'</h3>';
          } else {
            formattedText += '<p>'+ line +'</p>';
          }
        }
      }

      console.log('formattedText:', formattedText);

      this.selectedDocument.FormattedDocument = formattedText;
      // Output: ["This is a", "sample", "text."]

      // const regex = /^[\s\S]/g;
      // const regex = /[\r\n]+(.+?)[\r\n]+/g;

      // const matches = this.selectedDocument.Document.match(regex);
      // console.log('matches', matches);


    }
  }

  goBack(): void {
    this._location.back();
  }

  print() {
    this._bhAnalyticsService.clickEvent('Print Report Detail', this.selectedDocument.DocumentName);
    // this._bhAnalyticsService.clickEvent('Print Report Type', this.selectedDocument.DocumentType);
    this._printService.printHtmlString(this.printSectionElement.nativeElement.innerHTML);
  }

}
