import { Component, OnInit } from '@angular/core';

/** Public version of the health dictionary page that can be accessed through Resources before logging in. */
@Component({
  selector: 'app-public-health-dictionary',
  templateUrl: './public-health-dictionary.component.html',
  styleUrls: ['./public-health-dictionary.component.scss'],
})
export class PublicHealthDictionaryComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
