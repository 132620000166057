import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConstantsService } from 'src/app/service/constants.service';

@Component({
  selector: 'app-request-appointment-change-modal',
  templateUrl: './request-appointment-change-modal.component.html',
  styleUrls: ['./request-appointment-change-modal.component.scss'],
})
export class RequestAppointmentChangeModalComponent implements OnInit {

  constructor(
    public modalController: ModalController,
    public constantsService: ConstantsService
  ) { }

  ngOnInit() {}

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
