import { Injectable } from '@angular/core';
import {ConstantsService} from "./constants.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class RequestAppointmentService {

  constructor(private constantsService: ConstantsService,
              private httpClient: HttpClient) { }

  async getProviders(searchKey: string) {
    const url = `${this.constantsService.constantsData.apiUrl}/messages/search/recipients`;

    const response = await this.httpClient.post(url,
      "{SearchKey: \"" + searchKey + "\", MessageType: \"appointment\"}",
      { headers: { 'SMC-Ignore-500-Error': 'true' } }).toPromise() as any;
    if (response == undefined) {
      return [];
    }
    return response['children'];
  }



  async requestAppointment(data: string) {

    //console.log("refill request data: " + data);
    const url = `${this.constantsService.constantsData.apiUrl}/messages/send/appointmentRequest`;
    /*
    {
      "AppointmentOptions": {
        "alternateLocation": true
        "previouslySeen": true
        "seeDifferentProviders": true
      },
      "AppointmentReason": {
        "AppointmentFor": "ISC_PRIMARY_CARE"
        "AppointmentText": "my concern"
        AppointmentType": "ICS_NEW_CONCERN", ISC_FOLLOW_UP, ISC_OTHER,
      },
      "AppointmentTime": {
        "ClearDefault": true
        "Comment": "Schedule Comment"
        "Days": [ "ISC_MONDAY_MORNING", "ISC_WEDNESDAY_MORNING", "ISC_FRIDAY_MORNING" ]
        "IsDefault": true
        "TimeSlot": "ISC_MESSAGES_REQUEST_APPOINTMENT_WITHIN_TWO_WEEKS"
      },
      "ContactInfo": {
        "ClearDefault": true
        "Extension": 12
        "PhoneNumber": 1234567
        "SendMessage":
        "TimeToCall": "ISC_MESSAGES_REQUEST_APPOINTMENT_CALL_MORNING"
        "VoiceMailOK": true
      },
      "Location": 11
      "Provider": 45
      "Subject": "Appointment Request"
      "To": [45]
    }
    */

    const response = await this.httpClient.post(url, data).toPromise() as any;

    return response['children'];
  }
}
