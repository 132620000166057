import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/service/modal.service';
import { ModalType } from 'src/app/model/ModalTypeEnum';
import { Appointment } from 'src/app/model/Appointment';
import { Location, DatePipe } from '@angular/common';
import { AppointmentsResponse } from 'src/app/model/AppointmentsResponse';
import { ModalController, Platform } from '@ionic/angular';
import { RequestAppointmentChangeModalComponent } from './request-appointment-change-modal/request-appointment-change-modal.component';
import { BhanalyticsService } from "../../../../service/bhanalytics.service";
import { DateHandlerService } from 'src/app/service/date-handler.service';
import { NewMessagePage } from '../../messages/new-message/new-message.page';

@Component({
  selector: 'app-upcoming-visit',
  templateUrl: './upcoming-visit.component.html',
  styleUrls: [
    './upcoming-visit.component.scss',
    '../../../shared/smc-data-list/lightweight-list-style.scss'
  ],
  providers: [DatePipe]
})
export class UpcomingVisitComponent implements OnInit {


  appointmentDetails: Appointment = null;

  constructor(
    private modalService: ModalService,
    private _location: Location,
    private _datePipe: DatePipe,
    public modalController: ModalController,
    private _bhAnalyticsService: BhanalyticsService,
    private platform: Platform,
    private _dateHandler: DateHandlerService) { }

  ngOnInit() {
    if (history.state.data) {
      this.appointmentDetails = history.state.data;
    } else {
      this._location.back();
    }
  }

  async requestChange() {
    this._bhAnalyticsService.clickEvent('appointment change', '');
    const modal = await this.modalController.create({
      component: RequestAppointmentChangeModalComponent
    });
    this.modalService.register(modal);

    return await modal.present();
  }

  reschedule() {

  }

  cancel() {

  }

  async newMessage(mode) {
    this._bhAnalyticsService.clickEvent('NME from sidenav: ', mode);
    const modal = await this.modalController.create({
      component: NewMessagePage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        mode,
        upcomingAppt: this.appointmentDetails
      },
    });
    modal.onDidDismiss().then(async (data) => {

    });
    await modal.present();
  }

  addToCalendar() {
    if (this.appointmentDetails) {
      let text = "BEGIN:VCALENDAR\r\n";
      text = text + "VERSION:2.0\r\n";
      text = text + "PRODID:-//ZContent.net//Zap Calendar 1.0//EN\r\n";
      text = text + "CALSCALE:GREGORIAN\r\n";
      text = text + "METHOD:REQUEST\r\n";
      text = text + "BEGIN:VEVENT\r\n";
      text = text + "SUMMARY:" + this.appointmentDetails.Provider + " - " + this.appointmentDetails.Location + "\r\n";
      text = text + "SEQUENCE:0\r\n";
      text = text + "STATUS:CONFIRMED\r\n";
      text = text + "DTSTART:" + this._datePipe.transform(this._dateHandler.parseDateString(this.appointmentDetails.FromTime), 'yyyyMMddThhmmss') + "\r\n";
      if (this.appointmentDetails.ToTime) {
        text = text + "DTEND:" + this._datePipe.transform(this._dateHandler.parseDateString(this.appointmentDetails.ToTime), 'yyyyMMddThhmmss') + "\r\n";
      }
      text = text + "DTSTAMP:" + this._datePipe.transform(this._dateHandler.parseDateString(this.appointmentDetails.Timestamp), 'yyyyMMddThhmmss') + "\r\n";
      text = text + "LOCATION:" + this.appointmentDetails.Location + "\r\n";
      text = text + "DESCRIPTION: Provider: " + this.appointmentDetails.Provider + ", Location: " + this.appointmentDetails.Location + "  \r\n";
      text = text + "END:VEVENT\r\n";
      text = text + "END:VCALENDAR\r\n";
      var textFile = null;
      var data = new Blob([text], { type: 'text/calendar' });
      if (textFile !== null) {
        window.URL.revokeObjectURL(textFile);
      }
      location.href = window.URL.createObjectURL(data);
    }
  }

  addToCalendar2() {
    const fileName = 'appointment.ics';
    const summary = `Provider: ${this.appointmentDetails.Provider} - ${this.appointmentDetails.Location}`;
    const description = `Provider: ${this.appointmentDetails.Provider}, Location: ${this.appointmentDetails.Location}`;
    const dtStart = this._datePipe.transform(this._dateHandler.parseDateString(this.appointmentDetails.FromTime), 'yyyyMMddThhmmss');
    const dtEnd = this._datePipe.transform(this._dateHandler.parseDateString(this.appointmentDetails.ToTime), 'yyyyMMddThhmmss');
    const location = this.appointmentDetails.Location;
    let url = '';

    // Create a download link for the .ics file
    let icsFileContent =
      `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${dtStart}\r\n`;

    // DTEND:${dtEnd}
    if (this.appointmentDetails.ToTime) {
      icsFileContent += `DTEND:${dtEnd}\r\n`;
    }

    icsFileContent += `
LOCATION:${location}
SUMMARY:${summary}
END:VEVENT
END:VCALENDAR`;

    var downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/calendar;charset=utf-8,' + encodeURIComponent(icsFileContent);
    downloadLink.download = fileName;
    downloadLink.click();
  }

  contactProvider() {
    const provider = this.appointmentDetails.Provider.replace(/ /g, '%20');
    const url = 'https://providers.baystatehealth.org/search?unified=' + provider + '&sort=networks%2Crelevance%2Cavailability_density_best';
    window.open(url);
  }

  print() {
    this._bhAnalyticsService.clickEvent('Print Upcoming Appointment', '');
    window.print();
  }

}
