import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollingUtilitiesService {

  constructor() { }

  private _scrollToTopSubscriber: Subscriber<boolean>;
  scrollToTopEvents = new Observable<boolean>(subscriber => this._scrollToTopSubscriber = subscriber);

  scrollContentToTop() {
    this._scrollToTopSubscriber.next(true);
  }
}
