import { environment } from 'src/environments/environment';
import { PciLocation, PciLocationGroup } from './../../../../model/PatientCheckinData';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PatientCheckinData } from 'src/app/model/PatientCheckinData';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Navigation, Router } from '@angular/router';
import { FormsValidationService } from 'src/app/service/forms-validation.service';
import { PatientCheckinService } from 'src/app/service/patient-checkin.service';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { Workflow } from 'src/app/model/Workflow';
import { first } from 'rxjs/operators';
import { AwsTranslateService } from '../../../../service/aws-translate.service';
import { ModalController, NavController } from '@ionic/angular';
import { PciLabInfoPage } from '../pci-lab-info/pci-lab-info.page';

@Component({
  selector: 'bh-pci-service-lines',
  templateUrl: './pci-service-lines.component.html',
  styleUrls: ['./pci-service-lines.component.scss'],
})
export class PciServiceLinesComponent implements OnChanges {
  env = environment;
  @Input() checkinData: PatientCheckinData;
  @Output() next: EventEmitter<PatientCheckinData> = new EventEmitter();
  @Output() back: EventEmitter<PatientCheckinData> = new EventEmitter();
  groups: PciLocationGroup[] = [];
  labModal: any;
  workflows: Workflow[] = [
    { Title: 'Service', Content: '', State: 'current' },
    { Title: 'Reason for visit', Content: '', State: 'future' },
    { Title: 'Appointment', Content: '', State: 'future' },
    { Title: 'Location', Content: '', State: 'future' },
    { Title: 'Patient Info', Content: '', State: 'future' }
  ];

  form1: FormGroup = new FormGroup(
    {
      serviceLine: new FormControl('', [Validators.required]),
    }
  );
  constructor(
    public router: Router,
    private formValidationService: FormsValidationService,
    private pciService: PatientCheckinService,
    private analytics: BhanalyticsService,
    public AWSTranslate: AwsTranslateService,
    private modalCtrl: ModalController,

  ) {
  }


  /**
   * On load, update service line selection from global variable
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.checkinData) {
      // console.log('changes', changes);
      this.loadLocations();
    }
  }

  /**
   * On selection of service line, update check-in data
   */
  formStateChange() {
    const group: PciLocationGroup = this.form1.controls.serviceLine.value;
    if (group) {
      this.checkinData.group = group;
      this.checkinData.lg_seq = group.group_dli_seq;
    }
  }

  loadLocations() {
    this.checkinData.checkin_method = 'MyBaystate';
    // Copied from PCI-Information next()
    this.pciService.getLocationInfo(this.env.PCI_LOC_LOOKUP_ID).pipe(first()).subscribe((val: PciLocation) => {
      this.checkinData.location = val;
      this.form1.controls.serviceLine.setValue(this.checkinData.group);
      this.groups = this.checkinData.location.groups;
      console.log('groups', this.groups);

    },
      (err) => {
        console.log(err);
      });

  }

  /**
   * Return to previous page
   */
  previousPage() {
    this.analytics.clickEvent('PCI-ServiceLines: PreviousButton', '');
    this.back.emit(this.checkinData);
  }

  /**
   * Check form validity, continue to next page
   */
  nextPage() {

    if (this.form1.valid) {
      this.analytics.clickEvent('PCI-ServiceLines: NextButton', 'Form valid');
      // Temporarily for phase 2, use hard-coded lab and rad locations
      // Remove this line below when implementing dynamic location selection
      this.checkinData.dli_seq = (this.checkinData.group.group_display_name.toLowerCase() === 'lab') ?
      this.env.PCI_LAB_LOC_ID : this.env.PCI_RAD_LOC_ID;
      console.log('dli_seq', this.checkinData.dli_seq);
      if(this.checkinData.dli_seq === 6) {
          this.callLabInfoModal();
         //this.next.emit(this.labModal)
      } else {
        this.next.emit(this.checkinData);
      }
    } else {
      this.analytics.clickEvent('PCI-ServiceLines: NextButton', 'Form invalid');
      let focusDone = false;
      Object.keys(this.form1.controls).forEach(field => {
        const control: any = this.form1.get(field);
        control.markAsTouched({ onlySelf: true });
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement !== undefined && typeof control.nativeElement.setFocus === 'function') {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }

    this.labModal = async () => {
      this.analytics.clickEvent('Lab info modal: ', 'open');
      const modal = await this.modalCtrl.create({
        component: PciLabInfoPage,
        cssClass: "wide-modal",
        backdropDismiss: false,
        componentProps: {},
      });
      modal.onDidDismiss().then(async (data) => {

      });
      await modal.present();
    }

  }

 async callLabInfoModal() {
  this.analytics.clickEvent('Lab info modal: ', 'open');
  const modal = await this.modalCtrl.create({
    component: PciLabInfoPage,
    cssClass: "wide-modal",
    backdropDismiss: false,
    componentProps: {
    },
  });
  modal.onDidDismiss().then(async (data) => {

  });
  await modal.present();
}




}
