import { Injectable } from '@angular/core';
import { Workflow } from "../model/Workflow";
import { FormControl, FormGroup } from "@angular/forms";
import { Recipients } from "../model/Recipients";
import { after } from "selenium-webdriver/testing";

@Injectable({
  providedIn: 'root'
})
export class RequestAppointmentStateService {

  state: {
    subjectPrefix: string,
    provider: any,
    reasonType: string,
    reasonName: string,
    reasonText: string,
    // comment: string,
    routineText: string,
    newText: string,
    followupText: string,
    referral: string,
    referralText: string,
    other: string,
    otherText: string,

    timeFrame: string,
    minDate: string, //TODO: min and max are not used by the API.
    maxDate: string,
    preferredTime: string,
    slotSunAM: boolean,
    slotMonAM: boolean,
    slotTueAM: boolean,
    slotWedAM: boolean,
    slotThuAM: boolean,
    slotFriAM: boolean,
    slotSatAM: boolean,
    slotSunPM: boolean,
    slotMonPM: boolean,
    slotTuePM: boolean,
    slotWedPM: boolean,
    slotThuPM: boolean,
    slotFriPM: boolean,
    slotSatPM: boolean,
    anotherLocation: boolean,
    thisLocation: boolean,
    scheduleComments: string,

    contactType: string,
    contactPhoneNumber: string,
    contactPhoneNumberExt: string,
    contactBestTime: string,
    contactLeaveVoicemail: boolean,
    contactSaveContact: boolean
  };

  constructor() {
    this.clearState();
  }

  updateState(key: string, value: any) {
    this.state[key] = value;
  }

  saveForm(patientForm: FormGroup) {
    for (let formControlName in patientForm.controls) {
      this.state[formControlName] = patientForm.get(formControlName).value;
    }
  }

  loadForm(patientForm: FormGroup) {
    for (let formControlName in patientForm.controls) {
      patientForm.get(formControlName).setValue(this.state[formControlName]);
    }
  }

  getTimeFrame(): string {
    var timeframe = "";

    switch (this.state.timeFrame) {
      case "NextAvail":
        timeframe = "ISC_MESSAGES_REQUEST_APPOINTMENT_NEXT_AVAILABLE";
        break;
      case "NextWeek":
        timeframe = "ISC_MESSAGES_REQUEST_APPOINTMENT_WITHIN_WEEK";
        break;
      case "NextTwoWeeks":
        timeframe = "ISC_MESSAGES_REQUEST_APPOINTMENT_WITHIN_TWO_WEEKS";
        break;
      case "NextMonth":
        timeframe = "ISC_MESSAGES_REQUEST_APPOINTMENT_WITHIN_NEXT_MONTH";
        break;
      case "NextTwoMonths":
        timeframe = "ISC_MESSAGES_REQUEST_APPOINTMENT_WITHIN_TWO_MONTHS";
        break;
      case "NextThreeMonths":
        timeframe = "ISC_MESSAGES_REQUEST_APPOINTMENT_WITHIN_THREE_MONTHS";
        break;
      case "NextSixMonths":
        timeframe = "ISC_MESSAGES_REQUEST_APPOINTMENT_WITHIN_SIX_MONTHS";
        break;
      case "NextYear":
        timeframe = "ISC_MESSAGES_REQUEST_APPOINTMENT_WITHIN_ONE_YEAR";
        break;
    }

    return timeframe;
  }

  buildDaysList(): string[] {
    var daysList = [];

    if (this.state.slotSunAM) {
      daysList.push("ISC_SUNDAY_MORNING");
    }
    if (this.state.slotMonAM) {
      daysList.push("ISC_MONDAY_MORNING");
    }
    if (this.state.slotTueAM) {
      daysList.push("ISC_TUESDAY_MORNING");
    }
    if (this.state.slotWedAM) {
      daysList.push("ISC_WEDNESDAY_MORNING");
    }
    if (this.state.slotThuAM) {
      daysList.push("ISC_THURSDAY_MORNING");
    }
    if (this.state.slotFriAM) {
      daysList.push("ISC_FRIDAY_MORNING");
    }
    if (this.state.slotSatAM) {
      daysList.push("ISC_SATURDAY_MORNING");
    }
    if (this.state.slotSunPM) {
      daysList.push("ISC_SUNDAY_AFTERNOON");
    }
    if (this.state.slotMonPM) {
      daysList.push("ISC_MONDAY_AFTERNOON");
    }
    if (this.state.slotTuePM) {
      daysList.push("ISC_TUESDAY_AFTERNOON");
    }
    if (this.state.slotWedPM) {
      daysList.push("ISC_WEDNESDAY_AFTERNOON");
    }
    if (this.state.slotThuPM) {
      daysList.push("ISC_THURSDAY_AFTERNOON");
    }
    if (this.state.slotFriPM) {
      daysList.push("ISC_FRIDAY_AFTERNOON");
    }
    if (this.state.slotSatPM) {
      daysList.push("ISC_SATURDAY_AFTERNOON");
    }

    return daysList;
  }

  // getReasonText(): string {
  //   var reasonText = "";
  //   switch (this.state.reasonType) {
  //     case 'routine':

  //   }
  //   if (this.state.reasonType == "routine") {
  //     reasonText = "Routine visit: " + this.state.routineText;
  //   } else if (this.state.reasonType == "new") {
  //     reasonText = "New concern: " + this.state.newText;
  //   } else if (this.state.reasonType == "followup") {
  //     reasonText = "Follow-up visit: " + this.state.followupText;
  //   } else if (this.state.reasonType == "referral") {
  //     reasonText = "Referral: " + this.state.referralText;
  //   } else if (this.state.reasonType == "other") {
  //     reasonText = "Other: " + this.state.otherText;
  //   }
  //   return reasonText;
  // }

  buildRequestAppointmentRequest(): any {
    var sendMessage = false; // contactType == 'phone'
    if (this.state.contactType == 'message') {
      sendMessage = true;
    }

    var provider = this.state.provider ? this.state.provider.Provider.ID : null;
    var clinicalGroup = this.state.provider ? this.state.provider.ClinicalGroup.ID : null;
    // var reasonText = this.getReasonText();
    var reasonText = this.state.reasonText;
    var daysList = this.buildDaysList();
    var timeframe = this.getTimeFrame();

    var json =
    {

      AppointmentOptions: {
        alternateLocation: this.state.anotherLocation,
        previouslySeen: false, //TODO: DEFAULT?
        seeDifferentProviders: this.state.thisLocation
      },
      AppointmentReason: {
        AppointmentFor: "", //"ISC_PRIMARY_CARE", //TODO: DEFAULT?
        AppointmentText: reasonText,
        AppointmentType: this.state.reasonType
      },
      AppointmentTime: {
        ClearDefault: false, //TODO: DEFAULT?
        Comment: this.state.scheduleComments,
        Days: daysList,
        IsDefault: false, //TODO: DEFAULT?
        //TimeSlot: this.state.minDate + " - " + this.state.maxDate,//"" //"ISC_MESSAGES_REQUEST_APPOINTMENT_WITHIN_TWO_WEEKS" //TODO: DEFAULT?
        TimeSlot: timeframe
      },
      ContactInfo: {
        ClearDefault: false, //TODO: DEFAULT?
        Extension: this.state.contactPhoneNumberExt,
        IsDefault: this.state.contactSaveContact,
        PhoneNumber: this.state.contactPhoneNumber,
        SendMessage: sendMessage,
        TimeToCall: this.state.contactBestTime,
        VoiceMailOK: this.state.contactLeaveVoicemail
      },
      // provider query - clinical group id
      Location: clinicalGroup,
      Provider: provider,
      Subject: this.state.subjectPrefix + this.state.reasonName,
      To: [provider]
    }
      ;
    return json;
  }

  getProviderSummary(): string {
    var provider = this.getProviderSummarySimple();
    if (this.state.provider != "") {
      var providerJSON = this.state.provider;
      if (providerJSON.ClinicalGroup.Address) {
        provider = provider + "<br> " + providerJSON.ClinicalGroup.Address.Line1 + " " + providerJSON.ClinicalGroup.Address.Line2 + "<br>" + providerJSON.ClinicalGroup.Address.City + ", " + providerJSON.ClinicalGroup.Address.State + " " + providerJSON.ClinicalGroup.Address.PostalCode;
      }
    }
    return provider;
  }

  getProviderSummarySimple(): string {
    var provider = "";
    if (this.state.provider != "") {

      var providerJSON = this.state.provider;
      provider = providerJSON.Provider.FirstName + " " + providerJSON.Provider.LastName;


      if (providerJSON.ClinicalGroup.Address) {
        provider = provider + "<br>" + providerJSON.ClinicalGroup.Name;
      }
    }
    return provider;
  }

  getReasonSummary(): string {
    var reason = "";
    if (this.state.reasonType != "") {
      var reasonText = this.state.reasonText;

      reason = reasonText;
    }
    return reason;
  }

  getScheduleSummary(isFull: boolean = false): string {
    var schedule = "";
    var preferred = "";
    var dateString = "";

    switch (this.state.timeFrame) {
      case "NextAvail":
        dateString = "Next opening";
        break;
      case "NextWeek":
        dateString = "Next week";
        break;
      case "NextTwoWeeks":
        dateString = "Next 2 weeks";
        break;
      case "NextMonth":
        dateString = "Next month";
        break;
      case "NextTwoMonths":
        dateString = "Next 2 month";
        break;
      case "NextThreeMonths":
        dateString = "Next 3 month";
        break;
      case "NextSixMonths":
        dateString = "Next 6 month";
        break;
      case "NextYear":
        dateString = "Next year";
        break;
    }

    /*
   if (this.state.minDate != "") {
     dateString += this.state.minDate;
   }
   if (this.state.minDate != "" || this.state.maxDate != "") {
     dateString += " - ";
   }
   if (this.state.maxDate != "") {
     dateString += this.state.maxDate;
   }
   */

    if (this.state.preferredTime == "select") {
      var morningsString = ""
      var afternoonString = "";

      if (this.state.slotSunAM) {
        morningsString += "S,";
      }
      if (this.state.slotMonAM) {
        morningsString += "M,";
      }
      if (this.state.slotTueAM) {
        morningsString += "T,";
      }
      if (this.state.slotWedAM) {
        morningsString += "W,";
      }
      if (this.state.slotThuAM) {
        morningsString += "T,";
      }
      if (this.state.slotFriAM) {
        morningsString += "F,";
      }
      if (this.state.slotSatAM) {
        morningsString += "S,";
      }

      if (morningsString != "") {
        morningsString += " Mornings";
      }

      if (this.state.slotSunPM) {
        afternoonString += "S,";
      }
      if (this.state.slotMonPM) {
        afternoonString += "M,";
      }
      if (this.state.slotTuePM) {
        afternoonString += "T,";
      }
      if (this.state.slotWedPM) {
        afternoonString += "W,";
      }
      if (this.state.slotThuPM) {
        afternoonString += "T,";
      }
      if (this.state.slotFriPM) {
        afternoonString += "F,";
      }
      if (this.state.slotSatPM) {
        afternoonString += "S,";
      }

      if (afternoonString != "") {
        afternoonString += " Afternoons";
      }

      preferred = morningsString + "<br/>" + afternoonString;
    } else {
      preferred = "Next Timeslot"
    }

    schedule = dateString + "<br/>" + preferred;

    if (isFull) {
      if (this.state.anotherLocation) {
        schedule = schedule + "<br>Okay with seeing my provider at another location."
      }
      if (this.state.thisLocation) {
        schedule = schedule + "<br>Okay with seeing another provider."
      }
    }

    return schedule;
  }

  getContactSummary(): string {
    var contact = "";
    if (this.state.contactType == 'message') {
      contact = "Send a secure message";
    } else if (this.state.contactType == 'phone') {
      contact ="Call me at " + this.state.contactPhoneNumber + " " + this.state.contactPhoneNumberExt;

    }
    return contact;
  }

  updateWorkflowContents(workflows: Workflow[]) {
    workflows[0].Content = this.getProviderSummarySimple();
    workflows[1].Content = this.getReasonSummary();
    workflows[2].Content = this.getScheduleSummary();
    workflows[3].Content = this.getContactSummary();
  }

  clearState() {
    this.state = {
      subjectPrefix: '',
      provider: "",
      reasonType: "",
      reasonName: '',
      reasonText: '',
      routineText: "",
      newText: "",
      followupText: "",
      referral: "",
      referralText: "",
      other: "",
      otherText: "",

      timeFrame: "",
      minDate: "", //TODO: min and max are not used by the API.
      maxDate: "",
      preferredTime: "",
      slotSunAM: false,
      slotMonAM: false,
      slotTueAM: false,
      slotWedAM: false,
      slotThuAM: false,
      slotFriAM: false,
      slotSatAM: false,
      slotSunPM: false,
      slotMonPM: false,
      slotTuePM: false,
      slotWedPM: false,
      slotThuPM: false,
      slotFriPM: false,
      slotSatPM: false,
      anotherLocation: false,
      thisLocation: false,
      scheduleComments: "",

      contactType: "",
      contactPhoneNumber: "",
      contactPhoneNumberExt: "",
      contactBestTime: "",
      contactLeaveVoicemail: false,
      contactSaveContact: false
    };
  }
}
