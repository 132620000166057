import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/service/constants.service';

@Component({
  selector: 'app-forms-submission-complete',
  templateUrl: './forms-submission-complete.component.html',
  styleUrls: ['./forms-submission-complete.component.scss'],
})
export class FormsSubmissionCompleteComponent  {

  constructor(
    public constantsService: ConstantsService
  ) {}

}
