import { Component, OnInit } from '@angular/core';
import { Tile } from 'src/app/model/Tile';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticateService } from 'src/app/service/authenticate.service';
import { MenuController, ModalController, PopoverController } from "@ionic/angular";
import { StateService } from 'src/app/service/state.service';
import { PersonalCommunityUser } from 'src/app/model/PersonalCommunityUser';
import { MatSelectChange } from '@angular/material';
import { UserFriendlyError } from 'src/app/model/UserFriendlyError';
import { RolesService } from 'src/app/service/roles.service';
import { RoleType } from 'src/app/model/RoleType';
import { AccountMenuComponent } from '../../shared/account-menu/account-menu.component';
import { SelectUserListComponent } from '../../shared/select-user-list/select-user-list.component';
import { BhanalyticsService } from "../../../service/bhanalytics.service";
import { Subscription } from 'rxjs';
import { ProxyUser } from 'src/app/model/AuthStatus';
import { NewMessagePage } from '../messages/new-message/new-message.page';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class MainNavigationComponent {

  public mobile: boolean;
  private _proxyOnlyUserSubscription: Subscription;
  private _isLoggedInSubscription: Subscription;
  isProxyOnlyUser: boolean;
  navItems: Tile[] = [
    { image: 'Home_Outline_Blk', text: 'Home', link: "/authenticated/home" },
    { image: 'HealthRecord_Outline_Blk', text: 'Health Record', link: "/authenticated/health-record", disabledForProxyOnlyUser: true },
    { image: 'Messages_Outline_Blk', text: 'Messages', link: "/authenticated/messages", disabledForProxyOnlyUser: true },
    { image: 'Resources_Outline_Blk', text: 'Resources', link: "/authenticated/resources" },
    { image: 'Account_Outline_Blk', text: 'Account', link: "/authenticated/account" },
  ];

  currentRoute: string;

  userName: string;

  proxyUsers: ProxyUser[];

  constructor(
    private menu: MenuController,
    private router: Router,
    private authService: AuthenticateService,
    private stateService: StateService,
    private rolesService: RolesService,
    private popoverController: PopoverController,
    private analytics: BhanalyticsService,
    private modalCtrl: ModalController
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.urlAfterRedirects;
      }
    });
  }

  async ngOnInit() {
    this._proxyOnlyUserSubscription = this.rolesService.isProxyOnlyUser.subscribe(isProxyOnlyUser => this.isProxyOnlyUser = isProxyOnlyUser);

    const userRoles: RoleType[] = await this.rolesService.getRoles()
    this.navItems = this.navItems.filter(tile => {
      const blockedRolesForRoute = this.rolesService.getBlockedRolesByComponent(tile.link.split("/"));
      const intersection = userRoles.uniqueIntersection(blockedRolesForRoute);
      const allowNavigate = intersection.length == 0;
      return allowNavigate;
    })

    this.authService.getCachedAuthStatus().subscribe(authStatus => {
      if (authStatus != null) this.userName = authStatus.PatientName;
    });

    this._isLoggedInSubscription = this.authService.isLoggedIn().subscribe((isLoggedIn: boolean) => {
      if (isLoggedIn) this.authService.getProxyFor().then(value => this.proxyUsers = value);
    });

  }

  onResize(event) {

  }

  menuToggle() {
    this.menu.toggle('first');
  }

  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  openEnd() {
    this.menu.open('end');
  }

  openCustom() {
    this.menu.enable(true, 'custom');
    this.menu.open('custom');
  }

  toPossessive(name: string) {
    if (!name) return '';
    const endsWithS = name.toLowerCase()[name.length - 1] == 's';
    return name + (endsWithS ? "'" : "'s");
  }

  async newMessage(mode) {
    this.analytics.clickEvent('NME from sidenav: ', mode);
    const modal = await this.modalCtrl.create({
      component: NewMessagePage,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        mode
      },
    });
    modal.onDidDismiss().then(async (data) => {

    });
    await modal.present();
  }

  async proxyUserSelected(event: MatSelectChange) {
    this.analytics.clickEvent('select proxy', event.source.value);
    const userId = event.source.value;
    const selectedUser = this.proxyUsers.find(item => item.UserID == userId);
    if (!selectedUser) throw new UserFriendlyError('Failed to select new user');
    const displayName = selectedUser.DisplayName;
    this.analytics.switchUser('main-nav', userId);
    await this.authService.SwitchUser({ UserID: userId, StagedUserID: '' });
    this.stateService.setPatientName(displayName);
    location.reload();
  }

  closeDrawer() {
    this.menu.close('leftNavMenu');
  }

  async openUserSelect(event) {
    this.analytics.clickEvent('open proxy select', '');
    const popover = await this.popoverController.create({
      component: SelectUserListComponent,
      event: event,
      translucent: true
    });
    this.closeDrawer();
    return await popover.present();
  }

  ngOnDestroy() {
    this._proxyOnlyUserSubscription.unsubscribe();
    this._isLoggedInSubscription.unsubscribe();
  }

}
