import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { EmailChangeRequest } from '../model/EmailChangeRequest';
import { HttpBaseService } from './http-base.service';
import { ShareTestResult } from '../model/ShareTestResult';

interface PasswordChangeRequest {
  CurrentPassword: string,
  NewPassword: string,
  NewPasswordConfirm: string
}

@Injectable({providedIn: 'root'})
export class AccountService {

  data: AccountPageData = {
    summary: {},
    demographics: {},
    contacts: {},
    providers: {},
    proxyData: {}
  };
  private dataSources = {
    '/users/0/accountSummary': this.data.summary,
    '/ehr/category/demographics': this.data.demographics,
    '/ehr/category/contacts': this.data.contacts,
    '/ehr/category/providers': this.data.providers,
    '/users/0/proxyData': this.data.proxyData
  };

  constructor(
    private constantsService: ConstantsService,
    private httpClient: HttpClient,
    private _httpBaseService: HttpBaseService
  ) {
    for (const apiUrl in this.dataSources) {
      const url = this.constantsService.constantsData.apiUrl + apiUrl;
      // const options = {withCredentials: true};
      this.httpClient.get(url).subscribe(
        response => Object.assign(this.dataSources[apiUrl], response)
      );
    }
  }

  async changePassword(passwordChangeRequest: PasswordChangeRequest) {
    const response = this._httpBaseService.post(passwordChangeRequest, `/users/0/changePassword`, false,false);
    return response;
  }

  async changeEmail(emailChangeRequest: EmailChangeRequest) {
    const response = this._httpBaseService.post(emailChangeRequest, `/users/0/changeEmail`, false,false);
    return response;
  }

  async GetProxyData(){
    const response = await this._httpBaseService.getList("/users/0/proxyData", false);
    return response;
  }

  async getUserHistory(recordStart:number,recordCount:number=50, user?: UserIdentifier) {
    let url = '/users/0/accountHistory?recordCount='+recordCount+'&recordStart='+recordStart;
    const response = await this._httpBaseService.getList(url, true, user);
    return response;
  }
}
