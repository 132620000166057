import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Location, TitleCasePipe } from '@angular/common';
import { ConstantsService } from 'src/app/service/constants.service';
import { AuthenticateService } from "../../../service/authenticate.service";
import { StateService } from "../../../service/state.service";
import { sign } from "crypto";
import { ActivatedRoute } from '@angular/router';

/** Layout component for pages that use the 2 pane layout with a back button on the left */
@Component({
  selector: 'smc-back-pane-page',
  templateUrl: './back-pane-page.component.html',
  styleUrls: ['./back-pane-page.component.scss'],
  providers: [TitleCasePipe]
})
export class BackPanePageComponent implements AfterViewInit {

  urlParts: string[];
  currentPageName: string;
  showMenu = true;

  @ViewChild('menuContent') menuContent: ElementRef;
  @Input() signoutBack = false;
  @Input() hideFooter = false;

  constructor(
    private location: Location,
    private constants: ConstantsService,
    private titleCasePipe: TitleCasePipe,
    private authService: AuthenticateService,
    private stateService: StateService,
    private _activatedRoute: ActivatedRoute) { }

  ngAfterViewInit() {
    // Using timeout as workaround for ViewChangedAfterChecked error
    let timeout = setTimeout(() => {
      // console.log('MenuContent', this.menuContent);
      if (this.menuContent.nativeElement.children.length === 0) {
        this.showMenu = false;
      }

      this._activatedRoute.params.subscribe(params => {
        if (params['pageName']) {
          this.currentPageName = this.getHumanFriendlyName(params['pageName']);
        }
        else {
          const urlParts = location.href.split("/");
          if (urlParts && urlParts.length > 0) {
            this.currentPageName = this.getHumanFriendlyName(urlParts[urlParts.length - 1]);
          }
        }
      });

      clearTimeout(timeout);
      timeout = null;
    });

  }

  goBack(): void {
    if (this.signoutBack) {
      this.signOff();
    } else {
      this.location.back();
    }
  }

  async signOff() {
    await this.authService.logout();
    this.stateService.setUserId(null);
    // reload discarding hash
    window.location.assign('/');
  }

  getHumanFriendlyName(urlPart: string): string {
    if (urlPart.indexOf("?") > -1) {
      urlPart = urlPart.split("?")[0];
    }
    return this.constants.constantsData.pageNameLookup[urlPart] || this.titleCasePipe.transform(urlPart.split('-').join(' '));
  }
}
