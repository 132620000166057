import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Workflow} from "../../../../model/Workflow";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router, NavigationEnd} from "@angular/router";
import {RequestAppointmentStateService} from "../../../../service/request-appointment-state.service";
import {RequestAppointmentService} from "../../../../service/request-appointment.service";
import {FormsValidationService} from "../../../../service/forms-validation.service";
import { IonRadioGroup } from '@ionic/angular';
import { AwsTranslateService } from '../../../../service/aws-translate.service';

@Component({
  selector: 'app-apt-reason',
  templateUrl: './apt-reason.component.html',
  styleUrls: ['./apt-reason.component.scss'],
})
export class AptReasonComponent implements OnInit {

  public nextPageStateDisabled: boolean = false; //true;

  public radioSelectValue:string  = "";
  @ViewChild('reasonType') private reasonType: IonRadioGroup;

  patientForm: FormGroup = new FormGroup(
    {
      reasonType: new FormControl('', [Validators.required]),
      routineText: new FormControl('',{ validators: [], updateOn: this.formValidationService.getValidationUpdateOn()}),
      newText: new FormControl('', { validators: [], updateOn: this.formValidationService.getValidationUpdateOn()}),
      followupText: new FormControl('',{ validators: [], updateOn: this.formValidationService.getValidationUpdateOn()}),
      referralText: new FormControl('',{ validators: [], updateOn: this.formValidationService.getValidationUpdateOn()}),
      otherText: new FormControl('',{ validators: [], updateOn: this.formValidationService.getValidationUpdateOn()})
    }
  );

  public workflows: Workflow[] = [
    { Title: "Select Provider", Content: "", State: "past" },
    { Title: "Reason for Appointment", Content: "", State: "current" },
    { Title: "Date & Time", Content: "", State: "future" },
    { Title: "Your contact info", Content: "", State: "future" },
    { Title: "Review & submit", Content: "", State: "future" }
  ];

  constructor(
    private _requestAppointmentService: RequestAppointmentService,
    private _requestAppointmentStateService: RequestAppointmentStateService,
    public _router: Router,
    private formValidationService: FormsValidationService,
    public AWSTranslate: AwsTranslateService
  ) {

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.id === 1 &&
        event.url === event.urlAfterRedirects
      ) {
        this._router.navigateByUrl('/authenticated/health-record/visits/request-appointment/apt-select-provider');
      }
    }
    );

  }

  async ngOnInit() {
    //another hack part 1.......
    this.patientForm.get("reasonType").clearValidators();
    this.patientForm.get("reasonType").updateValueAndValidity();
    this._requestAppointmentStateService.loadForm(this.patientForm);
    this._requestAppointmentStateService.updateWorkflowContents(this.workflows);
    //this.clearFields();
    this.openSelectedRadioTextBox();
  }

  clearFields() {
    this.patientForm.get("routineText").disable();
    this.patientForm.get("routineText").clearValidators();
    document.getElementById("routineTextDiv").style.display = 'none';
    this.patientForm.get("newText").disable();
    this.patientForm.get("newText").clearValidators();
    document.getElementById("newTextDiv").style.display = 'none';
    this.patientForm.get("followupText").disable();
    this.patientForm.get("followupText").clearValidators();
    document.getElementById("followupTextDiv").style.display = 'none';
    this.patientForm.get("referralText").disable();
    this.patientForm.get("referralText").clearValidators();
    document.getElementById("referralTextDiv").style.display = 'none';
    this.patientForm.get("otherText").disable();
    this.patientForm.get("otherText").clearValidators();
    document.getElementById("otherTextDiv").style.display = 'none';
  }

  refreshFields() {
    this.patientForm.get("routineText").updateValueAndValidity();
    this.patientForm.get("newText").updateValueAndValidity();
    this.patientForm.get("followupText").updateValueAndValidity();
    this.patientForm.get("referralText").updateValueAndValidity();
    this.patientForm.get("otherText").updateValueAndValidity();
  }

  radioSelect(event) {
    this.radioSelectValue = event.detail.value;
  }

  selectStateChange() {

    this.radioSelectValue = this.reasonType.value;
    // this is an ion hack. since the input text field are inside the radio button group, the radio button group value
    // gets overwritten by the text inputs, so we have to save the actual radio select value and reset it.
    // this.patientForm.get("reasonType").setValue(this.radioSelectValue);
    this.clearFields();
    this.refreshFields();
    this.openSelectedRadioTextBox();
  }

  openSelectedRadioTextBox(){

    if (this.radioSelectValue == "routine") {
      this.patientForm.get("routineText").enable();
      this.patientForm.get("routineText").setValidators([Validators.required]);
      document.getElementById("routineTextDiv").style.display = 'block';
    } else if (this.radioSelectValue == "new") {
      this.patientForm.get("newText").enable();
      this.patientForm.get("newText").setValidators([Validators.required]);
      document.getElementById("newTextDiv").style.display = 'block';
    } else if (this.radioSelectValue == "followup") {
      this.patientForm.get("followupText").enable();
      this.patientForm.get("followupText").setValidators([Validators.required]);
      document.getElementById("followupTextDiv").style.display = 'block';
    } else if (this.radioSelectValue == "referral") {
      this.patientForm.get("referralText").enable();
      this.patientForm.get("referralText").setValidators([Validators.required]);
      document.getElementById("referralTextDiv").style.display = 'block';
    } else if (this.radioSelectValue == "other") {
      this.patientForm.get("otherText").enable();
      this.patientForm.get("otherText").setValidators([Validators.required]);
      document.getElementById("otherTextDiv").style.display = 'block';
    }
  }

  formStateChange() {
    this.selectStateChange();
    this.refreshFields();
  }

  previousPage() {
    this._requestAppointmentStateService.saveForm(this.patientForm);
    this._router.navigateByUrl('/authenticated/health-record/visits/request-appointment/apt-select-provider');
  }

  nextPage() {
    //another hack part 2.......
    this.patientForm.get("reasonType").setValidators([Validators.required]);
    this.patientForm.get("reasonType").updateValueAndValidity();
    Object.keys(this.patientForm.controls).forEach(field => {
      const control: any = this.patientForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    this.refreshFields();

    if (this.patientForm.valid) {
      this._requestAppointmentStateService.saveForm(this.patientForm);
      this._router.navigateByUrl('/authenticated/health-record/visits/request-appointment/apt-schedule');
    } else {
      let focusDone = false;
      Object.keys(this.patientForm.controls).forEach(field => {
        const control: any = this.patientForm.get(field);
        control.markAsTouched({ onlySelf: true });
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement != undefined && typeof control.nativeElement.setFocus === "function") {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }
  }
}
