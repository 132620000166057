import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ComponentOfTestResult } from 'src/app/model/ComponentOfTestResult';
import { Options } from 'ng5-slider';
import { SMCDataList } from 'src/app/model/SMCDataList';
import { DatePipe, Location } from '@angular/common';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { PrintService } from 'src/app/service/print.service';
import { ResultHistoryComponent } from '../../shared/result-history/result-history.component';
import {TestResultsService} from "../../../service/test-results.service";
import {test} from "@angular-devkit/core/src/virtual-fs/host";

interface CachedFunctionResult {
  func: Function;
  args: any[];
  returnValue: any;
}

/** View details of a component of a test result */
@Component({
  selector: 'app-test-result-component-details',
  templateUrl: './test-result-component-details.component.html',
  styleUrls: ['./test-result-component-details.component.scss'],
})
export class TestResultComponentDetailsComponent implements OnInit {

  @ViewChild('resultValueTemplate') resultValueTemplate: ElementRef;
  @ViewChild('resultHistory') private set resultHistorySetter(ref) {
    this.resultHistoryElement = ref;
  }

  colorInRange: string = "#72B342";
  colorNotInRange: string = "#c85e5e";

  resultComponent: ComponentOfTestResult;
  orderedBy: string;
  mainTest: string;
  referenceRangeMin: number;
  referenceRangeMax: number;
  rangeOptions: Options;
  details: SMCDataList;
  private resultHistoryElement: ResultHistoryComponent;

  constructor(
    private _hostElementRef: ElementRef,
    private _testResultsService: TestResultsService,
    private _location: Location,
    private _bhAnalyticsService: BhanalyticsService,
    private _printService: PrintService,
    private _el: ElementRef,
    private _datePipe: DatePipe
  ) { }

  ngOnInit() {
    if (!history.state.data) {
      this._location.back();
      return;
    }
    this.resultComponent = history.state.data;
    this.orderedBy = history.state.orderedBy;
    this.mainTest = history.state.mainTest;
    this.referenceRangeMin = this.getMinValue(this.resultComponent.ReferenceRange);
    this.referenceRangeMax = this.getMaxValue(this.resultComponent.ReferenceRange);
    this.rangeOptions = this.getOptions(this.resultComponent);
    this.details = this.getDetailsData(this.resultComponent);
    if (this.resultComponent.ResultInterpretation.indexOf("High") >-1 || this.resultComponent.ResultInterpretation.indexOf("Low") >-1) {
      this._el.nativeElement.style.setProperty("--test-result-component-color", this.colorNotInRange);
    } else {
      this._el.nativeElement.style.setProperty("--test-result-component-color", this.colorInRange);
    }
  }

  private getMinValue(referenceRange: string) {
    let minRange = 0;
    if (referenceRange.indexOf("-") > -1) {
      minRange = parseFloat(referenceRange.split("-")[0].replace(/[(]/g, ''));
    } else {
      if (referenceRange.indexOf(">") > -1) {
        minRange = parseFloat(referenceRange.split(">")[1].replace(/[(]/g, ''));
      }
    }
    return minRange;
  }

  private showSlider() {
    if ( this.resultComponent.ResultValue != null) {
      let resultString: String = this.resultComponent.ResultValue.toString();
      try {
        if (resultString.match(/\d+/g) == null ) {
          return false;
        }
      } catch (e) {}
    }

    return true;
  }

  private getMaxValue(referenceRange: string) {
    let maxRange = 1000;
    if (referenceRange.indexOf("-") > -1) {
      maxRange = parseFloat(referenceRange.split("-")[1].replace(/[)]/g, ''));
    } else {
      if (referenceRange.indexOf("<") > -1) {
        return parseFloat(referenceRange.split("<")[1].replace(/[)]/g, ''));
      }
    }
    return maxRange;
  }

  private getOptions(resultComponent) {
    let value = Number(resultComponent.ResultValue);
    if (resultComponent.ResultValueUnits == "%") {
      return {
        floor: 0,
        ceil: 100,
        enforceRange: false,
        enforceStep: false,
        showTicks: false,
        showTicksValues: false,
        readOnly: true,
        ticksArray: [value],
        getLegend: (value: number): string => {
          return '<b>' + value + ' ' + resultComponent.ResultValueUnits + '</b>';
        }
      };
    }
    let minRange = this.getMinValue(resultComponent.ReferenceRange);
    minRange = minRange - (minRange * 0.25);
    if (minRange < 0) {
      minRange = 0;
    }

    let maxRange = this.getMaxValue(resultComponent.ReferenceRange);
    maxRange = maxRange * 1.25;

    let options: Options = {
      floor: value < minRange ? value * 0.9 : minRange,
      ceil: maxRange < value ? value * 1.02 : maxRange,
      enforceRange: false,
      enforceStep: false,
      showTicks: false,
      showTicksValues: false,
      readOnly: true,
      ticksArray: [value],
      getLegend: (value: number): string => {
        return '<b>' + value + ' ' + resultComponent.ResultValueUnits + '</b>';
      }
    }

    return options;
  }

  private getDetailsData(resultComponent: ComponentOfTestResult): SMCDataList {
    //let cssClass = resultComponent.ResultInterpretation == 'High' || resultComponent.ResultInterpretation == 'Low' ? 'test-result-not-in-range' : 'test-result-in-range';
    let cssClass = this._testResultsService.getResultClass({column: {prop: "ResultValue"}, row: resultComponent} );
    let data = {
      options: {
        hideEmptyFields: false
      },
      items: [
        { label: 'Ordered by', value: this.orderedBy },
        { label: 'Main Test', value: this.mainTest },
        { label: 'Test date', value: [this._datePipe.transform(resultComponent.Date, 'MMM dd, yyyy')] },
        { label: 'Test location', value: [resultComponent.EnteredAt || resultComponent.EnteredBy || resultComponent.PerformedAt] },
        { label: 'Component Test', value: [resultComponent.Test] },
        { label: 'Result', value: [resultComponent.ResultValue + " " + resultComponent.ResultValueUnits], cellTemplate: this.resultValueTemplate, cellClass: this._testResultsService.getResultClass, cssClass: cssClass },
        { label: 'Normal range', value: resultComponent.ReferenceRange },
      ]
    };
    return data;
  }

  print() {
    this._bhAnalyticsService.clickEvent('Print Test Result Component Detail', '');
    this._printService.printHtmlString(this._hostElementRef.nativeElement.innerHTML);
    // window.print();
  }

}
