import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class XealthService {

  constructor(
    private http: HttpClient,
    private constants: ConstantsService
  ) { }

  getXealthLink(): Observable<any> {
    try {
      const url = `${this.constants.constantsData.apiUrl}/customapi/xealthLaunch`;
      const headers = { 'SMC-Ignore-All-Errors-For-Request': 'true'};
      return this.http.get(url, {headers}).pipe(
        map((data: any) => {
          return data;
        } ),
        catchError(err => {
          console.log('Received from Xealth: ', err);
          return of(err);
        })
      );
    } catch (err) {
      console.error('Error occurred getting Xealth link');
    }
  }
}
