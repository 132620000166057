import { AuthStatus } from './../../../model/AuthStatus';
import { BhanalyticsService } from 'src/app/service/bhanalytics.service';
import { Component, OnInit, Input } from '@angular/core';
import { PersonalCommunityUser } from 'src/app/model/PersonalCommunityUser';
import { StateService } from 'src/app/service/state.service';
import { AuthenticateService } from 'src/app/service/authenticate.service';
import { ProxyUser } from 'src/app/model/AuthStatus';

@Component({
  selector: 'app-select-user-list',
  templateUrl: './select-user-list.component.html',
  styleUrls: ['./select-user-list.component.scss'],
})
export class SelectUserListComponent implements OnInit {

  users: ProxyUser[];

  constructor(
    private stateService: StateService,
    private authService: AuthenticateService,
    private analytics: BhanalyticsService
  ) { }

  async ngOnInit() {
    this.users = await this.authService.getProxyFor();
  }

  async switchUser(user: PersonalCommunityUser) {
    const userId = this.stateService.getUserId();
    this.analytics.switchUser('main-menu', user.UserID);
    await this.authService.SwitchUser({ UserID: user.UserID, StagedUserID: user.StagedUserID });
    location.reload();
  }

}
