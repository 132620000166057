import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private _httpBaseService: HttpBaseService
    ) { }

  async getAllnews(user?: UserIdentifier) {
    const response = await this._httpBaseService.getList('/library/public/news', true, user);
    return response;
  }
}
