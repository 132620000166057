import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';
import { HTTPStatusService } from './http-status.service';
import { ModalService } from './modal.service';
import { ModalType } from '../model/ModalTypeEnum';
import { HttpClient } from '@angular/common/http';
import { AuthenticateService } from './authenticate.service';
import { Router, NavigationEnd } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { StateService } from './state.service';

enum IdleTimerType {
  Warning,
  Logout
}

/** Automatically logs users out if no HTTP calls are made within the number of seconds specified in ConstantsService. Shows a warning modal beforehand. */
@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {

  // Property for current timer. Only one timer is ever set at a time.
  private timer: {
    type: IdleTimerType,
    id: NodeJS.Timeout,
    startTime: number
  };
  // timeout lengths in milliseconds for warning and logout
  private timoutLengthMap;
  private warningModal: HTMLIonAlertElement;
  private userInteractionEvents = [
    'mousemove',
    'mousedown',
    'touchstart',
    'click',
    'scroll',
    'keypress'
  ];
  private lastUserInteractionTime = 0;

  constructor(
    private constantsService: ConstantsService,
    private httpStatusService: HTTPStatusService,
    private modalService: ModalService,
    private alertController: AlertController,
    private authenticateService: AuthenticateService,
    private httpClient: HttpClient,
    private router: Router,
    private stateService:StateService
  ) {
    this.stateService.getisPrivateDevice().subscribe(val => this.timoutLengthMap = new Map<IdleTimerType, number>([
      [IdleTimerType.Warning, this.constantsService.getTimeout(val) * 1000 - this.constantsService.getWarning(val) * 1000],
      [IdleTimerType.Logout, this.constantsService.getWarning(val) * 1000]
    ]));
   }

  /**  Start the service running. Only call this once for the entire app. */
  start() {
    if (this.timer) throw new Error('attempted to start the idle timeout service multiple times');
    this.resetTimer(IdleTimerType.Warning);
    this.httpStatusService.getHttpStatus().subscribe(() => this.resetTimer(IdleTimerType.Warning));
    this.userInteractionEvents.forEach(event => document.addEventListener(event, this.userInteractionOccurred.bind(this)));
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState == 'visible') this.checkIfSessionValid();
    });
    // ensure warning dialog closes if a logout is triggered from outside this service
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && event.urlAfterRedirects == '/login') {
        this.dismissWarningModal();
      }
    });
  }

  private cancelTimer() {
    if (this.timer) clearTimeout(this.timer.id);
    this.timer = null;
  }

  private resetTimer(timerType: IdleTimerType) {
    this.cancelTimer();
    this.timer = {
      type: timerType,
      id: setTimeout(() => this.timerExpired(), this.timoutLengthMap.get(timerType)),
      startTime: Date.now()
    };
  }

  private async timerExpired() {
    // if not logged in don't show any warning, just reset timer
    const isLoggedIn = this.authenticateService.isLoggedIn().getValue()
    // Unfortunately the personal community back end has an issue where 
    // login can fail but the server will report that the user is 
    // logged in. This tends to happen during maintenance. To handle 
    // this case we are disabling session time out effects any time 
    // the user is on the login page.
    if (!isLoggedIn || this.router.url == '/login') {
      this.resetTimer(IdleTimerType.Warning);
      return;
    }

    switch (this.timer.type) {

      // if the warning timer expired, check if user has interacted while
      // the timer is running, otherwise expired show a warning modal
      case IdleTimerType.Warning:
        if ((Date.now() - this.lastUserInteractionTime) < this.timoutLengthMap.get(IdleTimerType.Warning)) {
          this.refreshSession();
          return;
        }
        if (this.warningModal == null) {

          this.modalService.dismissAllOpen();
          this.warningModal = await this.alertController.create({
            header: 'You are about to be signed out',
            message: '',
            buttons: [
              {
                text: 'Sign out',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  this.warningModal = null;
                  this.userWantsToLogout();
                }
              }, {
                text: 'Continue',
                handler: () => {
                  this.warningModal = null;
                  this.refreshSession();
                }
              }
            ]
          });
          this.warningModal.present();
          this.resetTimer(IdleTimerType.Logout);

        }
        break;

      // if the logout timer expired log out
      case IdleTimerType.Logout:
        this.dismissWarningModal();
        await this.authenticateService.logout();
        window.location.assign('/');
        break;

    }
  }

  private refreshSession() {
    this.resetTimer(IdleTimerType.Warning);
    this.httpClient.get(
      this.constantsService.constantsData.apiUrl + '/_ping',
      {
        responseType: 'text',
        headers: { 'SMC-Dont-Show-Loader': 'true' }
      }
    ).toPromise();
  }

  private async userWantsToLogout() {
    await this.authenticateService.logout();
    window.location.assign('/');
  }

  private userInteractionOccurred() {
    this.lastUserInteractionTime = Date.now();
  }

  // handles page being returned to after being left in the background
  private checkIfSessionValid() {
    this.authenticateService.loadFreshAuthStatus().subscribe(async authStatus => {
      // if session still valid refresh the session
      if (authStatus.LoggedIn) {
        this.refreshSession();
      }
      // if session invalid call logout
      else {
        this.dismissWarningModal();
        if (this.router.url.startsWith('/authenticated/')) {
          await this.authenticateService.logout();
          window.location.assign('/');
        }
      }
    });
  }

  private dismissWarningModal() {
    if (this.warningModal) {
      this.warningModal.dismiss();
      this.warningModal = null;
    }
  }

}
