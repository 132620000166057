import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { IonInput, ModalController, PopoverController } from "@ionic/angular";
import { MessageFlow, SelectSubjectFlow } from "src/app/model/MessageFlow";
import {
  MSG_FLOW_TYPE_ID,
  MessageFlowListItem,
  MessageWorkflowStep,
} from "src/app/model/MessageFlowListItem";
import { MessageFlowSubject } from "src/app/model/MessageFlowSubject";
import {
  Recipient,
  RecipientSearchQuery,
  UploadedDocument,
} from "src/app/model/Messages";
import { BhanalyticsService } from "src/app/service/bhanalytics.service";
import { MessageFlowService } from "src/app/service/message-flow.service";
import { MessagesService } from "src/app/service/messages.service";
import { MedicalUrgentModalPage } from "./medical-urgent-modal/medical-urgent-modal.page";
import { PrescriptionState, PrescriptionStateService } from "src/app/service/prescription-state.service";
import { MedicinesService } from "src/app/service/medicines.service";
import { DateHandlerService } from "src/app/service/date-handler.service";
import { environment } from "src/environments/environment";
import { TestResult } from "src/app/model/TestResult";
import { SmcTableData } from "src/app/model/SmcTableData";
import { TestResultsService } from "src/app/service/test-results.service";
import { HealthRecordService } from "src/app/service/health-record.service";
import { Observable, Subscription, concat } from "rxjs";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { SmcTableComponent } from "src/app/component/shared/smc-table/smc-table.component";
import { PrescriptionService } from "src/app/service/prescription.service";
import { Workflow } from "../../../../model/Workflow";
import { Pharmacy, PharmacySearchQuery } from "src/app/model/Pharmacy";
import { ProviderService } from "src/app/service/provider.service";
import { Medication } from "src/app/model/Medication";
import { ConstantsService } from "src/app/service/constants.service";
import { Suggestions } from "src/app/model/suggestions";
import { StateService } from "src/app/service/state.service";
import { PersonalInformationService } from "src/app/service/personal-information.service";
import moment from 'moment';
import { RequestAppointmentStateService } from "src/app/service/request-appointment-state.service";
import { RequestAppointmentService } from "src/app/service/request-appointment.service";
import { AppointmentsService } from "src/app/service/appointments.service";
import { Appointment } from "src/app/model/Appointment";
import { RolesService } from "src/app/service/roles.service";
import { TestResultDetailsModalComponent } from "../../test-result-details-modal/test-result-details-modal.component";
import symptomsJson from 'src/assets/json/symptoms.json';
import { AwsTranslateService } from "src/app/service/aws-translate.service";
import { SymptomCheckerService } from "src/app/service/symptom-checker.service";
import { AuthenticateService } from "src/app/service/authenticate.service";
import { ModalService } from "src/app/service/modal.service";
import { ModalType } from 'src/app/model/ModalTypeEnum';
//import { SymptomCheckerService } from "src/app/service/symptom-checker.service";

@Component({
  selector: "app-new-message",
  templateUrl: "./new-message.page.html",
  styleUrls: ["./new-message.page.scss"],
})
export class NewMessagePage implements OnInit, AfterViewInit {
  @Input() mode: 'default' | 'appt-request' | 'rx-refill' | 'test-result' | 'appt-change' | 'appt-change-reschedule' | 'appt-change-cancel' = 'default';
  @Input() testResult;
  @Input() upcomingAppt;
  @Input() prescription;
  env = environment;

  // Elements
  @ViewChild("providerSearchbar") private providerSearchbar: IonInput;
  @ViewChild("iconColumnTemplate") iconColumnTemplate: ElementRef;
  @ViewChild("dateColumnTemplate") dateColumnTemplate: ElementRef;
  @ViewChild("iconSelectableTemplate") iconSelectableTemplate: ElementRef;
  @ViewChild(SmcTableComponent) smcTableComponent: SmcTableComponent;
  @ViewChild("radioButtonTemplate") radioButtonTemplate: ElementRef;
  @ViewChild("pharmacyLocation") pharmacyLocation: ElementRef;
  @ViewChild("favoritePharmacyTemplate") favoritePharmacyTemplate: ElementRef;
  @ViewChild("message") textAreaRef: ElementRef<HTMLTextAreaElement>;

  // Step Element References (for scroll focus)
  @ViewChild("messageTypeStep") messageTypeStep: ElementRef;
  @ViewChild("messageSubjectStep") messageSubjectStep: ElementRef;
  @ViewChild("visitReasonStep") visitReasonStep: ElementRef;
  @ViewChild("apptChangeStep") apptChangeStep: ElementRef;
  @ViewChild("upcomingApptsStep") upcomingApptsStep: ElementRef;
  @ViewChild("medicinesStep") medicinesStep: ElementRef;
  @ViewChild("billingSubjectStep") billingSubjectStep: ElementRef;
  @ViewChild("symptomsStep") symptomsStep: ElementRef;
  @ViewChild("symptomsQuestionsStep") symptomsQuestionsStep: ElementRef;
  @ViewChild("triageStep") traigeStep: ElementRef;
  @ViewChild("testResultsStep") testResultsStep: ElementRef;
  @ViewChild("pharmacyStep") pharmacyStep: ElementRef;
  @ViewChild("providerStep") providerStep: ElementRef;
  @ViewChild("timeframeStep") timeframeStep: ElementRef;
  @ViewChild("preferredTimesStep") preferredTimesStep: ElementRef;
  @ViewChild("writeMessageStep") writeMessageStep: ElementRef;

  // Main Menu Items
  mainMenuListItems: MessageFlowListItem[] = [];

  // Subject Items
  subjects: MessageFlowSubject[] = [];

  // Visit Items
  visits: MessageFlowSubject[] = [];

  // Billing Items
  billing: MessageFlowSubject[] = [];

  // Pharmacy Items
  pharmacy: MessageFlowSubject[] = [];

  // MessageType: General vs. Medical (provider/clinical team)
  messageType: "general" | "medical" = "general";
  subject = '';

  // Current View
  currentView: 'loading' | 'main' | 'confirmation' = 'loading';

  buttonView: "final" | "next" | "last" | "" = "";

  //Subject View
  subjectView:
    | "main"
    | "visits"
    | "billing"
    | "pharmacy"
    | "medicine"
    | "test-results" = "main";

  // Labels
  modalTitle: 'New Message' | 'Refill Prescription' | 'Request Appointment' | 'Change Appointment' = 'New Message';
  messageTypeLabel = this.AWSTranslate.read('Message Type', this.AWSTranslate.langId);
  confirmationHeading: 'Message Sent' | 'Prescription Requested' | 'Appointment Requested' | 'Appointment Change Requested' = 'Message Sent';
  feedbackPlaceholderText = 'Tell us what you liked about it (optional)';
  isUploadingFile = false;

  // Provider Search
  providerSearchTerm = "";
  providerSearchResults: Recipient[] = [];
  selectedProvider: Recipient;
  favoriteProviders: Recipient[] = [];
  selectedMainItem: MessageFlowListItem;
  selectedSubject: MessageFlowListItem = { heading: '', hideFromLiteUser: false };
  selectedBilling: MessageFlowListItem;

  // Pharmacy Search
  selectedPharmacy: Pharmacy;
  pharmacySearchTerm = "";
  pharmacySearchResults: any[] = [];
  favoritePharmacies: any[];
  selectedPrescription: MessageFlowListItem;
  prescriptionList: Medication[] = [];
  pharmacies: any;
  showOtherPharmacy = false;
  otherPharmacyValue = '';

  // Selection values
  selectedMedicine: MessageFlowListItem;
  selectedSubjectFlow: SelectSubjectFlow;
  choiceMade: boolean = false;
  condition: number = 0;
  list: any[] = new Array(5);
  category: any;

  // Appt Request
  selectedVisit: MessageFlowListItem;
  timeframes: MessageFlowSubject[] = [];
  selectedTimeframe = null;
  preferredTime = {
    heading: '',
    preferredTime: '',
    slotSunAM: false,
    slotMonAM: false,
    slotTueAM: false,
    slotWedAM: false,
    slotThuAM: false,
    slotFriAM: false,
    slotSatAM: false,
    slotSunPM: false,
    slotMonPM: false,
    slotTuePM: false,
    slotWedPM: false,
    slotThuPM: false,
    slotFriPM: false,
    slotSatPM: false,
    anotherLocation: false,
    thisLocation: false,
  };

  // Change Appointment
  apptChangeLabel: 'change' | 'reschedule' | 'cancel' = 'change';
  apptChangeActions = [];
  selectedApptChgAction = null;
  upcomingAppts = [];
  selectedAppt = null;
  otherAppointmentValue: string;
  showOtherAppointment = false;

  // Test Results
  testResultsData: TestResult[] = [];
  selectedResult: MessageFlowListItem;
  selectedResultData: MessageFlowListItem;
  data: SmcTableData;

  // Prescription
  medPromptHeading = 'Which prescription do you want to refill?';
  medDescription: 'prescription' | 'medication' = 'prescription';
  medicineHeading: string[] = [];
  prescriptions: Medication[] = [];
  otherPrescriptionValue: string;
  showOtherPrescription = false;
  medValue: any[] = [];

  // Symptoms
  symptomsDict: any[] = symptomsJson.predictive_text;
  symptoms: any[];
  selectedSymptoms: Suggestions[] = [];
  suggestions: Suggestions[] = [];
  symptomString = "";
  searchTerm = '';
  openSegment = 'results';
  openSegmentDiagnoses = 'all';
  symptomsSubject: MessageFlowListItem;

  //
  symptomsQuestionsSubject: MessageFlowListItem;
  //selectedDiagnosis: Suggestions[] = [];

  // Other Features
  search: string;
  facilitiesList: any[];
  attachments: UploadedDocument[] = [];
  feedbackText = '';
  showSubmit = false;
  stars = [1, 2, 3, 4, 5];
  showRatingTextAreaInput: boolean = false;
  selected: number;
  hover: number;
  isLiteUser = false;

  // Workflow Management
  currentStep: MessageWorkflowStep = 'message-type';
  currentStepIndex = 0;
  workflowSteps: MessageWorkflowStep[] = [
    'message-type',
    'message-subject',
    'provider',
    'write-message'
  ];
  patAge: number = 0;
  authId: number;

  //Diagnosis Value
  //patient object
  patientData = {
    authId: 0,
    patAge: 0,
    sex: "",
    region: "",
    pregnancy: 0,
    dob: "",
    symptoms: [],
    symptomsString: "",
    patAgeGroupID: 0
  }

  ageCanConceive: boolean = false;
  diagnosisValue: any;
  triageApiUrl: any;
  suggestedDiagnosis: any[];
  commonDiagnosis: any[];
  redFlagDiagnosis: any[];

  symptomsQuestionsAnswers = {
      question1: 'How quickly did your symptoms develop?',
      answer1: '',
      answer1text: '',
      question2: 'How long have you had your symptoms?',
      answer2: '',
      answer2text: '',
      question3: 'How have your symptoms changed over the last few hours/days?',
      answer3: '',
      answer3text: '',
      question4: 'How much pain or discomfort are you in?',
      answer4: '',
      answer4text: '',
      question5: 'How are your symptoms affecting your daily activities?',
      answer5: '',
      answer5text: '',
      question6: 'Do you feel better after taking medication for your symptoms?',
      answer6: '',
      answer6text: '',
      question7: 'Do you have any other serious, long term conditions such as diabetes, cancer, heart condition etc?',
      answer7: '',
      answer7text: '',
  };

  symptomsQuestionsAnswersQ1 =
    {
    question: 'How quickly did your symptoms develop?',
    answers:
      [
      { value: '1', answerText: 'Over minutes / hours' },
      { value: '2', answerText: 'Over days' },
      { value: '3', answerText: 'Over weeks' },
      { value: '4', answerText: 'Over months / years' }
      ]
    }
  symptomsQuestionsAnswersQ2 =
    {
    question: 'How long have you had your symptoms?',
    answers:
      [
      { value: '1', answerText: '0-6 days' },
      { value: '2', answerText: 'Weeks' },
      { value: '3', answerText: 'Months' },
      { value: '4', answerText: 'Years' }
      ]
    }
  symptomsQuestionsAnswersQ3 =
    {
    question: 'How have your symptoms changed over the last few hours/days?',
    answers:
      [
      { value: '1', answerText: 'Better' },
      { value: '2', answerText: 'Same' },
      { value: '3', answerText: 'Worse' },
      ]
    }
  symptomsQuestionsAnswersQ4 =
    {
    question: 'How much pain or discomfort are you in?',
    answers:
      [
      { value: '1', answerText: 'None' },
      { value: '2', answerText: 'Mild Discomfort' },
      { value: '3', answerText: 'Very Uncomfortable' },
      { value: '4', answerText: 'Unbearable' }
      ]
    }
  symptomsQuestionsAnswersQ5 =
    {
    question: 'How are your symptoms affecting your daily activities?',
    answers:
      [
      { value: '1', answerText: 'No effect' },
      { value: '2', answerText: 'Struggling to carry out usual activities' },
      { value: '3', answerText: 'Unable to carry out usual activities' },
      ]
    }
  symptomsQuestionsAnswersQ6 =
    {
    question: 'Do you feel better after taking medication for your symptoms?',
    answers:
      [
      { value: '1', answerText: 'Not taking any' },
      { value: '2', answerText: 'Yes' },
      { value: '3', answerText: 'No' },
      ]
    }
  symptomsQuestionsAnswersQ7 =
    {
    question: 'Do you have any other serious, long term conditions such as diabetes, cancer, heart condition etc?',
    answers:
      [
      { value: '2', answerText: 'Yes' },
      { value: '1', answerText: 'No' },
      ]
    }

  ageQuestionAnswers =
    {
    answers:
      [
      { value: '1', answerText: 'new born 0-28 d' },
      { value: '2', answerText: 'infant 29 d-1 yr' },
      { value: '3', answerText: 'younger child 1-5 yrs' },
      { value: '10', answerText: 'older child 6-12 yrs' },
      { value: '4', answerText: 'adolescent 13-16 yrs' },
      { value: '7', answerText: 'young adult 17-29 yrs' },
      { value: '5', answerText: 'adult 30-39 yrs' },
      { value: '8', answerText: 'adult 40-49 yrs' },
      { value: '9', answerText: 'adult 50-64 yrs' },
      { value: '6', answerText: 'senior 65 yrs-over' },
      ]
    }

  sexQuestionAnswers =
    {
    answers:
      [
      { value: '2', answerText: 'Male' },
      { value: '1', answerText: 'Female' },
      ]
    }

  pregnancyQuestionAnswers =
    {
    answers:
      [
      { value: 0, answerText: 'Don\'t know' },
      { value: 1, answerText: 'Not-Pregnant' },
      { value: 2, answerText: 'Pregnant' },
      ]
    }

  ageQuestion: any;
  sexQuestion: any;
  pregnancyQuestion: any;
  countryQuestion: any;

  q1: any;
  q2: any;
  q3: any;
  q4: any;
  q5: any;
  q6: any;
  q7: any;

  questionForm: FormGroup = this.formBuilder.group({
    q1: ['', Validators.required],
    q2: ['', Validators.required],
    q3: ['', Validators.required],
    q4: ['', Validators.required],
    q5: ['', Validators.required],
    q6: ['', Validators.required],
    q7: ['', Validators.required],
  });
  patientInfoForm: FormGroup = this.formBuilder.group({
    ageQuestion: [''],
    sexQuestion: [''],
    pregnancyQuestion: [''],
    countryQuestion: [''],
  });

  triageResults: any;
  triageLanguage: any;
  isFinishedLoadingSlider = false;
  rangeDisabled = false;
  get f() {
    return this.questionForm.controls;
  }

  completedQuestionnaire = false
  inputValue: string = '';

  hideMessageTextArea = false;
  showAdditionalInformation = false;
  writeMessageHeading: 'Write your message' | 'Additional Information' | 'Tell us why you need this visit' | 'Write a message about your symptoms' = 'Write your message';
  placeholderText = 'Say something';
  placeholderSubjectText = 'Please enter a subject';
  dropDownValues: any;
  defaultRegion = '12';

  patientForm: FormGroup = new FormGroup({
    pharmacyNotListed: new FormControl(),
    pharmacyText: new FormControl(),
  });

  //Subject Limit
  remainingCharacters: number;
  initialCharacters: number = 50;
  subscriptions: Subscription[] = [];

  messageForm: FormGroup = this.formBuilder.group({
    phone: [
      null,
      [
        Validators.required,
        Validators.pattern(/^[0-9\+\-\+\()\ ]/),
        Validators.minLength(12),
      ],
    ],
    message: [
      null,
      [
        Validators.required
      ],
    ],
    subjectInput: [
      '',
      [
      ],
    ]
  });

  // get phone(): AbstractControl {
  //   return this.messageForm.get("phone");
  // }
  // get message(): AbstractControl {
  //   return this.messageForm.get("message");
  // }
  // get subjectInput(): AbstractControl {
  //   return this.messageForm.get('subjectInput');
  // }

  errorMessages = {
    phone: [
      { type: "required", message: "A phone number is required." },
      { type: "pattern", message: "Phone number must match the format: xxx-xxx-xxxx." },
      { type: "minlength", message: "Phone number must match the format: xxx-xxx-xxxx.", },
    ],
    textbox: [{ type: "required", message: "Message is required." }],
    subjectInput: [
      { type: "maxLength", message: "Reached character limit for subject." }
    ]
  };

  //selectedProvider: MessageFlowListItem;
  constructor(
    private modalCtrl: ModalController,
    private messagesService: MessagesService,
    private analytics: BhanalyticsService,
    private messageFlowService: MessageFlowService,
    private popOverCtrl: PopoverController,
    private _changeDetectorRef: ChangeDetectorRef,
    private _prescriptionStateService: PrescriptionStateService,
    private _medicinesService: MedicinesService,
    private _dateHandler: DateHandlerService,
    private _testResultsService: TestResultsService,
    private _healthRecordService: HealthRecordService,
    private _prescriptionService: PrescriptionService,
    private _bhAnalyticsService: BhanalyticsService,
    public constantsService: ConstantsService,
    private formBuilder: FormBuilder,
    private stateService: StateService,
    private personalInformationService: PersonalInformationService,
    private providerService: ProviderService,
    private requestApptService: RequestAppointmentService,
    private reqApptStateService: RequestAppointmentStateService,
    private apptsService: AppointmentsService,
    private rolesService: RolesService,
    private renderer: Renderer2,
    public AWSTranslate: AwsTranslateService,
    public _symptomCheckerService: SymptomCheckerService,
    private auth: AuthenticateService,
    private modalService: ModalService
  ) { }

  ngAfterViewInit(): void {
    // console.log('Testing Emergency Need', this.AWSTranslate.read('Emergency Need', this.AWSTranslate.langId));
  }

  async ngOnInit() {
    //console.log('q1', this.symptomsQuestionsAnswersQ1.answers1);
  }

  async ionViewWillEnter() {
    this.toggleMedChatFab(true);
    this.loadPatientAge();
    await this.getPatientId();
    await this.loadPatientData();

    this.loadMessageFlow();
    await this.checkForLiteUser();
    this.setFavoriteProviders();
    this.setFavoritePharmacies();
    this.getPharmacyData();
    this.searchPrescriptions();
    this.setModeFlow();
    this.loadTestResults();
    this.loadUpcomingAppts();
    this.subscribeToControls();
    this.currentView = 'main';
  }

  subscribeToControls() {
    if (this.subscriptions.length === 0) {
    this.remainingCharacters = this.initialCharacters;
    this.subscriptions.push(
      this.messageForm.controls.subjectInput.valueChanges.subscribe((val) => {
        console.log('val subjectInput', val);
        this.remainingCharacters = this.initialCharacters - val.length;
        console.log('remaining', this.remainingCharacters);

      }),
      this.patientInfoForm.controls.ageQuestion.valueChanges.subscribe((val) => {
        console.log('age question value', val);
        this.ageQuestion = val;
        this.patientData.patAgeGroupID = val;
        this.canConceive(this.ageQuestion, this.patientData.sex);
      }),
      this.patientInfoForm.controls.sexQuestion.valueChanges.subscribe((val) => {
        //console.log('sex question value', val);
        this.patientData.sex = val;
        this.canConceive(this.ageQuestion, val);
      }),

    );
    }
  }



  loadPatientAge() {
    this.personalInformationService.demographics().then(demographics => {
      if (demographics && demographics.Patient) {
        console.log('patient data', demographics.Patient);

        const dob = demographics.Patient.DateOfBirth;
        const patAge = moment().diff(moment(dob, 'YYYY-MM-DD'), 'year');
        this.patAge = patAge;
        console.log('loadPatientAge', dob, patAge);
      }
    });
  }

  async loadPatientData() {
    try {
      this.dropDownValues = await this._symptomCheckerService.getDropdownValues();
      // Put north america first and sort other regions.
      const northAmericaId = this.defaultRegion;
      this.dropDownValues.travel_history.region.sort((a, b) => a.region_name.localeCompare(b.region_name));
      const northAmericaIndex = this.dropDownValues.travel_history.region.findIndex(region => region.region_id === northAmericaId);
      let region;
      if (northAmericaIndex !== -1) {
        const northAmericaObject = this.dropDownValues.travel_history.region.splice(northAmericaIndex, 1);
        console.log('north America Object', northAmericaObject);
         region = northAmericaObject[0].region_id;
        this.dropDownValues.travel_history.region.splice(0, 0, northAmericaObject[0]);
      }

     this.personalInformationService.demographics().then(demographics => {
       if (demographics && demographics.Patient) {
         console.log('patient data', demographics.Patient);

         const dob = demographics.Patient.DateOfBirth;
         const gender = demographics.Patient.Gender;

         const patAge = moment().diff(moment(dob, 'YYYY-MM-DD'), 'year');

         this.patientData.patAge = patAge;
         this.patientData.dob = dob;
         this.patientData.sex = gender.includes('MALE') ? 'm' : 'f'
         this.patientData.region = region;

         console.log('loadPatient', this.patientData);
       }
     });
    } catch (error) {
        console.log('error get patient data', error);
    }
  }

  getPatientAgeNumber() {

  }

  async getPatientId() {
    this.auth.loadFreshAuthStatus().subscribe(async authStatus => {
      this.authId = authStatus.EffectiveUserID;
      this.patientData.authId = this.authId;
    })
  }

  async checkForLiteUser(): Promise<boolean> {
    try {
      const userRoles = await this.rolesService.getRoles();
      console.log('checkForLiteUser: userRoles', userRoles);
      if (userRoles) {
        const intersection = userRoles.uniqueIntersection(['LITEUSER']);
        this.isLiteUser = intersection.length > 0;
        // this.isLiteUser = true; // Testing only
        if (this.isLiteUser) {
          this.mainMenuListItems = this.mainMenuListItems.filter(i => !i.hideFromLiteUser && i.messageType !== 'medical');
          this.subjects = this.subjects.filter(i => !i.hideFromLiteUser && i.messageType !== 'medical');
          this.visits = this.visits.filter(i => !i.hideFromLiteUser && i.messageType !== 'medical');
          this.billing = this.billing.filter(i => !i.hideFromLiteUser && i.messageType !== 'medical');
          this.timeframes = this.timeframes.filter(i => !i.hideFromLiteUser && i.messageType !== 'medical');
          this.apptChangeActions = this.apptChangeActions.filter(i => !i.hideFromLiteUser && i.messageType !== 'medical');
        }
      }
      return Promise.resolve(true);
    } catch (err) {
      console.error(err);
    }
  }

  canConceive(id, sex: string) {
    if (id && sex === '1') {
      console.log('id', id, 'sex', sex);
      const selectedAgeGroup = this.dropDownValues.age_groups.age_group.find(item => item.agegroup_id === id);
      console.log('selectedAgeGroup', selectedAgeGroup);
      if(selectedAgeGroup.can_conceive === 'true') {
        this.ageCanConceive = true;
      } else {
        this.ageCanConceive = false;
      }
    } else {
      this.ageCanConceive = false;
    }
  }

  scrollToStep(id: string) {
    // this.renderer.scrollIntoView(elem.nativeElement);
    console.log('scrollToStep', id);
    setTimeout(() => {
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }, 250);
  }

  toggleMedChatFab(hide: boolean) {
    const medChatiFrame = document.getElementById('medchat-launcher-frame') as HTMLElement;
    const medChatBubbleiFrame = document.getElementById('medchat-chat-frame') as HTMLElement;
    console.log('toggleMedChatFab', medChatiFrame, medChatBubbleiFrame);
    medChatiFrame.style.display = hide ? 'none' : 'block';
    medChatBubbleiFrame.style.display = hide ? 'none' : 'block';
  }


  openUrlInNewTab(url: string) {
    window.open(url, '_blank', 'noopener');
  }

  loadUpcomingAppts() {
    this.apptsService.getAppointments().then(appts => {
      if (appts && appts.Appointments) {
        // Filter out past appoinments
        this.upcomingAppts = appts.Appointments.filter(a => {
          const now = moment();
          const apptMoment = moment(a.Timestamp, 'YYYY-MM-DD HH:mm:ss');
          // const isUpcoming = true; // Testing only;
          const isUpcoming = apptMoment > now;
          return isUpcoming;
        });

        // Calculate if appt is less than 72 hours
        this.upcomingAppts.forEach(ua => {
          // const now = moment();
          // const apptMoment = moment(ua.Timestamp, 'YYYY-MM-DD HH:mm:ss');
          // const hoursUntilAppt = apptMoment.diff(now, 'hours');
          // const isLessThan72Hours = false; // Testing only
          // ua.hoursUntilAppt = hoursUntilAppt;
          // ua.isLessThan72Hours = isLessThan72Hours;
          ua.isLessThan72Hours = this.calcApptIsLessThan72Hours(ua);
          ua.formattedTime = this.formatAppointmentTime(ua);
          ua.heading = this.formatAppointmentHeading(ua)
        })
      }
      console.log('loadUpcomingAppts', appts, this.upcomingAppts);
    });
  }

  calcApptIsLessThan72Hours(appt): boolean {
    const now = moment();
    const apptMoment = moment(appt.Timestamp, 'YYYY-MM-DD HH:mm:ss');
    const hoursUntilAppt = apptMoment.diff(now, 'hours');
    return hoursUntilAppt < 72;
  }

  formatAppointmentTime(appt): string {
    const apptMoment = moment(appt.Timestamp, 'YYYY-MM-DD HH:mm:ss');
    return apptMoment.format('MMMM DD, YYYY h:mm A');
  }

  formatAppointmentHeading(appt): string {
    return appt.formattedTime + ', ' + appt.Provider + ' @ ' + appt.Location;
  }

  loadMessageFlow() {
    this.mainMenuListItems = this.messageFlowService.loadMainMenuItems();
    this.subjects = this.messageFlowService.loadMessageSubjects();
    this.visits = this.messageFlowService.loadVisitReasons();
    this.billing = this.messageFlowService.loadBillingSubjects();
    this.timeframes = this.messageFlowService.loadTimeframes();
    this.apptChangeActions = this.messageFlowService.loadApptChangeActions();
  }

  setModeFlow() {
    let item = null;
    let subject = null;
    this._bhAnalyticsService.customEvent('new-message', 'mode', this.mode);
    switch (this.mode) {
      case 'appt-request':
        item = this.mainMenuListItems.find(i => i.id === MSG_FLOW_TYPE_ID.MNU_REQUEST_APPT);
        this.selectMainMenuItem(item);
        break;

      case 'appt-change':
        item = this.mainMenuListItems.find(i => i.id === MSG_FLOW_TYPE_ID.MNU_CHANGE_APPT);
        this.selectMainMenuItem(item);
        break;

      case 'appt-change-cancel':
        item = this.mainMenuListItems.find(i => i.id === MSG_FLOW_TYPE_ID.MNU_CHANGE_APPT);
        this.selectMainMenuItem(item);
        subject = this.apptChangeActions.find(a => a.id === MSG_FLOW_TYPE_ID.AC_CANCEL)
        this.selectApptChangeAction(subject);
        this.upcomingAppt.isLessThan72Hours = this.calcApptIsLessThan72Hours(this.upcomingAppt);
        this.upcomingAppt.formattedTime = this.formatAppointmentTime(this.upcomingAppt);
        this.upcomingAppt.heading = this.formatAppointmentHeading(this.upcomingAppt);
        this.selectAppointment(this.upcomingAppt);
        break;

      case 'appt-change-reschedule':
        item = this.mainMenuListItems.find(i => i.id === MSG_FLOW_TYPE_ID.MNU_CHANGE_APPT);
        this.selectMainMenuItem(item);
        subject = this.apptChangeActions.find(a => a.id === MSG_FLOW_TYPE_ID.AC_RESCHEDULE)
        this.selectApptChangeAction(subject);
        this.upcomingAppt.isLessThan72Hours = this.calcApptIsLessThan72Hours(this.upcomingAppt);
        this.upcomingAppt.formattedTime = this.formatAppointmentTime(this.upcomingAppt);
        this.upcomingAppt.heading = this.formatAppointmentHeading(this.upcomingAppt);
        this.selectAppointment(this.upcomingAppt);
        break;

      case 'rx-refill':
        item = this.mainMenuListItems.find(i => i.id === MSG_FLOW_TYPE_ID.MNU_REFILL_RX);
        this.selectMainMenuItem(item);
        if (this.prescription) {
          // this.setPrescriptionSelection(p);
          this.selectPrescriptionItem(this.prescription);
        }
        break;

      case 'test-result':
        item = this.mainMenuListItems.find(i => i.id === MSG_FLOW_TYPE_ID.MNU_NON_URGENT_MESSAGE);
        this.selectMainMenuItem(item);
        const testSubject = this.subjects.find(s => s.id === MSG_FLOW_TYPE_ID.MSG_TEST_RESULT);
        this.selectSubjectItem(testSubject);
        console.log('testResult: ', this.testResult);
        this.selectTestResult(this.testResult);
        break;
    }
  }

  dismiss() {
    this.toggleMedChatFab(false);
    this.modalCtrl.dismiss();
  }

  hasWorkflowStep(step: MessageWorkflowStep): boolean {
    const stepExists = this.workflowSteps.find(w => w === step) !== undefined;
    // console.log('hasWorkflowStep: ' + step, stepExists);
    return stepExists;
  }

  isCurrWorkflowStep(step: MessageWorkflowStep): boolean {
    let isCurrentStep = false;
    const stepExists = this.workflowSteps.find(w => w === step);
    if (stepExists !== undefined) {
      const stepIndex = this.workflowSteps.findIndex(w => w === step);
      isCurrentStep = stepIndex > -1 && stepIndex === this.currentStepIndex;
    }
    //console.log('isCurrWorkflowStep: ' + step, isCurrentStep);
    return isCurrentStep;
  }

  buildSymptomsProfile() {

  }

  isPrevWorkflowStep(step: MessageWorkflowStep): boolean {
    let isPreviousStep = false;
    const stepExists = this.workflowSteps.find(w => w === step);
    if (stepExists !== undefined) {
      const stepIndex = this.workflowSteps.findIndex(w => w === step);
      isPreviousStep = stepIndex > -1 && stepIndex < this.currentStepIndex;
    }
    // console.log('isPrevWorkflowStep: ' + step, isPreviousStep);
    return isPreviousStep;
  }

  isFutureWorkflowStep(step: MessageWorkflowStep): boolean {
    let isFutureStep = false;
    const stepExists = this.workflowSteps.find(w => w === step);
    if (stepExists !== undefined) {
      const stepIndex = this.workflowSteps.findIndex(w => w === step);
      isFutureStep = stepIndex > -1 && stepIndex > this.currentStepIndex;
    }
    // console.log('isFutureWorkflowStep: ' + step, isFutureStep);
    return isFutureStep;
  }

  isLastWorkflowStep(): boolean {
    return (this.workflowSteps.length - 1 === this.currentStepIndex);
  }

  getStepNumber(step): number {
    let stepNumber = 0;
    const stepExists = this.workflowSteps.find(w => w === step);
    if (stepExists !== undefined) {
      const stepIndex = this.workflowSteps.findIndex(w => w === step);
      stepNumber = stepIndex + 1;
    }
    // console.log('getStepNumber: ' + step, stepNumber);
    return stepNumber;

  }

  uploadImages() {
    this._bhAnalyticsService.clickEvent("upload images", "");
    const fileInput = document.getElementById("fileInput") as HTMLElement;
    fileInput.click();
  }

  async updateAttachments(event) {
    for (const file of event.target.files) {
      console.log("file", file);
      this._bhAnalyticsService.clickEvent("update attachment", file.name);
      // this. _bhAnalyticsService.customEvent('new-message', 'update attachment', file.name);
      if (
        this.attachments.length <
        this.constantsService.constantsData.maxNumberOfPhotos
      ) {
        if (
          file.size > this.constantsService.constantsData.maxPhotoUploadSize
        ) {
        } else {
          try {
            this.isUploadingFile = true;
            const uploaded = await this.messagesService.uploadDocument(file);
            this.isUploadingFile = false;
            this.attachments.push(uploaded);
            console.log("attachments", this.attachments);
          } catch (error) {
          }
        }
      }
    }
  }

  removeAttachment(index: number) {
    this._bhAnalyticsService.clickEvent("remove attachment", index);
    // this. _bhAnalyticsService.customEvent('new-message', 'remove attachment', index);
    this.attachments.splice(index, 1);
  }

  async getPharmacyData() {
    const getPharmacyFavs =
      await this._prescriptionService.getFavoritePharmacies();
    console.log("pharm favs", getPharmacyFavs);
    console.log("pharmacies", this.pharmacies);
  }

  selectMainMenuItem(item: MessageFlowListItem) {
    console.log('main item', item);
    if(item) {
      this.selectedMainItem = item;
      this.confirmationHeading = 'Message Sent';
      this.writeMessageHeading = 'Write your message';
      this.hideMessageTextArea = false;
      console.log("this.selectedMainItem", this.selectedMainItem);
      // this._bhAnalyticsService.customEvent('new-message', 'main-menu-selection', item.Name);

        this._bhAnalyticsService.clickEvent('NewMsg: main-menu-selection', item.Name);

      switch (this.selectedMainItem.id) {
        case MSG_FLOW_TYPE_ID.MNU_EMERGENCY_NEED: // Emergency Need
          this._bhAnalyticsService.clickEvent('NewMsg: Emergency Need Modal', item.Name);
          this.openEmergencyUrgentCareModal("emergency");
          break;

        case MSG_FLOW_TYPE_ID.MNU_URGENT_MEDICAL_QUESTION: // Medical Question
          this._bhAnalyticsService.clickEvent('NewMsg: Urgent Message Modal', item.Name);
          this.openEmergencyUrgentCareModal("urgent");
          break;

        case MSG_FLOW_TYPE_ID.MNU_NON_URGENT_MESSAGE: // Non Urgent Question
          this._bhAnalyticsService.clickEvent('NewMsg: Non-urgent', item.Name);
          this.workflowSteps = [
            'message-type',
            'message-subject',
            'provider',
            'write-message'
          ];
          this.currentStep = 'message-subject';
          this.currentStepIndex = 1;
          this.modalTitle = this.AWSTranslate.read('New Message', this.AWSTranslate.langId);
          this.applyMessageValidation();
          break;

        case MSG_FLOW_TYPE_ID.MNU_REQUEST_APPT: // Request Appt
          this._bhAnalyticsService.clickEvent('NewMsg: Request Appt', item.Name);
          this.workflowSteps = [
            'message-type',
            'visit-reason',
            'provider',
            'timeframe',
            'preferred-times',
            'write-message'
          ];
          this.currentStep = 'visit-reason';
          this.currentStepIndex = 1;
          this.modalTitle = this.AWSTranslate.read('Request Appointment', this.AWSTranslate.langId);
          this.confirmationHeading = 'Appointment Requested';
          // this.writeMessageHeading = 'Tell us why you need this return visit';
          // this.hideMessageTextArea = false;
          this.writeMessageHeading = 'Additional Information';
          this.hideMessageTextArea = true;
          this.showAdditionalInformation = false;
          this.clearMessageValidation();
          break;

        case MSG_FLOW_TYPE_ID.MNU_CHANGE_APPT: // Change Appt
          this._bhAnalyticsService.clickEvent('NewMsg: Change Appt', item.Name);
          // this.openEmergencyUrgentCareModal("change-appt");
          this.workflowSteps = [
            'message-type',
            'appt-change-action',
            'upcoming-appts',
            'timeframe',
            'preferred-times',
            'write-message'
          ];
          this.currentStep = 'appt-change-action';
          this.currentStepIndex = 1;
          this.modalTitle = this.AWSTranslate.read('Change Appointment', this.AWSTranslate.langId);
          this.confirmationHeading = 'Appointment Change Requested';
          this.writeMessageHeading = 'Additional Information';
          this.hideMessageTextArea = true;
          this.showAdditionalInformation = false;
          this.clearMessageValidation();
          break;

        case MSG_FLOW_TYPE_ID.MNU_REFILL_RX: // Refill RX
          this._bhAnalyticsService.clickEvent('NewMsg: Refill Rx', item.Name);
          this.workflowSteps = [
            'message-type',
            'medicines',
            'pharmacy',
            'provider',
            'write-message'
          ];
          this.currentStep = 'medicines';
          this.currentStepIndex = 1;
          this.modalTitle = this.AWSTranslate.read('Refill Prescription', this.AWSTranslate.langId);
          this.medPromptHeading = 'Which prescription do you want to refill?';
          this.medDescription = 'prescription';
          this.confirmationHeading = 'Prescription Requested';
          this.writeMessageHeading = 'Additional Information';
          this.hideMessageTextArea = true;
          this.showAdditionalInformation = false;
          this.clearMessageValidation();
          //this.applyMessageValidation();
          break;

        case MSG_FLOW_TYPE_ID.MNU_BILLING_QUESTION: // Billing Question
          this._bhAnalyticsService.clickEvent('NewMsg: Billing Question', item.Name);
          this.workflowSteps = [
            'message-type',
            'billing-subject',
            'provider',
            'write-message'
          ];
          this.currentStep = 'billing-subject';
          this.currentStepIndex = 1;
          this.modalTitle = this.AWSTranslate.read('New Message', this.AWSTranslate.langId);
          this.applyMessageValidation();
          break;
      }
    }
    }

  clearMessageValidation() {
    this.messageForm.controls.message.clearValidators();
    this.messageForm.controls.message.updateValueAndValidity();
  }

  applyMessageValidation() {
    this.messageForm.controls.message.setValidators([Validators.required]);
    this.messageForm.controls.message.updateValueAndValidity();
  }

  applySubjectValidation() {
    if(this.selectedSubject.id === MSG_FLOW_TYPE_ID.MSG_OTHER) {
      this.messageForm.controls.subjectInput.setValidators([Validators.required]);
      this.messageForm.controls.message.setValidators([Validators.required]);
      this.messageForm.controls.subjectInput.updateValueAndValidity();
      this.messageForm.controls.message.updateValueAndValidity();
    }
  }

  selectSubjectItem(itm: MessageFlowListItem) {
    this.selectedSubject = itm;
    this.selectedSubject.active = true;
    this.choiceMade = false;
    this._bhAnalyticsService.customEvent('new-message', 'subject-selection', itm.Name);
    console.log("selected Subject", this.selectedSubject);

    switch (this.selectedSubject.id) {
      case MSG_FLOW_TYPE_ID.MSG_NEW_SYMPTOMS: // NEW_SYMPTOMS
        this.choiceMade = true;
        // this.openNewSymptomsPopover(this.selectedSubject);
        this.placeholderText = 'Would you like to let the provider know anything more about your symptoms or answers?';
        this.selectedSubject.type = 'medical';
        this.writeMessageHeading = 'Write a message about your symptoms';
        this.workflowSteps = [
          'message-type',
          'message-subject',
          'symptoms',
          'symptoms-questions',
          'triage',
          'provider',
          'write-message'
        ];
        break;

      case MSG_FLOW_TYPE_ID.MSG_MED_QUESTION: // MED_QUESTION
        this.selectedSubject.type = 'medical';
        this.medPromptHeading = 'Which medication can we help you with?';
        this.medDescription = 'medication';
        this.workflowSteps = [
          'message-type',
          'message-subject',
          'medicines',
          'provider',
          'write-message'
        ];
        break;

      case MSG_FLOW_TYPE_ID.MSG_TEST_RESULT: // TEST_RESULT
        this.selectedSubject.type = 'medical';
        this.workflowSteps = [
          'message-type',
          'message-subject',
          'test-results',
          'provider',
          'write-message'
        ];
        break;

      case MSG_FLOW_TYPE_ID.MSG_PAT_DATA: // Patient Report Data MSG_PAT_DATA
        this.selectedSubject.type = 'medical';
        this.workflowSteps = [
          'message-type',
          'message-subject',
          'provider',
          'write-message'
        ];
        break;

      case MSG_FLOW_TYPE_ID.MSG_LETTER_FORM: // LETTER_FORM
        this.selectedSubject.type = 'general';
        this.workflowSteps = [
          'message-type',
          'message-subject',
          'provider',
          'write-message'
        ];
        break;

      case MSG_FLOW_TYPE_ID.MSG_REFERRAL: // REFERRAL
        this.selectedSubject.type = 'medical';
        this.workflowSteps = [
          'message-type',
          'message-subject',
          'provider',
          'write-message'
        ];
        break;

      case MSG_FLOW_TYPE_ID.MSG_OTHER: // OTHER
        this.selectedSubject.type = 'general';
        this.workflowSteps = [
          'message-type',
          'message-subject',
          'provider',
          'write-message'
        ];
        //this.clearMessageValidation();
        this.applySubjectValidation();
        break;


      // Visit Reasons
      case MSG_FLOW_TYPE_ID.VIS_ANNUAL_WELLNESS: // Annual Wellness Visit
        this.selectedSubject.type = 'medical';
        this.placeholderText = 'Say something';
        this.hideMessageTextArea = true;
        this.clearMessageValidation();
        break;

      case MSG_FLOW_TYPE_ID.VIS_MEDICARE_WELLNESS: // Medicare Wellness Visit
        this.selectedSubject.type = 'medical';
        this.placeholderText = 'Say something';
        this.hideMessageTextArea = true;
        this.clearMessageValidation();
        break;

      case MSG_FLOW_TYPE_ID.VIS_WELL_CHILD_VISIT: // Child Wellness Visit
        this.selectedSubject.type = 'medical';
        this.placeholderText = 'Say something';
        this.hideMessageTextArea = true;
        this.clearMessageValidation();
        break;

      case MSG_FLOW_TYPE_ID.VIS_RETURN_VISIT: // Return Visit
        this.selectedSubject.type = 'medical';
        this.placeholderText = 'Reason for requesting an appointment';
        this.hideMessageTextArea = false;
        this.applyMessageValidation();
        break;

      case MSG_FLOW_TYPE_ID.VIS_INPATIENT_ER_FOLLOWUP: // Inpatient or ER Follow-up
        this.selectedSubject.type = 'medical';
        this.placeholderText = 'Tell us about your previous Inpatient/ER visit';
        this.hideMessageTextArea = false;
        this.applyMessageValidation();
        break;

      case MSG_FLOW_TYPE_ID.VIS_NEW_PATIENT: // New Patient
        this.selectedSubject.type = 'medical';
        this.placeholderText = 'Say something';
        this.workflowSteps = [
          'message-type',
          'visit-reason',
          'redirect-find-provider',
        ];
        this.hideMessageTextArea = true;
        this.clearMessageValidation();
        break;

      // Billing
      case MSG_FLOW_TYPE_ID.BIL_INSURANCE: // Insurance
        this.selectedSubject.type = 'general';
        // this.selectedSubject.heading = this.formatBillingQuestionPrefix(itm);
        break;

      case MSG_FLOW_TYPE_ID.BIL_PAYMENTS: // Payments
        this.selectedSubject.type = 'general';
        break;


      case MSG_FLOW_TYPE_ID.BIL_CHARGE: // Charges
        this.selectedSubject.type = 'general';
        break;

      case MSG_FLOW_TYPE_ID.BIL_FIN_ASST: // Financial Assistance
        this.selectedSubject.type = 'general';
        break;

      case MSG_FLOW_TYPE_ID.BIL_ADDR_CHANGE: // Billing Address Changes
        this.selectedSubject.type = 'general';
        break;

      case MSG_FLOW_TYPE_ID.BIL_OTHER: // Other
        this.selectedSubject.type = 'general';
        this.placeholderSubjectText = 'Please enter a subject header';
        this.selectedSubject.otherSubject = this.messageForm.controls.subjectInput.value
        break;
    }

    this.goToNextStep();
  }

  formatBillingQuestionPrefix(subject) {
    const prefix = this.AWSTranslate.read('Billing Question', this.AWSTranslate.langId);
    return (subject.heading.indexOf(prefix) > -1 ? '' : prefix) + ': ' + subject.heading
  }

  async goToFindProvider() {
    await this._bhAnalyticsService.clickEvent('NewMsg: redirect-find-provider', '');
    // Primary Care, New Patients
    location.href = 'https://providers.baystatehealth.org/search?primary_care=Primary%20Care%20Providers&sort=networks%2Crelevance%2Cavailability_density_best&filter=accepting_new_patients%3AT';
  }

  selectProvider(prov) {
    console.log("selectProvider:", prov);
    this.selectedProvider = prov;
    // this._bhAnalyticsService.customEvent('new-message', 'select-provider', prov.Provider.FirstName + ' ' + prov.Provider.LastName);
    this._bhAnalyticsService.clickEvent('NewMsg: select-provider', prov.Provider.FirstName + ' ' + prov.Provider.LastName);
    this.goToNextStep();
  }

  selectApptChangeAction(chg) {
    this.selectedApptChgAction = chg;
    // console.log('selectApptChangeAction', chg, chg.id === MSG_FLOW_TYPE_ID.AC_RESCHEDULE);
    this._bhAnalyticsService.clickEvent('NewMsg: select-appt-change-action', chg.heading);
    if (chg.id === MSG_FLOW_TYPE_ID.AC_RESCHEDULE) {
      this.apptChangeLabel = 'reschedule';
      this.workflowSteps = [
        'message-type',
        'appt-change-action',
        'upcoming-appts',
        'timeframe',
        'preferred-times',
        'write-message'
      ];
    } else {
      this.apptChangeLabel = 'cancel';
      this.workflowSteps = [
        'message-type',
        'appt-change-action',
        'upcoming-appts',
        'write-message'
      ];
    }
    this.goToNextStep();
  }

  async selectAppointment(appt) {
    console.log('selectAppointment: appt', appt)
    if (appt.isLessThan72Hours) {
      this.openEmergencyUrgentCareModal("change-appt-call");
    } else {
      this.selectedAppt = appt;
      await this.setProviderFromAppt(appt);
      this.selectedSubject.heading = appt.heading;
      this._bhAnalyticsService.clickEvent('NewMsg: select-appt', appt.formattedTime);
      this.goToNextStep();
    }
  }

  async selectOtherAppointment() {
    this.selectedAppt = { heading: this.otherAppointmentValue };
    this.setProviderFromAppt(null, true);
    this.goToNextStep();
  }

  async setProviderFromAppt(appt: Appointment, skipSearch = false): Promise<boolean> {
    try {
      // const provPayload = { detail: { value: appt.Provider } };
      const query: RecipientSearchQuery = {
        MessageType: this.messageType,
        SearchKey: appt ? appt.Provider : null,
      };
      const searchResults = skipSearch ? { children: [] } : await this.messagesService.searchProviders(query);
      console.log("search results =>", searchResults);
      if (
        searchResults &&
        searchResults.children &&
        searchResults.children.length === 1
      ) {
        this.selectedProvider = searchResults.children[0];
      } else {
        if (this.selectedApptChgAction.id === MSG_FLOW_TYPE_ID.AC_RESCHEDULE) {
          // Add Appointment Provider Selector
          this.workflowSteps = [
            'message-type',
            'appt-change-action',
            'upcoming-appts',
            'provider',
            'timeframe',
            'preferred-times',
            'write-message'
          ];
        } else {
          this.workflowSteps = [
            'message-type',
            'appt-change-action',
            'upcoming-appts',
            'provider',
            'write-message'
          ];
        }
      }
      return Promise.resolve(true);
    } catch (err) {
      console.error(err);
      return Promise.reject(true);
    }
  }

  selectTimeframe(tf: MessageFlowSubject) {
    this.selectedTimeframe = tf;
    // Assume all
    this.preferredTime.slotMonAM = true;
    this.preferredTime.slotMonPM = true;
    this.preferredTime.slotTueAM = true;
    this.preferredTime.slotTuePM = true;
    this.preferredTime.slotWedAM = true;
    this.preferredTime.slotWedPM = true;
    this.preferredTime.slotThuAM = true;
    this.preferredTime.slotThuPM = true;
    this.preferredTime.slotFriAM = true;
    this.preferredTime.slotFriPM = true;
    this._bhAnalyticsService.clickEvent('NewMsg: select-timeframe', tf.value);
    this.goToNextStep();
  }

  selectPreferredTime() {
    const pt = this.preferredTime;
    let heading = '';
    if (
      pt.slotMonAM && pt.slotMonPM &&
      pt.slotTueAM && pt.slotTuePM &&
      pt.slotWedAM && pt.slotWedPM &&
      pt.slotThuAM && pt.slotThuPM &&
      pt.slotFriAM && pt.slotFriPM
    ) {
      heading = 'Any day and time'
    } else {
      // Monday Text
      if (pt.slotMonAM || pt.slotMonPM) {
        heading += 'Mondays (' + (pt.slotMonAM ? 'AM' : '') + (pt.slotMonAM && pt.slotMonPM ? '-' : '') + (pt.slotMonPM ? 'PM' : '') + ')';
      }
      // Tuesday Text
      if (heading.length > 0 && (pt.slotTueAM || pt.slotTuePM)) {
        heading += ', ';
      }
      if (pt.slotTueAM || pt.slotTuePM) {
        heading += 'Tuesdays (' + (pt.slotTueAM ? 'AM' : '') + (pt.slotTueAM && pt.slotTuePM ? '-' : '') + (pt.slotTuePM ? 'PM' : '') + ')';
      }
      // Wednesday Text
      if (heading.length > 0 && (pt.slotWedAM || pt.slotWedPM)) {
        heading += ', ';
      }
      if (pt.slotWedAM || pt.slotWedPM) {
        heading += 'Wednesdays (' + (pt.slotWedAM ? 'AM' : '') + (pt.slotWedAM && pt.slotWedPM ? '-' : '') + (pt.slotWedPM ? 'PM' : '') + ')';
      }
      // Thursday Text
      if (heading.length > 0 && (pt.slotThuAM || pt.slotThuPM)) {
        heading += ', ';
      }
      if (pt.slotThuAM || pt.slotThuPM) {
        heading += 'Thursdays (' + (pt.slotThuAM ? 'AM' : '') + (pt.slotThuAM && pt.slotThuPM ? '-' : '') + (pt.slotThuPM ? 'PM' : '') + ')';
      }
      // Friday Text
      if (heading.length > 0 && (pt.slotFriAM || pt.slotFriPM)) {
        heading += ', ';
      }
      if (pt.slotFriAM || pt.slotFriPM) {
        heading += 'Fridays (' + (pt.slotFriAM ? 'AM' : '') + (pt.slotFriAM && pt.slotFriPM ? '-' : '') + (pt.slotFriPM ? 'PM' : '') + ')';
      }
    }

    this.preferredTime.heading = heading;
    this._bhAnalyticsService.clickEvent('NewMsg: select-preferredtime', this.preferredTime.heading);
    this.goToNextStep();
  }

  selectPharmacy(pharm) {
    console.log("select pharmacy:", pharm);
    this.selectedPharmacy = pharm;
    // this._bhAnalyticsService.customEvent('new-message', 'select-pharmacy', pharm.Name + ' , ' + pharm.Address.City);
    this._bhAnalyticsService.clickEvent('NewMsg: select-pharmacy', pharm.Name + ' , ' + pharm.Address.City);
    this.goToNextStep();
  }

  async viewTestResult(tr, ev) {
    ev.stopPropagation();
    console.log('viewTestResult', tr, ev);
    const modal = await this.modalCtrl.create({
      component: TestResultDetailsModalComponent,
      cssClass: "wide-modal",
      backdropDismiss: false,
      componentProps: {
        data: tr,
        tableData: this.testResultsData,
        source: 'new-message'
      },
    });
    modal.onDidDismiss().then(async (data) => {
      if (data && data.data && data.data.isSelected) {
        this.selectTestResult(tr);
      }
    });
    await modal.present();
  }

  selectTestResult(tr) {
    this.selectedResult = tr;
    console.log("selected Result", this.selectedResult);
    // this._bhAnalyticsService.customEvent('new-message', 'select-test-result', tr.OrderedItemDisplay);
    this._bhAnalyticsService.clickEvent('NewMsg: select-test-result', tr.OrderedItemDisplay);
    const testDate = (tr.ObservationTime && tr.ObservationTime.indexOf(' ') > -1) ? tr.ObservationTime.split(' ')[0] : tr.Date;
    this.selectedResultData = {
      // heading: tr.OrderedItemDisplay
      heading: tr.OrderedItemDisplay + ' (' + testDate + ')',
      hideFromLiteUser: false
    }
    console.log('selected result data', this.selectedResultData.heading);
    //this.reachedTestResultStep = true;
    // this.choiceMade = true;
    // this.buttonView = "next";
    console.log("selected tr", tr);
    this.goToNextStep();
  }

  setPrescriptionSelection(p: Medication) {
    this._bhAnalyticsService.clickEvent('NewMsg: select-presc-med', p.Medication);
    if (p.isSelected) {
      this.medicineHeading.push(p.Medication);
      this.prescriptionList.push(p);
    } else if (!p.isSelected) {
      const indexMed = this.medicineHeading.findIndex((m) =>
        m.includes(p.Medication)
      );
      console.log("indexMed", indexMed);
      this.medicineHeading.splice(indexMed, 1);
      const index = this.prescriptionList.find((m) => m.Code === p.Code);
      console.log('prescription index', index);
      this.prescriptionList = this.prescriptionList.filter(m => m.Code !== index.Code);
    }

    console.log(' prescriptionList', this.prescriptionList);
    //const medicineHeading = this.selectedMedicine.heading.concat(this.selectedMedicine.heading);
    console.log("selectedMedicine heading", this.medicineHeading);

    this.selectedPrescription = {
      heading: this.medicineHeading.join(", "),
      hideFromLiteUser: false
    };
    this.choiceMade = true;
  }

  selectPrescriptionItem(p: Medication) {
    //this.selectedPrescription = p;
    p.isSelected = !p.isSelected;
    this.setPrescriptionSelection(p);
  }

  prescriptionChange(event) {
    console.log("event detail checked", event.detail.checked);
    this.showOtherPrescription = event.detail.checked;
  }

  setOtherAppointment(event) {
    this.showOtherAppointment = event.detail.checked;
  }

  setOtherPharmacy(event) {
    this.showOtherPharmacy = event.detail.checked;
  }

  continueTriage() {
    this._bhAnalyticsService.clickEvent('NewMsg: symptomQuestions',this.symptomsQuestionsAnswers);
    this.goToNextStep();
  }

  continuePharmacy() {
    this._bhAnalyticsService.clickEvent('NewMsg: continue-other-pharmacy', this.otherPharmacyValue);
    this.goToNextStep();
  }

  continueSymptomQuestion() {
      this.compileAllAnswers();
      this.goToNextStep();
  }

  continueRxRefill() {
    console.log('selectPrescriptions', this.selectedPrescription, this.otherPrescriptionValue);
    let rxHeading = '';
    for (const pre of this.prescriptionList) {
      rxHeading += pre.OrderedItemDisplay + ', ';
    }
    // Handle OTHER Prescription
    if (this.showOtherPrescription) {
      rxHeading += this.otherPrescriptionValue;
    }
    this.selectedPrescription = {
      heading: rxHeading,
      hideFromLiteUser: false
    };
    this._bhAnalyticsService.clickEvent('NewMsg: continue-rx-refill', this.selectedPrescription.heading);
    // this._bhAnalyticsService.customEvent('new-message', 'select-prescription', this.selectedSubject.heading);
    this.goToNextStep();
  }

  toggleAdditionalNotes(event) {
    console.log("showTextArea: event detail checked", event.detail.checked);
    this.showAdditionalInformation = event.detail.checked;
    this._bhAnalyticsService.clickEvent('NewMsg: toggle-additional-notes', event.detail.checked ? 'yes' : 'no');

    if (this.showAdditionalInformation) {
      this.applyMessageValidation();
    } else {
      this.clearMessageValidation();
    }
  }

  goToNextStep() {
    if ((this.currentStepIndex + 1) < this.workflowSteps.length) {
      this.currentStepIndex += 1;
      this.currentStep = this.workflowSteps[this.currentStepIndex];
      this._bhAnalyticsService.clickEvent('NewMsg: goto-next-step', this.currentStep);
       console.log('goToNextStep', this.currentStep);
      switch (this.currentStep) {
        case 'message-subject':
          this.scrollToStep('messageSubjectStep');
          break;

        case 'symptoms':
          this.scrollToStep('symptomsStep');
          break;

        case 'symptoms-questions':
          this.scrollToStep('symptomsQuestionsStep');
          break;

        case 'triage':
          this.scrollToStep('triageStep');
          break;

        case 'test-results':
          this.scrollToStep('testResultsStep');
          break;

        case 'provider':
          this.scrollToStep('providerStep');
          break;

        case 'medicines':
          this.scrollToStep('medicinesStep');
          break;

        case 'appt-change-action':
          this.scrollToStep('apptChangeStep');
          break;

        case 'billing-subject':
          this.scrollToStep('billingSubjectStep');
          break;

        case 'pharmacy':
          this.scrollToStep('pharmacyStep');
          break;

        case 'message-type':
          this.scrollToStep('messageTypeStep');
          break;

        case 'preferred-times':
          this.scrollToStep('preferredTimesStep');
          break;

        case 'timeframe':
          this.scrollToStep('timeframeStep');
          break;

        case 'upcoming-appts':
          this.scrollToStep('upcomingApptsStep');
          break;

        case 'visit-reason':
          this.scrollToStep('visitReasonStep');
          break;

        case 'write-message':
          this.scrollToStep('writeMessageStep');
          break;

      }
    }
  }

  goToPrevStep(step) {
    const stepExists = this.workflowSteps.find(w => w === step);
    if (stepExists !== undefined) {
      const stepIndex = this.workflowSteps.findIndex(w => w === step);
      this.currentStepIndex = stepIndex;
      this.currentStep = stepExists;
      console.log('goToPrevStep: ', stepIndex, this.currentStepIndex, this.currentStep);
      if (stepIndex === 0) {
        this.modalTitle = this.AWSTranslate.read('New Message', this.AWSTranslate.langId);
      }
    }
    console.log('goToPrevStep: ', stepExists, this.currentStepIndex, this.currentStep);
    this._bhAnalyticsService.clickEvent('NewMsg: goto-prev-step', this.currentStep);
  }

  goBack() {
    this.currentStepIndex = this.currentStepIndex > 0 ? this.currentStepIndex - 1 : 0;
    this.currentStep = this.workflowSteps[this.currentStepIndex];
    this._bhAnalyticsService.clickEvent('NewMsg: go-back', this.currentStep);
    if (this.currentStepIndex === 0) {
      this.modalTitle = this.AWSTranslate.read('New Message', this.AWSTranslate.langId);
    }
  }

  async openEmergencyUrgentCareModal(type) {
    const modal = await this.modalCtrl.create({
      component: MedicalUrgentModalPage,
      cssClass: "info-modal",
      backdropDismiss: false,
      componentProps: {
        type: type,
      },
    });
    modal.onDidDismiss().then(async (data) => { });
    await modal.present();
  }

  async loadTestResults(filter?: Date | null) {
    const [rows, lastLoginDate, facilitiesList] = await Promise.all([
      this._testResultsService.getTestResults(),
      this._testResultsService.getLastLoginDate(),
      this._healthRecordService.getFacilitiesPhoneNumber(),
    ]);
    this.facilitiesList = facilitiesList.providers;
    const tableData: SmcTableData = {
      columns: [
        {
          name: "",
          prop: "OrderCategory",
          cellTemplate: this.iconColumnTemplate,
          headerClass: "header-icon",
          cellClass: "cell-icon",
          collapseCol: 0,
        },
        {
          name: "Test",
          prop: "OrderedItemDisplay",
          maxWidth: 2000,
          cellClass: "card-bold",
          collapseCol: 1,
          collapseMinWidth: 200,
        },
        {
          name: "Result status",
          prop: "ResultStatus",
          maxWidth: 2000,
          cellClass: "card-detail",
          collapseCol: 1,
          collapseMinWidth: 200,
        },
        {
          name: "Ordered by",
          prop: "OrderedBy",
          maxWidth: 2000,
          cellClass: "card-detail",
          collapseCol: 1,
          collapseMinWidth: 200,
        },
        {
          name: "Date",
          prop: "Date",
          cellTemplate: this.dateColumnTemplate,
          maxWidth: 120,
          cellClass: "card-detail",
          collapseCol: 1,
          collapseMinWidth: 200,
        },
        {
          name: "Location",
          prop: "LocationFromMultiple",
          maxWidth: 2000,
          cellClass: "card-detail",
          collapseCol: 1,
          collapseMinWidth: 200,
        },
        // { name: 'Provider Number', prop: 'ProviderPhone', cellClass: 'card-detail' },
        {
          name: "",
          cellTemplate: this.iconSelectableTemplate,
          maxWidth: 60,
          cellClass: "select-icon",
          collapseCol: 2,
        },
      ],
      rows: rows.sort((a, b) => (b.Date > a.Date ? 1 : -1)),
    };

    // mark test results as unread if they are dated later than the last login date,
    // or if the user has never logged in before mark all as unread
    tableData.rows = tableData.rows.map((row) => {
      const testResultDate = new Date(row.Date);
      const location =
        row.EnteredAt ||
        row.EnteredBy ||
        row.EnteringOrganization ||
        row.PerformedAt;
      return Object.assign(row, {
        unread:
          lastLoginDate == null ||
          testResultDate.getTime() > lastLoginDate.getTime(),
        LocationFromMultiple: location,
        ProviderPhone: this.getFilteredFacilityNumber(location.toLowerCase()),
      });
    });
    if (filter && filter != null) {
      tableData.rows = tableData.rows.filter((a: any) =>
        this._dateHandler.parseDateString(a.Date).isSameDay(filter)
      );
    }

    tableData.rows = tableData.rows.sort((a, b) => {
      if (a.Date === b.Date) {
        if (a.OrderedItemDisplay === b.OrderedItemDisplay) {
          return a.EnteringOrganization > b.EnteringOrganization
            ? -1
            : a.EnteringOrganization > b.EnteringOrganization
              ? 1
              : 0;
        } else {
          return a.OrderedItemDisplay > b.OrderedItemDisplay ? -1 : 1;
        }
      } else {
        return a.Date > b.Date ? -1 : 1;
      }
    });

    tableData.rows = tableData.rows.filter(
      (result, index) =>
        index ===
        tableData.rows.findIndex(
          (otherResult) =>
            result.OrderedItemDisplay === otherResult.OrderedItemDisplay &&
            result.Date === otherResult.Date
        )
    );

    this.data = tableData;
    this._changeDetectorRef.detectChanges();
    tableData.rows.filter((a: any) => { });

    this._changeDetectorRef.detectChanges();

    this.data.rows.forEach((r) => {
      this.testResultsData.push(r);
    });
    console.log("table result data", this.testResultsData);

    this.testResultsData = this.testResultsData.map((t) => {
      const resultItem = t.ResultItems ? t.ResultItems[0] : "";
      return { ...t, ...resultItem };
    });
    console.log("test results", this.testResultsData);
  }

  getFilteredFacilityNumber(locationStr: string) {
    const locationName = locationStr
      .toLowerCase()
      .replace(/[^a-zA-Z0-9] | \s+ /g, "");
    const filteredFacility = this.facilitiesList.filter((facilityNumber) => {
      const facilityName = facilityNumber.name
        .toLowerCase()
        .replace(/[^a-zA-Z0-9] | \s+ /g, "");
      const facilityCode = facilityNumber.code
        .toLowerCase()
        .replace(/[^a-zA-Z0-9] | \s+ /g, "");
      const namesMatch =
        locationName.match(facilityName) ||
        locationName
          .split(" ")
          .join("")
          .indexOf(facilityName.split(" ").join("")) > -1;
      const codeMatchesName = facilityCode.match(locationName);
      return codeMatchesName || namesMatch;
    });
    return filteredFacility[0] ? filteredFacility[0].phone : "";
  }

  async setFavoriteProviders() {
    this.favoriteProviders = (
      await this.messagesService.getFavoriteProviders(this.messageType)
    ).children;
    if (
      this.favoriteProviders &&
      (!this.providerSearchTerm || this.providerSearchTerm.length < 1)
    ) {
      this.providerSearchResults = [].concat(this.favoriteProviders);
    }
  }

  async searchProviders(searchEvent) {
    console.log("searchProviders", searchEvent, searchEvent.detail.value);
    this.providerSearchTerm = searchEvent.detail.value;
    const queryText = this.messagesService.cleanMessage(
      this.providerSearchTerm
    );
    console.log("queryText", queryText);

    if (queryText != null && queryText.length == 0) {
      this.setFavoriteProviders();
      return;
    }

    if (queryText != null && queryText.length > 2) {
      this.analytics.searchEvent(queryText);
      //const searchTerm = this.searchTextInput.value;
      const query: RecipientSearchQuery = {
        MessageType: this.messageType,
        SearchKey: queryText,
      };
      const searchResults = await this.messagesService.searchProviders(query);
      console.log("search results =>", searchResults);
      if (
        !searchResults ||
        !searchResults.children ||
        !searchResults.children.length
      ) {
        this.providerSearchResults = [];
      } else {
        this.providerSearchResults = searchResults.children.slice(0, 30);
      }
    } else {
      this.providerSearchTerm = "";
      this.providerSearchResults = [];
      this.favoriteProviders = (
        await this.messagesService.getFavoriteProviders(this.messageType)
      ).children;
    }
    // this.patientForm.get("searchTextInput").updateValueAndValidity();
    // this.formStateChange();
    console.log("providerSearchResult", this.providerSearchResults);
    return Promise.resolve(this.providerSearchResults);
  }

  async searchPrescriptions() {
    const prescriptions = await this._medicinesService.getMedicines();
    const prescriptionRow = prescriptions.filter(
      (medicine) =>
        medicine.EnteredAt.toLowerCase().includes("baystate") &&
        (medicine.ToTime == "" ||
          new Date() < this._dateHandler.parseDateString(medicine.ToTime))
    );
    console.log("prescriptions =>", prescriptionRow);
    this.prescriptions = prescriptionRow;
    this.prescriptions.forEach((p) => {
      return (this.prescription && this.prescription.Medication === p.Medication ? p.isSelected = true : p.isSelected = false);
    });
  }

  toggleFavoriteProvider(prov: any) {
    const isFavorite = prov.Favorite === 0 ? 1 : 0;
    prov.Favorite = isFavorite;
    const providerIds = [prov.Provider.ID];
    if (isFavorite === 1) {
      this.providerService.addFavoriteProvider(providerIds);
    } else {
      this.providerService.deleteFavoriteProvider(providerIds);
    }
    console.log("toggleFavorite:", prov, prov.Favorite);
  }

  async searchSymptoms(ev) {
    console.log("search event", ev);
    try {
      this.searchTerm = ev.detail.value;
      this.symptoms = [];
      this.suggestions = [];
      if (ev) {

        //Call symptoms
       //const symptomRes = await this._symptomCheckerService.getPredictiveText(ev.detail.value);

       //console.log("symptoms", symptomRes);

        console.log("event", ev.detail.value);
        console.log("predictive text", this.symptomsDict);
        if (ev.detail.value.length < 3) {
          this.suggestions = []; return;
        }
        const lowerCaseSearch = ev.detail.value.replace(/\ /gi, "").toLowerCase().trim();
        let matchingSuggestions = this.symptomsDict.filter(suggestion => suggestion.replace(/\ /gi, "").toLowerCase().trim().indexOf(lowerCaseSearch) > -1);
        matchingSuggestions.sort((a, b) => a.length - b.length);
        matchingSuggestions = matchingSuggestions.slice(0, 10);
        //this.suggestions = matchingSuggestions;
        for (const ms of matchingSuggestions) {
          let matching: Suggestions = {
            name: ms,
            isChecked: this.selectedSymptoms.find(s => s.name === ms && s.isChecked) !== undefined
          };
          this.suggestions.push(matching);
        }
        this.suggestions = this.suggestions.slice(0, 10);
        console.log('suggestions', this.suggestions);

      }
      //this.currentView = "content";
    } catch (err) {
      // console.error("Search error: ", err);
    }
  }

  // addSymptom(valueString: string) {
  //   this.suggestions = [];
  //   let values = valueString.split(",");

  //   // strip spaces at start or end
  //   values = values.map((value) => {
  //     let result = value.replace(/^ */, "");
  //     result = result.replace(/ *$/, "");
  //     return result;
  //   });

  //   for (const value of values) {
  //     this._newSymptomInput.el.value = "";
  //     for (const symptom of this.symptoms) {
  //       if (value.toLowerCase() === symptom.value.toLowerCase()) {
  //         return;
  //       }
  //     }

  //     // Highlight misspelled or incorrect symptoms.
  //     if (value !== "") {
  //       this.symptoms.push({ value, isValid: true });
  //       this.form1.controls.symptoms.setValue(this.symptoms);
  //     }
  //   }
  //   console.log('suggestions', this.suggestions);
  // }

  // addSymptomKeyup(event: KeyboardEvent, value: string) {
  //   if (event.key == "Enter") {
  //     this.addSymptom(value);
  //   } else if (this.symptomsDict.length > 0) {
  //     if (value.length < 3) {
  //       this.suggestions = [];
  //       return;
  //     }
  //     const lowerCaseSearch = value.toLowerCase();
  //     let matchingSuggestions = this.symptomsDict.filter((suggestion) =>
  //       suggestion.toLowerCase().includes(lowerCaseSearch)
  //     );
  //     matchingSuggestions.sort((a, b) => a.length - b.length);
  //     matchingSuggestions = matchingSuggestions.slice(0, 5);
  //     this.suggestions = matchingSuggestions;
  //     console.log('predictive suggestion', this.suggestions);
  //   }
  // }

  setSymptom(sypm, event) {
    console.log("symptom checked", event, sypm);
    this.selectedSymptoms.push(sypm);
    this.selectedSymptoms = this.selectedSymptoms.filter(ss => ss.isChecked === true);
  }

  updateSymptom(sypm, event) {
    this.selectedSymptoms = this.selectedSymptoms.filter(ss => ss.isChecked === true);
  }

  async selectSymptoms() {
    this._bhAnalyticsService.clickEvent('NewMsg: new-symptoms-dismiss', '');
    //console.log('selected data =>', data.data.symptoms);
    const symptoms: Suggestions[] = this.selectedSymptoms;

    console.log("symptoms", symptoms);
    const symptomHeader: string[] = [];
    symptoms.forEach((s) => {
      symptomHeader.push(s.name);
      this.symptomsSubject = {
        heading: symptomHeader.join(", "),
        hideFromLiteUser: false
      };
    });

    this.patientData.symptoms = Object.assign([], symptoms);
    this.setDiagnosis();
    console.log("selected subject symptoms", this.selectedSubject);
    this.goToNextStep();
    //this.choiceMade = false;
    // this.selectedSubject.navigateToProviderStep = true;
    // this.reachedProviderStep = true;
  }

  onChangeRegion(event) {
    console.log('Region', event);
    this.countryQuestion = event;
    this.patientData.region = event;
  }
  onChangeAge(event) {
    console.log('Age', event);
    //this.ageQuestion = event;
    //this.patientData.patAgeGroupID = event;
  }
  getSexAnswer(event) {
    console.log('Sex', event);
    //this.sexQuestion = event;
    //this.patientData.sex = event;
  }
  getPregnantAnswer(event) {
    console.log('Pregnant', event);
    this.pregnancyQuestion = event;
    if(event !== undefined) {
      this.patientData.pregnancy = event;
    }
  }

  getQ1Answer(event) {
    console.log('Q1:', event.detail.value);
    this.q1 = event.detail.value;
  }
  getQ2Answer(event) {
    //console.log('Q2:', event.detail.value);
    this.q2 = event.detail.value;
  }
  getQ3Answer(event) {
    //console.log('Q3:', event.detail.value);
    this.q3 = event.detail.value;
  }
  getQ4Answer(event) {
    //console.log('Q4:', event.detail.value);
    this.q4 = event.detail.value;
  }
  getQ5Answer(event) {
    //console.log('Q5:', event.detail.value);
    this.q5 = event.detail.value;
  }
  getQ6Answer(event) {
    //console.log('Q6:', event.detail.value);
    this.q6 = event.detail.value;
  }
  getQ7Answer(event) {
    //console.log('Q7:', event.detail.value);
    this.q7 = event.detail.value;
  }

  async compileAllAnswers() {
    //await this.setDiagnosis();
      this.symptomsQuestionsAnswers.answer1 = this.q1.value;
      this.symptomsQuestionsAnswers.answer1text = this.q1.answerText;

      this.symptomsQuestionsAnswers.answer2 = this.q2.value;
      this.symptomsQuestionsAnswers.answer2text = this.q2.answerText;

      this.symptomsQuestionsAnswers.answer3 = this.q3.value;
      this.symptomsQuestionsAnswers.answer3text = this.q3.answerText;

      this.symptomsQuestionsAnswers.answer4 = this.q4.value;
      this.symptomsQuestionsAnswers.answer4text = this.q4.answerText;

      this.symptomsQuestionsAnswers.answer5 = this.q5.value;
      this.symptomsQuestionsAnswers.answer5text = this.q5.answerText;

      this.symptomsQuestionsAnswers.answer6 = this.q6.value;
      this.symptomsQuestionsAnswers.answer6text = this.q6.answerText;

      this.symptomsQuestionsAnswers.answer7 = this.q7.value;
      this.symptomsQuestionsAnswers.answer7text = this.q7.answerText;

      console.log('list of q and as', this.symptomsQuestionsAnswers);
      this.compileQuestionAnswerString();

      this.setTriageScore(this.symptomsQuestionsAnswers);
  }

  setTriageScore(scFormObj) {
      try {
        this.isFinishedLoadingSlider = false;
        this.auth.loadFreshAuthStatus().subscribe(async authStatus => {

          const symptomArray: string[] = [];
          this.patientData.symptoms.forEach((symptom) => {
          symptomArray.push(symptom.name);
          this.symptomString = symptomArray.join(',');
          });
          this.patientData.symptomsString = this.symptomString;

           // try call twice because isabel occasionally returns an invalid response on initial call
           // await this.loadSuggestedDiagnosis();
           this.triageApiUrl = this.diagnosisValue.triage_api_url;


          // this.suggestedDiagnosis = Object.assign([],this.diagnosisValue.diagnoses);

          // this.redFlagDiagnosis = this.suggestedDiagnosis.filter(sd => sd.red_flag === 'true');

          // this.commonDiagnosis = this.suggestedDiagnosis.filter(sd =>sd.common_diagnosis === 'true')
          // if (this.suggestedDiagnosis === null) {
          //   await this.loadSuggestedDiagnosis();
          //   this.suggestedDiagnosis = this.diagnosisValue.diagnoses;

        //   if (this.suggestedDiagnosis === null) {

        //     return;
        //   }
        // }

          //console.log('patientData', this.patientData.symptomsString);
          const url: string = this.triageApiUrl;
          console.log('url', url);

          const regex = /dx=[0-9,]*/;
          const match = url.match(regex);
          console.log('match', match);

          let dx = '';
          if(match) {
            dx = match[0].replace(/^dx=/, '');
            console.log('dx', dx);
          }

          this.triageResults = await this._symptomCheckerService.getTriageScore(scFormObj, authStatus.EffectiveUserID, dx, this.patientData.symptomsString, this.patientData);
          console.log('triageScore', this.triageResults.where_to_now.triage_score);
          this.triageResults = Number(this.triageResults.where_to_now.triage_score);
          this.isFinishedLoadingSlider = true;
          this.rangeDisabled = true;
          this.getTriageResultLangauge();

        });
      } catch (error) {

      }
  }


  getTriageResultLangauge() {
      if(this.triageResults <= 59) {
          this.triageLanguage = 'Send Provider Symptoms';
          this.goToNextStep();
      }
      else if(this.triageResults >= 60 && this.triageResults <= 84 ) {
        this.triageLanguage = 'Urgent';
      }
      else {
        this.triageLanguage = 'ER';
      }
  }


  async setDiagnosis(): Promise<boolean> {
    try {
      console.log('patient data', this.patientData);

      this.auth.loadFreshAuthStatus().subscribe(async authStatus => {
        this.authId = authStatus.EffectiveUserID;
        const symptomArray: string[] = [];
        this.patientData.symptoms.forEach((symptom) => {
          symptomArray.push(symptom.name);
          this.symptomString = symptomArray.join(',');
        });
        this.patientData.symptomsString = this.symptomString;
        console.log('patientData', this.patientData.symptomsString);

        //  loadSuggestedDiagnosis = async () => {
        //   const returnValue = await this._symptomCheckerService.getSuggestedDiagnoses2(this.patientData, this.authId);
        //   if (!returnValue || !returnValue.diagnosis_checklist) {
        //     return null;
        //   } else {
        //     console.log('diagnoses', returnValue);
        //     return returnValue;
        //   }
        // };

        // try call twice because isabel occasionally returns an invalid response on initial call
        await this.loadSuggestedDiagnosis();
        this.triageApiUrl = this.diagnosisValue.triage_api_url;
        console.log('triage_api_url', this.triageApiUrl);

        this.suggestedDiagnosis = Object.assign([],this.diagnosisValue.diagnoses);
        console.log('suggested Diagnosis', this.suggestedDiagnosis);
        this.redFlagDiagnosis = this.suggestedDiagnosis.filter(sd => sd.red_flag === 'true');
        console.log('red flag Diagnosis', this.redFlagDiagnosis);
        this.commonDiagnosis = this.suggestedDiagnosis.filter(sd =>sd.common_diagnosis === 'true')
        if (this.suggestedDiagnosis === null) {
          await this.loadSuggestedDiagnosis();
          this.suggestedDiagnosis = this.diagnosisValue.diagnoses;
          console.log('suggested Diagnosis', this.suggestedDiagnosis);
          if (this.suggestedDiagnosis === null) {
            this.modalService.open('Encountered an issue', 'Could not process your request, please try again in a few minutes', ModalType.BASIC);
            return;
          }
        }

        // handle no diagnoses returned by isabel
        // if (this.suggestedDiagnosis.diagnosis_checklist.NoResult) {
        //   const noResultsTxtArr = suggestedDiagnosis.diagnosis_checklist.NoResult.information;
        //   this.getInvalidSymptoms(noResultsTxtArr);
        //   this.modalService.open('Encountered an issue', suggestedDiagnosis.diagnosis_checklist.NoResult.information, ModalType.BASIC);
        //   return;
        // }
      });
        return Promise.resolve(true);
    } catch (error) {
        return Promise.resolve(false);
    }
  }

  // selectDiagnosis(d, event) {
  //   console.log("diagnoses checked", event, d);
  //   this.selectedDiagnosis.push(d);
  //   this.selectedDiagnosis = this.selectedDiagnosis.filter(ss => ss.isChecked === true);
  // }

  async loadSuggestedDiagnosis() {
    try {
      console.log('patient data', this.patientData);

      const returnValue = await this._symptomCheckerService.getSuggestedDiagnoses2(this.patientData, this.authId).toPromise();
      console.log('return value', returnValue.diagnoses_checklist);
      if (!returnValue) {
          this.diagnosisValue = null;
      } else {
        this.diagnosisValue = returnValue.diagnoses_checklist;
        console.log('diagnoses',  this.diagnosisValue.diagnoses);
      }
      return this.diagnosisValue;
    } catch (error) {
      console.error('Error fetching data:', error);
      return error;
    }

  }

  async sendMessage() {
    this._bhAnalyticsService.clickEvent('NewMsg: send-message-submit', '');
    try {
      if (this.messageForm.valid) {
        await this.send();
        console.log("trying to get to final stage");
        this.currentView = 'confirmation';
      } else {
        return;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async requestRefill() {
    this._bhAnalyticsService.clickEvent('NewMsg: request-refill-submit', '');
    try {
      if (this.messageForm.valid) {
        const contactPhone = this.messageForm.controls.phone.value ? '  Contact Phone: ' + this.messageForm.controls.phone.value + '\n\n' : '';
        const msg = this.messageForm.controls.message.value ? '  Message: ' + this.messageForm.controls.message.value + '\n\n' : '';

        // Process each listed selection
        if (this.prescriptionList && this.prescriptionList.length > 0) {
          for (const pre of this.prescriptionList) {
            const medTopic = '  Medication to Refill: ' + pre.Medication + '\n\n';
            const state: PrescriptionState = {
              medication: pre,
              medicineNotListed: false,
              medicineText: "",
              comments: '\n\n' + contactPhone + medTopic + msg,
              pharmacy: this.selectedPharmacy,
              pharmacyNotListed: this.showOtherPharmacy,
              pharmacyText: this.showOtherPharmacy ? this.otherPharmacyValue : '',
              provider: this.selectedProvider,
              contactType: 'message',
              contactPhoneNumber: this.messageForm.controls.phone.value,
              contactPhoneNumberExt: "",
              contactBestTime: "",
              contactLeaveVoicemail: false,
              contactSaveContact: false
            }
            console.log('request fill stuff', this.selectedPrescription, this.prescriptionList, this.selectedPharmacy, this.selectedProvider, this.messageForm.controls.phone.value, state);
            const requestData = this._prescriptionStateService.buildPrescriptionRequest(state);
            console.log('request Data', requestData);
            await this._prescriptionService.refillRequest(requestData);
            await this._prescriptionStateService.clearState();
            this.currentView = 'confirmation';
          }
        }

        // Process unlisted prescription
        if (this.showOtherPrescription) {
          const medTopic = '  Medication to Refill: ' + this.otherPrescriptionValue + '\n\n';
          const state: PrescriptionState = {
            medication: "",
            medicineNotListed: true,
            medicineText: this.otherPrescriptionValue,
            comments: '\n\n' + contactPhone + medTopic + msg,
            pharmacy: this.selectedPharmacy,
            pharmacyNotListed: this.showOtherPharmacy,
            pharmacyText: this.showOtherPharmacy ? this.otherPharmacyValue : '',
            provider: this.selectedProvider,
            contactType: 'message',
            contactPhoneNumber: this.messageForm.controls.phone.value,
            contactPhoneNumberExt: "",
            contactBestTime: "",
            contactLeaveVoicemail: false,
            contactSaveContact: false
          };
          console.log('request fill stuff', this.selectedPrescription, this.prescriptionList, this.selectedPharmacy, this.selectedProvider, this.messageForm.controls.phone.value, state);
          const requestData = this._prescriptionStateService.buildPrescriptionRequest(state);
          console.log('request Data', requestData);
          await this._prescriptionService.refillRequest(requestData);
          await this._prescriptionStateService.clearState();
          this.currentView = 'confirmation';

        }
      } else {
        console.log('Form is not valid', this.messageForm.valid, this.messageForm);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async requestAppointment() {
    if (this.messageForm.valid) {
      try {
        // this.reqApptStateService.updateState();
        const contactPhone = this.messageForm.controls.phone.value ? '  Contact Phone: ' + this.messageForm.controls.phone.value + '\n\n' : '';
        const msg = this.messageForm.controls.message.value ? '  Message: ' + this.messageForm.controls.message.value + '\n\n' : '';
        let action = '';
        if (this.selectedMainItem.id === MSG_FLOW_TYPE_ID.MNU_CHANGE_APPT) {
          console.log('requestAppointment: reschedule', this.selectedApptChgAction);
          if (this.selectedApptChgAction.id === MSG_FLOW_TYPE_ID.AC_RESCHEDULE) {
            this.reqApptStateService.state.subjectPrefix = 'Reschedule Request: ';
            action = 'Reschedule';
          } else {
            this.reqApptStateService.state.subjectPrefix = 'Cancellation Request: ';
            action = 'Cancel';
          }
        } else {
          this.reqApptStateService.state.subjectPrefix = 'Appointment Request: ';
          action = 'Schedule';
        }
        const apptAction = this.selectedMainItem.id === MSG_FLOW_TYPE_ID.MNU_CHANGE_APPT ?
          '  Appointment to ' + action + ': ' + (this.showOtherAppointment ? this.otherAppointmentValue : this.selectedAppt.heading) + '\n\n' :
          '  Appointment to Schedule: ' + this.selectedSubject.heading + '\n\n';
        this.reqApptStateService.state.reasonName = this.showOtherAppointment ? 'Other Appointment' : this.selectedSubject.heading;
        // this.reqApptStateService.state.reasonName = this.selectedSubject.heading;
        this.reqApptStateService.state.reasonType = this.selectedSubject.value;
        this.reqApptStateService.state.reasonText = '\n\n' + contactPhone + apptAction + msg;
        this.reqApptStateService.state.provider = this.selectedProvider;
        this.reqApptStateService.state.timeFrame = (this.selectedTimeframe) ? this.selectedTimeframe.value : null;
        this.reqApptStateService.state.slotMonAM = this.preferredTime.slotMonAM;
        this.reqApptStateService.state.slotMonPM = this.preferredTime.slotMonPM;
        this.reqApptStateService.state.slotTueAM = this.preferredTime.slotTueAM;
        this.reqApptStateService.state.slotTuePM = this.preferredTime.slotTuePM;
        this.reqApptStateService.state.slotWedAM = this.preferredTime.slotWedAM;
        this.reqApptStateService.state.slotWedPM = this.preferredTime.slotWedPM;
        this.reqApptStateService.state.slotThuAM = this.preferredTime.slotThuAM;
        this.reqApptStateService.state.slotThuPM = this.preferredTime.slotThuPM;
        this.reqApptStateService.state.slotFriAM = this.preferredTime.slotFriAM;
        this.reqApptStateService.state.slotFriPM = this.preferredTime.slotFriPM;
        this.reqApptStateService.state.contactType = 'message';
        this.reqApptStateService.state.contactPhoneNumber = this.messageForm.controls.phone.value;
        const requestData = this.reqApptStateService.buildRequestAppointmentRequest();
        await this.requestApptService.requestAppointment(requestData);
        this.reqApptStateService.clearState();
        this.currentView = 'confirmation';
      } catch (err) {
        console.error(err);
      }
    }
  }

  setReqApptSubject() {
  }

  finished() {
    if (this.feedbackText) {
      this._bhAnalyticsService.customEvent('new-message', 'feedback-text', this.feedbackText);
    } else {
      this._bhAnalyticsService.clickEvent('NewMsg: done-without-feedback', '');
    }
    this.modalCtrl.dismiss();
  }

  formatAddress(address) {
    let fullAddress = address.Line1;
    if (address.Line2) {
      fullAddress = fullAddress + " " + address.Line2;
    }
    fullAddress =
      fullAddress +
      ", " +
      address.City +
      ", " +
      address.State +
      " " +
      address.PostalCode;

    return fullAddress;
  }


  submitReview(i) {
    this.condition = i + 1;
    console.log("index star", i);
    this._bhAnalyticsService.customEvent('new-message', 'star-rating: ' + this.modalTitle, this.condition);

    this.showRatingTextAreaInput = true;
    if (this.showRatingTextAreaInput) {
      //this.focusTextArea();
    }
  }

  focusTextArea() {
    this.showSubmit = true;
    console.log("event test");
    const textarea = "";
    console.log("textarea", textarea);
    const len = this.feedbackText.length;
    //textarea.focus();
    if (len > 0) {
      //textarea.se(len, len, 'forward');
    }
  }

  testStateChange(event) {
    console.log("test state change", event.detail.checked);
  }

  async setFavoritePharmacies() {
    this.favoritePharmacies =
      await this._prescriptionService.getFavoritePharmacies();
    if (
      this.favoritePharmacies &&
      (!this.pharmacySearchTerm || this.pharmacySearchTerm.length < 1)
    ) {
      this.pharmacySearchResults = [].concat(this.favoritePharmacies);
    }
  }

  async searchPharmacy(searchEvent) {
    this._bhAnalyticsService.clickEvent('NewMsg: searching-pharmacies', '');

    this.pharmacySearchTerm = searchEvent.detail.value;
    const queryText = this.pharmacySearchTerm;

    if (queryText != null && queryText.length == 0) {
      this.setFavoritePharmacies();
      return;
    }

    if (queryText != null && queryText.length > 2) {
      this.analytics.searchEvent(queryText);
      //const searchTerm = this.searchTextInput.value;
      const query = queryText;
      const searchResults = await this._prescriptionService.getPharmacies(
        query
      );
      if (!searchResults) {
        this.pharmacySearchResults = [];
      } else {
        this.pharmacySearchResults = searchResults.slice(0, 30);
      }
    } else {
      this.providerSearchTerm = "";
      this.pharmacySearchResults = [];
      this.favoritePharmacies =
        await this._prescriptionService.getFavoritePharmacies();
    }
    // this.patientForm.get("searchTextInput").updateValueAndValidity();
    // this.formStateChange();
    return Promise.resolve(this.pharmacySearchResults);
  }

  toggleFavoritePharmacy(pharm: any) {
    if (pharm.Preferred == 0) {
      pharm.Preferred = 1;
      this.addToFavorites(pharm);
    } else if (pharm.Preferred == 1) {
      pharm.Preferred = 0;
      this.removeFromFavorites(pharm);
      this.pharmacySearchResults = this.pharmacySearchResults.filter(p => p.Preferred !== pharm.Preferred);
    }
    this._bhAnalyticsService.clickEvent('NewMsg: toggle-pharm-favorite', pharm.Preferred);
  }

  async addToFavorites(row) {
    this._bhAnalyticsService.clickEvent("add to pharmacy favorites", row.id);
    await this._prescriptionService.addFavoritePharmacy(row.id);
    //await this.searchPharmacy(this.search );
  }

  async removeFromFavorites(row) {
    this._bhAnalyticsService.clickEvent(
      "remove from pharmacy favorites",
      row.id
    );
    await this._prescriptionService.deleteFavoritePharmacy(row.id);
    await this.searchPharmacy(this.search);
  }

  // showLastBtn(n: number) {
  //   console.log('number last step', n);
  //   this.lastStep = n;
  // }

  compileQuestionAnswerString() {
    const qanda =  'Questions related to symptoms: ' + '\n'
     + '\n' + 'Question 1 ' + this.symptomsQuestionsAnswers.question1 + '\n' + this.symptomsQuestionsAnswers.answer1text
     + '\n' + 'Question 2 ' + this.symptomsQuestionsAnswers.question2 + '\n' + this.symptomsQuestionsAnswers.answer2text
     + '\n' + 'Question 3 ' + this.symptomsQuestionsAnswers.question3 + '\n' + this.symptomsQuestionsAnswers.answer3text
     + '\n' + 'Question 4 ' + this.symptomsQuestionsAnswers.question4 + '\n' + this.symptomsQuestionsAnswers.answer4text
     + '\n' + 'Question 5 ' + this.symptomsQuestionsAnswers.question5 + '\n' + this.symptomsQuestionsAnswers.answer5text
     + '\n' + 'Question 6 ' + this.symptomsQuestionsAnswers.question6 + '\n' + this.symptomsQuestionsAnswers.answer6text
     + '\n' + 'Question 7 ' + this.symptomsQuestionsAnswers.question7 + '\n' + this.symptomsQuestionsAnswers.answer7text;
    console.log('qanda', qanda);
    return qanda;
    //this.inputValue = qanda;
  }

  public onValueChange(event: Event): void {
    //const
    console.log(event.target);
    const value = (event.target as any).value;
    // this.textAreaData = value;
    //console.log('test text area', this.textAreaData);
  }

  formatSymptomsSubject(): string {
    // console.log('formatSymptomsSubject: selected', this.selectedSymptoms);
    let formattedText = '';
    if (this.selectedSymptoms) {
      let symptomNames: string[] = [];
      for (const s of this.selectedSymptoms) {
        symptomNames.push(s.name);
      }
      if (symptomNames.length > 3) {
        const truncSymptoms = symptomNames.slice(0, 3);
        // console.log('formatSymptomsSubject: trunc', truncSymptoms);
        formattedText = truncSymptoms.join(', ') + '...';
      } else {
        formattedText = symptomNames.join(', ');
      }
    }
    // console.log('formatSymptomsSubject: formattedText', formattedText);
    return formattedText;
  }

  formatPharmacySubject(): string {
    let formattedText = "";
    if (this.prescriptionList) {
      let prescriptionNames: string[] = [];
      for (const p of this.prescriptionList) {
        prescriptionNames.push(this.filterMedicationString(p.Medication));
      }
      let truncSymptoms = [];
      if (prescriptionNames.length > 1) {
        truncSymptoms = prescriptionNames.slice(0, 5);
        formattedText = truncSymptoms.join(", ");
      } else {
        formattedText = prescriptionNames.join(", ");
      }
    }
    formattedText = this.truncateString(formattedText);
    console.log("formatMedicineSubject: formattedText", formattedText);
    return formattedText;
  }

  filterMedicationString(input: string): string {
    const result = input.match(/^(.*?)(\d+)/);
    //console.log('result', result);
    let filteredString = "";
    if (result && result[1]) {
      filteredString = result[1].trim();
    }
    //console.log('filtered string', filteredString);

    return filteredString;
  }

  truncateString(value: string): string {
    const limit = 150;
    if(value.length <= limit) {
      return value;
    }
    else {
      return value.substring(0, limit);
    }
  }

  async send() {
    try {
      console.log(
        'selected subject type', this.selectedSubject, this.selectedSubject.type
      );
      const msgText = this.messageForm.controls.message.value;
      const contactPhone = this.messageForm.controls.phone.value;
      const contactInfo = 'Contact Phone:' + contactPhone + '\n\n';
      const testResult = this.selectedResultData ? 'Test Result:' + this.selectedResultData.heading + '\n\n' : '';
      const medTopic = this.selectedPrescription ? 'Medication:' + this.selectedPrescription.heading + '\n\n' : '';
      const symptoms = this.selectedSymptoms.length > 0 ? 'Symptoms:' + this.symptomsSubject.heading + '\n\n' : '';
      let symptomQuestionsAnswers = '';
      if( this.triageResults && this.triageResults < 60) {
        symptomQuestionsAnswers = this.compileQuestionAnswerString();
        console.log('symptomQuestionsAnswers', symptomQuestionsAnswers);
      }
        symptomQuestionsAnswers !== '' ? symptomQuestionsAnswers + '\n\n': '';
      const subject = this.messagesService.cleanMessage(
        this.selectedMainItem.id === MSG_FLOW_TYPE_ID.MNU_BILLING_QUESTION ?
          this.formatBillingQuestionPrefix(this.selectedSubject) :
          this.selectedSubject.heading +
          (this.selectedResultData ? ': ' + this.selectedResultData.heading : '') +
          (this.selectedPrescription ? ': ' + this.formatPharmacySubject() : '') +
          (this.selectedSymptoms.length > 0 ? ': ' + this.formatSymptomsSubject() : '')
      );
      const body = this.messagesService.cleanMessage(contactInfo + testResult + medTopic + symptoms + '\n\n' + symptomQuestionsAnswers + '\n\n' + 'message body: ' + msgText );
      // const to = this.selectedProvider.Provider.LastName + ', ' + this.selectedProvider.Provider.FirstName;
      console.log('body of message', body);

      if (this.selectedSubject.type === 'medical') {
        // Send Medical Question
        await this.messagesService.sendMedicalQuestion({
          To: this.selectedProvider.Provider.ID,
          From: this.stateService.patientName.getValue(),
          Subject: subject,
          Text: body,
          Attachments: this.attachments.map(attachment => attachment.DocumentID)
        });
      } else {
        // Send General Question
        console.log('selectedSubject', this.selectedSubject.id );
        await this.messagesService.sendGeneralQuestion({
          To: this.selectedProvider.Provider.ID,
          From: this.stateService.patientName.getValue(),
          Subject: this.selectedSubject.id === 105 ? subject + ': ' + this.messageForm.controls.subjectInput.value : subject,
          Text: body,
          Attachments: this.attachments.map(attachment => attachment.DocumentID)
        });
      }

    } catch (err) {
      console.error(err);
    }
  }


}
