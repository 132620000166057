import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ActivateAccountService } from 'src/app/service/activate-account.service';
import { AccountService } from 'src/app/service/account.service';
import { ModalService } from 'src/app/service/modal.service';
import { ModalType } from 'src/app/model/ModalTypeEnum';
import { EmailChangeRequest } from 'src/app/model/EmailChangeRequest';
import { FormsValidationService } from 'src/app/service/forms-validation.service';
import { BhanalyticsService } from "../../../service/bhanalytics.service";
import { AwsTranslateService } from '../../../service/aws-translate.service';

@Component({
  selector: 'app-change-email-modal',
  templateUrl: './change-email-modal.component.html',
  styleUrls: ['./change-email-modal.component.scss'],
})
export class ChangeEmailModalComponent {

  @ViewChild('newEmail') newEmail;
  @ViewChild('newEmailConfirm') newEmailConfirm;
  @ViewChild('password') password;

  @Input() email: any;

  currentEmail: string;
  changeEmailForm: FormGroup;
  emailsMatch: boolean = true;


  constructor(
    public modalController: ModalController,
    private activateAccountService: ActivateAccountService,
    public accountService: AccountService,
    private modalService: ModalService,
    private formValidationService: FormsValidationService,
    private _bhAnalyticsService: BhanalyticsService,
    public AWSTranslate: AwsTranslateService
  ) {
    this.changeEmailForm = new FormGroup({
      Password: new FormControl('', Validators.required),
      NewEmail: new FormControl('', [Validators.required, this.formValidationService.getEmailValidator()]),
      NewEmailConfirm: new FormControl('', [Validators.required, this.formValidationService.getEmailValidator()])
    });
  }

  async ionViewWillEnter() {
    this.onChangeEmailFormChange();
  }


  dismiss() {
    this.modalController.dismiss();
  }

  onChangeEmailFormChange(): void {

    this.changeEmailForm.valueChanges.subscribe(val => {
      this.emailsMatch = val.NewEmail == val.NewEmailConfirm;
    })


  }

  async changeEmail() {
    Object.keys(this.changeEmailForm.controls).forEach(field => {
      const control: any = this.changeEmailForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    if (this.changeEmailFormComplete()) {
      let emailChangeRequest = new EmailChangeRequest();
      emailChangeRequest.Password = this.changeEmailForm.controls.Password.value;
      emailChangeRequest.Email = this.changeEmailForm.controls.NewEmail.value;
      emailChangeRequest.Reason = '';

      const response = await this.accountService.changeEmail(emailChangeRequest);
      if (response) {
        this.modalService.open("Success", "Your email has been updated.", ModalType.BASIC);
        this.modalController.dismiss({
          'email': this.changeEmailForm.controls.NewEmail.value
        });
      }
      this._bhAnalyticsService.clickEvent('Submit Change Email', emailChangeRequest.Email);
    } else {
      let focusDone = false;
      Object.keys(this.changeEmailForm.controls).forEach(field => {
        const control: any = this.changeEmailForm.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
        control.updateValueAndValidity();
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement != undefined && typeof control.nativeElement.setFocus === "function") {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }
  }

  changeEmailFormComplete(): boolean {
    return this.changeEmailForm.valid && this.emailsMatch;
  }

}
