import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BhanalyticsService } from '../../../service/bhanalytics.service';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

/**
 * ID: bh-feedback-page
 * Name: BH Feedback Page
 * Description: Simple NPS rating tool that sends data to our bh-analytics engine.  Captures user satisfaction.
 * Version: 1
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 */
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackPageComponent implements OnInit {
  env = environment;
  @Input() feedbackHeading = 'How likely are you to recommend ' + this.env.APPNAME + ' to a colleague?';
  @Input() feedbackCode: string;
  @Input() userId: string;
  @Input() lowRatingLabel = 'Not likely';
  @Input() highRatingLabel = 'Extremely likely';
  selectedRating = null;
  form1: FormGroup = this.formBuilder.group({
    feedbackText: [null],
  });
  showErrorMessage = false;
  submitAttempted = false;
  validationMessages = {
    feedbackText: [{ type: 'required', message: 'Field is required.' }],
  };
  placeholderText = '';
  currentView: 'form' | 'confirmation' = 'form';

  constructor(
    private modalCtrl: ModalController,
    private analytics: BhanalyticsService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
  }

  setRating(rating) {
    this.analytics.clickEvent('feedback: selected-rating', rating);
    if (rating < 7) {
      this.placeholderText = 'What was missing or disappointing in your experience? (Optional)';
    } else {
      this.placeholderText = 'What do you like the most about your experience? (Optional)';
    }
    this.selectedRating = rating;
  }

  submit() {
    const feedbackText = this.form1.controls.feedbackText.value;
    this.analytics.customEvent('nps-feedback-' + this.feedbackCode.toLowerCase(), this.selectedRating, this.userId + '|' + feedbackText);
    this.currentView = 'confirmation';
  }

  dismiss() {
    this.analytics.clickEvent('feedback: dismiss', '');
    this.modalCtrl.dismiss();
  }

}
