import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import constantsJson from '../../assets/json/constants.json';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  public constantsData: any;

  constructor() {
    this.constantsData = constantsJson;

    // switch to production values if compiling for production
    if (environment.production) {
      const prodValueKeys = Object.keys(this.constantsData.prodValues);
      for (const key of prodValueKeys) {
        this.constantsData[key] = this.constantsData.prodValues[key];
      }
    }
  }

  public getTimeout(isprivateDevice: boolean): number {
    if (isprivateDevice) {
      return this.constantsData.timeoutInSecondsPrivateDevice;
    } else {
      return this.constantsData.timeoutInSeconds;
    }
  }

  public getWarning(isprivateDevice: boolean): number {
    if (isprivateDevice) {
      return this.constantsData.timeoutWarningInSecondsPrivateDevice;
    } else {
      return this.constantsData.timeoutWarningInSeconds;
    }
  }

}
