import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AwsTranslateService } from '../../../../service/aws-translate.service';

@Component({
  selector: 'app-locked-tile-modal',
  templateUrl: './locked-tile-modal.component.html',
  styleUrls: ['./locked-tile-modal.component.scss'],
})
export class LockedTileModalComponent implements OnInit {

  constructor( private _modalController: ModalController, public AWSTranslate: AwsTranslateService) { }

  ngOnInit() {}


  public async dismiss(){
    await this._modalController.dismiss();
  }
}
