import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Workflow} from "../../../../model/Workflow";
import {SmcTableData} from "../../../../model/SmcTableData";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router, NavigationEnd} from "@angular/router";
import {PrescriptionStateService} from "../../../../service/prescription-state.service";
import {PrescriptionService} from "../../../../service/prescription.service";
import {Pharmacy} from "../../../../model/Pharmacy";
import {SmcTableComponent} from "../../../shared/smc-table/smc-table.component";
import {BhanalyticsService} from "../../../../service/bhanalytics.service";

@Component({
  selector: 'app-rx-select-pharmacy',
  templateUrl: './rx-select-pharmacy.component.html',
  styleUrls: ['./rx-select-pharmacy.component.scss'],
})
export class RxSelectPharmacyComponent  implements OnInit {

  public data: SmcTableData;
  public nextPageStateDisabled: boolean = false; //true;
  public search:string;

  displaySubmitError = false;

  patientForm: FormGroup = new FormGroup(
    {
      pharmacyNotListed: new FormControl(),
      pharmacyText: new FormControl(),
    }
  );

  public workflows: Workflow[] = [
    { Title: "Select medicine", Content: "", State: "past" },
    { Title: "Select provider", Content: "", State: "past" },
    { Title: "Select pharmacy", Content: "", State: "current" },
    { Title: "Your contact info", Content: "", State: "future" },
    { Title: "Review & submit", Content: "", State: "future" }
  ];

  @ViewChild(SmcTableComponent) smcTableComponent: SmcTableComponent;
  @ViewChild('radioButtonTemplate') radioButtonTemplate: ElementRef;
  @ViewChild('pharmacyLocation') pharmacyLocation: ElementRef;
  @ViewChild('favoritePharmacyTemplate') favoritePharmacyTemplate: ElementRef;

  constructor(private _prescriptionService: PrescriptionService,
              private _prescriptionStateService: PrescriptionStateService,
              public _router : Router,
              private _bhAnalyticsService: BhanalyticsService) {
                this._router.events.subscribe((event) => {
                  if (event instanceof NavigationEnd && event.id === 1 &&
                    event.url === event.urlAfterRedirects
                  ) {
                    this._router.navigateByUrl('/authenticated/health-record/medicines/request-prescription/rx-select-medicine');
                  }
                });
              }

  async ngOnInit() {
    this.data = new SmcTableData();
    this.data.columns = [
      { name:'', cellTemplate:this.radioButtonTemplate  ,maxWidth:50, collapseCol: 0 },
      { name:'Pharmacy', prop:'Name', cellClass: 'card-bold', collapseCol: 1, collapseMinWidth: 200   },
      { name:'Location', prop: 'id',cellTemplate:this.pharmacyLocation, cellClass: 'card-detail', collapseCol: 1, collapseMinWidth: 200   },
      { name:'Favorite?', prop:'Preferred', cellTemplate:this.favoritePharmacyTemplate, width:100, cellClass: 'favorite-column', collapseCol: 2}
    ];
    this.data.rows = [];

    await this.searchPharmacy('');

    console.log('patientForm', this.patientForm);
    console.log('load workflow contents', this.workflows);


    this._prescriptionStateService.loadForm(this.patientForm);
    this._prescriptionStateService.updateWorkflowContents(this.workflows);
    this.formStateChange();
    console.log('data', this.data);
  }

  formStateChange() {
    //this.nextPageStateDisabled = true;
    if (this.patientForm.get("pharmacyNotListed").value == true) {
      this.patientForm.get("pharmacyText").enable();
      this.patientForm.get("pharmacyText").setValidators([Validators.required]);
      document.getElementById("pharmacyTextDiv").style.display = 'block';
      this.smcTableComponent.selected = [];
      //deselect the table row.
      if (this.patientForm.get("pharmacyText").value) {
        //this.nextPageStateDisabled = false;
      }
    } else {
      this.patientForm.get("pharmacyText").disable();
      this.patientForm.get("pharmacyText").setValidators([]);
      document.getElementById("pharmacyTextDiv").style.display = 'none';
      if (this.smcTableComponent.selected.length > 0) {
        //this.nextPageStateDisabled = false;
      }
    }
  }

  previousPage() {
    this._prescriptionStateService.saveForm(this.patientForm);
    this._router.navigateByUrl('/authenticated/health-record/medicines/request-prescription/rx-select-provider');
  }

  nextPage() {
    if (this._prescriptionStateService.state.pharmacy ||
      (this.patientForm.get("pharmacyNotListed").value == true && this.patientForm.valid)) {
      this.displaySubmitError = false;
      this._prescriptionStateService.saveForm(this.patientForm);
      this._router.navigateByUrl('/authenticated/health-record/medicines/request-prescription/rx-contact-info');
    } else if (!this._prescriptionStateService.state.pharmacy && this.patientForm.get("pharmacyNotListed").value == false) {
      this.displaySubmitError = true;
    } else {
      this.displaySubmitError = false;
      let focusDone = false;
      Object.keys(this.patientForm.controls).forEach(field => {
        const control: any = this.patientForm.get(field);
        control.markAsTouched({ onlySelf: true });
        if (!focusDone && control.errors) {
          setTimeout(() => {
            if (control.nativeElement != undefined && typeof control.nativeElement.setFocus === "function") {
              // safe to use the function
              control.nativeElement.setFocus();
            }
          }, 500);
          focusDone = true;
        }
      });
    }
  }

  async searchPharmacy(data) {
    if(data) {
      this.search = data;
      console.log("SEARCH: " + data);
      this._bhAnalyticsService.searchEvent(data);
      this.smcTableComponent.rows = await this._prescriptionService.getPharmacies(data);
      this.formStateChange();
    } else {
      this.smcTableComponent.rows = await this._prescriptionService.getFavoritePharmacies();
      console.log('smc row values', this.smcTableComponent.rows);

    }
  }

  async addToFavorites(row){
    this._bhAnalyticsService.clickEvent('add to pharmacy favorites', row.id);
    await this._prescriptionService.addFavoritePharmacy(row.id);
    await this.searchPharmacy(this.search );
  }

  async removeFromFavorites(row){
    this._bhAnalyticsService.clickEvent('remove from pharmacy favorites', row.id);
    await this._prescriptionService.deleteFavoritePharmacy(row.id);
    await this.searchPharmacy(this.search );
  }

  selectPharmacy(data) : void {
    this._bhAnalyticsService.clickEvent('select pharmacy', data.selected[0]);
    //console.log("SELECTED PHARM: " + data.selected[0].id);
    //this._prescriptionStateService.state.pharmacy = data.selected[0].id;
    this._prescriptionStateService.state.pharmacy = JSON.stringify(data.selected[0]);
    this.patientForm.get("pharmacyNotListed").setValue(false);
    this.formStateChange();
  }

}
