import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { SmcTableData } from "src/app/model/SmcTableData";
import { MessagesService } from "src/app/service/messages.service";
import { Router } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { PreviousRouteService } from "src/app/service/previous-route.service";
import { AlertController, ModalController } from "@ionic/angular";
import { ModalService } from "src/app/service/modal.service";
import { BhanalyticsService } from "src/app/service/bhanalytics.service";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { MessageModalComponent } from "../message-modal/message-modal.component";
import { Message } from "src/app/model/Messages";

// export interface ArchivedMessages {
//   unread: boolean;
//   from: string;
//   subject: string;
//   date: string;
//   id: number;
//   hasAttachments: number;
//   isSent: boolean;
// }

@Component({
  selector: "app-archived-messages",
  templateUrl: "./archived-messages.component.html",
  styleUrls: ["./archived-messages.component.scss"],
})
export class ArchivedMessagesComponent {
  messages: Message[] = [];

  constructor(
    private messagesService: MessagesService,
    private router: Router,
    private modalCtrl: ModalController,
  ) {}

  viewMessage(event): void {
    // this.router.navigate(['authenticated', 'messages', 'archived-message', event.selected[0].id]);
    this.router.navigate([
      "authenticated",
      "messages",
      "archived",
      "view-message",
      event.selected[0].id,
    ]);
  }

  async ngOnInit() {
    this.getArchivedMessages();
  }

  async getArchivedMessages() {
    this.messages = [];
    try {
      let messages = [];
      const recRes = (await this.messagesService.archivedMessagesList()).received.children
        .map((item) => ({
          unread: !item.IsRead,
          from: item.From,
          subject: item.Subject,
          date: item.Time,
          id: item.id,
          hasAttachments: item.HasAttachments,
          isSent: false,
        }));
      if (Array.isArray(recRes) && recRes.length > 0) {
        console.log("getArchivedMessages: received", recRes);
        messages = recRes;
      }
      const sentRes = (await this.messagesService.archivedMessagesList()).sent.children
      .map((item) => ({
        unread: !item.IsRead,
        from: item.From,
        subject: item.Subject,
        date: item.Time,
        id: item.id,
        hasAttachments: item.HasAttachments,
        isSent: true,
      }));
    if (Array.isArray(sentRes) && sentRes.length > 0) {
      console.log("getArchivedMessages: sent", sentRes);
      messages = messages.concat(sentRes);
      messages.sort((a, b) => (b.date > a.date ? 1 : -1));
    }

    this.messages = messages;

    } catch (error) {
      throw error;
    }
  }
}
