import { forEach } from '@angular-devkit/schematics';
import { filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, ComponentRef, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConstantsService } from 'src/app/service/constants.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SymptomCheckerService } from 'src/app/service/symptom-checker.service';
import { AuthenticateService } from 'src/app/service/authenticate.service';
import { SymptomCheckerStateService } from '../../../../service/symptom-checker-state.service';
import { ModalService } from 'src/app/service/modal.service';
import { ModalType } from 'src/app/model/ModalTypeEnum';
import { Workflow } from '../../../../model/Workflow';
import { MessagesService } from 'src/app/service/messages.service';
import { questionTextMap } from '../sc-utils/questionTextMapMethods';
import { RequestAppointmentService } from 'src/app/service/request-appointment.service';
import { insertStylesIntoIonSegmentShadowRoot } from '../sc-utils/ionSegmentShadowMethod';
import { generateDiagnosesList } from '../sc-utils/generateDiagnosesList';
import { MatSelectChange } from '@angular/material';
import { PrintService } from 'src/app/service/print.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AwsTranslateService } from '../../../../service/aws-translate.service';

type FormState = 'send-symptoms' | 'find-care';
type PageState = 'severityQuestions' | 'diagnosis' | 'care-options';
type CareOptions =  'careOptions' | 'symptoms' | 'location' | 'covidWarning' | 'covidAppointmentConfirmation';
type DiagnosisFilters = 'show-all' | 'common' | 'red-flag';


@Component({
  selector: 'app-sc-causes',
  templateUrl: './sc-causes.component.html',
  styleUrls: ['./../symptom-checker.component.scss'],
})

export class ScCausesComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private symptomCheckerStateService: SymptomCheckerStateService,
    private constantsService: ConstantsService,
    private auth: AuthenticateService,
    private sanitizer: DomSanitizer,
    private modalService: ModalService,
    private messagesService: MessagesService,
    private symptomCheckerService: SymptomCheckerService,
    private requestAppointmentService: RequestAppointmentService,
    private printService: PrintService,
    private router: Router,
    private AWSTranslate: AwsTranslateService
  ) {
    // Get Form state from url and set it to state service
    this.activatedRoute.params.subscribe(params => { this.currentFormState = params.formState; });
    this.symptomCheckerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.constantsService.constantsData.symptomCheckerUrl);
    // If being redirected within the form wizard, send user back to first section
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.id === 1 &&
        event.url === event.urlAfterRedirects
      ) {
        this.router.navigateByUrl('/authenticated/resources/symptom-checker/sc-basic-info/' +  this.currentFormState);
      }

    });
  }

  @ViewChild('covidMedChat') covidMedChat: ElementRef;
  @ViewChild('printSection') printSection: ElementRef;

  // apply styling inside IonSegment shadow roots (ionic graphical issue fix)
  @ViewChild('q2') private set setQ2(component)               { insertStylesIntoIonSegmentShadowRoot(component); }
  @ViewChild('q3') private set setQ3(component)               { insertStylesIntoIonSegmentShadowRoot(component); }
  @ViewChild('q6') private set setQ6(component)               { insertStylesIntoIonSegmentShadowRoot(component); }
  @ViewChild('q7') private set setQ7(component)               { insertStylesIntoIonSegmentShadowRoot(component); }

  expandList = false;
  selectedOpt = false;
  selectedFilter: DiagnosisFilters;
  basicInfo: any;
  showDiagnosisList = true;
  showLoadingSpinner: boolean;
  showSeverityDesc = false;
  showSymptomDesc = false;
  authId: number;
  showDisc: boolean;
  currentFormState: FormState;
  showEmptyFieldsError: boolean;
  showIsabelEmptyFieldsError: boolean;
  suggestedDiagnosis: any;
  filteredDiagnosis: any;
  symptomCheckerUrl: SafeResourceUrl;
  // Hardcode 'Primary Care Providers' and pass into provider url.
  defaultSymptomValue = 'primary care providers';
  dropDownValues: any;
  triageResults: any;
  predictiveText: string[] = [];
  suggestions: string[] = [];
  currentCareOpt: CareOptions;
  pageState: PageState;
  showSymptomError = false;
  symptomString = '';
  symptoms: any[];
  medChatScript: HTMLScriptElement;

  public questionTextMap = questionTextMap();

  // public savedQuestionAnswers: Map<number, string> = new Map([]);
  public savedQuestionAnswers: Map<number, string>;

  public severityForm: FormGroup = new FormGroup({
    q1: new FormControl('', Validators.required),
    q2: new FormControl('', Validators.required),
    q3: new FormControl('', Validators.required),
    q4: new FormControl('', Validators.required),
    q5: new FormControl('', Validators.required),
    q6: new FormControl('', Validators.required),
    q7: new FormControl('', Validators.required)
  });

  public workflows: Workflow[] = [
    { Title: 'Tell us about your symptoms', Content: '', State: 'past' },
    { Title: 'Possible causes', Content: '', State: 'current' },
    { Title: 'Where to get care?', Content: '', State: 'future' },
  ];

  async ngOnInit() {
    this.changeForm();
    this.symptomCheckerStateService.getCurrentSectionFormData(this.severityForm);
    this.basicInfo = this.symptomCheckerStateService.getCurrentState();
    this.symptoms = this.symptomCheckerStateService.getSymptoms();

    const suggestedDiagnosis = this.symptomCheckerStateService.getDiagnosesList();
    this.suggestedDiagnosis = suggestedDiagnosis;

    if (this.suggestedDiagnosis) {
      // handle no diagnoses returned by isabel
      if (this.suggestedDiagnosis.diagnosis_checklist.please_note) {
        this.showSymptomError = true;
      }
      if (!Array.isArray(this.suggestedDiagnosis.diagnosis_checklist.diagnosis)) {
        this.suggestedDiagnosis.diagnosis_checklist.diagnosis = [this.suggestedDiagnosis.diagnosis_checklist.diagnosis];
      }
      const covidId = '14964';
      const covidDiagnoses = this.suggestedDiagnosis.diagnosis_checklist.diagnosis.filter(diagnosis => diagnosis.diagnoses_id === covidId);
      const covidUrl = 'https://providers.baystatehealth.org/search?unified=baystate%20telehealth&sort=networks%2Crelevance%2Cavailability_density_best';
      covidDiagnoses.forEach(covidDiagnosis => covidDiagnosis.knowledge_window_url = covidUrl);

      // check for covid in top 5
      for (let i = 0; i < 10; i++) {
        if (this.suggestedDiagnosis.diagnosis_checklist.diagnosis[i].diagnoses_id == covidId) {
          this.currentCareOpt = 'covidWarning';
          // Append MedChat script.
          this.symptomCheckerStateService.appendMedChatScript();
          // Allow widget to load before storing elements.
          setTimeout(() => {
            const chatBtn = (document.querySelector('#medchat-launcher-frame') as HTMLElement);
            const chatRoom = (document.querySelector('#medchat-chat-frame') as HTMLElement);
            if (chatRoom) {
              this.symptomCheckerStateService.storeMedChatElements(chatBtn, chatRoom);
            }
          }, 1200);
          return;
        }
      }
    }
    // Default filters to 'show all'
    this.selectedFilter = 'show-all';
  }

  changeForm() {
    if (this.currentFormState === 'find-care') {
      this.workflows[2].Title =  'Possible causes';
      this.workflows[1].Title = 'Where to get care?';
      this.constantsService.constantsData.pageNameLookup['sc-causes'] = 'Severity Level';
    } else {
      this.pageState = 'diagnosis';
      this.constantsService.constantsData.pageNameLookup['sc-causes'] = 'Symptoms';
    }
  }

  sendToProvider(q1: string, q2: string, q3: string, q4: string, q5: string, q6: string, q7: string) {
    // Refactor validation and save logic into sep method.
    const fieldsToValidate = [q1, q2, q3, q4, q5, q6, q7];
    this.showEmptyFieldsError = fieldsToValidate.some(argument => argument == null || argument == '');
    this.savedQuestionAnswers = new Map([
      [1, q1],
      [2, q2],
      [3, q3],
      [4, q4],
      [5, q5],
      [6, q6],
      [7, q7],
    ]);

    if (this.showEmptyFieldsError) {
      return;
    }
  }

  // Select severity.
  setSelectedAnswer(selectedRadio: any, question: string, answer: string) {
    selectedRadio.target.children[0].checked = true;
    // Set selected answer based on question and answer passed.
    this.severityForm.get(question).setValue(answer);
  }

  matSelectChange(event: MatSelectChange) {
    event.source._elementRef.nativeElement.parentElement.parentElement.parentElement.classList.add('value-selected');
  }

  previousPage() {
    this.router.navigateByUrl('/authenticated/resources/symptom-checker/sc-basic-info/' + this.currentFormState);
  }

  navigateToNextSection() {
    this.router.navigateByUrl('/authenticated/resources/symptom-checker/sc-findcare/' + this.currentFormState);
  }

  getBasicInfoText(answer: string, infoId: string) {
    let returnTxt = '';
    const dropdownObj = this.symptomCheckerStateService.getDropdownList();
    if (dropdownObj !== undefined) {
      switch (infoId) {
        case 'age_groups':
          dropdownObj.age_groups.age_group.forEach((age) => {
            if (age.agegroup_id === answer) {
              returnTxt = age.name + ' (' + age.yr_from + ' - ' + age.yr_to + ')';
            }
          });
          break;
        case 'travel_history':
          dropdownObj.travel_history.region.forEach((region) => {
            if (region.region_id === answer) {
              returnTxt = region.region_name;
            }
          });
          break;
      }
    }
    return returnTxt;
  }

  nextPage() {
    this.updateWorkflowOnChange();
    this.pageState = 'severityQuestions';
  }

  backToSeverityQuestions() {
    this.pageState = 'severityQuestions';
  }

  // Update workflows.
  updateWorkflowOnChange() {
    const updateWorkFlowPast = this.workflows;
    updateWorkFlowPast.splice(1, 1, {Title: this.workflows[1].Title, Content: '', State: 'past'});
    updateWorkFlowPast.splice(2, 2, {Title: this.workflows[2].Title, Content: '', State: 'current'});
    this.workflows = updateWorkFlowPast;
  }

  async getResults(btnClicked: string) {
    if (!this.severityForm.valid) {
      this.showEmptyFieldsError = true;
      return;
    } else {
      this.showEmptyFieldsError = false;
    }

    // Update state and direct user to next form section.
    this.symptomCheckerStateService.setCurrentSectionFormData(this.severityForm);
    this.symptomCheckerStateService.mapAnswerToQuestion(this.savedQuestionAnswers);
    this.updateWorkflowOnChange();

    this.auth.loadFreshAuthStatus().subscribe(async authStatus => {
      const returnedTriageUrl: string = this.suggestedDiagnosis.diagnosis_checklist.triage_api_url;
      const dx = returnedTriageUrl.match(/&dx=[0-9,]*&/)[0].slice(4, -1);
      const currentFormObj =  this.symptomCheckerStateService.getCurrentState();
      this.triageResults = await this.symptomCheckerService.triage(currentFormObj, authStatus.EffectiveUserID, dx);
      if (this.currentFormState === 'send-symptoms') {
        if (btnClicked === 'find-care') {
          this.router.navigateByUrl('/authenticated/resources/symptom-checker/sc-findcare/' + this.currentFormState);
        } else {
          this.generateProviderMsg();
        }
      } else {
        this.pageState = 'care-options';
        this.showLoadingSpinner = true;
        this.styleMedChat();
      }
    });
  }

  requestTeleheathAppointment(email: string, phone: string, comments: string) {
    this.showIsabelEmptyFieldsError = !(email || phone);
    if (this.showIsabelEmptyFieldsError) {
      return;
    }
    this.auth.loadFreshAuthStatus().subscribe(async authStatus => {
      await this.requestAppointmentService.requestAppointment(JSON.stringify({
        To: this.constantsService.constantsData.symptomCheckerCovid19PoolID,
        From: authStatus.PatientName,
        Subject: `COVID-19 Telehealth Appointment Request for ${authStatus.PatientName}`,
        Attachments: [],
        Text:
          `${authStatus.PatientName} has requested a Telehealth appointment due to symptoms of COVID-19
          Email: ${email}
          Phone: ${phone}
          Symptoms provided by patient: ${this.symptoms.join(', ')}
          Additional Comments:
          ${comments}`
      }))
      .then(success => {
        this.modalService.open(
          'Telehealth Request Sent',
          `Thank you for requesting an appointment! You will see an appointment in your Baystate Health Connect app shortly.
          If you don't see your scheduled appointment within 24 hours, please call your provider to find out the status of your appointment.
          Here are some additional materials for your review:
          <ul>
            <li>
              <a href="https://www.baystatehealth.org/medical-services/covid19" target="_blank">Baystate Health COVID-19 Information Page</a>
            </li>
            <li>
              <a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html" target="_blank">CDC Covid-19
              Symptom Checker</a>
            </li>
          </ul>
          `,
          ModalType.BASIC
        );
      })
      .catch(err => {
        console.log(err);
      });
      this.currentCareOpt = 'covidAppointmentConfirmation';
    });
  }
  // Send message to provider.
  generateProviderMsg() {
    // Update state and direct user to next form section.
    this.symptomCheckerStateService.mapAnswerToQuestion(this.savedQuestionAnswers);
    console.log('generateProviderMsg: questionTextMap', this.questionTextMap);
    console.log('generateProviderMsg: savedQuestionAnswers', this.savedQuestionAnswers);
    const getQuestionResponse = (n: number) => this.questionTextMap.get(n).options.find(option => option.code == this.savedQuestionAnswers.get(n)).text;
    this.messagesService.openPrepopulatedMessage({
      subject: 'My Symptoms',
      body:
        `I am experiencing the following symptoms: ${this.symptomString}.

        Questionnaire:
        1. How quickly did your symptoms develop?
        ${getQuestionResponse(1)}
        2. How long have you had your symptoms?
        ${getQuestionResponse(2)}
        3. How have your symptoms changed over the last few hours/days?
        ${getQuestionResponse(3)}
        4. How much pain or discomfort are you in?
        ${getQuestionResponse(4)}
        5. How are your symptoms affecting your daily activities?
        ${getQuestionResponse(5)}
        6. Do you feel better after taking medication for your symptoms?
        ${getQuestionResponse(6)}
        7. Do you have any other serious, long term conditions such as diabetes, cancer, heart condition etc?
        ${getQuestionResponse(7)}


        Additional Comments:

        `
    });
  }

  styleMedChat() {
    // Get MedChat elements.
    const medChatElements = this.symptomCheckerStateService.getMedChatElements();
    const chatBtn = (medChatElements.medChatBtn as HTMLElement);
    const chatRoom = (medChatElements.medChatRoom as HTMLElement);

    // Move MedChat elements into component.
    // Allow time to load.
    setTimeout(() => {
      // Display widget.
      chatBtn.style.display = 'block';
      chatRoom.style.display = 'block';

      document.querySelector('#chat-box').appendChild(chatBtn);
      document.querySelector('#chat-box').appendChild(chatRoom);
      this.showLoadingSpinner = false;
    }, 100);
  }

  openUrlInNewTab(url: string) {
    window.open(url, '_blank', 'noopener');
  }

  // Generates ordered list of diagnoses, based on filters applied.
  printDiagnosis() {
    // Get list filtered selected and check what to list by.
    const generatedList = generateDiagnosesList(this.suggestedDiagnosis.diagnosis_checklist.diagnosis, this.selectedFilter);
    this.printService.printHtmlString(generatedList);
  }
}
