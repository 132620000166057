import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(private _httpBaseService: HttpBaseService,
    private _datePipe: DatePipe
    ) { }

  async getUpcomingEvents() {
    const response = await this._httpBaseService.post({Format: "events",StartDate:this._datePipe.transform(new Date(),'yyyy-M-dd')}, '/calendar/events');
    return response['Events'];
  }
}
