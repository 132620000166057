import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'smc-form-field',
  templateUrl: './smc-form-field.component.html',
  styleUrls: ['./smc-form-field.component.scss'],
})
export class SmcFormFieldComponent implements OnInit {

  @Input() public formControlNameAtr: string;
  @Input() public group: FormGroup;
  @Input() public label: string;
  @Input() public labelColumnWidth: string;
  @Input() public inputColumnWidth: string;
  @Input() public required: string; // display a red asterisk with label only
  @Input() public displayErrors: string;
  @Input() public requiredError: string;
  @Input() public patternError: string;
  @Input() public displayGenericError: string;
  @Input() public genericError: string;
  @Input() public noUnderline: string;

  constructor() { }

  ngOnInit() {
    if (!this.displayErrors) {
      this.displayErrors = 'false';
    }

    if (!this.labelColumnWidth) {
      this.labelColumnWidth = '200px';
    }

    if (!this.inputColumnWidth) {
      this.inputColumnWidth = '70%';
    }

    if (this.label == null) {
      this.inputColumnWidth = '100%';
    }

    if (!this.noUnderline) {
      this.noUnderline = 'false';
    }
  }

  grabFocus() {
    const control: any = this.group.get(this.formControlNameAtr);
    if (control.nativeElement != undefined) {
      setTimeout(() => {
        if (control.nativeElement != undefined && typeof control.nativeElement.setFocus === 'function') {
          // safe to use the function
          control.nativeElement.setFocus();
        }
      }, 500);
    }
  }
}
