import { Injectable } from "@angular/core";
import { ConstantsService } from "./constants.service";
import { HttpClient } from "@angular/common/http";
import {
  MessageList,
  Message,
  RecipientSearchQuery,
  RecipientList,
  UploadResponse,
  ScanStatus,
  UploadedDocument,
  SendableMessage,
  ReplyMessage,
  MessageRecord,
} from "../model/Messages";
import { Router } from "@angular/router";

interface PrepopulatedMessage {
  subject: string;
  body: string;
}

@Injectable({
  providedIn: "root",
})
export class MessagesService {
  constructor(
    private constantsService: ConstantsService,
    private httpClient: HttpClient,
    private _router: Router
  ) {}
  testResults: any;
  prepopulatedMessage: PrepopulatedMessage;

  cleanMessage(msg: string): string {
    try {
      if (msg !== undefined && msg) {
        //const decodedMsg = decodeURIComponent(escape(msg));
        const escapeMsg = escape(msg)
        //console.log('escape Message', escapeMsg);

        const modifiedEscapeMsg = escapeMsg.replace(/%u2018/g, '%27') //single left quote
                                           .replace(/%u201C/g, '%22') //double left quote
                                           .replace(/%u201D/g, '%22') //double right quote
                                           .replace(/%u2019/g, '%27') //single right quote
                                           .replace(/%u2013/g, '%2D') // dash
                                           //.replace(/%20/g, '%u20'); //empty space

        //console.log('modified Escape Message', modifiedEscapeMsg);

        const decodedMsg = decodeURIComponent(modifiedEscapeMsg);
        //console.log('decoded Message', decodedMsg);

        const string1 = /\‘/gi;
        const string2 = /\’/gi;
        const string3 = /\`/gi;
        const string4 = /\“/gi;
        const string5 = /\”/gi;
        const cleanedMsg = decodedMsg
          .replace(string1, "'")
          .replace(string2, "'")
          .replace(string3, "`")
          .replace(string4, '"')
          .replace(string5, '"');
        //console.log("cleaned msg", cleanedMsg);

        return cleanedMsg;
      } else {
        return msg;
      }
    } catch (err) {
      //console.error("Error occurred while replacing subjects", err, msg);
      return msg;
    }
  }

  cleanMessageList(msgList: MessageList): MessageList {
    const newMsgList = Object.assign({}, msgList);
    if (newMsgList && newMsgList.children && newMsgList.children.length > 0) {
      newMsgList.children.forEach((m) => {
        m.Subject = this.cleanMessage(m.Subject);
      });
    }
    newMsgList.cleaned = true;
    return newMsgList;
  }

  async inbox(): Promise<MessageList> {
    try {
      const url =
        this.constantsService.constantsData.apiUrl + "/messages/folders/Inbox";
      const msgList = (await this.httpClient
        .get(url)
        .toPromise()) as MessageList;
      // console.log('inbox: before cleaning', msgList);
      const cleanedMsgList = this.cleanMessageList(msgList);
      // console.log('inbox: after cleaning', cleanedMsgList);
      return Promise.resolve(cleanedMsgList);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async archivedMessagesList(): Promise<{
    received: MessageList;
    sent: MessageList;
  }> {
    try {
      const receivedUrl =
        this.constantsService.constantsData.apiUrl +
        "/messages/folders/InboxArchive";
      const sentUrl =
        this.constantsService.constantsData.apiUrl +
        "/messages/folders/SentArchive";
      const responses: MessageList[] = await Promise.all([
        this.httpClient.get(receivedUrl).toPromise() as Promise<MessageList>,
        this.httpClient.get(sentUrl).toPromise() as Promise<MessageList>,
      ]);
      const receivedMsgList = this.cleanMessageList(responses[0]);
      const sentMsgList = this.cleanMessageList(responses[1]);
      const response = { received: receivedMsgList, sent: sentMsgList };
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async message(id: number): Promise<MessageRecord> {
    try {
      const url =
        this.constantsService.constantsData.apiUrl + "/messages/" + id;
      const msg = (await this.httpClient.get(url).toPromise()) as MessageRecord;
      msg.Text = this.cleanMessage(msg.Text);
      msg.Subject = this.cleanMessage(msg.Subject);
      return Promise.resolve(msg);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  archiveMessages(ids: number[]): Promise<Object> {
    const url =
      this.constantsService.constantsData.apiUrl + "/messages/archive";
    const body = { ids };
    return this.httpClient.post(url, body).toPromise();
  }

  unarchiveMessages(ids: number[]): Promise<Object> {
    const url =
      this.constantsService.constantsData.apiUrl + "/messages/unarchive";
    const body = { ids };
    return this.httpClient.post(url, body).toPromise();
  }

  async sentMessages(): Promise<MessageList> {
    try {
      const url =
        this.constantsService.constantsData.apiUrl + "/messages/folders/Sent";
      const msgList = (await this.httpClient
        .get(url)
        .toPromise()) as MessageList;
      // console.log('sentMessages: msgList: ', msgList);
      const cleanedMsgList = this.cleanMessageList(msgList);
      // console.log('sentMessages: cleanedMsgList: ', cleanedMsgList);
      return Promise.resolve(cleanedMsgList);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  searchProviders(query: RecipientSearchQuery): Promise<RecipientList> {
    const url =
      this.constantsService.constantsData.apiUrl +
      "/messages/search/recipients";
    const response = this.httpClient
      .post(url, query, { headers: { "SMC-Ignore-500-Error": "true" } })
      .toPromise();
    if (response === undefined) {
      const newResponse = [] as unknown;
      return newResponse as Promise<RecipientList>;
    }

    return response as Promise<RecipientList>;
  }

  sendReplyMessage(message: ReplyMessage): Promise<any> {
    // Clean subject and message body
    message.Subject = this.cleanMessage(message.Subject);
    message.Text = this.cleanMessage(message.Text);
    const url = this.constantsService.constantsData.apiUrl + "/messages/send";
    return this.httpClient.post(url, message).toPromise();
  }

  sendMedicalQuestion(message: SendableMessage): Promise<any> {
    // Clean subject and message body
    message.Subject = this.cleanMessage(message.Subject);
    message.Text = this.cleanMessage(message.Text);
    const url =
      this.constantsService.constantsData.apiUrl +
      "/messages/send/medicalQuestion";
    return this.httpClient.post(url, message).toPromise();
  }

  sendGeneralQuestion(message: SendableMessage): Promise<any> {
    // Clean subject and message body
    message.Subject = this.cleanMessage(message.Subject);
    message.Text = this.cleanMessage(message.Text);
    const url =
      this.constantsService.constantsData.apiUrl +
      "/messages/send/generalQuestion";
    return this.httpClient.post(url, message).toPromise();
  }

  getFavoriteProviders(
    messageType: "medical" | "general"
  ): Promise<RecipientList> {
    const url =
      this.constantsService.constantsData.apiUrl +
      "/messages/search/recipients";
    const response = this.httpClient
      .post(url, { Favorites: true, MessageType: messageType })
      .toPromise();
    if (response === undefined) {
      const newResponse = [] as unknown;
      return newResponse as Promise<RecipientList>;
    }
    return response as Promise<RecipientList>;
  }

  uploadDocument(file: File): Promise<UploadedDocument> {
    return new Promise(async (resolveDocumentUpload, rejectDocumentUpload) => {
      try {
        const uploadUrl =
          this.constantsService.constantsData.apiUrl + "/documents/upload";
        const formData = new FormData();
        formData.append("filename", file.name);
        formData.append("file", file);
        const uploadResponse = (await this.httpClient
          .post(uploadUrl, formData, {
            headers: { "SMC-Dont-Set-Content-Type": "true" },
          })
          .toPromise()) as UploadResponse;

        const documentStatusUrl =
          this.constantsService.constantsData.apiUrl +
          "/documents/status/" +
          uploadResponse.DocumentID;
        const maxStatusChecks = 10;

        const checkDocumentStatus = async (tryCount: number) => {
          const status = (await this.httpClient
            .get(documentStatusUrl)
            .toPromise()) as ScanStatus;
          if (status.ScanStatus === "PASS") {
            resolveDocumentUpload(
              Object.assign({ FileName: file.name }, status, uploadResponse)
            );
          } else if (
            status.ScanStatus === "IN_PROCESS" &&
            tryCount < maxStatusChecks
          ) {
            setTimeout(() => checkDocumentStatus(tryCount + 1), 1000);
          } else {
            rejectDocumentUpload("Issue scanning file for viruses");
          }
        };

        checkDocumentStatus(0);
      } catch (error) {
        rejectDocumentUpload(error);
      }
    });
  }

  getAttachmentDownloadUrl(id: string) {
    return `${this.constantsService.constantsData.apiUrl}/messages/attachments/${id}`;
  }

  openPrepopulatedMessage(message: PrepopulatedMessage) {
    message.body = this.cleanMessage(message.body);
    message.subject = this.cleanMessage(message.subject);
    this.prepopulatedMessage = message;
    this._router.navigate(["/authenticated/messages/compose-message"]);
  }
}
