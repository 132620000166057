import { Injectable } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { ModalComponent } from "../component/shared/modal/modal.component";
import { ModalType } from "../model/ModalTypeEnum";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private controller: ModalController) { }

  private _openModals: HTMLIonModalElement[] = [];

  public async open(title: string, body: string, type: ModalType) {
    const modal = await (this.controller.create({
      component: ModalComponent, componentProps: {
        title: title,
        body: body,
        modalType: type
      }
    }));
    await modal.present();
    this._openModals.push(modal);
    return modal;
  }

  /** Register a modal so it can be automatically closed when necessary (such as a session timeout) */
  public register(modal: HTMLIonModalElement) {
    this._openModals.push(modal);
  }

  public dismiss(modal) {
    return this.controller.dismiss(modal);
  }

  public dismissAllOpen() {
    const closedIndexes: number[] = [];
    this._openModals.forEach((modal, index) => {
      this.dismiss(modal);
      closedIndexes.push(index)
    });
    this._openModals = this._openModals.filter((_, index) => !closedIndexes.includes(index));
  }

  public async dismissModalById(id) {
    this.controller.dismiss(null, null, id);
  }






}
